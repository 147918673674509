import React, { memo } from "react";

const ModalBody: React.FC<any> = ({
  noPadding,
  children,
}: {
  noPadding?: boolean;
  children: any;
}) => {
  return (
    <div className={`noPadding${noPadding ? "" : "o-modal-body-noPadding"}`}>
      {children}
    </div>
  );
};

export default memo(ModalBody);
