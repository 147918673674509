import { LOCATION_DELIVERY_NAME, removeLocalStorageByName } from "./location";

export const CART_NAME = "cart";

// TODO:
// Trường hợp đăng nhập đăng xuất, hiện tại chưa thấy quản lý localstorage cho việc lưu theo từng user.
// Tạm thời clear localstorage cart khi đăng nhập thành công.
export const clearLocalCart = () => {
  removeLocalStorageByName(CART_NAME);
  removeLocalStorageByName(LOCATION_DELIVERY_NAME);
  // removeLocalStorageByName(DEFAULT_LOCATION_DELIVERY_NAME);
};

export default {};
