export type BreakPointSwiper = {
  [key: number]: {
    slidesPerView?: number | string;
    slidesPerGroup?: number;
    spaceBetween?: number;
    loopFillGroupWithBlank?: boolean;
    centeredSlides?: boolean;
    loop?: boolean;
    freeMode?: boolean;
  };
};

export const breakpointByPerViewItem = (
  perViewItem: number,
  mobileOnlyOneItem?: boolean,
): BreakPointSwiper => ({
  // check if turn on one items in mobile:
  320: {
    slidesPerView: mobileOnlyOneItem ? 1 : 2,
    slidesPerGroup: mobileOnlyOneItem ? 1 : 2,
    spaceBetween: mobileOnlyOneItem ? 0 : 20,
  },
  480: {
    slidesPerView: mobileOnlyOneItem ? 1 : 2,
    slidesPerGroup: mobileOnlyOneItem ? 1 : 2,
    spaceBetween: mobileOnlyOneItem ? 0 : 20,
  },

  // default breakpoints:
  // temporary unuse 768 breakpoint:
  768: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: perViewItem,
    slidesPerGroup: perViewItem,
    loopFillGroupWithBlank: true,
    spaceBetween: 20,
  },
});
export const COMMON = (perViewItem?: number): BreakPointSwiper =>
  breakpointByPerViewItem(perViewItem || 1);
export const HOME = (perViewItem: number): BreakPointSwiper =>
  breakpointByPerViewItem(perViewItem);
export const SEEN_PRODUCT = (): BreakPointSwiper => ({
  320: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 0,
  },
  480: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 2,
    slidesPerGroup: 1,
    spaceBetween: 20,
  },
  992: {
    slidesPerView: 3,
    slidesPerGroup: 1,
    spaceBetween: 20,
  },
  1200: {
    slidesPerView: 4,
    slidesPerGroup: 1,
    spaceBetween: 20,
  },
});
export const PROMOTION_PRODUCT = (itemLength: number): BreakPointSwiper => ({
  320: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
  },
  480: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
  },
  768: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 15,
    centeredSlides: itemLength < 3,
  },
  1024: {
    slidesPerView: 2,
    slidesPerGroup: 1,
    spaceBetween: 15,
    centeredSlides: itemLength < 3,
  },
  1200: {
    slidesPerView: 3,
    slidesPerGroup: 1,
    spaceBetween: 15,
    centeredSlides: itemLength < 3,
  },
});

export const SERVICE_PACKAGES = (): BreakPointSwiper => ({
  320: {
    slidesPerGroup: 1,
    centeredSlides: true,
    slidesPerView: "auto",
    spaceBetween: 10,
  },
  480: {
    slidesPerGroup: 1,
    centeredSlides: true,
    slidesPerView: "auto",
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 20,
  },

  1024: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    loopFillGroupWithBlank: true,
    spaceBetween: 20,
  },
});
export const SERVICE_PACKAGES_DESKTOP = (): BreakPointSwiper => ({
  320: {
    slidesPerView: 1,
  },
  480: {
    slidesPerView: 1,
  },
  575: {
    slidesPerView: 2,
  },
  768: {
    slidesPerView: 2,
  },
  992: {
    slidesPerView: 3,
  },
});
export const PRODUCTS_FEATURE = (): BreakPointSwiper => ({
  320: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
  },
  480: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
  },
  768: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 20,
  },

  1024: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 20,
  },
});
export const PRODUCT_DETAIL = (perViewItem: number): BreakPointSwiper =>
  breakpointByPerViewItem(perViewItem);
export const VIEWED_PRODUCT = (perViewItem: number): BreakPointSwiper =>
  breakpointByPerViewItem(perViewItem);
export const RATING_IMAGE_PRODUCT = (perViewItem: number): BreakPointSwiper =>
  breakpointByPerViewItem(perViewItem, true);
export const PRODUCTS_PROMOTION_DETAIL = (
  perViewItem: number,
): BreakPointSwiper => ({
  320: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
  },
  480: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 0,
  },
  576: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 0,
  },
  1024: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    loopFillGroupWithBlank: true,
    spaceBetween: 20,
  },
  1440: {
    slidesPerView: perViewItem,
    slidesPerGroup: perViewItem,
    loopFillGroupWithBlank: true,
    spaceBetween: 20,
  },
});

export const OTHER_PROMOTION_RELATIVE = (
  perViewItem: number,
): BreakPointSwiper => ({
  320: {
    slidesPerView: 1.2,
    slidesPerGroup: 1,
    spaceBetween: 20,
    centeredSlides: true,
  },
  480: {
    slidesPerView: 1.5,
    slidesPerGroup: 1,
    spaceBetween: 20,
    centeredSlides: true,
  },
  576: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    loopFillGroupWithBlank: true,
    spaceBetween: 20,
  },
  1440: {
    slidesPerView: perViewItem,
    slidesPerGroup: perViewItem,
    loopFillGroupWithBlank: true,
    spaceBetween: 20,
  },
});

export const CAROUSEL_IDS = {
  // 2 DÀN RỜI
  SPLIT: "home-caro-split",
  // MULTI
  MULTI: "home-caro-multi",
  // MÁY LỌC KHÔNG KHÍ
  AIR_PURIFIER: "home-caro-air-purifier",
  // CAO CẤP
  SKY_AIR: "home-caro-sky-air",
  // CAROUSEL CHI TIẾT SP
  DETAIL_RELATED_PRODUCTS: "detail-caro-related-product",
  // CAROUSEL CHI TIẾT SP
  SEEN_PRODUCTS: "seen-product",
  // CAROUSEL SERVICE PACKAGES
  SERVICE_PACKAGES: "service-packages-carousel",
  // RELATED_ACTICLE
  RELATED_ACTICLE: "related-acticle",
  // PRODUCTS_PROMOTION_DETAIL
  PRODUCTS_PROMOTION_DETAIL: "promotion-detail",
  // OTHER_PROMOTION_RELATIVE
  OTHER_PROMOTION_RELATIVE: "other-promotion-relative",
};
