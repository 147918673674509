export const REQUEST_FETCH_PAY_PAGE = "REQUEST_FETCH_PAY_PAGE";
export const FETCH_PAY_PAGE_SUCCESS = "FETCH_PAY_PAGE_SUCCESS";
export const FETCH_PAY_PAGE_ERROR = "FETCH_PAY_PAGE_ERROR";

export const requestFetchPayPage = () => ({
  type: REQUEST_FETCH_PAY_PAGE
});

export const fetchPayPageOnSuccess = (pay: any) => ({
  type: FETCH_PAY_PAGE_SUCCESS,
  pay
});

export const fetchPayPageOnError = (error: any) => ({
  type: FETCH_PAY_PAGE_ERROR,
  error
});