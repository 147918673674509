import { AnyAction } from "redux";
import {
  FETCH_CONTACT_PAGE_ERROR,
  FETCH_CONTACT_PAGE_SUCCESS,
  REQUEST_FETCH_CONTACT_PAGE,
} from "redux/actions/pages/contact";

const defaultState = {
  data: {
    contact: {},
  },
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_FETCH_CONTACT_PAGE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CONTACT_PAGE_SUCCESS:
      return {
        data: {
          ...state.data,
          contact: action.contact,
        },
        error: null,
        loading: false,
      };
    case FETCH_CONTACT_PAGE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
