import { fetchGuaranteeService } from "api/interna-api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchGuaranteeServicePageOnError,
  fetchGuaranteeServicePageOnSuccess,
  REQUEST_FETCH_GUARANTEE_SERVICE_PAGE,
} from "redux/actions/pages/guaranteeService";

function* fetchGuaranteeSerivePageFlow() {
  try {
    const data = yield call(fetchGuaranteeService);
    yield put(fetchGuaranteeServicePageOnSuccess(data));
  } catch (error) {
    yield put(fetchGuaranteeServicePageOnError(error));
  }
}

export default function* watchFetchGuaranteeServicePageSaga() {
  yield takeLatest(
    REQUEST_FETCH_GUARANTEE_SERVICE_PAGE,
    fetchGuaranteeSerivePageFlow,
  );
}
