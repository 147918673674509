import Loading from 'components/atoms/Loading';

import { fetchRedirect301Service } from 'api/interna-api';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';

interface RedirectDataTypes {
  success?: boolean,
  data?: {
    id: number,
    from: string,
    to: string
  } | null,
  error?: {
    code: string
  }
  message?: string
}

interface RedirectNavigateProps {
  errors?: unknown;
}

const Redirect301: React.FC<RedirectNavigateProps> = ({
  errors,
}) => {
  const errorCode = Array.isArray(errors) && errors[0] ? errors[0].code : 404;
  const { slug } = useParams<{ slug: string }>();
  const { pathname } = useLocation();

  const [dataRedirect, setDataRedirect] = useState<RedirectDataTypes | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchRedirect301 = async () => {
      try {
        setIsLoading(true);
        const data = await fetchRedirect301Service(pathname);
        setDataRedirect(data);
        setIsLoading(false);
      } catch (errors: any) {
        if (errors?.response) {
          setDataRedirect({
            data: null,
            error: {
              code: errors?.response.status
            },
          });
        }
        setIsLoading(false);
      }
    }

    const prefixPages = ['/danh-muc', '/kinh-nghiem-hay', '/san-pham', '/khuyen-mai'];
    if (((prefixPages.includes(pathname) && slug) || errorCode === 404) && pathname !== '/khong-tim-thay') {
      fetchRedirect301();
    }
  }, [pathname]);

  if (isLoading) return <Loading />;

  if (dataRedirect?.data) {
    window.open(dataRedirect.data.to, '_self');
    return null;
  }
  if (dataRedirect?.error?.code) {
    return (<Redirect
      to={{
        pathname: '/khong-tim-thay'
      }}
    />);
  }

  return null;
};

const RedirectNavigate: React.FC<RedirectNavigateProps> = (props) => <Redirect301 {...props} />;

export default RedirectNavigate;
