export const REQUEST_FETCH_MENU = "REQUEST_FETCH_MENU";
export const REQUEST_FETCH_MENU_SUCCESS = "REQUEST_FETCH_MENU_SUCCESS";
export const REQUEST_FETCH_MENU_ERROR = "REQUEST_FETCH_MENU_ERROR";
export const REQUEST_FETCH_FOOTER_MENU = "REQUEST_FETCH_FOOTER_MENU";
export const REQUEST_FETCH_FOOTER_MENU_SUCCESS = "REQUEST_FETCH_FOOTER_MENU_SUCCESS";
export const REQUEST_FETCH_FOOTER_MENU_ERROR = "REQUEST_FETCH_FOOTER_MENU_ERROR";

export const requestFetchMenus = () => ({
  type: REQUEST_FETCH_MENU
});

export const fetchMenusOnSuccess = (menus: any) => ({
  type: REQUEST_FETCH_MENU_SUCCESS,
  menus
});

export const fetchMenusOnError = (error: any) => ({
  type: REQUEST_FETCH_MENU_ERROR,
  error
});

export const requestFetchFooterMenus = () => ({
  type: REQUEST_FETCH_FOOTER_MENU
});

export const fetchFooterMenusOnSuccess = (menus: any) => ({
  type: REQUEST_FETCH_FOOTER_MENU_SUCCESS,
  menus
});

export const fetchFooterMenusOnError = (error: any) => ({
  type: REQUEST_FETCH_FOOTER_MENU_ERROR,
  error
});
