export const REQUEST_FETCH_PRODUCT_TYPES = "REQUEST_FETCH_PRODUCT_TYPES";
export const REQUEST_FETCH_PRODUCT_TYPES_ON_SUCCESS =
  "REQUEST_FETCH_PRODUCT_TYPES_ON_SUCCESS";
export const REQUEST_FETCH_PRODUCT_TYPES_ON_ERROR =
  "REQUEST_FETCH_PRODUCT_TYPES_ON_ERROR";

export const requestFetchProductTypes = () => ({
  type: REQUEST_FETCH_PRODUCT_TYPES,
});

export const requestFetchProductTypesOnSuccess = (productTypes: any) => ({
  type: REQUEST_FETCH_PRODUCT_TYPES_ON_SUCCESS,
  productTypes,
});

export const requestFetchProductTypesOnError = (error: any) => ({
  type: REQUEST_FETCH_PRODUCT_TYPES_ON_ERROR,
  error,
});
