import { AnyAction } from "redux";
import {
  REMOVE_LOCATION_BUYING,
  SAVE_LOCATION_BUYING,
} from "redux/actions/location/locationBuying";

export default (state = null, action: AnyAction) => {
  switch (action.type) {
    case SAVE_LOCATION_BUYING:
      return action.locationBuying;
    case REMOVE_LOCATION_BUYING:
      return null;
    default:
      return state;
  }
};
