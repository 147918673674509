import { AnyAction } from "redux";
import { TOGGLE_CART_BOX_HEADER } from "redux/actions/cart/cartBoxHeader";

export default (state = false, action: AnyAction) => {
  switch (action.type) {
    case TOGGLE_CART_BOX_HEADER:
      return !state;
    default:
      return state;
  }
};
