import Link from "components/atoms/Link";
import RightNavTop from "components/molecules/RightNavTop";
import SearchAll from "components/molecules/searchAll";
import Grid, { GridCol, GridRow } from "components/organisms/Grid";
import { BASE_URL } from "constants/env";
import useWindowSize from "hooks/useWindowSize";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { joinClassnames } from "utils/map-modifier";
import { isExternal, mappingChildrenToParent } from "utils/utils-common";
import Menu, { MenuItem } from "../Menu";
import { useSelector } from "hooks/useApp";
import "./index.scss";

interface Props {
  children?: React.ReactNode;
  href?: string;
  modifiers?: Modifiers;
  menus: any;
  show?: boolean;
  onToggleMenu?: () => void;
  onClick?: () => void;
  onToggleShowingBoxMobile?: (status: boolean) => void;
  onToggleShowingSearchOrderTablet?: (status: boolean) => void;
  onCloseToggleCheckOrderTablet?: boolean;
  onCloseToggleCheckOrderMobile?: boolean;
}

const NavMain: React.FC<Props> = ({
  show,
  menus,
  onToggleShowingBoxMobile,
  onToggleShowingSearchOrderTablet,
  onToggleMenu,
  onCloseToggleCheckOrderTablet,
  onCloseToggleCheckOrderMobile,
}: Props) => {
  const owlClass = "m-nav-main";
  const [subMenuVisibleId, setSubMenuVisibleId] = useState();
  const [currentPathName, setCurrentPathName] = useState("");
  const location = useLocation();
  const pathName = location.pathname;
  const { width } = useWindowSize();
  const CONDITION_MOBILE = width <= 1366;
  const [mobile, setMobile] = useState(CONDITION_MOBILE);
  const [isIpadOS, setIsIpadOS] = useState(isIpad());
  const [thisRefWidth, setThisRefWidth] = useState<{
    width: number;
    left: number;
  }>();

  const replacePath = (path: string, search: string) => {
    let x = path.replace(search, "");
    return x;
  };
  const trimPathByEmpty = (path: string) => {
    return path.includes("/danh-muc/") ? replacePath(path, "/danh-muc/") : path;
  };

  useEffect(() => {
    if (pathName) {
      if (trimPathByEmpty(currentPathName) !== trimPathByEmpty(pathName)) {
        onToggleMenu && onToggleMenu();
        setCurrentPathName(pathName);
        setSubMenuVisibleId(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  function isIpad() {
    const ua = window.navigator.userAgent;
    if (ua.indexOf("iPad") > -1 || width < 1199) {
      return true;
    }

    if (ua.indexOf("Macintosh") > -1) {
      try {
        document.createEvent("TouchEvent");
        return true;
      } catch (e) { }
    }
    return false;
  }

  const setSubMenuStyle = () => {
    if (gridRef && gridRef.current) {
      setThisRefWidth({
        width: window.innerWidth,
        left: -(window.innerWidth - gridRef.current.clientWidth) / 2,
      });
    }
  };

  useEffect(() => {
    setMobile(CONDITION_MOBILE);
    setIsIpadOS(isIpad());
    setSubMenuStyle();
  }, [CONDITION_MOBILE, width]);

  const gridRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSubMenuStyle();
  }, [gridRef]);

  const menu = useMemo(
    () =>
      menus.mainMenus.length > 0 && (
        <Menu>
          {mappingChildrenToParent(menus.mainMenus).map(
            (_menu: any, i: number) => (
              <React.Fragment key={i}>
                {_menu.children.length ? (
                  <MenuItem
                    className={joinClassnames(
                      _menu.id === subMenuVisibleId && "active",
                    )}
                  >
                    <Link
                      rel="noreferrer"
                      href={
                        _menu.link
                          ? _menu.link
                          : _menu.reference
                            ? `/${_menu.reference.slug}`
                            : "/"
                      }
                      className={joinClassnames(
                        "sub-menu",
                        _menu.id === subMenuVisibleId ? "showed" : "closed",
                      )}
                    >
                      {_menu.title}
                      <span
                        className="navbar-toggler"
                        {...(mobile || isIpad
                          ? {
                            onClick: e => {
                              e.preventDefault();
                              e.stopPropagation();
                              setSubMenuVisibleId(
                                _menu.id === subMenuVisibleId
                                  ? undefined
                                  : _menu.id,
                              );
                            },
                          }
                          : {})}
                      >
                        <span className="right-icon"></span>
                      </span>
                    </Link>

                    <MMenuSub className="m-menu-sub">
                      <div className="m-menu-sub_container">
                        <div className="m-menu-sub_title">{_menu.title}</div>
                        {_menu.children.map(child => (
                          <div
                            key={child.id}
                            onClick={() => {
                              setSubMenuVisibleId(undefined);
                            }}
                            className={`sub-menuitem-wrap ${child.is_show_image === 0 ? "left" : "right"
                              }`}
                          >
                            {child.is_show_image === 0 ? (
                              <Link
                                href={
                                  child.reference_id
                                    ? `/${child.reference.slug}`
                                    : child.link
                                }
                                isLinkRouter={!isExternal(child.link)}
                              >
                                {child.title}
                              </Link>
                            ) : (
                              <Link
                                href={
                                  child.reference_id
                                    ? `/${child.reference.slug}`
                                    : `${child.link}`
                                }
                                isLinkRouter={!isExternal(child.link)}
                              >
                                <img
                                  alt={child.title}
                                  src={`${BASE_URL}${child.image}`} // use normal <img> attributes as props
                                />
                              </Link>
                            )}
                          </div>
                        ))}
                      </div>
                    </MMenuSub>
                  </MenuItem>
                ) : (
                  <MenuItem>
                    <Link
                      href={
                        _menu.reference_id
                          ? `/${_menu.reference.slug}`
                          : _menu.link
                      }
                    >
                      {_menu.title}
                    </Link>
                  </MenuItem>
                )}
              </React.Fragment>
            ),
          )}
        </Menu>
      ),
    [menus.mainMenus, mobile, subMenuVisibleId],
  );

  const rightNavTop = useMemo(() => {
    return (
      <RightNavTop
        className="nav-top-mobile"
        onToggleShowingBoxMobile={onToggleShowingBoxMobile}
        onToggleShowingSearchBoxTablet={onToggleShowingSearchOrderTablet}
        onCloseToggleCheckOrderTablet={onCloseToggleCheckOrderTablet}
        onCloseToggleCheckOrderMobile={onCloseToggleCheckOrderMobile}
      />
    );
  }, [
    onCloseToggleCheckOrderMobile,
    onCloseToggleCheckOrderTablet,
    onToggleShowingBoxMobile,
    onToggleShowingSearchOrderTablet,
  ]);

  const searchFormDesktop = useMemo(() => {
    return <SearchAll className="desktop" />;
  }, []);

  const {
    ref: {
      hotTopHeader: { visible },
    },
  } = useSelector(state => state.view);

  return (
    <Container
      className={joinClassnames(
        owlClass,
        show && "show",
        isIpadOS && "isIpadOS",
        currentPathName.includes("/san-pham") && "unfixed",
        visible ? "has-hot-top-banner" : "",
      )}
      dataLeft={
        currentPathName.includes("/san-pham") && thisRefWidth
          ? `${thisRefWidth.left}px`
          : "0"
      }
    >
      <Grid ref={gridRef}>
        <GridRow>
          <GridCol>
            <div className="container flex-direction-column-mobile">
              {menu}
              {rightNavTop}
              {searchFormDesktop}
            </div>
          </GridCol>
        </GridRow>
      </Grid>
    </Container>
  );
};

NavMain.defaultProps = {};
type NavMainTypes = {
  dataLeft: string;
};
type MMenuSubTypes = {};
const MMenuSub = styled.div<MMenuSubTypes>``;
const Container = styled.div<NavMainTypes>`
  ${({ dataLeft }) =>
    dataLeft &&
    css`
      .m-menu {
        ${MMenuSub} {
          left: ${dataLeft}!important;
        }
      }
    `}
`;

export default memo(NavMain);
