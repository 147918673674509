import Link from "components/atoms/Link";
import LoadingBox from "components/molecules/LoadingBox";
import NavMain from "components/molecules/NavMain";
import RightNavTop from "components/molecules/RightNavTop";
import SearchAll from "components/molecules/searchAll";
import SearchOrder from "components/molecules/searchorder";
import { BASE_URL } from "constants/env";
import { useSelector } from "hooks/useApp";
import useOutsideClick from "hooks/useOutsideClick";
import useWindowSize from "hooks/useWindowSize";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { requestFetchMenus } from "redux/actions/general/menus";
import { fetchProductsByTypeOnSuccess } from "redux/actions/products/byType";
import { fetchCartProducts } from "redux/actions/products/cart";
import { readyElement, readyRef } from "redux/reducers/view/ref";
import { objectFilter } from "utils/common";
import { joinClassnames } from "utils/map-modifier";
import { Grid, GridCol, GridRow } from "../Grid";
import "./index.scss";

interface HeaderProps {
  isFixed: boolean;
  callParent: (status: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ isFixed, children, callParent }) => {
  const dispatch = useDispatch();
  const headerRef = useRef<HTMLElement>(null);
  const [fullMenuVisible, setFullMenuVisible] = useState(false);
  const [cartNotifyStatus, setCartNotifyStatus] = useState(false);
  const { menus } = useSelector((state: any) => state.general);
  const {
    products: { cart }
  } = useSelector(state => state);
  const [showingSearchOrderMobile, setShowingSearchOrderMobile] = useState(
    false
  );
  const [toggleCheckOrderTablet, setToggleCheckOrderTablet] = useState(false);
  const searchBoxRef = useRef<HTMLDivElement>(null);
  const mobileSearchRef = useRef<HTMLDivElement>(null);
  const searchBoxRefTablet = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();
  const SearchBoxMobile_query = 768;
  const IPAD_LANDSCAPE = 1199;
  const [isMobile, setIsMobile] = useState<boolean>(width < 768);
  const [isTablet, setIsTablet] = useState<boolean>(
    width > SearchBoxMobile_query - 1 && width < IPAD_LANDSCAPE
  );
  const [isLessThanIpadLandscape, setIsLessThanIpadLandscape] = useState<
    boolean
  >(width < IPAD_LANDSCAPE);
  const [isShowSearch, setIsShowSearch] = useState<boolean>(false);
  useEffect(() => {
    setIsMobile(width < SearchBoxMobile_query);
    setIsTablet(width > SearchBoxMobile_query - 1 && width < IPAD_LANDSCAPE);
    setIsLessThanIpadLandscape(width < IPAD_LANDSCAPE);
  }, [width]);

  useOutsideClick(searchBoxRefTablet, () => {
    if (toggleCheckOrderTablet) {
      setToggleCheckOrderTablet(false);
    }
  });

  useOutsideClick(searchBoxRef, () => {
    if (showingSearchOrderMobile) {
      setShowingSearchOrderMobile(false);
    }
  });

  useOutsideClick(mobileSearchRef, () => {
    if (isShowSearch) {
      setIsShowSearch(false);
    }
  });

  useEffect(() => {
    dispatch(requestFetchMenus());
    if (window.location.pathname === "/gio-hang") {
      const appliedCode = sessionStorage.getItem("_cabh_");
      if (appliedCode) {
        dispatch(fetchCartProducts(appliedCode));
      } else {
        dispatch(fetchCartProducts());
      }
    } else {
      dispatch(fetchCartProducts());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { data } = cart;
    if (data && data.list) {
      if (data.list.length > 0) {
        setCartNotifyStatus(true);
      } else {
        setCartNotifyStatus(false);
      }
    }
  }, [cart]);

  useEffect(() => {
    callParent(isShowSearch);
  }, [callParent, isShowSearch]);

  useEffect(() => {
    if (headerRef && headerRef.current) {
      setTimeout(() => {
        dispatch(
          readyRef("header", {
            clientHeight: (headerRef.current && headerRef.current.clientHeight) || 0
          })
        );
      }, 2500)
    }

  }, [dispatch, headerRef, width]);

  const rightNavTop = useMemo(() => {
    return <RightNavTop className="nav-top-desktop" />;
  }, []);

  const headerTop = useMemo(() => {
    return (
      <div className="header-top">
        <Grid>
          <GridRow>
            <GridCol>
              <div className="container flex-direction-column-mobile">
                <Link
                  className="logo-daikin"
                  href="/"
                  onClick={() => {
                    dispatch(
                      fetchProductsByTypeOnSuccess("loai-2-dan-roi", [], {}),
                    );
                  }}
                >
                  <svg
                    id="logo.2dd76d64"
                    xmlns="http://www.w3.org/2000/svg"
                    width="190.409"
                    height="40.013"
                    viewBox="0 0 190.409 40.013"
                  >
                    <g id="Group_1493" data-name="Group 1493">
                      <path
                        id="Path_2184"
                        data-name="Path 2184"
                        d="M43.463,0H0V40.013Z"
                        fill="#44c8f5"
                      />
                      <g id="Group_1492" data-name="Group 1492">
                        <path
                          id="Path_2185"
                          data-name="Path 2185"
                          d="M21.662,0H0V20.007Z"
                        />
                      </g>
                    </g>
                    <g id="Group_1494" data-name="Group 1494">
                      <path
                        id="Path_2186"
                        data-name="Path 2186"
                        d="M104.04,8.693l-5.8,22.628h10.072l5.657-22.628Z"
                        fill="#00a1e4"
                      />
                      <path
                        id="Path_2187"
                        data-name="Path 2187"
                        d="M79.751,8.693,66.919,31.321h6.623l2.07-3.725h9.107l.69,3.725h9.8L91.065,8.693ZM81.131,21.8H78.785l3.725-6.485L83.752,21.8Z"
                        fill="#00a1e4"
                      />
                      <path
                        id="Path_2188"
                        data-name="Path 2188"
                        d="M149.705,8.693l-5.657,22.628h9.934l5.657-22.628Z"
                        fill="#00a1e4"
                      />
                      <path
                        id="Path_2189"
                        data-name="Path 2189"
                        d="M145.152,8.693h-10.21l-8.555,7.451,1.793-7.451h-9.52L113,31.321h9.52l1.932-7.865,5.105,7.865h11.59L132.6,19.455Z"
                        fill="#00a1e4"
                      />
                      <path
                        id="Path_2190"
                        data-name="Path 2190"
                        d="M58.5,8.693H43.325L37.668,31.321H55.053c6.9,0,13.8-5.1,13.8-13.108,0-9.244-10.35-9.52-10.35-9.52Zm.552,10.21s-.69,7.313-6.9,7.313H48.568l3.173-12.142h3.173c1.1,0,4.416.276,4.14,4.829Z"
                        fill="#00a1e4"
                      />
                      <path
                        id="Path_2191"
                        data-name="Path 2191"
                        d="M183.51,8.693,180.2,21.939,175.783,8.693H164.607L158.95,31.321h6.9L169.3,17.937l4.415,13.384H184.75l5.659-22.628Z"
                        fill="#00a1e4"
                      />
                    </g>
                  </svg>
                </Link>
                {rightNavTop}
              </div>
            </GridCol>
          </GridRow>
        </Grid>
      </div>
    );
  }, [dispatch, rightNavTop]);

  const handleToggleMobile = useCallback(status => {
    setShowingSearchOrderMobile(status);
  }, [])

  const handleToggleTablet = useCallback(status => {
    setToggleCheckOrderTablet(status);
  }, [])

  const handleToggleMenu = useCallback(() => {
    setFullMenuVisible(false);
  }, [])

  const navMain = useMemo(() => {
    return menus?.mainMenus?.length > 0 ? <NavMain
      show={fullMenuVisible}
      menus={menus}
      onToggleMenu={handleToggleMenu}
      onToggleShowingBoxMobile={handleToggleMobile}
      onToggleShowingSearchOrderTablet={handleToggleTablet}
      onCloseToggleCheckOrderTablet={toggleCheckOrderTablet}
      onCloseToggleCheckOrderMobile={showingSearchOrderMobile}
    /> : <LoadingBox label="" />;
  }, [fullMenuVisible, handleToggleMenu, handleToggleMobile, handleToggleTablet, menus, showingSearchOrderMobile, toggleCheckOrderTablet]);

  const [readyHotTopElement, setReadyHotTopElement] = useState<boolean>(false);

  const cartItemTop = useMemo(() => {
    return (
      <Link href="/gio-hang" className={joinClassnames("menu-button menu-button--cart", readyHotTopElement ? "has-hot-top-banner" : "")}>
        <span
          className={joinClassnames(
            "icon-cart",
            cartNotifyStatus && "has-item"
          )}
        />
      </Link>
    );
  }, [cartNotifyStatus, readyHotTopElement]);

  const topMenuIconSearch = useMemo(() => {

    return (
      <div
        className={joinClassnames("menu-button menu-button--search", readyHotTopElement ? "has-hot-top-banner" : "")}
        onClick={() => {
          setIsShowSearch(!isShowSearch);
        }}
      >
        <span className={joinClassnames("icon-search")} />
      </div>
    );
  }, [isShowSearch, readyHotTopElement]);

  const searchOrderFormMobile = useMemo(() => {

    return (
      isMobile && (
        <SearchOrder
          ref={searchBoxRef}
          showBox={showingSearchOrderMobile}
          className="mobile"
        />
      )
    );
  }, [isMobile, showingSearchOrderMobile]);

  const searchOrderFormTablet = useMemo(() => {
    return (
      isTablet && (
        <div className="toggle-cart-box_check-order searchorder-tablet tablet">
          <SearchOrder
            ref={searchBoxRefTablet}
            showBox={toggleCheckOrderTablet}
            className="tablet"
          />
        </div>
      )
    );
  }, [isTablet, toggleCheckOrderTablet]);

  const handleSubmitMobile = useCallback(() => {
    setIsShowSearch(false);
  }, [])

  const searchAllFormMobile = useMemo(() => {
    return (
      isLessThanIpadLandscape && (
        <div className="wrap-searchAll">
          <SearchAll
            className="mobile"
            isShowSearch={isShowSearch}
            ref={mobileSearchRef}
            onSubmitted={handleSubmitMobile}
          />
          <div
            className={joinClassnames(
              "mobile-search-mask",
              isShowSearch ? "show" : ""
            )}
            onClick={() => {
              setIsShowSearch(false);
            }}
          ></div>
        </div>
      )
    );
  }, [handleSubmitMobile, isLessThanIpadLandscape, isShowSearch]);

  const collapseMenuButton = useMemo(() => {
    return (
      <button
        aria-label="Toggle Menu Button"
        aria-roledescription="toggle menu button"
        className={
          joinClassnames(fullMenuVisible
            ? "menu-button menu-button--main collapsed"
            : "menu-button menu-button--main",
            readyHotTopElement ? "has-hot-top-banner" : ""
          )
        }
        onClick={() => setFullMenuVisible(!fullMenuVisible)}
      >
        <span className="navbar-toggler">
          <span />
          <span />
          <span />
        </span>
      </button>
    );
  }, [fullMenuVisible, readyHotTopElement]);

  const { data: systemData } = useSelector(state => state.general.system);

  const hotTopBanner = useMemo(() => {
    if (!systemData) {
      return null;
    }

    const filterKeys = objectFilter(
      systemData,
      (item: any) => item.key === 'banners_top_desktop'
        || item.key === 'banners_top_mobile'
        || item.key === 'url_banners'
        || item.key === 'banner_color');

    if (!filterKeys) return null;

    const {
      banners_top_desktop,
      banners_top_mobile,
      url_banners,
      banner_color,
    } = filterKeys;

    const readyHotTopImage = !!(banners_top_desktop && banners_top_mobile);
    setReadyHotTopElement(readyHotTopImage);
    dispatch(readyElement('hotTopHeader', readyHotTopImage));
    return readyHotTopImage ? (
      <a className="top-ad-banner" href={url_banners || '/'} style={{ backgroundColor: banner_color || 'transparent' }}>
        <img src={`${BASE_URL}${width >= 768 ? banners_top_desktop : banners_top_mobile}`} alt="top banner" className="top-ad-banner_img" />
      </a>
    ) : null;
  }, [dispatch, systemData, width]);

  return (
    <header
      id="header"
      className={`o-header ${isFixed ? "" : " o-header-none-fixed"}`}
      ref={headerRef}
    >
      {/* hot top banner */}
      {hotTopBanner}
      {/* button collapse menu */}
      {collapseMenuButton}
      {/* header top menu */}
      {headerTop}
      {/* nav main menu */}
      {navMain}
      {/* cart icon top */}
      {cartItemTop}
      {/* top menu - icon search */}
      {topMenuIconSearch}
      {/* search order form mobile */}
      {searchOrderFormMobile}
      {/* search order form tablet */}
      {searchOrderFormTablet}
      {/* search all form mobile */}
      {searchAllFormMobile}
    </header>
  );
};

export default memo(Header);
