import RedirectNavigate from 'components/common/redirect301';
import ResetLocalData from "components/pages/ResetLocalData";
import { lazy } from "react";
const DashboardAccount = lazy(() =>
  import("components/pages/DashboardAccount"),
);
const Home = lazy(() => import("components/pages/Home"));
const NotFound = lazy(() => import("components/pages/NotFound")); //styled, lazyed
const Promotion = lazy(() => import("components/pages/Promotion"));
const Payment = lazy(() => import("components/pages/Payment"));
const SuccessSchedule = lazy(() => import("components/pages/SuccessSchedule"));
const SearchResult = lazy(() => import("components/pages/SearchResult"));
const Faq = lazy(() => import("components/pages/Faq"));
const Schedule = lazy(() => import("components/pages/Schedule"));
const SearchOrderStatus = lazy(() =>
  import("components/pages/SearchOrderStatus"),
);
const DashboardOrderStatusShipping = lazy(() =>
  import("components/pages/DashboardOrderStatusShipping"),
);
const PaymentComplete = lazy(() => import("components/pages/PaymentComplete"));
const PaymentCompleteTracking = lazy(() =>
  import("components/pages/PaymentCompleteTracking"),
);
const Compare = lazy(() => import("components/pages/Compare"));
const ProductDetailPage = lazy(() =>
  import("components/pages/ProductDetailPage"),
);
const ShoppingCart = lazy(() => import("components/pages/ShoppingCart"));
const Tips = lazy(() => import("components/pages/Tips"));
const TipDetail = lazy(() => import("components/pages/TipDetail"));
const PaymentError = lazy(() => import("components/pages/PaymentError"));
const ProductsHaidanroiMobile = lazy(() =>
  import("components/pages/ProductsHaidanroiMobile"),
);
const ProductsMultiMobile = lazy(() =>
  import("components/pages/ProductsMultiMobile"),
);
const ProductsSkyairMobile = lazy(() =>
  import("components/pages/ProductsSkyairMobile"),
);
const ProductsMaylocKKMobile = lazy(() =>
  import("components/pages/ProductsMaylocKKMobile"),
);

export const routes = [
  {
    path: "/",
    component: Home, // Da gan heading h1
  },
  {
    path: "/ket-qua",
    component: Home, // Đổi url /ket-qua phục vụ cho SEO tracking. 5/7/21
  },
  {
    path: "/san-pham/:slug",
    component: ProductDetailPage, // Da gan heading h1
  },

  {
    path: "/khuyen-mai/:slug",
    component: Promotion, // Khong co heading
  },
  {
    path: "/gio-hang",
    component: ShoppingCart, // Da gan heading h1
  },
  {
    path: "/thanh-toan",
    component: Payment, // Da gan heading h1
  },
  {
    path: "/ket-qua-tim-kiem",
    component: SearchResult, // Da gan heading h1
  },
  {
    path: "/faq",
    component: Faq, // Da gan heading h1
  },
  {
    path: "/dat-lich-bao-tri-thanh-cong",
    component: SuccessSchedule, // Da gan heading h1
  },
  {
    path: "/dat-lich-bao-tri",
    component: Schedule, // Da gan heading h1
  },
  {
    path: "/tim-kiem-don-hang",
    component: SearchOrderStatus, // Da gan heading h1
  },
  {
    path: "/ket-qua-tim-kiem-don-hang",
    component: DashboardOrderStatusShipping, // Da gan heading h1
  },
  {
    path: "/thanh-toan-thanh-cong/:orderCode",
    component: PaymentComplete, // Da gan heading h1
  },
  {
    path: "/thanh-toan-thanh-cong/:orderCode/tracking",
    component: PaymentCompleteTracking, // Da gan heading h1
  },

  {
    path: "/so-sanh",
    component: Compare, // Khong co heading
  },
  {
    path: "/kinh-nghiem-hay",
    component: Tips, // Da gan heading h1
  },
  {
    path: "/kinh-nghiem-hay/:slug",
    component: TipDetail, // Da gan heading h1
  },
  {
    path: "/huy-don-hang",
    component: PaymentError, // Da gan heading h1
  },
  {
    path: "/khong-tim-thay",
    component: NotFound,
  },
  {
    path: "/tai-khoan",
    component: DashboardAccount, // Da gan heading h1
  },
  {
    path: "/reset",
    component: ResetLocalData,
  },
  {
    path: "/danh-muc/loai-2-dan-roi",
    component: ProductsHaidanroiMobile,
  },

  {
    path: "/danh-muc/loai-multi",
    component: ProductsMultiMobile,
  },
  {
    path: "/danh-muc/sky-air",
    component: ProductsSkyairMobile,
  },
  {
    path: "/danh-muc/may-loc-khong-khi",
    component: ProductsMaylocKKMobile,
  },
  {
    path: "/danh-muc/:slug",
    component: RedirectNavigate,
  },
];
