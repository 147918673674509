import banks from "./banks";
import cities from "./cities";
import { combineReducers } from "redux";
import comment from "./comment";
import districts from "./districts";
import email from "./email";
import menus from "./menus";
import rating from "./rating";
import scheduleCities from "./scheduleCities";
import subSkyAir from "./subSkyAir";
import subscriber from "./subscriber";
import system from "./system";
import toggleBanner from "./toggleBanner";
import toggleSeenProducts from "./toggleSeenProducts";
import validationAccount from "./validationAccount";
import wards from "./wards";
import productCities from "./productCities";
import cardToken from "./cardToken";
import utm from "./utm";

export default combineReducers({
  banks,
  cities,
  productCities,
  scheduleCities,
  districts,
  wards,
  rating,
  menus,
  subscriber,
  email,
  system,
  toggleBanner,
  toggleSeenProducts,
  subSkyAir,
  validationAccount,
  comment,
  cardToken,
  utm,
});
