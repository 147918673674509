import Image from "components/atoms/Image";
import { maxQuery, minQuery, SizeMax, SizeMin } from "constants/media";
import React, { ReactNode } from "react";
import styled, { css } from "styled-components/macro";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import "./index.scss";

export enum PaddingSize {
  S15 = 15,
  S20 = 20,
  S25 = 25,
  S30 = 30,
  S35 = 35,
  S40 = 40,
  S45 = 45,
  S50 = 50,
  S55 = 55,
  S60 = 60,
}

type ResonsivesQueryType = {
  at: {
    min?: SizeMin;
    max?: SizeMax;
  };
  value: PaddingSize;
};

type PaddingType = {
  defaultValue?: {
    at?: {
      min?: SizeMin;
      max?: SizeMax;
    };
    value: PaddingSize;
  };
  responsives?: ResonsivesQueryType[];
};

interface SectionProps {
  children: ReactNode;
  space?:
    | "default"
    | "space-responsive"
    | "space-xs"
    | "space-mxs"
    | "space-sm"
    | "space-md"
    | "space-xl"
    | "space-xs-t"
    | "space-sm-t"
    | "space-md-t"
    | "space-xl-t"
    | "space-xs-b"
    | "space-sm-b"
    | "space-md-b"
    | "space-xl-b";
  modifiers?: (
    | "page"
    | "container"
    | "nopadding"
    | "blue"
    | "gray"
    | "grayDark"
    | "white"
    | "mainContent")[];
  padding?: PaddingType;
  bgrSrc?: string;
  className?: string;
  id?: string;
  fullHeight?: boolean;
}

const Section = React.forwardRef<HTMLDivElement, SectionProps>(
  (
    {
      space,
      modifiers,
      children,
      bgrSrc,
      className,
      id,
      fullHeight,
      padding,
    }: SectionProps,
    ref,
  ) => {
    return (
      <SectionTyped
        className={joinClassnames(
          mapMultiClassnames(
            "o-section",
            space,
            modifiers,
            fullHeight && "fullheight",
          ),
          className,
        )}
        id={id}
        ref={ref}
        padding={padding}
      >
        {bgrSrc && (
          <div className="o-section_bgr">
            <Image src={bgrSrc} alt="Section Image" />
          </div>
        )}
        {children}
      </SectionTyped>
    );
  },
);

type SectionTypedProps = {
  padding?: PaddingType;
};

const SectionTyped = styled.section<SectionTypedProps>`
  ${({ padding }) => {
    if (padding) {
      const { defaultValue, responsives } = padding;
      if (defaultValue) {
        const { at: atDefault, value: valueDefault } = defaultValue;

        if (atDefault) {
          const { min, max } = atDefault;
          if (min) {
            return minQuery(
              min,
              css`
                padding-top: ${valueDefault}px;
                padding-bottom: ${valueDefault}px;
              `,
            );
          } else if (max) {
            return maxQuery(
              max,
              css`
                padding-top: ${valueDefault}px;
                padding-bottom: ${valueDefault}px;
              `,
            );
          } else {
            return css``;
          }
        }
        return css`
          padding-top: ${valueDefault}px;
          padding-bottom: ${valueDefault}px;
        `;
      }
      if (responsives) {
        return css`
          ${responsives.map(responsive => {
            const { at, value } = responsive;
            const { min, max } = at;
            if (min) {
              return minQuery(
                min,
                css`
                  padding-top: ${value}px;
                  padding-bottom: ${value}px;
                `,
              );
            }
            if (max) {
              return maxQuery(
                max,
                css`
                  padding-top: ${value}px;
                  padding-bottom: ${value}px;
                `,
              );
            }
            return css`
              padding-top: ${value}px;
              padding-bottom: ${value}px;
            `;
          })}
        `;
      }
    }
  }}
`;

export default React.memo(Section);
