import React, { useMemo } from "react";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import "./index.scss";

interface StyleProps {
  [key: string]: string | number;
}
interface HeadingProps {
  type?: "h1" | "h2" | "h3" | "h4" | "h5";
  size?: "medium" | "large" | "xl" | "mxl" | "sm" | "32" | "28" | "20";
  weight?: "light" | "medium" | "bold";
  modifiers?: (
    | "center"
    | "no-margin-bottom"
    | "right"
    | "italic"
    | "blue"
    | "blue-dark"
    | "white"
    | "normal"
    | "gray"
    | "primary"
    | "uppercase"
    | "ellipsis"
    | "reset-line-height")[];
  children: React.ReactNode;
  className?: string;
  responsive?: boolean;
  styles?: StyleProps;
}

const Heading: React.FC<HeadingProps> = ({
  type,
  size,
  modifiers,
  children,
  weight,
  className,
  responsive,
  styles,
}: HeadingProps) => {
  const originalClassName = useMemo(
    () =>
      joinClassnames(
        mapMultiClassnames(
          "a-heading",
          weight,
          size && `size-${size}`,
          modifiers,
          responsive && "responsive",
        ),
        className,
      ),
    [className, modifiers, responsive, size, weight],
  );

  const renderHeading = useMemo(() => {
    switch (type) {
      case "h1":
        return (
          <h1 style={styles} className={originalClassName}>
            {children}
          </h1>
        );
      case "h2":
        return (
          <h2 style={styles} className={originalClassName}>
            {children}
          </h2>
        );
      case "h3":
        return (
          <h3 style={styles} className={originalClassName}>
            {children}
          </h3>
        );
      case "h4":
        return (
          <h4 style={styles} className={originalClassName}>
            {children}
          </h4>
        );
      case "h5":
        return (
          <h5 style={styles} className={originalClassName}>
            {children}
          </h5>
        );
      default:
        return (
          <h2 style={styles} className={originalClassName}>
            {children}
          </h2>
        );
    }
  }, [children, originalClassName, styles, type]);

  return renderHeading;
};

export default Heading;
