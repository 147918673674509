import Layout from "components/layout";
import DaikinNotfound from "images/common/banner-not-found.jpg";
import React, { lazy } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components/macro";

const Text = lazy(() => import("components/atoms/Text"));
const Heading = lazy(() => import("components/atoms/Heading"));
const Banner = lazy(() => import("components/organisms/Banner"));
const Link = lazy(() => import("components/atoms/Link"));
const Head = lazy(() => import("components/molecules/Head"));

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = () => (
  <Page className="p-notfound">
    <Layout>
      <Head
        data={{
          titlePage: "Không tìm thấy",
          title: "",
          description: "",
          keywords: "",
          image: "",
        }}
      />
      <Helmet>
        <meta name="render:status_code" content="404" />
      </Helmet>

      <Banner bgImgSrc={DaikinNotfound}>
        <Heading type="h2">Rất Tiếc!</Heading>
        <div className="des">
          <Text> Chúng tôi không thể tìm thấy trang bạn muốn truy cập</Text>
          <Link href="/" buttonLink modifiers={["rounded"]} variant="primary">
            Trang chủ
          </Link>
        </div>
      </Banner>
    </Layout>
  </Page>
);

const Page = styled.div`
  &_left {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    span {
      font-size: 56px;
      font-weight: bold;
      line-height: 56px;
    }
    .a-text {
      margin-top: 16px;
      margin-bottom: 21px;
    }
  }
`;

export default NotFound;
