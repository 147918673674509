import React from "react";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import "./index.scss";

interface ButtonToolbarProps {
  modifiers?: Modifiers;
  className?: string;
}

const ButtonToolbar: React.FC<ButtonToolbarProps> = ({
  children,
  modifiers,
  className,
}) => {
  return (
    <div
      className={joinClassnames(
        mapMultiClassnames("m-btn-toolbar", modifiers),
        className,
      )}
    >
      {children}
    </div>
  );
};

export default ButtonToolbar;
