export const REQUEST_FETCH_SUPPLY_PRODUCT = "REQUEST_FETCH_SUPPLY_PRODUCT";
export const FETCH_SUPPLY_PRODUCT_SUCCESS = "FETCH_SUPPLY_PRODUCT_SUCCESS";
export const FETCH_SUPPLY_PRODUCT_ERROR = "FETCH_SUPPLY_PRODUCT_ERROR";

export const REQUEST_FETCH_SUPPLIES_BY_HP = "REQUEST_FETCH_SUPPLIES_BY_HP";
export const FETCH_SUPPLIES_BY_HP_SUCCESS = "FETCH_SUPPLIES_BY_HP_SUCCESS";
export const FETCH_SUPPLIES_BY_HP_ERROR = "FETCH_SUPPLIES_BY_HP_ERROR";

export const requestFetchSuplyProduct = () => ({
  type: REQUEST_FETCH_SUPPLY_PRODUCT,
});

export const fetchSuplyProductOnSuccess = (supplies: any) => ({
  type: FETCH_SUPPLY_PRODUCT_SUCCESS,
  supplies,
});

export const fetchSuplyProductOnError = (error: any) => ({
  type: FETCH_SUPPLY_PRODUCT_ERROR,
  error,
});

export const requestFetchSuppliesByHp = (id: any) => ({
  type: REQUEST_FETCH_SUPPLIES_BY_HP,
  id,
});

export const fetchSuppliesByHpOnSuccess = (suppliesByHp: any) => ({
  type: FETCH_SUPPLIES_BY_HP_SUCCESS,
  suppliesByHp,
});

export const fetchSuppliesByHpOnError = (error: any) => ({
  type: FETCH_SUPPLIES_BY_HP_ERROR,
  error,
});

// non -redux acton
export const fetchRange = (supplyCase: string) => {
  const defaultOption = [
    { value: "3", label: "3 mét" },
    { value: "4", label: "4 mét" },
    { value: "5", label: "5 mét" },
    { value: "6", label: "6 mét" },
    { value: "7", label: "7 mét" },
    { value: "8", label: "8 mét" },
    { value: "9", label: "9 mét" },
    { value: "10", label: "10 mét" },
    { value: "11", label: "11 mét" },
    { value: "12", label: "12 mét" },
    { value: "13", label: "13 mét" },
    { value: "14", label: "14 mét" },
    { value: "15", label: "15 mét" },
  ]; // 3-15m
  const moreOption = [
    { value: "16", label: "16 mét" },
    { value: "17", label: "17 mét" },
    { value: "18", label: "18 mét" },
    { value: "19", label: "19 mét" },
    { value: "20", label: "20 mét" },
    { value: "21", label: "21 mét" },
    { value: "22", label: "22 mét" },
    { value: "23", label: "23 mét" },
    { value: "24", label: "24 mét" },
    { value: "25", label: "25 mét" },
    { value: "26", label: "26 mét" },
    { value: "27", label: "27 mét" },
    { value: "28", label: "28 mét" },
    { value: "29", label: "29 mét" },
    { value: "30", label: "30 mét" },
  ];
  switch (supplyCase) {
    case "1HP - 1.5HP":
      return defaultOption;
    case "2HP - 2.5HP":
      return defaultOption;
    case "3HP":
      return defaultOption.concat(moreOption);
    default:
      return defaultOption;
  }
};

export const fetchTextDataViaRange = (range: number) => {
  if (range >= 6 && range <= 10) {
    return {
      title:
        "Những trường hợp khoảng cách giữa dàn nóng và dàn lạnh từ 6 – 10 mét:",
      content: [
        "Dàn lạnh phòng ngủ 5 hoặc 2, dàn nóng đặt tại ban công cùng tầng",
        "Dàn lạnh phòng ngủ 5 hoặc 6, dàn nóng đặt trên sân thượng",
        "Dàn lạnh phòng khách, dàn nóng đặt ban công chỗ máy giặt khu bếp phía sau",
      ],
    };
  } else if (range >= 11 && range <= 15) {
    return {
      title:
        "Những trường hợp khoảng cách giữa dàn nóng và dàn lạnh từ 11 – 15 mét:",
      content: [
        "Dàn lạnh phòng khách 1, dàn nóng đặt trên sân thượng",
        "Dàn lạnh phòng ngủ 2, dàn nóng đặt trên sân thượng",
      ],
    };
  } else if (range >= 16 && range <= 30) {
    return {
      title:
        "Những trường hợp khoảng cách giữa dàn nóng và dàn lạnh từ 16 – 30 mét:",
      content: ["Dàn lạnh để tầng trệt, dàn nóng đặt tại sân thượng"],
    };
  } else {
    return {
      title:
        "Những trường hợp khoảng cách giữa dàn nóng và dàn lạnh từ 3 – 5 mét:",
      content: [
        "Dàn lạnh đặt phòng ngủ, phòng khách, dàn nóng đi xuyên qua vách tường kế bên",
        "Dàn lạnh đặt phòng ngủ,  dàn nóng đặt tại ban công phòng ngủ",
        "Dàn lạnh đặt phòng ngủ hoặc phòng khách,  dàn nóng đặt tại mái tôn hoặc tầng thượng tiếp theo",
      ],
    };
  }
};
// non -redux end
