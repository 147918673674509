import { centerYFlexCss } from "constants/css";
import useOutsideClick from "hooks/useOutsideClick";
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import Button from "../Button";
import "./index.scss";

type AlignType = "left" | "right";

type ActiveStyleType = {
  icon?: {
    icon: ReactNode;
    position: AlignType;
  };
  colorActive?: string;
};
interface Props {
  show?: boolean;
  children?: React.ReactNode;
  title?: string | ReactNode;
  span?: string;
  modifiers?: string[];
  button?: ReactNode;
  className?: string;
  menuClassName?: string;
  noPaddingMenu?: boolean;
  alignLeftContent?: boolean;
  activeStyle?: ActiveStyleType;
  closeOnChange?: boolean;
  shortTransition?: boolean;
  onShow?: (show: boolean) => void;
  onChange?: (item: any) => void;
}

const Dropdown: React.FC<Props> = ({
  show,
  children,
  modifiers,
  title,
  className,
  menuClassName,
  noPaddingMenu,
  alignLeftContent,
  activeStyle,
  closeOnChange,
  shortTransition,
  onShow,
  onChange,
}: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isOpenMenu, setOpenMenu] = useState(show);
  const activeIconPosition = useMemo(
    () => activeStyle && activeStyle.icon && activeStyle.icon.position,
    [activeStyle],
  );
  const activeIcon = useMemo(() => activeStyle && activeStyle.icon, [
    activeStyle,
  ]);
  const activeIconIcon = useMemo(
    () => activeStyle && activeStyle.icon && activeStyle.icon.icon,
    [activeStyle],
  );
  const activeColorActive = useMemo(
    () => activeStyle && activeStyle.colorActive,
    [activeStyle],
  );

  const toggleMenu = () => {
    setOpenMenu(!isOpenMenu);
  };

  useEffect(() => {
    setOpenMenu(show);
  }, [show]);

  useEffect(() => {
    if (isOpenMenu) {
      document.addEventListener("mousedown", handleClickOutSide);
      onShow && onShow(true);
    } else {
      document.removeEventListener("mousedown", handleClickOutSide);
      onShow && onShow(false);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenMenu]);

  const handleClickOutSide = (e: any) => {
    if (menuRef?.current?.contains(e.target)) {
      return;
    }
    setOpenMenu(false);
  };

  const childrenItems = useMemo(() => {
    const handleClickMenuItem = (item: any) => {
      const { props } = item;
      if (props) {
        const { value, disabled } = props;
        if (!disabled) {
          onChange && onChange(value);
          closeOnChange && setOpenMenu(!isOpenMenu);
        }
      }
    };
    return React.Children.map(children, (item, index) => (
      <DropdownMenuItem
        key={index}
        className={"a-dropdown-link"}
        onClick={() => handleClickMenuItem(item)}
      >
        {item}
      </DropdownMenuItem>
    ));
  }, [children, closeOnChange, isOpenMenu, onChange]);

  useOutsideClick(menuRef, () => isOpenMenu && setOpenMenu(false));

  return (
    <Container
      ref={menuRef}
      className={joinClassnames(
        mapMultiClassnames("a-dropdown", modifiers),
        shortTransition && "shortTransition",
        className,
      )}
      noPaddingMenu={noPaddingMenu}
      alignLeftContent={alignLeftContent}
      activeStyle={activeStyle}
    >
      <ButtonExtended
        onClick={toggleMenu}
        variant="link"
        aligncontent={activeIconPosition}
      >
        {activeIcon && (
          <IconWrap
            aligncontent={activeIconPosition}
            colorActive={activeColorActive}
            isOpenMenu={isOpenMenu}
          >
            <IconWrapInner>{activeIconIcon}</IconWrapInner>
          </IconWrap>
        )}
        <TitleWrap colorActive={activeColorActive}>{title}</TitleWrap>
      </ButtonExtended>
      <DropdownMenu
        className={`a-dropdown-menu ${menuClassName} ${isOpenMenu ? "a-dropdown-menu-show" : "a-dropdown-menu-hide"
          }`}
      >
        {React.Children && childrenItems}
      </DropdownMenu>
    </Container>
  );
};

const IconWrapInner = styled.span`
  ${centerYFlexCss};
`;

type IconWrapType = {
  aligncontent?: AlignType;
  colorActive?: string;
  isOpenMenu?: boolean;
};
const IconWrap = styled.span<IconWrapType>`
  ${centerYFlexCss};

  ${({ aligncontent }) =>
    aligncontent && aligncontent === "left"
      ? css`
          margin-right: 0.25em;
        `
      : css`
          margin-left: 0.25em;
        `}

  ${({ colorActive }) =>
    colorActive &&
    css`
      color: ${colorActive};
    `}
`;

const TitleWrap = styled.label<{ colorActive?: string }>`
  margin-bottom: 0;
  cursor: pointer;

  ${({ colorActive }) =>
    colorActive &&
    css`
      color: ${colorActive};
    `}
`;

const ButtonExtended = styled(Button) <{ aligncontent?: AlignType }>`
  cursor: pointer;

  ${({ aligncontent }) =>
    aligncontent && aligncontent === "left"
      ? css`
          flex-direction: row;
        `
      : css`
          flex-direction: row-reverse;
        `}
`;

const DropdownMenuItem = styled.div``;

const DropdownMenu = styled.div``;

type ContainerTypes = {
  noPaddingMenu?: boolean;
  alignLeftContent?: boolean;
  activeStyle?: ActiveStyleType;
};
const Container = styled.div<ContainerTypes>`
  ${({ noPaddingMenu }) =>
    noPaddingMenu &&
    css`
      ${DropdownMenu} {
        padding: 0;
      }
    `}

  ${({ alignLeftContent }) =>
    alignLeftContent &&
    css`
      ${DropdownMenu} {
        left: 0 !important;
        transform: translateX(0) !important;
        width: auto;
      }
    `}

  ${({ activeStyle }) =>
    activeStyle &&
    activeStyle.colorActive &&
    css`
      ${DropdownMenuItem} {
        &:hover {
          color: ${activeStyle.colorActive};
        }
      }
    `}
`;

export default React.memo(Dropdown);
