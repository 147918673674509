import { fetchShippingpage } from "api/interna-api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchShippingPageOnError,
  fetchShippingPageOnSuccess,
  REQUEST_FETCH_SHIPPING_PAGE,
} from "redux/actions/pages/shipping";

function* fetchShippingPageFlow() {
  try {
    const data = yield call(fetchShippingpage);
    yield put(fetchShippingPageOnSuccess(data));
  } catch (error) {
    yield put(fetchShippingPageOnError(error));
  }
}

export default function* watchFetchShippingPageSaga() {
  yield takeLatest(REQUEST_FETCH_SHIPPING_PAGE, fetchShippingPageFlow);
}
