import PrivateRouteNew from "components/common/PrivateRouteNew";
import NotFound from "components/pages/NotFound";
import { routes } from "constants/routes";
import useAnalytics from "hooks/useAnalytics";
import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import "sass/common-important.scss";
import "sass/common-using.scss";
// Styles must use direct files imports
import DynamicRoutingPage from "components/common/DynamicPage";
import SignInUp from "components/pages/SignInUp";
import Social from "components/pages/Social";
import { INTERNAL } from "constants/env";
import { VALUE_INTERNAL } from "constants/global";
import useCheckHaveApplyTCDMInShoppingCart from "hooks/useCheckHaveApplyTCDMInShoppingCart";
import useGTM from "hooks/useGTM";
import useQueryParamsUTM from "hooks/useQueryParamsUTM";
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import 'swiper/swiper.scss'; // core Swiper

const ActivateRegister = lazy(() =>
  import("components/pages/ActivateRegister"),
);
const ChangePassword = lazy(() => import("components/pages/ChangePassword"));

const App: React.FC = () => {
  SwiperCore.use([Autoplay]);
  useQueryParamsUTM();
  useAnalytics();
  useGTM();
  useCheckHaveApplyTCDMInShoppingCart();

  return (
    <Switch>
      <Route
        path="/dang-nhap"
        exact
        component={SignInUp}
      />
      <Route
        path="/dang-ky"
        exact
        component={SignInUp}
      />

      <Route
        path="/activate"
        exact
        component={ActivateRegister}
      />

      <Route
        path="/social"
        exact
        component={Social}
      />

      <Route
        path="/change-password"
        exact
        component={ChangePassword}
      />

      <Route
        path="/dat-lai-mat-khau"
        exact
        component={ChangePassword}
      />

      {
        INTERNAL === VALUE_INTERNAL
          ?
          routes.map((route, i) => (
            <PrivateRouteNew
              key={i}
              path={route.path}
              exact
              component={route.component}
            />
          ))
          :
          routes.map((route, i) => (
            <Route key={i} path={route.path} exact component={route.component} />
          ))
      }

      {
        INTERNAL === VALUE_INTERNAL ? (
          <PrivateRouteNew
            path="/:slug"
            exact
            component={DynamicRoutingPage}
          />
        ) : (
          <Route path="/:slug">
            <DynamicRoutingPage />
          </Route>
        )
      }

      <Route>
        <NotFound />
      </Route>
    </Switch >
  );
};

export default App;
