import Icon from "components/atoms/Icon";
import colors from "constants/colors";
import { useAppDispatch } from "hooks/useApp";
import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { joinClassnames } from "utils/map-modifier";
import "./index.scss";

interface ChatGroupProps {
  className?: string;
  hotline?: string;
  fbPageId?: string;
  fbPageColor?: string;
}

const ChatGroup: React.FC<ChatGroupProps> = ({
  className,
  hotline,
  fbPageId,
  fbPageColor,
}) => {
  const [showList, setShowList] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    var chatAnimationGroup = document.querySelectorAll(".chat-animation-group");
    if (chatAnimationGroup && chatAnimationGroup.length > 0) {
      chatAnimationGroup[0].addEventListener("click", function () {
        setShowList(true);
      });
    }
  }, [dispatch]);

  return (
    <div className={joinClassnames("m-chatGroup", className)}>
      <div className="m-chatGroup_container">
        <div
          className="pulsation"
          style={{ backgroundColor: fbPageColor || colors.richElectricBlue }}
        ></div>
        <div
          className="pulsation"
          style={{ backgroundColor: fbPageColor || colors.richElectricBlue }}
        ></div>

        <div className={joinClassnames("chat-list-group",
          showList && "show",
          ((hotline && !(fbPageId && fbPageColor)) || (!hotline && (fbPageId && fbPageColor))) && 'single')}
        >
          <ul className="chat-list">
            {hotline ? (
              <li className="chat-list_item chat-list_item-hotline">
                <Link
                  rel="noreferrer"
                  to=""
                  onClick={e => {
                    e.preventDefault();
                  }}
                  className="chat-list_item_link"
                  onMouseDown={() => {
                    setShowList(false);
                    window.open(`tel:${hotline}`, "_self");
                  }}
                >
                  <span className="wrap-item-icon">
                    <svg
                      className="list-item-hotline"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                      ></path>
                    </svg>
                  </span>
                  <span className="chat-item-label">
                    <span className="chat-item-title">
                      Hotline:&nbsp;
                      {hotline.toString().length === 8
                        ? `${hotline
                          .toString()
                          .slice(0, 4)} ${hotline.toString().slice(4, 8)}`
                        : hotline}
                    </span>
                  </span>
                </Link>
              </li>
            ) : null}

            {(fbPageId && fbPageColor) ? (
              <li className="chat-list_item chat-list_item-message">
                <Link
                  rel="noreferrer"
                  to=""
                  onClick={e => {
                    e.preventDefault();
                  }}
                  className="chat-list_item_link"
                  onMouseDown={() => {
                    setShowList(false);
                    window.open(`https://m.me/${fbPageId}`, "_blank");
                  }}
                >
                  <span className="wrap-item-icon">
                    <svg
                      className="list-item-message"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M224 32C15.9 32-77.5 278 84.6 400.6V480l75.7-42c142.2 39.8 285.4-59.9 285.4-198.7C445.8 124.8 346.5 32 224 32zm23.4 278.1L190 250.5 79.6 311.6l121.1-128.5 57.4 59.6 110.4-61.1-121.1 128.5z"
                      ></path>
                    </svg>
                  </span>
                  <span className="chat-item-label">
                    <span className="chat-item-title">
                      Chat ngay để được tư vấn
                    </span>
                  </span>
                </Link>
              </li>
            ) : null}
          </ul>
        </div>
        {
          hotline && fbPageId && fbPageColor && (
            <div
              className={joinClassnames(
                "chat-animation-group",
                showList && "list-showing",
              )}
            >
              <div className="chat-animate-icon first-label-icon">
                <Icon iconName="contact" />
              </div>
              <div
                className="chat-animate-icon close-icon close-menu-contact"
                onClick={() => {
                  setShowList(false);
                }}
              >
                <svg
                  width={12}
                  height={13}
                  viewBox="0 0 14 14"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <g transform="translate(-4087 108)">
                    <g>
                      <path
                        transform="translate(4087 -108)"
                        fill="currentColor"
                        d="M 14 1.41L 12.59 0L 7 5.59L 1.41 0L 0 1.41L 5.59 7L 0 12.59L 1.41 14L 7 8.41L 12.59 14L 14 12.59L 8.41 7L 14 1.41Z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="chat-animate-icon hotline">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                  ></path>
                </svg>
              </div>
              <div className="chat-animate-icon livechat">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path
                    fill="currentColor"
                    d="M416 224V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64v160c0 35.3 28.7 64 64 64v54.2c0 8 9.1 12.6 15.5 7.8l82.8-62.1H352c35.3.1 64-28.6 64-63.9zm96-64h-64v64c0 52.9-43.1 96-96 96H192v64c0 35.3 28.7 64 64 64h125.7l82.8 62.1c6.4 4.8 15.5.2 15.5-7.8V448h32c35.3 0 64-28.7 64-64V224c0-35.3-28.7-64-64-64z"
                  ></path>
                </svg>
              </div>
              <div className="chat-animate-icon message">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    fill="currentColor"
                    d="M224 32C15.9 32-77.5 278 84.6 400.6V480l75.7-42c142.2 39.8 285.4-59.9 285.4-198.7C445.8 124.8 346.5 32 224 32zm23.4 278.1L190 250.5 79.6 311.6l121.1-128.5 57.4 59.6 110.4-61.1-121.1 128.5z"
                  ></path>
                </svg>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default memo(ChatGroup);
