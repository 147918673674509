import { fetchPayPage } from "api/interna-api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchPayPageOnError,
  fetchPayPageOnSuccess,
  REQUEST_FETCH_PAY_PAGE,
} from "redux/actions/pages/pay";

function* fetchPayPageFlow() {
  try {
    const data = yield call(fetchPayPage);
    yield put(fetchPayPageOnSuccess(data));
  } catch (error) {
    yield put(fetchPayPageOnError(error));
  }
}

export default function* watchFetchPayPageSaga() {
  yield takeLatest(REQUEST_FETCH_PAY_PAGE, fetchPayPageFlow);
}
