export const REQUEST_SAVE_SCHEDULE = "REQUEST_SAVE_SCHEDULE";
export const REQUEST_SAVE_SCHEDULE_ON_SUCCESS =
  "REQUEST_SAVE_SCHEDULE_ON_SUCCESS";
export const REQUEST_SAVE_SCHEDULE_ON_ERROR = "REQUEST_SAVE_SCHEDULE_ON_ERROR";
export const requestSaveSchedule = (scheduleObj: any) => ({
  type: REQUEST_SAVE_SCHEDULE,
  scheduleObj
});

export const saveScheduleOnSuccess = (scheduleInfo: any) => ({
  type: REQUEST_SAVE_SCHEDULE_ON_SUCCESS,
  scheduleInfo
});

export const saveScheduleOnError = (error: any) => ({
  type: REQUEST_SAVE_SCHEDULE_ON_ERROR,
  error
});

export const REQUEST_REMOVE_SCHEDULE = "REQUEST_REMOVE_SCHEDULE";
export const REQUEST_REMOVE_SCHEDULE_ON_SUCCESS =
  "REQUEST_REMOVE_SCHEDULE_ON_SUCCESS";
export const REQUEST_REMOVE_SCHEDULE_ON_ERROR =
  "REQUEST_REMOVE_SCHEDULE_ON_ERROR";
export const requestRemoveSchedule = () => ({
  type: REQUEST_REMOVE_SCHEDULE
});

export const removeScheduleOnSuccess = () => ({
  type: REQUEST_REMOVE_SCHEDULE_ON_SUCCESS
});
