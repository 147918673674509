import { getCriterias } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchCriteriasOnError,
  fetchCriteriasOnSuccess,
  REQUEST_FETCH_CRITERIAS,
} from "redux/actions/products/criterias";

function* fetchDetailProductFlow({ id }: AnyAction) {
  try {
    const data = yield call(getCriterias, id);
    yield put(fetchCriteriasOnSuccess(data));
  } catch (error) {
    yield put(fetchCriteriasOnError(error));
  }
}

export default function* watchFetchDetailProductSaga() {
  yield takeLatest(REQUEST_FETCH_CRITERIAS, fetchDetailProductFlow);
}
