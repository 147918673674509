import aboutUs from "./aboutus";
import activate from "./activate";
import bookingPackage from "./bookingPackage";
import { combineReducers } from "redux";
import compare from "./compare";
import contact from "./contact";
import dashboard from "./dashboard";
import faq from "./faq";
import guaranteeService from "./guaranteeService";
import home from "./home";
import mailSubscriber from "./mailsubscriber";
import packageService from "./packageService";
import pay from "./pay";
import payment from "./payment";
import product from "./product";
import productTypes from "./productTypes";
import promotion from "./promotion";
import refund from "./refundpolicy";
import schedule from "./schedule";
import services from "./services";
import shipping from "./shipping";
import statusOrder from "./statusOrder";

export default combineReducers({
  home,
  aboutUs,
  promotion,
  services,
  packageService,
  bookingPackage,
  guaranteeService,
  pay,
  shipping,
  refund,
  contact,
  faq,
  schedule,
  product,
  compare,
  payment,
  statusOrder,
  dashboard,
  productTypes,
  mailSubscriber,
  activate,
});
