import {
  getAccessRefreshToken,
  getAccessToken,
  removeAccessToken,
  setAccessRefreshToken,
  setAccessToken,
} from "api/access-token";
import { updateTokenByRefreshToken } from "api/interna-api";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_URL } from "constants/env";
import { toast } from "react-toastify";

export const httpToken = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
httpToken.interceptors.request.use(
  function(config: AxiosRequestConfig) {
    const token = getAccessToken();

    // Do something before request is sent
    return {
      ...config,
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    };
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers: ((accessToken: string) => void)[] = [];

const onAccessTokenFetched = (accessToken: string): void => {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach(callback => callback(accessToken));
  subscribers = [];
};

const addSubscriber = (callback: (accessToken: string) => void): void => {
  subscribers.push(callback);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const resetTokenAndReattemptRequest = async (error: any): Promise<unknown> => {
  try {
    const { response: errorResponse } = error;
    // Your own mechanism to get the refresh token to refresh the JWT token
    const resetToken = await getAccessRefreshToken();
    if (!resetToken) {
      // We can't refresh, throw the error anyway
      toast.error(
        "Phiên xác thực đăng nhập không thành công. Vui lòng đăng nhập lại.",
      );
      return Promise.reject(error);
    }
    /* Proceed to the token refresh procedure
      We create a new Promise that will retry the request,
      clone all the request configuration from the failed
      request in the error object. */
    const retryOriginalRequest = new Promise(resolve => {
      /* We need to add the request retry to the queue
        since there another request that already attempt to
        refresh the token */
      addSubscriber((accessToken: string) => {
        errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
        resolve(axios(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const response = await updateTokenByRefreshToken(resetToken);

      if (!response.data) {
        return Promise.reject(error);
      }
      const newToken = response.data.token;
      const newRefreshToken = response.data.refreshToken;
      // save the newly refreshed token for other requests to use
      setAccessToken(newToken);
      setAccessRefreshToken(newRefreshToken);
      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(newToken);
    }
    return retryOriginalRequest;
  } catch (err) {
    toast.error("Phiên đăng nhập hết hạn. Vui lòng đăng nhập lại.");
    setTimeout(() => {
      removeAccessToken();
      window.location.reload();
      return Promise.reject(err);
    }, 1400);
  }
};

// Add a response interceptor
httpToken.interceptors.response.use(
  function(response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log(response);
    return response;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const { response } = error;
    if (response) {
      const { status } = response;
      switch (status) {
        case 401: {
          // Authentication error:
          return resetTokenAndReattemptRequest(error);
        }
      }
    }
    return Promise.reject(error);
  },
);
