export const REQUEST_FETCH_GUARANTEE_SERVICE_PAGE =
         "REQUEST_FETCH_GUARANTEE_SERVICE_PAGE";
export const FETCH_GUARANTEE_SERVICE_PAGE_SUCCESS =
         "FETCH_GUARANTEE_SERVICE_PAGE_SUCCESS";
export const FETCH_GUARANTEE_SERVICE_PAGE_ERROR =
         "FETCH_GUARANTEE_SERVICE_PAGE_ERROR";

export const requestFetchGuaranteeServicePage = () => ({
         type: REQUEST_FETCH_GUARANTEE_SERVICE_PAGE
       });

export const fetchGuaranteeServicePageOnSuccess = (guaranteeService: any) => ({
         type: FETCH_GUARANTEE_SERVICE_PAGE_SUCCESS,
         guaranteeService
       });

export const fetchGuaranteeServicePageOnError = (error: any) => ({
         type: FETCH_GUARANTEE_SERVICE_PAGE_ERROR,
         error
       });
