import Button from "components/atoms/Button";
import Textfield from "components/atoms/Textfield";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { joinClassnames } from "utils/map-modifier";
import CartBox from "../CartBox";
import Fieldrow, { Fieldcell } from "../Fieldrow";
import ToggleCartBox from "../ToggleCartBox";
import "./index.scss";

interface SearchOrderProps {
  showBox?: boolean;
  className?: string;
}

const SearchOrder = React.forwardRef<HTMLDivElement, SearchOrderProps>(
  ({ showBox, className }, ref) => {
    const { handleSubmit, getValues, setValue, register } = useForm<FormData>({
      mode: "onBlur",
      defaultValues: {
        searchKey: "",
      },
    });
    const history = useHistory();

    const onSubmit = (data: any) => {
      const { searchKey } = data;
      if (searchKey) {
        setValue("searchKey", "");
        history.push(`/ket-qua-tim-kiem-don-hang?q=${searchKey}`);
      } else return;
    };

    return (
      <div
        ref={ref}
        className={joinClassnames("m-searchOrder", className || "")}
      >
        <ToggleCartBox show={showBox} modifiers={["no-transition"]}>
          <CartBox>
            <CartBox.Body>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Fieldrow modifiers={["input"]}>
                  <Fieldcell>
                    <Textfield
                      name="searchKey"
                      placeholder="Nhập mã đơn hàng để tra cứu"
                      autoComplete="off"
                      type="text"
                      modifiers={["small"]}
                      value={getValues().searchKey}
                      noBar
                      button={
                        <Button variant="dark" size="small">
                          Tra cứu
                        </Button>
                      }
                      refs={register}
                    />
                  </Fieldcell>
                </Fieldrow>
              </form>
            </CartBox.Body>
          </CartBox>
        </ToggleCartBox>
      </div>
    );
  },
);

export default SearchOrder;
