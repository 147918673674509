import { fetchSubmitOrder } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchSubmitOrderOnError,
  fetchSubmitOrderOnSuccess,
  REQUEST_FETCH_SUBMIT_ORDER,
  resetDataOrderSuccessful,
  setPaymentMethod,
  setShippingInformation,
  setShippingMethod,
  SET_PAYMENT_METHOD,
  SET_SHIPPING_INFORMATION,
  SET_SHIPPING_METHOD,
} from "redux/actions/pages/payment";

function setShippingInformationDispatch({ shipInfo }: AnyAction) {
  return put(setShippingInformation(shipInfo));
}

function setShippingMethodDispatch({ shipMethod }: AnyAction) {
  return put(setShippingMethod(shipMethod));
}

function setPaymentMethodDispatch({ paymentMethod }: AnyAction) {
  return put(setPaymentMethod(paymentMethod));
}

function* fetchSubmitOrderDispatch({ orderDetail }: AnyAction) {
  try {
    const data = yield call(fetchSubmitOrder, orderDetail);
    yield put(fetchSubmitOrderOnSuccess(data));
    yield put(resetDataOrderSuccessful());
  } catch (error) {
    yield put(fetchSubmitOrderOnError(error));
  }
}

export default function* watchChangePaymentInfo() {
  yield takeLatest(SET_SHIPPING_INFORMATION, setShippingInformationDispatch);
  yield takeLatest(SET_SHIPPING_METHOD, setShippingMethodDispatch);
  yield takeLatest(SET_PAYMENT_METHOD, setPaymentMethodDispatch);
  yield takeLatest(REQUEST_FETCH_SUBMIT_ORDER, fetchSubmitOrderDispatch);
}
