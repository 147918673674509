import { AnyAction } from "redux";
import {
  FETCH_SUBMIT_ORDER_ERROR,
  FETCH_SUBMIT_ORDER_SUCCESS,
  REQUEST_FETCH_SUBMIT_ORDER,
  RESET_DATA_ORDER_SUCCESSFUL,
  SET_PAYMENT_METHOD,
  SET_SHIPPING_INFORMATION,
  SET_SHIPPING_METHOD,
} from "redux/actions/pages/payment";

const defaultState = {
  data: {
    shipInfo: {},
    shipMethod: "",
    paymentMethod: {},
    order: {},
    status: {},
  },
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case SET_SHIPPING_INFORMATION:
      return {
        ...state,
        data: {
          ...state.data,
          shipInfo: action.shipInfo,
        },
      };
    case SET_SHIPPING_METHOD:
      return {
        ...state,
        data: {
          ...state.data,
          shipMethod: action.shipMethod,
        },
      };
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        data: {
          ...state.data,
          paymentMethod: action.paymentMethod,
        },
      };
    case REQUEST_FETCH_SUBMIT_ORDER:
      return {
        ...state,
        data: {
          ...state.data,
          order: action.orderDetail,
        },
        loading: true,
      };
    case FETCH_SUBMIT_ORDER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          status: {
            isSuccess: true,
            data: action.orderStatus.data,
          },
        },
        loading: false,
      };
    case FETCH_SUBMIT_ORDER_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case RESET_DATA_ORDER_SUCCESSFUL:
      return {
        data: {
          shipInfo: {},
          shipMethod: "",
          paymentMethod: {},
          order: {},
          status: {},
        },
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};
