export const REQUEST_FETCH_WARDS = "REQUEST_FETCH_WARDS";
export const REQUEST_FETCH_WARDS_SUCCESS = "REQUEST_FETCH_WARDS_SUCCESS";
export const REQUEST_FETCH_WARDS_ERROR = "REQUEST_FETCH_WARDS_ERROR";

export const requestFetchWards = (districtId: number) => ({
  type: REQUEST_FETCH_WARDS,
  districtId
});

export const fetchWardsOnSuccess = (wards: any) => ({
  type: REQUEST_FETCH_WARDS_SUCCESS,
  wards
});

export const fetchWardsOnError = (error: any) => ({
  type: REQUEST_FETCH_WARDS_ERROR,
  error
});
