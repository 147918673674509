import { fetchAboutUsPage } from "api/interna-api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchAboutUsPageOnError,
  fetchAboutUsPageOnSuccess,
  REQUEST_FETCH_ABOUT_US_PAGE,
} from "redux/actions/pages/aboutus";

function* fetchAboutUsPageFlow() {
  try {
    const data = yield call(fetchAboutUsPage);
    yield put(fetchAboutUsPageOnSuccess(data));
  } catch (error) {
    yield put(fetchAboutUsPageOnError(error));
  }
}

export default function* watchFetchAboutUsPageSaga() {
  yield takeLatest(REQUEST_FETCH_ABOUT_US_PAGE, fetchAboutUsPageFlow);
}
