import { fetchScheduleCities } from "api/interna-api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchScheduleCitiesOnError,
  fetchScheduleCitiesOnSuccess,
  REQUEST_FETCH_SCHEDULE_CITIES,
} from "redux/actions/general/cities";

function* fetchScheduleCitiesFlow() {
  try {
    const data = yield call(fetchScheduleCities);
    yield put(fetchScheduleCitiesOnSuccess(data));
  } catch (error) {
    yield put(fetchScheduleCitiesOnError(error));
  }
}

export default function* watchFetchScheduleCitiesSaga() {
  yield takeLatest(REQUEST_FETCH_SCHEDULE_CITIES, fetchScheduleCitiesFlow);
}
