import { AnyAction } from "redux";
import {
  REQUEST_VALIDATION_ACCOUNT,
  REQUEST_VALIDATION_ACCOUNT_ERROR,
  REQUEST_VALIDATION_ACCOUNT_SUCCESS,
} from "redux/actions/general/validationAccount";

const defaultState = {
  data: {},
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_VALIDATION_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_VALIDATION_ACCOUNT_SUCCESS:
      return {
        ...state,
        data: action.data,
        error: null,
        loading: false,
      };
    case REQUEST_VALIDATION_ACCOUNT_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
