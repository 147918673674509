import { AnyAction } from "redux";
import {
  CHANGE_STATUS_IS_HAVE_SETUP_SERVICE,
  DECREASE_INCREASE_CART_PRODUCTS,
  FETCH_CART_PRODUCTS,
  FETCH_CART_PRODUCTS_ERROR,
  FETCH_CART_PRODUCTS_SUCCESS,
  REMOVE_CART_PRODUCTS,
  UPDATE_CART_PRODUCTS,
  USE_PROMOTION_CODE,
} from "redux/actions/products/cart";

const defaultState = {
  data: {},
  error: null,
  loading: false,
  loaded: false,
  code: null,
  checkAction: null,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case FETCH_CART_PRODUCTS:
    case DECREASE_INCREASE_CART_PRODUCTS:
    case USE_PROMOTION_CODE:
    case REMOVE_CART_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CART_PRODUCTS:
      return {
        ...state,
        error: null,
        loading: true,
        code: null,
        checkAction: null,
      };
    case FETCH_CART_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.cart,
        error: null,
        loading: false,
        loaded: true,
        code:
          action.cart.length === 0 || action.code === ""
            ? null
            : action.code && action.cart.total_promotion > 0
              ? action.code
              : state.code,
        checkAction: action.checkAction ? action.checkAction : null,
      };
    case FETCH_CART_PRODUCTS_ERROR:
      switch (action?.error?.response?.data?.errors[0]?.code) {
        case "599":
          const prevItemId = localStorage.getItem("prevcartitemId");
          if (prevItemId) {
            const prevItem: LocalPrevCartItem = JSON.parse(prevItemId);
            const updateItem: LocalPrevCartItem = {
              ...prevItem,
              isError: true,
            };
            localStorage.setItem("prevcartitemId", JSON.stringify(updateItem));
          }
          break;
        default:
          break;
      }

      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: true,
        code:
          action?.error?.response?.data?.errors[0]?.code === "518" ? "" : state.code,
        checkAction: action.checkAction ? action.checkAction : null,
      };
    case CHANGE_STATUS_IS_HAVE_SETUP_SERVICE:
      return {
        ...state,
        error: null,
        code: state.code,
        checkAction: null,
      };
    default:
      return state;
  }
};
