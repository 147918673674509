import Layout from "components/layout";
import Head from "components/molecules/Head";
import LoadingBox from "components/molecules/LoadingBox";
import Cookies from "js-cookie";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import "./index.scss";

interface ResetLocalDataProps { }

const ResetLocalData: React.FC<ResetLocalDataProps> = () => {
  const history = useHistory();
  const resetPageData = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const listLocalStorages = [
          "userToken",
          "refreshToken",
          "pathname",
          "productIds",
          "compare",
          "cart",
          "___",
          "prevcartitemId",
          "locationDelivery",
          "defaultLocation",
          "order",
          "productIds",
          "lastAddToCartProductItem",
          "machine-guest",
        ];

        const listSessionStorages = ["_cabh_", "_coupon_", "_kcg_"];

        const listCookies = [
          "_cg_",
          "cbcbk",
          "copied-code",
          "SSIDIS",
          "needVerify.InfoBuyingForm",
          "needVerify.ManageAccountForm",
          "needVerify.MessageForm",
          "needVerify.RatingCommentForm",
          "needVerify.ResetPassForm",
          "needVerify.ReviewProductForm",
          "needVerify.SearchAll",
          "needVerify.SignInUpForm",
          "needVerify.SearchOrderStatus",
        ];

        listLocalStorages.map(item => localStorage.removeItem(item));
        listSessionStorages.map(item => sessionStorage.removeItem(item));
        listCookies.map(item => Cookies.remove(item));
        resolve({ success: true });
      }, 3000);
    });
  };

  const processData = useCallback(() => {
    resetPageData()
      .then((res: any) => {
        if (res.success) {
          history.push("/");
        }
      })
      .catch(() => {
        // console.log("error");
      })
      .finally(() => { });
  }, [history]);

  useEffect(() => {
    processData();
  }, [processData]);

  return (
    <Layout>
      <Head
        data={{
          titlePage: "Reset",
          title: "Reset Page",
          description: "Reset Page",
          keywords: "",
          image: "",
        }}
      />
      <br />
      <br />
      <LoadingBox label="Đang làm mới lại trang..." />
    </Layout>
  );
};

export default ResetLocalData;
