export const REQUEST_FETCH_BANKS = "REQUEST_FETCH_BANKS";
export const REQUEST_FETCH_BANKS_SUCCESS = "REQUEST_FETCH_BANKS_SUCCESS";
export const REQUEST_FETCH_BANKS_ERROR = "REQUEST_FETCH_BANKS_ERROR";

export const requestFetchBanks = () => ({
  type: REQUEST_FETCH_BANKS,
});

export const fetchBanksOnSuccess = (banks: any) => ({
  type: REQUEST_FETCH_BANKS_SUCCESS,
  banks,
});

export const fetchBanksOnError = (error: any) => ({
  type: REQUEST_FETCH_BANKS_ERROR,
  error,
});
