export const REQUEST_FETCH_ABOUT_US_PAGE = "REQUEST_FETCH_ABOUT_US_PAGE";
export const FETCH_ABOUT_US_PAGE_SUCCESS = "FETCH_ABOUT_US_PAGE_SUCCESS";
export const FETCH_ABOUT_US_PAGE_ERROR = "FETCH_ABOUT_US_PAGE_ERROR";

export const requestFetchAboutUsPage = () => ({
  type: REQUEST_FETCH_ABOUT_US_PAGE
});

export const fetchAboutUsPageOnSuccess = (aboutUs: any) => ({
  type: FETCH_ABOUT_US_PAGE_SUCCESS,
  aboutUs
});

export const fetchAboutUsPageOnError = (error: any) => ({
  type: FETCH_ABOUT_US_PAGE_ERROR,
  error
});
