import React, { memo, useMemo } from "react";
import { Link as LinkRouter } from "react-router-dom";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import { isExternal } from "utils/utils-common";
import "./index.scss";

interface LinkProps {
  onClick?: () => void;
  weight?: "light" | "bold";
  className?: string;
  href: string;
  children: React.ReactNode;
  buttonLink?: boolean;
  disabled?: boolean;
  isLinkRouter?: boolean;
  target?: boolean;
  rel?: string;
  variant?:
    | "primary"
    | "primary-border"
    | "secondary"
    | "success"
    | "warning"
    | "danger"
    | "info"
    | "light"
    | "dark"
    | "compare"
    | "link"
    | "link-gray";
  modifiers?: (
    | "rounded"
    | "square"
    | "floating"
    | "small"
    | "login"
    | "facebook"
    | "google"
    | "white"
    | "black"
    | "colorBlack"
    | "icon"
    | "w-min-185"
    | "w-min-200"
    | "w-min-210"
    | "w-min-226"
    | "w-min-255"
    | "center")[];
  size?: "xxsmall" | "xsmall" | "small" | "large";
  width?: "fullwidth";
}

const Link: React.FC<LinkProps> = ({
  href,
  target,
  className,
  buttonLink,
  variant,
  modifiers,
  size,
  weight,
  width,
  rel,
  children,
  onClick,
}: LinkProps) => {
  const classnames = useMemo(
    () =>
      joinClassnames(
        className,
        mapMultiClassnames(
          buttonLink ? "a-link-button" : "a-link",
          variant,
          modifiers,
          size,
          weight,
          width,
        ),
      ),
    [buttonLink, className, modifiers, size, variant, weight, width],
  );

  return !isExternal(href) ? (
    <LinkRouter
      to={href || ""}
      target={target ? "_blank" : ""}
      onClick={onClick}
      className={classnames}
      rel={rel}
    >
      {children}
    </LinkRouter>
  ) : (
    <a
      rel="noreferrer"
      href={href}
      target={target ? "_blank" : ""}
      className={classnames}
    >
      {children}
    </a>
  );
};

Link.defaultProps = {
  isLinkRouter: true,
  target: false,
};

export default memo(Link);
