import { postRating } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  postRatingOnError,
  postRatingOnSuccess,
  REQUEST_POST_RATING,
} from "redux/actions/general/rating";

function* postRatingFlow({ data }: AnyAction) {
  try {
    const responseData = yield call(postRating, data);
    yield put(postRatingOnSuccess(responseData));
  } catch (error) {
    yield put(postRatingOnError(error));
  }
}

export default function* watchPostRatingSaga() {
  yield takeLatest(REQUEST_POST_RATING, postRatingFlow);
}
