import { fetchSystem } from "api/interna-api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchSystemOnError,
  fetchSystemOnSuccess,
  REQUEST_FETCH_SYSTEM,
} from "../../redux/actions/general/system";

function* fetchSystemFlow() {
  try {
    const data = yield call(fetchSystem);
    yield put(fetchSystemOnSuccess(data));
  } catch (error) {
    yield put(fetchSystemOnError(error));
  }
}

export default function* watchFetchSystemSaga() {
  yield takeLatest(REQUEST_FETCH_SYSTEM, fetchSystemFlow);
}
