import { AnyAction } from "redux";
import { ON_TOGGLE_BANNER } from "redux/actions/general/toggleBanner";

const defaultState = {
  toggle: null,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case ON_TOGGLE_BANNER:
      return {
        ...state,
        toggle: state.toggle === null ? true : false,
      };
    default:
      return state;
  }
};
