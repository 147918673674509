import React from "react";
import styled from "styled-components/macro";

export interface CarouselItemProps {
  ratio?: number;
}

const CarouselItem: React.FC<CarouselItemProps> = ({ children, ratio }) =>
  ratio ? (
    <SwiperSlideItem className="swiper-slide" ratio={ratio}>
      <SwiperItemContent>{children}</SwiperItemContent>
    </SwiperSlideItem>
  ) : (
    <div className="swiper-slide">{children}</div>
  );

const SwiperItemContent = styled.div``;

const SwiperSlideItem = styled.div<{ ratio?: number }>`
  position: relative;

  &:before {
    content: "";
    padding-bottom: ${({ ratio }) => (ratio && ratio * 100) || 100}%;
    display: block;
  }

  ${SwiperItemContent} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export default CarouselItem;
