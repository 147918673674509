import {
  InterpolationValue,
  SimpleInterpolation,
  css,
} from "styled-components/macro";

export enum SizeMin {
  XSmall = "375px",
  MXSmall = "400px",
  MSmall = "425px",
  Small = "576px",
  Medium = "768px",
  Large = "992px",
  ExtraLarge = "1200px",
  XExtraLarge = "1600px",
  XXExtraLarge = "1920px",
}

export enum SizeMax {
  XSmall = "374px",
  MSmall = "424px",
  Small = "575px",
  Medium = "767px",
  Large = "991px",
  ExtraLarge = "1199px",
  XExtraLarge = "1599px",
  XXExtraLarge = "1919px",
  SmallerMedium = "640px",
}
export const minQuery = (
  size: SizeMin,
  args: SimpleInterpolation,
): SimpleInterpolation => css`
  @media (min-width: ${size}) {
    ${args}
  }
`;

export const ipad = (
  args: SimpleInterpolation,
  orientation?: "portrait" | "landscape",
  generation?: "1_2" | "3_4",
): SimpleInterpolation | InterpolationValue => {
  if (orientation && !generation) {
    return orientation === "portrait"
      ? css`
          @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            ${args}
          }
        `
      : css`
          @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
            ${args}
          }
        `;
  }
  if (orientation && generation) {
    return css`
      @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: orientation) and (-webkit-min-device-pixel-ratio: ${generation ===
        "1_2"
          ? 1
          : 2}) {
        ${args}
      }
    `;
  }
  return css`
    @media (min-device-width: 768px) and (max-device-width: 1024px) {
      ${args}
    }
  `;
};

export const phone = (
  args: SimpleInterpolation,
  orientation?: "portrait" | "landscape",
): SimpleInterpolation | InterpolationValue => {
  if (orientation) {
    return orientation === "portrait"
      ? css`
          @media (min-device-width: 320px) and (max-device-width: 767px) and (orientation: portrait) {
            ${args}
          }
        `
      : css`
          @media (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {
            ${args}
          }
        `;
  }
  return css`
    @media (min-device-width: 320px) and (max-device-width: 767px) {
      ${args}
    }
  `;
};

export const fromIpad = (args: SimpleInterpolation): SimpleInterpolation => css`
  @media (min-device-width: 768px) {
    ${args}
  }
`;

export const fromLap12 = (
  args: SimpleInterpolation,
): SimpleInterpolation => css`
  @media (min-device-width: 1200px) {
    ${args}
  }
`;

export const maxQuery = (
  size: SizeMax,
  args: SimpleInterpolation,
): SimpleInterpolation => css`
  @media (max-width: ${size}) {
    ${args}
  }
`;

export const betweenQuery = (
  minSize: SizeMin,
  maxSize: SizeMax,
  args: SimpleInterpolation,
): SimpleInterpolation => css`
  @media (min-width: ${minSize}) and (max-width: ${maxSize}) {
    ${args}
  }
`;

export const landscape = (args: SimpleInterpolation) => css`
  @media (orientation: landscape) {
    ${args}
  }
`;

export const portrait = (args: SimpleInterpolation) => css`
  @media (orientation: portrait) {
    ${args}
  }
`;

type OrientationTypes = "portrait" | "landscape";
export const orientationQuery = (
  orientationValue: OrientationTypes,
  minDeviceWidth: string,
  maxDeviceWidth: string,
  args: SimpleInterpolation,
) => css`
  @media (orientation: orientationValue) and (min-device-width: minDeviceWidth) and (max-device-width: maxDeviceWidth) {
    ${args}
  }
`;
