import { fetcRefundPolicyPage } from "api/interna-api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchRefundPolicyPageOnError,
  fetchRefundPolicyPageOnSuccess,
  REQUEST_FETCH_REFUND_PAGE,
} from "redux/actions/pages/refundpolicy";

function* fetchRefundPolicyPageFlow() {
  try {
    const data = yield call(fetcRefundPolicyPage);
    yield put(fetchRefundPolicyPageOnSuccess(data));
  } catch (error) {
    yield put(fetchRefundPolicyPageOnError(error));
  }
}

export default function* watchFetchRefundPolicyPageSaga() {
  yield takeLatest(REQUEST_FETCH_REFUND_PAGE, fetchRefundPolicyPageFlow);
}
