import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { postSaveUTM } from 'redux/actions/general/utm';
import { UtmTypes } from 'redux/reducers/general/utm';

const useQueryParamsUTM = <Params extends Record<string, any>>() => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const queryObject = Object.fromEntries(new URLSearchParams(location.search || location.hash.replace('#', '?'))) as Params;
    const utmSource = document.referrer;
    if (queryObject && (location.search || location.hash)) {
      if (queryObject['utm_campaign']
        || queryObject['utm_content']
        || queryObject['utm_id']
        || queryObject['utm_medium']
        || queryObject['utm_source']
        || queryObject['utm_term']
        || utmSource
      ) {
        const dataUTM: UtmTypes = {
          utmCampaign: queryObject['utm_campaign'] ?? '',
          utmContent: queryObject['utm_content'] ?? '',
          utmId: queryObject['utm_id'] ?? '',
          utmMedium: queryObject['utm_medium'] ?? '',
          utmSource: queryObject['utm_source'] ?? utmSource,
          utmTerm: queryObject['utm_term'] ?? '',
        }
        dispatch(postSaveUTM(dataUTM));
      }
      
    }

    
  }, [location.search, location.hash, dispatch]);
  
};

export default useQueryParamsUTM;
