import { fetchSearchProducts } from "api/interna-api";
import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import Image from "components/atoms/Image";
import Link from "components/atoms/Link";
import Textfield from "components/atoms/Textfield";
import { BASE_URL } from "constants/env";
import { useDebounce } from "hooks/useDebounce";
import Cookies from "js-cookie";
import React, { memo, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { joinClassnames } from "utils/map-modifier";
import { notEmptyObject } from "utils/utils-common";
import CartBox from "../CartBox";
import ToggleSearchResults from "../ToggleSearchResults";
import "./index.scss";

interface SearchAllProps {
  className?: string;
  isShowSearch?: boolean;
  onSubmitted?: () => void;
}

const SearchAll = React.forwardRef<HTMLDivElement, SearchAllProps>(
  ({ className, isShowSearch, onSubmitted }, ref) => {
    const [searchProductResult, setSearchProductResult] = useState([]);
    const history = useHistory();
    const [show, setToggle] = useState(false);
    const [searchKeyValue, setSearchKeyValue] = useState("");
    const formSearchRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const debouncedSearch = useDebounce(searchKeyValue, 1000);

    useEffect(() => {
      if (debouncedSearch) {
        if (searchKeyValue) {
          fetchSearchProducts({ searchKey: searchKeyValue })
            .then(rs => {
              if (Object.entries(rs).length > 0) {
                const { products } = rs;
                setSearchProductResult(products);
              }
            })
            .catch(error => {
              // console.log(error);
            });
        }
      } else {
        setSearchProductResult([]);
      }
    }, [debouncedSearch, searchKeyValue]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleClick = (e: any) => {
      if (formSearchRef.current && !formSearchRef.current.contains(e.target)) {
        setToggle(false);
      }
    };

    useEffect(() => {
      window.addEventListener("click", handleClick);
      return () => {
        window.removeEventListener("click", handleClick);
      };
    }, [handleClick]);

    const handleToggleSearch = () => {
      setToggle(!show);
    };

    const { getValues, handleSubmit, register, errors } = useForm<FormData>({
      defaultValues: {
        searchKey: "",
      },
    });

    const onSubmit = (data: any) => {
      if (data.searchKey !== null) {
        const { searchKey } = data;
        setToggle(true);
        setSearchKeyValue("");
        history.push(`/ket-qua-tim-kiem?q=${searchKey}`);
        onSubmitted && onSubmitted();
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [needVerify, setNeedVerify] = useState(false);
    const [count, setCount] = useState<number>();

    useEffect(() => {
      if (notEmptyObject(errors)) {
        const countSave = Cookies.get("needVerify.SearchAll");
        setCount(countSave ? Number(countSave) + 1 : count ? count + 1 : 1);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
      if (count !== undefined) {
        setNeedVerify(count === 3);
        Cookies.set("needVerify.SearchAll", count ? count.toString() : "1", {
          expires: 1, // 1 day
        });
      }
    }, [count]);

    useEffect(() => {
      const checkNeedVerifyCount = Cookies.get("needVerify.SearchAll");
      if (checkNeedVerifyCount) {
        setCount(Number(checkNeedVerifyCount));
        if (Number(checkNeedVerifyCount) >= 3) {
          setNeedVerify(true);
        }
      }
    }, []);

    return (
      <div
        className={joinClassnames(
          "m-searchAll",
          className || "",
          isShowSearch ? "show" : "",
        )}
        ref={ref}
      >
        <div
          className="form-h-search"
          onClick={handleToggleSearch}
          ref={formSearchRef}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Textfield
              name="searchKey"
              noBar
              modifiers={["xsmall"]}
              value={getValues().searchKey}
              refs={register}
              placeholder="Nhập sản phẩm dịch vụ cần tìm"
              onChange={e => {
                setSearchKeyValue(e.target.value);
              }}
              required
              autoComplete="off"
            />
            <Button variant="link" type="submit">
              <Icon iconName="search" />
            </Button>
          </form>
          {searchProductResult.length > 0 && show && (
            <ToggleSearchResults>
              <CartBox>
                <CartBox.Body>
                  <CartBox.Scroll>
                    {searchProductResult.map(p => (
                      <Link
                        key={`search-item-index-${p.product_id}`}
                        href={`/san-pham/${p.slug}`}
                      >
                        <CartBox.Item key={p.product_id}>
                          <CartBox.Image>
                            <Image
                              src={`${BASE_URL}${p.thumbnail}`}
                              alt={p.name}
                            />
                          </CartBox.Image>
                          <CartBox.Info>
                            <CartBox.Name>{p.name}</CartBox.Name>
                            <CartBox.Model>Model: {p.model}</CartBox.Model>
                            <CartBox.Price>
                              <CartBox.Label>Giá</CartBox.Label>
                              <CartBox.Val>
                                <>
                                  {p.discount &&
                                    Object.keys(p.discount).length > 0 &&
                                    p.discount.discount_value > 0 ? (
                                    <ins>
                                      {window.formatMoney(
                                        p.discount.discount_type === 1
                                          ? Math.round(
                                            (p.price *
                                              (1 -
                                                p.discount.discount_value /
                                                100)) /
                                            1000,
                                          ) * 1000
                                          : Math.round(
                                            (p.price -
                                              p.discount.discount_value) /
                                            1000,
                                          ) * 1000,
                                      )}
                                    </ins>
                                  ) : (
                                    p.price && (
                                      <ins>
                                        {window.formatMoney(
                                          Math.round(p.price / 1000) * 1000,
                                        )}
                                      </ins>
                                    )
                                  )}
                                </>
                              </CartBox.Val>
                            </CartBox.Price>
                          </CartBox.Info>
                        </CartBox.Item>
                      </Link>
                    ))}
                  </CartBox.Scroll>
                </CartBox.Body>
              </CartBox>
            </ToggleSearchResults>
          )}
        </div>
      </div>
    );
  },
);

SearchAll.defaultProps = {};

export default memo(SearchAll);
