import React from "react";
import "./index.scss";

type Modifiers = "center";

interface Props {
  modifiers?: Modifiers[];
}
interface Component extends React.FC<Props> {
  Header: React.FC<Props>;
  Title: React.FC<Props>;
  Body: React.FC<Props>;
  Scroll: React.FC<Props>;
  Item: React.FC<Props>;
  Image: React.FC<Props>;
  Info: React.FC<Props>;
  Name: React.FC<Props>;
  Model: React.FC<Props>;
  Price: React.FC<Props>;
  Label: React.FC<Props>;
  Val: React.FC<Props>;
  Quantity: React.FC<Props>;
  Input: React.FC<Props>;
  Footer: React.FC<Props>;
}

const CartBox: Component = props => {
  return <div className={"m-cart-box"}>{props.children}</div>;
};

CartBox.Header = props => {
  return <div className={"m-cart-box-header"}>{props.children}</div>;
};

CartBox.Title = props => {
  return <div className={"m-cart-box-header-title"}>{props.children}</div>;
};

CartBox.Body = props => {
  return <div className={"m-cart-box-body"}>{props.children}</div>;
};

CartBox.Scroll = props => {
  return <div className={"m-cart-box-body-scroll"}>{props.children}</div>;
};

CartBox.Item = props => {
  return <div className={"m-cart-box-item"}>{props.children}</div>;
};

CartBox.Image = props => {
  return <div className={"m-cart-box-image"}>{props.children}</div>;
};

CartBox.Info = props => {
  return <div className={"m-cart-box-item-info"}>{props.children}</div>;
};

CartBox.Name = props => {
  return <div className={"m-cart-box-item-info-name"}>{props.children}</div>;
};

CartBox.Model = props => {
  return <div className={"m-cart-box-item-info-model"}>{props.children}</div>;
};

CartBox.Price = props => {
  return <div className={"m-cart-box-item-info-price"}>{props.children}</div>;
};

CartBox.Label = props => {
  return <div className={"m-cart-box-item-info-label"}>{props.children}</div>;
};

CartBox.Val = props => {
  return <div className={"m-cart-box-item-info-val"}>{props.children}</div>;
};

CartBox.Quantity = props => {
  return (
    <div className={"m-cart-box-item-info-quantity"}>{props.children}</div>
  );
};

CartBox.Input = props => {
  return (
    <div className={"m-cart-box-item-info-quantity-input"}>
      {props.children}
    </div>
  );
};

CartBox.Footer = props => {
  return <div className={"m-cart-box-footer"}>{props.children}</div>;
};

export default CartBox;
