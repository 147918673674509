export const REQUEST_FETCH_CITIES = "REQUEST_FETCH_CITIES";
export const REQUEST_FETCH_CITIES_SUCCESS = "REQUEST_FETCH_CITIES_SUCCESS";
export const REQUEST_FETCH_CITIES_ERROR = "REQUEST_FETCH_CITIES_ERROR";
export const REQUEST_FETCH_SCHEDULE_CITIES = "REQUEST_FETCH_SCHEDULE_CITIES";
export const REQUEST_FETCH_SCHEDULE_CITIES_SUCCESS =
  "REQUEST_FETCH_SCHEDULE_CITIES_SUCCESS";
export const REQUEST_FETCH_SCHEDULE_CITIES_ERROR =
  "REQUEST_FETCH_SCHEDULE_CITIES_ERROR";

export const requestFetchCities = (countryId: number) => ({
  type: REQUEST_FETCH_CITIES,
  countryId,
});

export const fetchCitiesOnSuccess = (cities: any) => ({
  type: REQUEST_FETCH_CITIES_SUCCESS,
  cities,
});

export const fetchCitiesOnError = (error: any) => ({
  type: REQUEST_FETCH_CITIES_ERROR,
  error,
});

export const requestFetchScheduleCities = () => ({
  type: REQUEST_FETCH_SCHEDULE_CITIES,
});

export const fetchScheduleCitiesOnSuccess = (cities: any) => ({
  type: REQUEST_FETCH_SCHEDULE_CITIES_SUCCESS,
  cities,
});

export const fetchScheduleCitiesOnError = (error: any) => ({
  type: REQUEST_FETCH_SCHEDULE_CITIES_ERROR,
  error,
});
