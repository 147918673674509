export const SET_SHIPPING_INFORMATION = "SET_SHIPPING_INFORMATION";

export const SET_SHIPPING_METHOD = "SET_SHIPPING_METHOD";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";

export const REQUEST_FETCH_SUBMIT_ORDER = "REQUEST_FETCH_SUBMIT_ORDER";
export const FETCH_SUBMIT_ORDER_SUCCESS = "FETCH_SUBMIT_ORDER_SUCCESS";
export const FETCH_SUBMIT_ORDER_ERROR = "FETCH_SUBMIT_ORDER_ERROR";
export const RESET_DATA_ORDER_SUCCESSFUL = "RESET_DATA_ORDER_SUCCESSFUL";

export const setShippingInformation = (shipInfo: Object) => ({
  type: SET_SHIPPING_INFORMATION,
  shipInfo,
});

export const setShippingMethod = (shipMethod: string) => ({
  type: SET_SHIPPING_METHOD,
  shipMethod,
});

export const setPaymentMethod = (paymentMethod: Object) => ({
  type: SET_PAYMENT_METHOD,
  paymentMethod,
});

export const requestFetchSubmitOrder = (orderDetail: any) => ({
  type: REQUEST_FETCH_SUBMIT_ORDER,
  orderDetail,
});

export const fetchSubmitOrderOnSuccess = (orderStatus: any) => ({
  type: FETCH_SUBMIT_ORDER_SUCCESS,
  orderStatus,
});

export const fetchSubmitOrderOnError = (error: any) => ({
  type: FETCH_SUBMIT_ORDER_ERROR,
  error,
});

export const resetDataOrderSuccessful = () => ({
  type: RESET_DATA_ORDER_SUCCESSFUL,
});
