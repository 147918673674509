import React from "react";
import { mapMultiClassnames } from "utils/map-modifier";
import "./index.scss";

interface Props {
  modifiers?: string[];
  onClick?: () => void;
  itemClassName?: string;
  children?: React.ReactNode;
}

const NavTop: React.FC<Props> = ({ modifiers = [], children }) => (
  <ul className={mapMultiClassnames("m-nav-top-ul", modifiers)}>{children} </ul>
);

export const NavTopItem: React.FC<Props> = ({
  itemClassName = "",
  children,
}) => <li className={itemClassName}>{children}</li>;

export default React.memo(NavTop);
