export const FETCH_CART_PRODUCTS = "FETCH_CART_PRODUCTS";
export const FETCH_CART_PRODUCTS_SUCCESS = "FETCH_CART_PRODUCTS_SUCCESS";
export const FETCH_CART_PRODUCTS_ERROR = "FETCH_CART_PRODUCTS_ERROR";

export const DECREASE_INCREASE_CART_PRODUCTS =
  "DECREASE_INCREASE_CART_PRODUCTS";
export const REMOVE_PRODUCT_IN_CART = "REMOVE_PRODUCT_IN_CART";
export const UPDATE_CART_PRODUCTS = "UPDATE_CART_PRODUCTS";
export const USE_PROMOTION_CODE = "USE_PROMOTION_CODE";
export const USE_PROMOTION_CODE_ERROR = "USE_PROMOTION_CODE_ERROR";
export const USE_PROMOTION_CODE_SUCCESS = "USE_PROMOTION_CODE_SUCCESS";
export const REMOVE_CART_PRODUCTS = "REMOVE_CART_PRODUCTS";
export const REMOVE_PROMOTION_CODE = "REMOVE_PROMOTION_CODE";
export const REMOVE_PROMOTION_CODE_SUCCESS = "REMOVE_PROMOTION_CODE_SUCCESS";
export const REMOVE_PROMOTION_CODE_ERROR = "REMOVE_PROMOTION_CODE_ERROR";

export const CHANGE_STATUS_IS_HAVE_SETUP_SERVICE =
  "CHANGE_STATUS_IS_HAVE_SETUP_SERVICE";

export const fetchCartProducts = (code?: any, checkAction?: any) => ({
  type: FETCH_CART_PRODUCTS,
  code,
  checkAction,
});

export const removePromotionCode = () => ({
  type: REMOVE_PROMOTION_CODE,
});

export const fetchCartProductsOnSuccess = (
  cart: any,
  code?: any,
  checkAction?: any,
) => {
  return {
    type: FETCH_CART_PRODUCTS_SUCCESS,
    cart,
    code,
    checkAction,
  };
};

export const fetchCartProductsOnError = (error: any, checkAction?: any) => ({
  type: FETCH_CART_PRODUCTS_ERROR,
  error,
  checkAction,
});

export const descreaseIncreaseCartProducts = (
  product: any,
  sign: string,
  code?: string,
) => ({
  type: DECREASE_INCREASE_CART_PRODUCTS,
  product,
  sign,
  code,
});

export const updateCartProducts = (product: any, quantity: number) => ({
  type: UPDATE_CART_PRODUCTS,
  product,
  quantity,
});

// action call use promotion code:
export const applyPromotionCode = (code: string, checkAction: string) => ({
  type: USE_PROMOTION_CODE,
  code,
  checkAction,
});

export const removeCartProducts = (code?: any) => ({
  type: REMOVE_CART_PRODUCTS,
  code,
});

export const onChangeStatusIsHaveSetupService = (
  productId: number,
  code?: string,
) => ({
  type: CHANGE_STATUS_IS_HAVE_SETUP_SERVICE,
  productId,
  code,
});
