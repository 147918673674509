import { fetchContactPage } from "api/interna-api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchContactPageOnError,
  fetchContactPageOnSuccess,
  REQUEST_FETCH_CONTACT_PAGE,
} from "redux/actions/pages/contact";

function* fetchContactPageFlow() {
  try {
    const data = yield call(fetchContactPage);
    yield put(fetchContactPageOnSuccess(data));
  } catch (error) {
    yield put(fetchContactPageOnError(error));
  }
}

export default function* watchFetchContactPageSaga() {
  yield takeLatest(REQUEST_FETCH_CONTACT_PAGE, fetchContactPageFlow);
}
