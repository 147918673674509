import Layout from "components/layout";
import Breadcrumb, {
  BreadcrumbItem,
  BreadcrumbItemLink,
} from "components/molecules/Breadcrumb";
import SignInUpForm from "components/molecules/SignInUpForm";
import Grid, { GridCol, GridRow } from "components/organisms/Grid";
import Section from "components/organisms/Section";
import React, { lazy } from "react";
import { useLocation } from "react-router-dom";
import "./index.scss";
const Head = lazy(() => import("components/molecules/Head"));

interface LoginProps {}

const SignInUp: React.FC<LoginProps> = (props: LoginProps) => {
  const owlClass = "p-signinup";
  const location = useLocation();
  const page = location.pathname;
  return (
    <div className={owlClass}>
      <Layout>
        <Head
          data={{
            titlePage: "Đăng ký / Đăng nhập",
            title: "",
            description: "",
            keywords: "",
            image: "",
          }}
        />
        <Breadcrumb>
          <BreadcrumbItemLink href="/">Trang chủ</BreadcrumbItemLink>
          <BreadcrumbItemLink href="/tai-khoan">Tài Khoản</BreadcrumbItemLink>
          <BreadcrumbItem>
            {page === "/dang-nhap" ? "Đăng nhập" : "Đăng ký"}
          </BreadcrumbItem>
        </Breadcrumb>
        <Section space="space-xl">
          <Grid>
            <GridRow>
              <GridCol colMd={12}>
                <SignInUpForm />
              </GridCol>
            </GridRow>
          </Grid>
        </Section>
      </Layout>
    </div>
  );
};

SignInUp.defaultProps = {};

export default SignInUp;
