export const REQUEST_ACTIVATE = "REQUEST_ACTIVATE";
export const REQUEST_ACTIVATE_SUCCESS = "REQUEST_ACTIVATE_SUCCESS";
export const REQUEST_ACTIVATE_ERROR = "REQUEST_ACTIVATE_ERROR";

export const requestActivate = (token: string) => ({
  type: REQUEST_ACTIVATE,
  token,
});

export const requestActivateOnSuccess = (status: any) => ({
  type: REQUEST_ACTIVATE_SUCCESS,
  status,
});

export const requestActivateOnError = (error: any) => ({
  type: REQUEST_ACTIVATE_ERROR,
  error,
});
