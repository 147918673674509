import { fetchSubSkyAir } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchSubSkyAirOnError,
  fetchSubSkyAirOnSuccess,
  REQUEST_FETCH_SUB_SKYAIR,
} from "redux/actions/general/subSkyAir";

function* fetchSubSkyAirFlow({ slug }: AnyAction) {
  try {
    const data = yield call(fetchSubSkyAir, slug);
    yield put(fetchSubSkyAirOnSuccess(data));
  } catch (error) {
    yield put(fetchSubSkyAirOnError(error));
  }
}

export default function* watchFetchSubSkyAirsSaga() {
  yield takeLatest(REQUEST_FETCH_SUB_SKYAIR, fetchSubSkyAirFlow);
}
