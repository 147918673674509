export const SAVE_LOCATION_BUYING = "SAVE_LOCATION_BUYING";
export const GET_LOCATION_BUYING = "GET_LOCATION_BUYING";
export const REMOVE_LOCATION_BUYING = "REMOVE_LOCATION_BUYING";

export const saveLocationBuying = (locationBuying: LocationItem) => ({
  type: SAVE_LOCATION_BUYING,
  locationBuying,
});

export const getLocationBuying = (locationBuying: LocationItem) => ({
  type: GET_LOCATION_BUYING,
  locationBuying,
});

export const removeLocationBuying = () => ({
  type: REMOVE_LOCATION_BUYING,
});
