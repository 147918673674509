import TimeAgo from "javascript-time-ago";
import moment from "moment";
import vi from "javascript-time-ago/locale/vi";

TimeAgo.addDefaultLocale(vi);

const timeAgo = new TimeAgo("vi");

export const nonAccentVietnamese = (str: string) => {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
};

export function getParams(): { [key: string]: string } {
  return window.location.search
    .split(/[?&]/)
    .map(s => s.split("="))
    .filter(a => a.length === 2)
    .map(([k, v]) => [decodeURIComponent(k), decodeURIComponent(v)])
    .reduce(
      (a, [k, v]) => ({
        ...a,
        [k]: v,
      }),
      {},
    );
}

export const mappingChildrenToParent = (
  array: any,
  footerMenu: boolean = false,
) => {
  var map: any = {};
  for (var i = 0; i < array.length; i++) {
    var obj = array[i];
    obj.children = [];
    map[obj.id] = obj;
    if (footerMenu && obj.parent_id === 14) {
      delete obj.parent_id;
    }
    if (obj.parent_id === 1) {
      delete obj.parent_id;
    }
    var parent = obj.parent_id || "-";
    if (!map[parent]) {
      map[parent] = {
        children: [],
      };
    }
    map[parent].children.push(obj);
  }
  return map["-"].children;
};

window.formatMoney = (value: any) =>
  new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "VND",
    currencyDisplay: "symbol",
  }).format(value);

export const formatMoneyD = (value: number) =>
  Math.round(value).toLocaleString().replaceAll(',', '.');

const checkDomain = (url: string) => {
  if (url.indexOf("//") === 0) {
    url = window.location.protocol + url;
  }
  return url
    .toLowerCase()
    .replace(/([a-z])?:\/\//, "$1")
    .split("/")[0];
};

export const isExternal = (url: string) =>
  url &&
  ((url.length > 1 && url.indexOf(":") > -1) || url.indexOf("//") > -1) &&
  checkDomain(window.location.href) !== checkDomain(url);

export const filterSEOTags = (systemData: SystemProps[]) =>
  systemData.filter(
    (item: SystemProps) =>
      String(item.key) === "seo_title" ||
      String(item.key) === "seo_description" ||
      String(item.key) === "seo_image" ||
      String(item.key) === "seo_keyword" ||
      String(item.key) === "twitter_url" ||
      String(item.key) === "twitter_logo" ||
      [],
  );

export const processCashbackString = (content: string) =>
  content
    .replace("\r", "")
    .replace("\n", "")
    .replace("<div>&nbsp;</div>", "");

export const replaceTagByEmpty = (
  str: string,
  rStr: string,
  exceptULLI?: boolean,
) => {
  if (!str) {
    return "";
  }

  let result = str
    .replace(/<div>/g, rStr)
    .replace(/<\/div>/g, rStr)
    .replace(/<p>/g, rStr)
    .replace(/<\/p>/g, rStr)
    .replace(/<p>&nbsp;<\/p>/g, rStr)
    .replace(/&nbsp;/g, rStr)
    .replace(/<\/br>/g, rStr);

  if (exceptULLI) {
    result = result
      .replace(/<ul>/g, rStr)
      .replace(/<\/ul>/g, rStr)
      .replace(/<li>/g, rStr)
      .replace(/<\/li>/g, rStr);
  }

  return result;
};

export const notEmptyObject = (obj: {}): boolean =>
  obj && Object.keys(obj).length > 0 && obj.constructor === Object;

export const emptyObject = (obj: {}): boolean =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export const scrollIntoId = (id: string, offset = 0) => {
  const targetId = document.getElementById(id);
  if (targetId) {
    window.scrollTo({
      top: targetId.offsetTop - offset,
      left: 0,
      behavior: "smooth",
    });
  }
};

export const scrollIntoRef = (refEle: HTMLDivElement, offset = 0) => {
  if (refEle) {
    window.scrollTo({
      top: refEle.offsetTop - offset,
      left: 0,
      behavior: "smooth",
    });
  }
};

export const scrollIntoPosition = (point: number) => {
  if (point) {
    window.scrollTo({
      top: point,
      left: 0,
      behavior: "smooth",
    });
  }
};

export const cumulativeOffset = element => {
  var top = 0,
    left = 0;
  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while (element);

  return {
    top: top,
    left: left,
  };
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

const regexFindVideo = /(.avi|.mp4|.flv|.mov|.wmv)$/;
// const images = ['a.avi','b.mp3','c.mp4','d.flv','e.mov','f.wmv','g.png','h.jpg']
// const videoTypeList = images.filter(item => item.match(regexFindVideo))
// const otherTypeList = images.filter(item => !item.match(regexFindVideo))

// ************************************
// isVideo function:
// ************************************
export const isVideo = (url: string) => url.match(regexFindVideo);

// ************************************
// scrolled function:
// ************************************

// Usage:

// 1.
// HTML implement:
// <InfiniteScrollExtendedOuter
//   onScroll={e =>
//     scrolled(e.currentTarget, handleCbScrolled)
//   }
// >
// ...
// </InfiniteScrollExtendedOuter>

// 2.
// Callback function handleCbScrolled:
// const handleCbScrolled = (target: EventTarget & HTMLDivElement) => {
//   console.log(target.clientHeight);
// };

/**
 * Checking when scrolled to end of the element
 * @param target the target dom element
 * @param cb the callback function data
 
 * @html-implement
 * <InfiniteScrollExtendedOuter onScroll={e => scrolled(e.currentTarget, handleCbScrolled)}>...</InfiniteScrollExtendedOuter>
 * @method-implement
 * const handleCbScrolled = (target: EventTarget & HTMLDivElement) => {console.log(target.clientHeight);};
 */

// export const scrolled = (target: any, cb: (target: any) => void) => {
//   if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
//     cb(target);
//   }
// };

// ************************************
// Checking Object avalable data:
// ************************************

/**
 * Get keys or values of a special object
 * @param obj the object to process
 * @param type the type to get keys or values
 */
export const getObject = (obj: Object, type: "keys" | "values" | "enties") =>
  type === "keys"
    ? Object.keys(obj)
    : type === "values"
      ? Object.values(obj)
      : Object.entries(obj);

// ************************************
// Checking Object has a key:
// ************************************

/**
 * Get keys or values of a special object
 * @param obj the object to process
 * @param key the key to find
 */
// export const objectHasKey = (obj: Object, key: string) =>
//   obj.hasOwnProperty(key);

// ************************************
// Checking Object has some keys:
// ************************************

/**
 * Get keys or values of a special object
 * @param obj the object to process
 * @param keys the key to find
 */
// export const objectHasKeys = (obj: Object, keys: string[]) =>
//   keys.map(key => obj.hasOwnProperty(key));

// ************************************
// Checking Object only has some keys:
// ************************************

/**
 * Get keys or values of a special object
 * @param obj the object to process
 * @param keys the key to find
 */
// export const objectOnlyHasKey = (obj: Object, key: string) =>
//   getObject(obj, "keys").every(k => k === key);

/**
 * Check keys of a special object is available
 * @param obj the object to process
 */
export const isAvailableObject = (obj: Object) =>
  getObject(obj, "keys").length > 0;

export const BROWSERS = {
  MozillaFirefox: "Mozilla Firefox",
  SamsungInternet: "Samsung Internet",
  Opera: "Opera",
  MicrosoftInternetExplorer: "Microsoft Internet Explorer",
  MicrosoftEdge: "Microsoft Edge",
  GoogleChromeOrChromium: "Google Chrome or Chromium",
  AppleSafari: "Apple Safari",
  Unknown: "unknown",
};

export const getBrowser = () => {
  let sBrowser;
  const sUsrAg = navigator.userAgent;

  // The order matters here, and this may report false positives for unlisted browsers.

  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = BROWSERS.MozillaFirefox;
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    sBrowser = BROWSERS.SamsungInternet;
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = BROWSERS.Opera;
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = BROWSERS.MicrosoftInternetExplorer;
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf("Edge") > -1) {
    sBrowser = BROWSERS.MicrosoftEdge;
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = BROWSERS.GoogleChromeOrChromium;
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = BROWSERS.AppleSafari;
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = BROWSERS.Unknown;
  }
  return sBrowser;
};

export const getMachine = (): Machine => {
  var unknown = "-";

  // screen
  var screenSize = "";
  let width, height;
  const screen = window.screen;

  if (screen.width) {
    width = screen.width ? screen.width : "";
    height = screen.height ? screen.height : "";
    screenSize += "" + width + " x " + height;

    while (screenSize.indexOf(" ") > -1) {
      screenSize = screenSize.replace(" ", "");
    }
  }

  // browser
  var nVer = navigator.appVersion;
  var nAgt = navigator.userAgent;
  var browser = navigator.appName;
  var version = "" + parseFloat(navigator.appVersion);
  var majorVersion = parseInt(navigator.appVersion, 10);
  var nameOffset, verOffset, ix;

  // Opera
  if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
    browser = "Opera";
    version = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) !== -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Opera Next
  if ((verOffset = nAgt.indexOf("OPR")) !== -1) {
    browser = "Opera";
    version = nAgt.substring(verOffset + 4);
  }
  // Legacy Edge
  else if ((verOffset = nAgt.indexOf("Edge")) !== -1) {
    browser = "Microsoft Legacy Edge";
    version = nAgt.substring(verOffset + 5);
  }
  // Edge (Chromium)
  else if ((verOffset = nAgt.indexOf("Edg")) !== -1) {
    browser = "Microsoft Edge";
    version = nAgt.substring(verOffset + 4);
  }
  // MSIE
  else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
    browser = "Microsoft Internet Explorer";
    version = nAgt.substring(verOffset + 5);
  }
  // Chrome
  else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browser = "Chrome";
    version = nAgt.substring(verOffset + 7);
  }
  // Safari
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browser = "Safari";
    version = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) !== -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Firefox
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browser = "Firefox";
    version = nAgt.substring(verOffset + 8);
  }
  // MSIE 11+
  else if (nAgt.indexOf("Trident/") !== -1) {
    browser = "Microsoft Internet Explorer";
    version = nAgt.substring(nAgt.indexOf("rv:") + 3);
  }
  // Other browsers
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (verOffset = nAgt.lastIndexOf("/"))
  ) {
    browser = nAgt.substring(nameOffset, verOffset);
    version = nAgt.substring(verOffset + 1);
    if (browser.toLowerCase() === browser.toUpperCase()) {
      browser = navigator.appName;
    }
  }
  // trim the version string
  if ((ix = version.indexOf(";")) !== -1) version = version.substring(0, ix);
  if ((ix = version.indexOf(" ")) !== -1) version = version.substring(0, ix);
  if ((ix = version.indexOf(")")) !== -1) version = version.substring(0, ix);

  majorVersion = parseInt("" + version, 10);
  if (isNaN(majorVersion)) {
    version = "" + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  // mobile version
  var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

  // cookie
  var cookieEnabled = navigator.cookieEnabled ? true : false;

  if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled) {
    document.cookie = "testcookie";
    cookieEnabled = document.cookie.indexOf("testcookie") !== -1 ? true : false;
  }

  // system
  var os = unknown;
  var clientStrings = [
    { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
    { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
    { s: "Windows Vista", r: /Windows NT 6.0/ },
    { s: "Windows Server 2003", r: /Windows NT 5.2/ },
    { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
    { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
    { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
    { s: "Windows 98", r: /(Windows 98|Win98)/ },
    { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
    { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: "Windows CE", r: /Windows CE/ },
    { s: "Windows 3.11", r: /Win16/ },
    { s: "Android", r: /Android/ },
    { s: "Open BSD", r: /OpenBSD/ },
    { s: "Sun OS", r: /SunOS/ },
    { s: "Chrome OS", r: /CrOS/ },
    { s: "Linux", r: /(Linux|X11(?!.*CrOS))/ },
    { s: "iOS", r: /(iPhone|iPad|iPod)/ },
    { s: "Mac OS X", r: /Mac OS X/ },
    { s: "Mac OS", r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: "QNX", r: /QNX/ },
    { s: "UNIX", r: /UNIX/ },
    { s: "BeOS", r: /BeOS/ },
    { s: "OS/2", r: /OS\/2/ },
    {
      s: "Search Bot",
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
    },
  ];
  for (var id in clientStrings) {
    var cs = clientStrings[id];
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }

  var osVersion = unknown;

  if (/Windows/.test(os)) {
    osVersion = /Windows (.*)/.exec(os)[1];
    os = "Windows";
  }

  switch (os) {
    case "Mac OS":
    case "Mac OS X":
    case "Android":
      osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([._\d]+)/.exec(
        nAgt,
      )[1];
      break;

    case "iOS":
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
      osVersion = osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0);
      break;
  }

  // flash (you'll need to include swfobject)
  /* script src="//ajax.googleapis.com/ajax/libs/swfobject/2.2/swfobject.js" */
  var flashVersion = "no check";
  if (typeof swfobject !== "undefined") {
    var fv = swfobject.getFlashPlayerVersion();
    if (fv.major > 0) {
      flashVersion = fv.major + "." + fv.minor + " r" + fv.release;
    } else {
      flashVersion = unknown;
    }
  }

  while (os.indexOf(" ") > -1) {
    os = os.replace(" ", "");
  }

  return {
    id: `local-machine-${os}-${osVersion}-${browser}-${version}-${majorVersion}-${screenSize}`,
    screen: screenSize,
    browser: browser,
    browserVersion: version,
    browserMajorVersion: majorVersion,
    mobile: mobile,
    os,
    osVersion: osVersion,
    cookies: cookieEnabled,
    flashVersion: flashVersion,
    visited: 1,
  };
};

export const saveThisMachine = (mc?: Machine) => {
  if (mc) {
    localStorage.setItem("machine-guest", JSON.stringify({ ...mc }));
  } else {
    const thisMachine = getThisMachine();
    if (thisMachine) {
      localStorage.setItem("machine-guest", JSON.stringify({ ...thisMachine }));
    } else {
      const info = getMachine();
      localStorage.setItem("machine-guest", JSON.stringify(info));
    }
  }
};

export const getThisMachine = (): Machine => {
  const info = localStorage.getItem("machine-guest");
  return info ? JSON.parse(info) : getMachine();
};

export const updateThisMachineVisitCount = (newProps?: any): number => {
  const thisMachine = getThisMachine();
  const visited = thisMachine.visited ? thisMachine.visited + 1 : 1;
  const newUpdate: Machine = {
    ...thisMachine,
    visited,
    ...newProps,
  };
  saveThisMachine(newUpdate);
  return visited;
};

export const isBetweenHoliday = (startDate: Date, endDate: Date) => {
  const now = new Date();
  return now > startDate && now < endDate;
};

export const getTimeAgo = (createdAt: string) => {
  const newDate = moment(createdAt).format("MM/DD/YYYY hh:mm:ss");
  return timeAgo.format(new Date(newDate));
};

// const tax10 = (taxCode: string) =>
//   taxCode.length === 10 && !isNaN(Number(taxCode));
// const tax13 = (taxCode: string) =>
//   taxCode.length === 14 &&
//   taxCode.substr(10, 1) === "-" &&
//   !isNaN(Number(taxCode.substr(0, 9))) &&
//   !isNaN(Number(taxCode.substr(11, 3)));

export const checkTaxCode = (taxCode: string) => checkInputText(taxCode);
// tax10(taxCode) || tax13(taxCode);

export const checkAddress = (address: string) =>
  !!address.match(
    // eslint-disable-next-line no-useless-escape
    /^[a-z0-9A-Z_ ,\/\-ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂ ưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+$/,
  );
export const checkInputText = (inputText: string) =>
  !!inputText.match(
    // eslint-disable-next-line no-useless-escape
    /^[a-z0-9A-Z_ ,\/ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂ ưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+$/,
  );

export const convertToLocaleDate = (date: Date) =>
  new window.Date(date).toLocaleDateString();
