export const REQUEST_POST_COMMENT = "REQUEST_POST_COMMENT";
export const REQUEST_POST_COMMENT_SUCCESS = "REQUEST_POST_COMMENT_SUCCESS";
export const REQUEST_POST_COMMENT_ERROR = "REQUEST_POST_COMMENT_ERROR";
export const RESET_COMMENT = "RESET_COMMENT";

export const resetComment = () => ({
  type: RESET_COMMENT,
});

export const requestPostComment = (data: any) => ({
  type: REQUEST_POST_COMMENT,
  data,
});

export const postCommentOnSuccess = (data: any) => ({
  type: REQUEST_POST_COMMENT_SUCCESS,
  data,
});

export const postCommentOnError = (error: any) => ({
  type: REQUEST_POST_COMMENT_ERROR,
  error,
});
