import Button from "components/atoms/Button";
import Loading from "components/atoms/Loading";
import Modal from "components/organisms/Modal";
import ModalBody from "components/organisms/Modal/Body";
import ModalFooter from "components/organisms/Modal/Footer";
import ModalHeader from "components/organisms/Modal/Header";
import { COMMON_LOADING_SIZE } from "constants/loading";
import { LoadingInfo, WrapLoading } from "constants/styledComponent";
import React, { useState } from "react";
import DeliveryProvinceSelection from "../DeliveryProvinceSelection";
import "./index.scss";
interface LocationSelectModalProps {
  currentIndex: number;
  show?: boolean;
  list?: any[];
  onCloseRequest?: () => void;
  onSelectedValue: (item: any) => void;
}

const LocationSelectModal: React.FC<LocationSelectModalProps> = ({
  currentIndex,
  show,
  list,
  onCloseRequest,
  onSelectedValue,
}) => {
  const [
    currentSelectedDeliveryItemIndex,
    setCurrentSelectedDeliveryItemIndex,
  ] = useState(currentIndex);

  const handleChangeProvince = (list: any[], item: any) => {
    setCurrentSelectedDeliveryItemIndex(
      list.findIndex(findItem => findItem.name === item.name) || 0,
    );
  };

  const handleSubmitProvince = (item: any) => {
    onSelectedValue(item);
  };

  return (
    <Modal
      isOpen={Boolean(show)}
      onCloseRequest={onCloseRequest}
      portalClassName="location-select-modal"
    >
      <ModalHeader
        noPadding
        onCloseRequest={onCloseRequest}
        iconClose={false}
      />
      <ModalBody>
        <h5 className="styled-modal-title">
          Hãy chọn <strong>tỉnh thành của bạn</strong> <br />
          để chúng tôi cung cấp <strong>chính xác</strong> tình trạng hàng.
        </h5>
        {!list ? (
          <WrapLoading>
            <Loading widthHeight={COMMON_LOADING_SIZE} />
            <LoadingInfo>Đang tải tỉnh thành</LoadingInfo>
          </WrapLoading>
        ) : (
          <DeliveryProvinceSelection
            list={list}
            currentIndex={currentSelectedDeliveryItemIndex}
            onChange={item => {
              if (list)
                handleChangeProvince(
                  list,
                  list.find(listItem => listItem.name === item),
                );
            }}
            labelSize="small"
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          variant="primary"
          size="small"
          onClick={() => {
            if (list && list[currentSelectedDeliveryItemIndex]) {
              handleSubmitProvince(list[currentSelectedDeliveryItemIndex]);
              onCloseRequest && onCloseRequest();
            }
          }}
        >
          Xác nhận
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(LocationSelectModal);
