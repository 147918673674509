import Cookies from "js-cookie";

export const PRODUCT = {
  SESSION: {
    COUPPON: "_coupon_",
    GAME_COUPPON: "_kcg_",
  },
};

export const removeGameCouponLocalStoreFlow = () => {
  sessionStorage.removeItem("_kcg_"); //ket qua code_gaming
  Cookies.remove("_cg_"); //code_gaming
  Cookies.remove("copied-code"); //action user copy code
};
export const removeItemCouponLocalStoreFlow = () => {
  sessionStorage.removeItem("_coupon_");
  sessionStorage.removeItem("_cabh_"); // codeAppliedByHand
};
export const removeCouponLocalStoreFlow = () => {
  removeItemCouponLocalStoreFlow();
  removeGameCouponLocalStoreFlow();
};

export const VALUE_INTERNAL = "true";
