export const REQUEST_FETCH_FILTERS = "REQUEST_FETCH_FILTERS";
export const REQUEST_FETCH_FILTERS_ON_SUCCESS =
  "REQUEST_FETCH_FILTERS_ON_SUCCESS";
export const REQUEST_FETCH_FILTERS_ON_ERROR = "REQUEST_FETCH_FILTERS_ON_ERROR";

export const requestFetchFilters = (index: number) => ({
  type: REQUEST_FETCH_FILTERS,
  index
});

export const requestFetchFiltersOnSuccess = (filters: any) => ({
  type: REQUEST_FETCH_FILTERS_ON_SUCCESS,
  filters
});

export const requestFetchFiltersOnError = (error: any) => ({
  type: REQUEST_FETCH_FILTERS_ON_ERROR,
  error
});
