import { fetchSearchProducts } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchSearchProductsOnError,
  fetchSearchProductsOnSuccess,
  REQUEST_SEARCH_PRODUCTS,
} from "../../redux/actions/products/search";

function* fetchSearchProductsFlow({ searchKey }: AnyAction) {
  try {
    const data = yield call(fetchSearchProducts, { searchKey: searchKey });
    yield put(fetchSearchProductsOnSuccess(data));
  } catch (error) {
    yield put(fetchSearchProductsOnError(error));
  }
}

export default function* watchFetchSearchProductsSaga() {
  yield takeLatest(REQUEST_SEARCH_PRODUCTS, fetchSearchProductsFlow);
}
