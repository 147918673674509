import Link from "components/atoms/Link";
import React from "react";
import { joinClassnames } from "utils/map-modifier";
import "./index.scss";

interface ImageProps {
  src: string;
  alt: string;
  ratio?: number;
  hasLink?: string;
  className?: string;
  imgTagOnly?: boolean;
  lazy?: boolean;
}

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  ratio,
  hasLink,
  className,
  imgTagOnly,
  lazy = true,
}) => {
  const loading = (lazy && "lazy") || undefined;
  return imgTagOnly ? (
    <img
      loading={loading}
      className="a-image_img"
      src={src}
      alt={alt}
      width="100%"
      height="auto"
    />
  ) : ratio ? (
    <div
      className={joinClassnames("a-image a-image-ratio", className || "")}
      data-ratio={`${ratio * 100}%`}
    >
      {hasLink ? (
        <Link href={hasLink}>
          <img
            loading={loading}
            className="a-image_img"
            src={src}
            alt={alt}
            width="100%"
            height="auto"
          />
        </Link>
      ) : (
        <img
          loading={loading}
          className="a-image_img"
          src={src}
          alt={alt}
          width="100%"
          height="auto"
        />
      )}
    </div>
  ) : (
    <div className={joinClassnames("a-image", className || "")}>
      {hasLink ? (
        <Link href={hasLink}>
          <img
            loading={loading}
            className="a-image_img"
            src={src}
            alt={alt}
            width="100%"
            height="auto"
          />
        </Link>
      ) : (
        <img
          loading={loading}
          className="a-image_img"
          src={src}
          alt={alt}
          width="100%"
          height="auto"
        />
      )}
    </div>
  );
};

export default React.memo(Image);
