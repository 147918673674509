
import LocationSelectModal from "components/molecules/LocationSelectModal";
import ModalsCompare from "components/molecules/ModalsCompare";
import ViewedProduct from "components/molecules/ViewedProduct";
import {
  getDefaultLocationDeliveryStore,
  setDefaultLocationDeliveryStore
} from "constants/location";
import { zIndex } from "constants/zIndexs";
import { useSelector } from "hooks/useApp";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { requestFetchCities } from "redux/actions/general/cities";
import { requestFetchSystem } from "redux/actions/general/system";
import { saveLocationBuying } from "redux/actions/location/locationBuying";
import { setModal } from "redux/reducers/view/modal";
import { VALUE_INTERNAL } from "constants/global";
import { INTERNAL } from "constants/env";

const FixedComponents: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const [showLocationSelectModal, setShowLocationSelectModal] = useState(false);
  const [listCities, setListCities] = useState([]);
  const { cities } = useSelector(state => state.general);
  const { modal } = useSelector(state => state.view);
  const userData = useSelector(state => state?.userInfor);

  const isValidUser = useMemo(() => {
    if (INTERNAL !== VALUE_INTERNAL) { return true }
    return !!userData && !!userData?.active
  }, [userData])

  useEffect(() => {
    if (!isValidUser) { return };

    // Fetching System
    dispatch(requestFetchSystem());

    // Fetching Cities
    (!cities || (cities && cities.data.length <= 0)) && dispatch(requestFetchCities(1));

    // Init Location Buying
    let defaultLocationLocal = getDefaultLocationDeliveryStore();
    if (!defaultLocationLocal) {
      dispatch(setModal("stock", true));
    } else {
      dispatch(saveLocationBuying(defaultLocationLocal));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isValidUser]);

  useEffect(() => {
    if (!isValidUser) { return };
    setShowLocationSelectModal(modal["stock"]);
  }, [modal, isValidUser]);

  const { locationBuying } = useSelector(state => state);
  const [
    currentSelectedDeliveryItemIndex,
    setCurrentSelectedDeliveryItemIndex,
  ] = useState<number>(-1);

  useEffect(() => {
    if (!isValidUser) { return };
    if (cities && cities?.data?.length > 0) {
      setListCities(
        cities.data.map((city: any) => ({
          name: city.name,
          id: city.id,
        })),
      );
    }
  }, [cities, isValidUser]);

  useEffect(() => {
    if (!isValidUser) { return };
    if (locationBuying && listCities && !!listCities?.length) {
      const fIndex = listCities.findIndex(
        item => item.name === locationBuying.name,
      );
      setCurrentSelectedDeliveryItemIndex(fIndex > -1 ? fIndex : 0);
    }
  }, [listCities, locationBuying, isValidUser]);

  return (
    <>
      {showLocationSelectModal && (
        <LocationSelectModal
          currentIndex={currentSelectedDeliveryItemIndex}
          show={showLocationSelectModal}
          list={listCities}
          onSelectedValue={(item: any) => {
            setDefaultLocationDeliveryStore(item);
            setShowLocationSelectModal(false);
            dispatch(saveLocationBuying(item));
            dispatch(setModal("stock", false));
            toast.info("Tỉnh thành đã được lưu");
          }}
        />
      )}
      <ToastContainer
        autoClose={3500}
        hideProgressBar
        closeOnClick
        pauseOnHover
        style={{ zIndex: zIndex.toast }}
      />

      <ViewedProduct />
      <ModalsCompare />
    </>
  );
};

export default FixedComponents;
