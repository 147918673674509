export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_REGISTER = "AUTH_REGISTER";
export const AUTH_REGISTER_DONE = "AUTH_REGISTER_DONE";
export const AUTH_REGISTER_FAILED = "AUTH_REGISTER_FAILED";
export const AUTH_LOGIN_DONE = "AUTH_LOGIN_DONE";
export const AUTH_LOGIN_FAILD = "AUTH_LOGIN_FAILD";
export const AUTH_FORGET_PASSWORD = "AUTH_FORGET_PASSWORD";
export const AUTH_FORGET_PASSWORD_DONE = "AUTH_FORGET_PASSWORD_DONE";
export const AUTH_FORGET_PASSWORD_ERROR = "AUTH_FORGET_PASSWORD_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_ERROR_GET_TOKEN_BY_REFRESH_TOKEN =
  "AUTH_ERROR_GET_TOKEN_BY_REFRESH_TOKEN";
export const AUTH_ERROR_TOKEN_INVALID = "AUTH_ERROR_TOKEN_INVALID";

export const authLogin = (email: string, password: string) => ({
  type: AUTH_LOGIN,
  email,
  password,
});

export const authRegister = (
  name: string,
  email: string,
  password: string,
) => ({
  type: AUTH_REGISTER,
  name,
  email,
  password,
});

export const authRegisterDone = () => ({
  type: AUTH_REGISTER_DONE,
});
export const authRegisterFailed = (error: any) => ({
  type: AUTH_REGISTER_FAILED,
  error,
});

export const authLogout = () => ({
  type: AUTH_LOGOUT,
});

export const authLoginDone = (token: any, refreshToken: any) => ({
  type: AUTH_LOGIN_DONE,
  token,
  refreshToken,
});

export const authLoginFailed = (error: any) => ({
  type: AUTH_LOGIN_FAILD,
  error,
});

export const authForgetPassword = (email: string) => ({
  type: AUTH_FORGET_PASSWORD,
  email,
});

export const authForgotPasswordDone = () => ({
  type: AUTH_FORGET_PASSWORD_DONE,
});

export const authForgotPasswordError = () => ({
  type: AUTH_FORGET_PASSWORD_ERROR,
});

export const authErrorGetTokenByRefreshToken = () => ({
  type: AUTH_ERROR_GET_TOKEN_BY_REFRESH_TOKEN,
});

export const authErrorTokenInvalid = () => ({
  type: AUTH_ERROR_TOKEN_INVALID,
});
