import { AnyAction } from "redux";
import {
  REQUEST_MAIL_SUBSCRIBER,
  REQUEST_MAIL_SUBSCRIBER_ERROR,
  REQUEST_MAIL_SUBSCRIBER_SUCCESS,
} from "redux/actions/pages/mailsubscriber";

const defaultState = {
  data: {},
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_MAIL_SUBSCRIBER:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_MAIL_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        data: {},
        error: null,
        loading: false,
      };
    case REQUEST_MAIL_SUBSCRIBER_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
