import { AnyAction } from "redux";
import {
  ON_SET_CURRENT_TARGET,
  ON_TOGGLE_SEEN_PRODUCTS,
} from "redux/actions/general/toggleSeenProducts";

const defaultState: {
  toggle: boolean;
  compareTarget: CompareTarget;
} = {
  toggle: false,
  compareTarget: null,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case ON_TOGGLE_SEEN_PRODUCTS:
      return {
        ...state,
        toggle: action.status,
      };
    case ON_SET_CURRENT_TARGET:
      return {
        ...state,
        compareTarget: {
          target: action.target,
          status: action.status,
        },
      };
    default:
      return state;
  }
};
