import { fetchCart, fetchCartUpdate } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  CHANGE_STATUS_IS_HAVE_SETUP_SERVICE,
  DECREASE_INCREASE_CART_PRODUCTS,
  fetchCartProductsOnError,
  fetchCartProductsOnSuccess,
  FETCH_CART_PRODUCTS,
  REMOVE_CART_PRODUCTS,
  REMOVE_PROMOTION_CODE,
  REMOVE_PROMOTION_CODE_SUCCESS,
  UPDATE_CART_PRODUCTS,
  USE_PROMOTION_CODE,
  USE_PROMOTION_CODE_ERROR,
  USE_PROMOTION_CODE_SUCCESS,
} from "redux/actions/products/cart";

const getCart = () => {
  const LocalCart = window.localStorage.getItem("cart");
  if (LocalCart) {
    const LOCAL_CART = JSON.parse(LocalCart);
    return LOCAL_CART.map((c: any) => {
      return {
        ...c,
        isHaveSetupService: c.isHaveSetupService || 0,
      };
    });
  }
  return [];
};

const saveCartInLocalStorage = (product: any, quantity = 1, sign: string) => {
  let cart = getCart();
  let foundProduct = cart.find(p => p.productId === product.product_id);
  if (foundProduct) {
    if (sign === "+") {
      foundProduct.quantity += quantity;
    } else {
      foundProduct.quantity -= quantity;
    }
  } else {
    cart = [
      ...cart,
      {
        productId: product.product_id,
        quantity: quantity,
        isHaveSetupService: 1,
        useTradeProduct: false,
        tradeProductTemp: false
      },
    ];
  }
  // TODO: SET CART INCLUDE CASHBACK:
  window.localStorage.setItem("cart", JSON.stringify(cart));
  return cart;
};

const saveIsHaveSetupService = (productId: number) => {
  let cart = getCart();
  // console.log('saveIsHaveSetupService', cart)
  cart = cart.map(p =>
    p.productId === productId
      ? {
        ...p,
        // isHaveSetupService:  Number(!p.isHaveSetupService)
      }
      : p,
  );

  // TODO: SET CART INCLUDE CASHBACK:
  window.localStorage.setItem("cart", JSON.stringify(cart));
  return cart;
};

export const changeStatusOfTradeProduct = async (
  productId: number,
  isStatus: boolean,
) => {
  let cart = getCart();
  cart = cart.map(p =>
    p.productId === productId
      ? {
        ...p,
        useTradeProduct: isStatus,
      }
      : p,
  );

  // TODO: SET CART INCLUDE CASHBACK:
  window.localStorage.setItem("cart", JSON.stringify(cart));
};

export const changeStatusOfTradeProductTemp = async (
  productId: number,
  isStatus: boolean,
) => {
  let cart = getCart();
  cart = cart.map(p =>
    p.productId === productId
      ? {
        ...p,
        tradeProductTemp: isStatus,
      }
      : p,
  );

  // TODO: SET CART INCLUDE CASHBACK:
  window.localStorage.setItem("cart", JSON.stringify(cart));
};

export const resetStatusOfTradeProduct = async (callback?: () => void) => {
  let cart = getCart();
  cart = cart.map((p) => ({
    ...p,
    useTradeProduct: false,
    tradeProductTemp: false
  }));

  // TODO: SET CART INCLUDE CASHBACK:
  window.localStorage.setItem("cart", JSON.stringify(cart));

  if (callback) callback();
};

export const saveRenewalValue = (
  productId: number,
  brandValue: string,
  wattage: string,
  usedTime: string,
  doesItWork: string,
) => {
  const localRenewalValue = window.localStorage.getItem("renewal");
  if (localRenewalValue) {
    const LOCAL_RENEWAL_VALUE = JSON.parse(localRenewalValue);

    window.localStorage.setItem(
      "renewal",
      JSON.stringify([
        ...LOCAL_RENEWAL_VALUE,
        {
          productId,
          brandValue,
          wattage,
          usedTime,
          doesItWork,
        },
      ]),
    );
  } else {
    window.localStorage.setItem(
      "renewal",
      JSON.stringify([
        {
          productId,
          brandValue,
          wattage,
          usedTime,
          doesItWork,
        },
      ]),
    );
  }
};

export const removeRenewalValue = (productId: number) => {
  const localRenewalValue = window.localStorage.getItem("renewal");
  if (localRenewalValue && localRenewalValue.length > 0) {
    const LOCAL_RENEWAL_VALUE = JSON.parse(localRenewalValue);

    window.localStorage.setItem(
      "renewal",
      JSON.stringify(
        LOCAL_RENEWAL_VALUE.filter(data => data.productId !== productId),
      ),
    );
  }
};

function* decreaseIncreaseCartProductsFlow({
  product,
  sign,
  code,
  checkAction,
}: AnyAction) {
  try {
    let cart: LocalCartItem[] = saveCartInLocalStorage(product, 1, sign);
    const prevItemId = localStorage.getItem("prevcartitemId");

    if (prevItemId) {
      const prevItem: LocalPrevCartItem = JSON.parse(prevItemId);
      cart = cart.filter(
        item =>
          item.productId !== prevItem.id ||
          (item.productId === prevItem.id && !prevItem.isError),
      );
    }

    const data = yield call(fetchCart, cart, code ? code : "");

    yield put(fetchCartProductsOnSuccess(data, code, checkAction));
  } catch (error) {
    yield put(fetchCartProductsOnError(error));
  }
}

function* updateCartFlow({ product, quantity }: AnyAction) {
  try {
    let cart = saveCartInLocalStorage(product, quantity, "+");

    const data = yield call(fetchCart, cart, "");

    yield put(fetchCartProductsOnSuccess(data));
  } catch (error) {
    yield put(fetchCartProductsOnError(error));
  }
}

// PROMOTION CODE:
function* applyPromotionCodeFlow({ code, checkAction }: AnyAction) {
  try {
    let cart = getCart();
    let data = [];
    if (cart.length > 0) {
      data = yield call(fetchCart, cart, code);
    }
    yield put(
      fetchCartProductsOnSuccess(data, code, USE_PROMOTION_CODE_SUCCESS),
    );
  } catch (error) {
    yield put(fetchCartProductsOnError(error, USE_PROMOTION_CODE_ERROR));
  }
}

function* removeCartFlow({ code }: AnyAction) {
  try {
    let cart = getCart();
    let data = [];
    data = yield call(fetchCart, cart, code);
    yield put(fetchCartProductsOnSuccess(data, code));
  } catch (error) {
    yield put(fetchCartProductsOnError(error));
  }
}

// Fetch cart
function* fetchCartFlow({ code, checkAction }: AnyAction) {
  try {
    let cart = getCart();
    let data = [];
    data = yield call(fetchCart, cart, code);
    yield put(fetchCartProductsOnSuccess(data, code, checkAction));
  } catch (error) {
    yield put(fetchCartProductsOnError(error));
  }
}

function* removePromotionCodeFlow() {
  try {
    let cart = getCart();
    let data = [];
    data = yield call(fetchCart, cart, "");
    yield put(
      fetchCartProductsOnSuccess(data, "", REMOVE_PROMOTION_CODE_SUCCESS),
    );
  } catch (error) {
    yield put(fetchCartProductsOnError(error));
  }
}

function* onChangeIsHaveSetupService({ productId, code }: AnyAction) {
  try {
    let cart = saveIsHaveSetupService(productId);
    const data = yield call(fetchCartUpdate, cart, code);
    yield put(fetchCartProductsOnSuccess(data));
  } catch (error) {
    yield put(fetchCartProductsOnError(error));
  }
}

export default function* watchFetchCartSaga() {
  yield takeLatest(
    DECREASE_INCREASE_CART_PRODUCTS,
    decreaseIncreaseCartProductsFlow,
  );
  yield takeLatest(UPDATE_CART_PRODUCTS, updateCartFlow);
  yield takeLatest(FETCH_CART_PRODUCTS, fetchCartFlow);
  yield takeLatest(
    CHANGE_STATUS_IS_HAVE_SETUP_SERVICE,
    onChangeIsHaveSetupService,
  );
  yield takeLatest(USE_PROMOTION_CODE, applyPromotionCodeFlow);
  yield takeLatest(REMOVE_PROMOTION_CODE, removePromotionCodeFlow);
  yield takeLatest(REMOVE_CART_PRODUCTS, removeCartFlow);
}
