export const REQUEST_FETCH_PRODUCTS_BY_TYPE = "REQUEST_FETCH_PRODUCTS_BY_TYPE";
export const FETCH_PRODUCTS_BY_TYPE_SUCCESS = "FETCH_PRODUCTS_BY_TYPE_SUCCESS";
export const FETCH_PRODUCTS_BY_TYPE_ERROR = "FETCH_PRODUCTS_BY_TYPE_ERROR";

export const requestFetchProductsByType = (
  filters: FilterProps,
  homeFilter?: FilterProps,
) => ({
  type: REQUEST_FETCH_PRODUCTS_BY_TYPE,
  filters,
  homeFilter,
});

export const fetchProductsByTypeOnSuccess = (
  slug: string,
  byType: any,
  homeFilter: FilterProps,
) => ({
  type: FETCH_PRODUCTS_BY_TYPE_SUCCESS,
  slug,
  byType,
  homeFilter,
});

export const fetchProductsByTypeOnError = (slug: string, error: any) => ({
  type: FETCH_PRODUCTS_BY_TYPE_ERROR,
  slug,
  error,
});
