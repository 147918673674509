import store from "app/store";
import LoadingState from "components/atoms/LoadingState";
import ErrorBoundary from "components/organisms/ErrorBoundary";
import FixedComponents from "FixedComponents";
import React, { Suspense } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

render(
  <Suspense fallback={<LoadingState />}>
    <Provider store={store}>
      <ErrorBoundary>
        <BrowserRouter>
          <App />
          <FixedComponents />
        </BrowserRouter>
      </ErrorBoundary>
    </Provider>
  </Suspense>,
  document.getElementById("root"),
);
serviceWorker.unregister();
