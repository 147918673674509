import { AnyAction } from "redux";

const initialState = {
  productId: -1,
  isOutStock: false,
};

const SET_IS_OUTSTOCK = "SET_IS_OUTSTOCK";

export const setIsOutStock = (productId: number, isOutStock: boolean) => ({
  type: SET_IS_OUTSTOCK,
  productId,
  isOutStock,
});

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_IS_OUTSTOCK:
      return {
        ...state,
        productId: action.productId,
        isOutStock: action.isOutStock,
      };
    default:
      return state;
  }
};
