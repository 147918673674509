export const REQUEST_FETCH_PROMOTION_PAGE = "REQUEST_FETCH_PROMOTION_PAGE";
export const FETCH_PROMOTION_PAGE_SUCCESS = "FETCH_PROMOTION_PAGE_SUCCESS";
export const FETCH_PROMOTION_PAGE_ERROR = "FETCH_PROMOTION_PAGE_ERROR";

export const requestFetchPromotionPage = (promotion: any) => ({
  type: REQUEST_FETCH_PROMOTION_PAGE,
  promotion,
});

export const fetchPromotionPageOnSuccess = (promotion: any) => ({
  type: FETCH_PROMOTION_PAGE_SUCCESS,
  promotion,
});

export const fetchPromotionPageOnError = (error: any) => ({
  type: FETCH_PROMOTION_PAGE_ERROR,
  error,
});

export const REQUEST_FETCH_PROMOTION_BANNER = "REQUEST_FETCH_PROMOTION_BANNER";
export const FETCH_PROMOTION_BANNER_SUCCESS = "FETCH_PROMOTION_BANNER_SUCCESS";
export const FETCH_PROMOTION_BANNER_ERROR = "FETCH_PROMOTION_BANNER_ERROR";

export const requestFetchPromotionBanner = () => ({
  type: REQUEST_FETCH_PROMOTION_BANNER,
});

export const fetchPromotionBannerOnSuccess = (banner: any) => ({
  type: FETCH_PROMOTION_BANNER_SUCCESS,
  banner,
});

export const fetchPromotionBannerOnError = (error: any) => ({
  type: FETCH_PROMOTION_BANNER_ERROR,
  error,
});
