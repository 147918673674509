import { AnyAction } from "redux";
import {
  REQUEST_REMOVE_SCHEDULE,
  REQUEST_REMOVE_SCHEDULE_ON_SUCCESS,
  REQUEST_SAVE_SCHEDULE,
  REQUEST_SAVE_SCHEDULE_ON_ERROR,
  REQUEST_SAVE_SCHEDULE_ON_SUCCESS,
} from "redux/actions/pages/schedule";

const defaultState = {
  data: {},
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_SAVE_SCHEDULE:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_SAVE_SCHEDULE_ON_SUCCESS:
      return {
        data: action.scheduleInfo,
        error: null,
        loading: false,
      };
    case REQUEST_SAVE_SCHEDULE_ON_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case REQUEST_REMOVE_SCHEDULE:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case REQUEST_REMOVE_SCHEDULE_ON_SUCCESS:
      return {
        ...state,
        data: {},
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};
