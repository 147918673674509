export const REQUEST_FETCH_REFUND_PAGE = "REQUEST_FETCH_REFUND_PAGE";
export const FETCH_REFUND_PAGE_SUCCESS = "FETCH_REFUND_PAGE_SUCCESS";
export const FETCH_REFUND_PAGE_ERROR = "FETCH_REFUND_PAGE_ERROR";

export const requestFetchRefundPolicyPage = () => ({
  type: REQUEST_FETCH_REFUND_PAGE
});

export const fetchRefundPolicyPageOnSuccess = (refund: any) => ({
  type: FETCH_REFUND_PAGE_SUCCESS,
  refund
});

export const fetchRefundPolicyPageOnError = (error: any) => ({
  type: FETCH_REFUND_PAGE_ERROR,
  error
});