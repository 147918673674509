import Button from "components/atoms/Button";
import Fielderror from "components/atoms/Fielderror";
import Icon from "components/atoms/Icon";
import Loading from "components/atoms/Loading";
import Text from "components/atoms/Text";
import Textfield from "components/atoms/Textfield";
import Modal from "components/organisms/Modal";
import ModalBody from "components/organisms/Modal/Body";
import ModalFooter from "components/organisms/Modal/Footer";
import { VALIDATION_PATTERN } from "constants/validation";
import { useSelector } from "hooks/useApp";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  requestClearSubscriber,
  requestFetchSaveSubscriber,
} from "redux/actions/general/subscriber";
import { Grid, GridCol, GridRow } from "../Grid";
import "./index.scss";
type FormData = {
  subscriber: string;
};

interface SubscribeProps {
  hide?: boolean;
}

const SubScribe: React.FC<SubscribeProps> = ({ hide }) => {
  const owlClass = "o-sub-scribe";
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    formState,
  } = useForm<FormData>({
    mode: "onChange",
  });

  const touched = Object.values(formState.touched);

  const submitSubscriber = (data: any) => {
    dispatch(requestFetchSaveSubscriber(data.subscriber));
  };

  const { subscriber } = useSelector(state => state.general);

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [result, setResult] = useState({
    success: false,
    message: "",
  });

  useEffect(() => {
    const { data, loading } = subscriber;
    setLoading(loading);
    if (data) {
      setOpen(true);
      setResult({
        success: data.success,
        message: data.success
          ? "Bạn đã đăng ký nhận bản tin thành công"
          : data.errors[0].message,
      });
      dispatch(requestClearSubscriber());
      if (data.success) {
        setValue("subscriber", "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriber]);
  return (
    <div className={`${owlClass} ${(hide && "hide") || ""}`}>
      <Grid>
        <GridRow>
          <GridCol colMd={6}>
            <div className="left">
              <div className="left_letter">
                <Icon iconName="mail" />
              </div>
              <div className="left_text">
                <Text modifiers={["uppercase", "white"]} weight="bold">
                  ĐĂNG KÝ EMAIL ĐỂ NHẬN THÊM NHIỀU THÔNG TIN MỚI TỪ DAIKIN
                </Text>
              </div>
            </div>
          </GridCol>
          <GridCol colMd={6}>
            <div className="right">
              <form
                onSubmit={handleSubmit(submitSubscriber)}
                className="subscriber-form"
              >
                <Textfield
                  name="subscriber"
                  modifiers={
                    (touched && formState.touched.name) || errors.name
                      ? errors.name
                        ? ["invalid"]
                        : ["valid"]
                      : []
                  }
                  type="text"
                  button={
                    <Button type="submit" modifiers={["small"]} variant="dark">
                      Đăng ký
                    </Button>
                  }
                  label="Địa chỉ Email của bạn"
                  value={getValues().subscriber}
                  refs={register({
                    required: {
                      value: true,
                      message: "Bạn chưa nhập - Email",
                    },
                    pattern: {
                      value: VALIDATION_PATTERN.common.email,
                      message: "Email không đúng định dạng",
                    },
                  })}
                />
                {errors.subscriber && (
                  <Fielderror>{errors.subscriber.message}</Fielderror>
                )}
              </form>
            </div>
          </GridCol>
        </GridRow>
      </Grid>
      {loading && (
        <div className="sub-loading">
          <Loading />
        </div>
      )}
      <Modal isOpen={open}>
        <ModalBody>
          <Text modifiers={["center"]}>{result.message}</Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setOpen(false)} variant="primary" size="small">
            Đóng
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

SubScribe.defaultProps = {};

export default SubScribe;
