// map classnames
export const mapClassnames = (
  name: string,
  ...params: (string | undefined | boolean)[]
) =>
  (Array.isArray(params) &&
    params.length &&
    params
      .filter(item => item)
      .map((param: string | undefined | boolean) => `${name}-${param}`)
      .join(" ")) ||
  name;

// map modifiers
export const mapMultiClassnames = (
  name: string,
  ...modifiers: (string | string[] | undefined | boolean)[]
) => {
  const conclusion =
    (Array.isArray(modifiers) &&
      !!modifiers.length &&
      modifiers
        .filter(item => item)
        .map((item: string | string[] | undefined | boolean) =>
          typeof item === "string"
            ? `${name}-${item}`
            : Array.isArray(item) && !!item.length
            ? mapClassnames(name, ...item)
            : "",
        )) ||
    [];

  conclusion.unshift(name);
  return conclusion.join(" ");
};

// join classnames
export const joinClassnames = (...classes: (string | undefined | boolean)[]) =>
  classes.filter(item => item).join(" ");
