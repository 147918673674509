export const REQUEST_POST_CARD_TOKEN = "REQUEST_POST_CARD_TOKEN";
export const REQUEST_POST_CARD_TOKEN_SUCCESS =
  "REQUEST_POST_CARD_TOKEN_SUCCESS";
export const REQUEST_POST_CARD_TOKEN_ERROR = "REQUEST_POST_CARD_TOKEN_ERROR";

export const requestPostCardToken = (requestParam: CardTokenParams) => ({
  type: REQUEST_POST_CARD_TOKEN,
  requestParam,
});

export const postCardTokenOnSuccess = (cardToken: any) => ({
  type: REQUEST_POST_CARD_TOKEN_SUCCESS,
  cardToken,
});

export const postCardTokenOnError = (error: any) => ({
  type: REQUEST_POST_CARD_TOKEN_ERROR,
  error,
});
