import Button from "components/atoms/Button";
import Checkbox from "components/atoms/Checkbox";
import Fielderror from "components/atoms/Fielderror";
import Heading from "components/atoms/Heading";
import Icon from "components/atoms/Icon";
import Image from "components/atoms/Image";
import Link from "components/atoms/Link";
import LoadingState from "components/atoms/LoadingState";
import Text from "components/atoms/Text";
import Textfield from "components/atoms/Textfield";
import Fieldrow, { Fieldcell } from "components/molecules/Fieldrow";
import Modal from "components/organisms/Modal";
import ModalBody from "components/organisms/Modal/Body";
import ModalFooter from "components/organisms/Modal/Footer";
import { clearLocalCart } from "constants/cart";
import { API_URL, KEY_CAPTCHA } from "constants/env";
import { VALIDATION_PATTERN } from "constants/validation";
import {
  dispatchForgotPasswordResetState,
  dispatchReInit,
  useLoginForm,
} from "hooks/useLoginForm";
import ImageLogin from "images/common/image-login.png";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { authLogin, authRegister } from "redux/actions/auth/auths";
import { requestMailSubscriber } from "redux/actions/pages/mailsubscriber";
import ResetPassForm from "../ResetPassForm";
import "./index.scss";
interface LoginProps { }

type FormData = {
  name: string;
  email: string;
  password: string;
  password_repeat: string;
  save: boolean;
};

const messageLoginNotExternalUser = `Bạn đang sử dụng tài khoản nội bộ để đăng nhập vào hệ thống khách hàng.
    Vui lòng sử dụng tài khoản này đăng nhập trên <a style="text-decoration: underline; font-weight: bold; color: #666666;" href="https://internalshop.daikin.com.vn/">hệ thống nội bộ</a>. Xin cảm ơn.`;

const messageLoginNotInternalUser = `Bạn đang sử dụng tài khoản khách hàng để đăng nhập vào hệ thống nội bộ.
    Vui lòng sử dụng tài khoản này đăng nhập trên hệ thống khách hàng. Xin cảm ơn.`;

const messageRegisterNotExternalUser = `Bạn đang sử dụng tài khoản nội bộ để đăng ký hệ thống khách hàng.
    Vui lòng sử dụng tài khoản này đăng ký trên hệ thống nội bộ. Xin cảm ơn.`;

const messageRegisterNotInternalUser = `Bạn đang sử dụng tài khoản khách hàng để đăng ký hệ thống nội bộ.
    Vui lòng sử dụng tài khoản này đăng ký trên hệ thống khách hàng. Xin cảm ơn.`;

const SignInUpForm: React.FC<LoginProps> = (_props: LoginProps) => {
  const owlClass = "o-loginform";
  const location = useLocation();
  const page = location.pathname;
  const history = useHistory();
  const [loadingPage, setLoadingPage] = useState(false);
  const [subscriberEmail, setSubscriberEmail] = useState<string>();
  const [notifyWrongUserType, setNotifyWrongUserType] = useState({
    status: false,
    message: ``,
  });
  const { pathname } = useLocation();
  const {
    error,
    done,
    redirect,
    loading,
    showSuccessForgotPassword,
  } = useLoginForm();

  const {
    handleSubmit,
    getValues,
    formState,
    errors,
    register,
    watch,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      name: "",
      email: "",
      password: "",
      password_repeat: "",
      save: true,
    },
  });
  const touched = Object.values(formState.touched);
  const dispatch = useDispatch();
  const onSubmit = (data: any) => {
    setLoadingPage(true);
    if (page === "/dang-nhap" || page === "/gio-hang") {
      const { email, password } = data;
      dispatch(authLogin(email, password));
    } else {
      const { name, email, password } = data;
      setSubscriberEmail(email);
      dispatch(authRegister(name, email, password));
    }
  };

  useEffect(() => {
    if (done) {
      setLoadingPage(false);
      dispatchReInit();
      if (page === "/dang-ky") {
        setNotify({
          ...notify,
          status: true,
          message: "Tài khoản của bạn đã được tạo thành công!",
        });
      } else if (page === "/gio-hang") {
        history.push("/thanh-toan");
      } else {
        clearLocalCart();
        history.push("/");
      }
    }

    if (error && !loading) {
      const {
        response: {
          data: { errors },
        },
      } = error;
      if (errors) {
        const { code, title } = errors[0];
        if (
          code === "007" ||
          code === "303" ||
          code === "301" ||
          code === "002" ||
          code === "003" ||
          code === "001" ||
          code === "not_internal" ||
          code === "not_external"
        ) {
          if (code === "007") {
            toast.error(
              "Tài khoản chưa kích hoạt, vui lòng kiểm tra email để kích hoạt tài khoản",
            );
          }
          if (code === "303" || code === "301") {
            toast.error("Mật khẩu không đúng");
          }
          if (code === "002") {
            toast.error("Email không tồn tại");
          }
          if (code === "003") {
            toast.error("Tài khoản không tồn tại, vui lòng thử lại");
          }
          if (code === "001") {
            toast.error("Email đã tồn tại");
          }
          if (code === "not_internal") {
            setNotifyWrongUserType({
              ...notifyWrongUserType,
              status: true,
              message: page === "/dang-nhap" ? messageLoginNotInternalUser : messageRegisterNotInternalUser
            })
          }
          if (code === "not_external") {
            setNotifyWrongUserType({
              ...notifyWrongUserType,
              status: true,
              message: page === "/dang-nhap" ? messageLoginNotExternalUser : messageRegisterNotExternalUser
            })
          }
        } else {
          toast.error(title);
        }
      }
      setLoadingPage(false);
      dispatchReInit();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done, history, error]);

  useEffect(() => {
    return () => {
      // reset next subject:
      dispatchReInit();
    };
  }, []);

  useEffect(() => {
    dispatchReInit();
  }, [pathname]);

  const loginBySocialMedia = (socialMedia: string) => {
    window.location.replace(`${API_URL}/customer/auth/${socialMedia}`);
  };

  useEffect(() => {
    if (page === "/dang-nhap") {
      const searchParams = new URLSearchParams(history.location.search);
      const errorCode = searchParams.get("errorCode");

      if (errorCode === "not_internal") {
        setNotifyWrongUserType({
          ...notifyWrongUserType,
          status: true,
          message: messageLoginNotInternalUser
        })
      }
      if (errorCode === "not_external") {
        setNotifyWrongUserType({
          ...notifyWrongUserType,
          status: true,
          message: messageLoginNotExternalUser
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [toggleForgetPasswordForm, setToggleForgetPasswordForm] = useState(
    false,
  );
  const [notify, setNotify] = useState({
    status: false,
    message: "",
  });
  const onCloseModal = () => {
    setNotify({
      status: false,
      message: "",
    });
  };

  const onCloseModalWrongUserType = () => {
    setNotifyWrongUserType({
      status: false,
      message: "",
    });
  };

  const [subscribe, setSubscribe] = useState(true);

  const goToLogin = () => {
    onCloseModal();

    // request subscribe
    if (subscribe && subscriberEmail) {
      dispatch(requestMailSubscriber(subscriberEmail));
    }

    if (redirect) { history.push(redirect); }
  };

  const [needVerify, setNeedVerify] = useState(false);
  const [count, setCount] = useState<number>();
  const [registerVerify, setRegisterVerify] = useState<boolean>(false);

  useEffect(() => {
    if (errors) {
      const countSave = Cookies.get("needVerify.SignInUpForm");
      setCount(countSave ? Number(countSave) + 1 : count ? count + 1 : 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (count !== undefined) {
      if (page === "/dang-ky") {
        setRegisterVerify(false);
      } else {
        setNeedVerify(count >= 3);
        Cookies.set("needVerify.SignInUpForm", count ? count.toString() : "1", {
          expires: 1, // 1 day
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    const checkNeedVerifyCount = Cookies.get("needVerify.SignInUpForm");
    if (checkNeedVerifyCount) {
      setCount(Number(checkNeedVerifyCount));
      if (Number(checkNeedVerifyCount) >= 3) {
        setNeedVerify(true);
      }
    }
  }, []);

  return (
    <div className={owlClass}>
      {loadingPage && <LoadingState />}
      {page !== "/gio-hang" && (
        <div className={`${owlClass}_left`}>
          <div className={`${owlClass}_left_img`}>
            <Image src={ImageLogin} alt="Login Icon" />
          </div>
        </div>
      )}
      <div className={`${owlClass}_right`}>
        <div className={`${owlClass}_right_title`}>
          <Heading
            type="h1"
            weight="bold"
            modifiers={["uppercase", "blue", "center", "no-margin-bottom"]}
            size="xl"
          >
            {page === "/dang-nhap" || page === "/gio-hang"
              ? "đăng nhập"
              : "đăng ký"}
          </Heading>
        </div>
        <div className={`${owlClass}_right_desc`}>
          <Text modifiers={["gray", "center"]} size="sm">
            {page === "/dang-nhap" || page === "/gio-hang"
              ? "Vui Lòng Nhập Đúng Thông Tin Đăng Nhập"
              : "Vui Lòng Nhập Đúng Thông Tin Đăng Ký"}
          </Text>
        </div>
        <div className={`${owlClass}_right_form`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {page === "/dang-ky" && (
              <Fieldrow modifiers={["input"]}>
                <Fieldcell>
                  <Textfield
                    name="name"
                    label="Họ & tên"
                    type="text"
                    value={getValues().name}
                    autoFocus
                    modifiers={
                      (touched && formState.touched.name) || errors.name
                        ? errors.name
                          ? ["invalid"]
                          : ["valid"]
                        : []
                    }
                    refs={register({
                      required: {
                        value: false,
                        message: "Bạn chưa nhập họ tên",
                      },
                      validate: value =>
                        value.length <= 0
                          ? "Bạn chưa nhập họ tên"
                          : true,
                    })}
                  />
                  {errors.name && (
                    <Fielderror>{errors.name.message}</Fielderror>
                  )}
                </Fieldcell>
              </Fieldrow>
            )}
            <Fieldrow modifiers={["input"]}>
              <Fieldcell>
                <Textfield
                  name="email"
                  label="Nhập email"
                  type="email"
                  value={getValues().email}
                  {...(page === "/dang-nhap" ? { autoFocus: true } : {})}
                  modifiers={
                    (touched && formState.touched.email) || errors.email
                      ? errors.email
                        ? ["invalid"]
                        : ["valid"]
                      : []
                  }
                  refs={register({
                    required: {
                      value: true,
                      message: "Bạn chưa nhập - Email",
                    },
                    ...(page === "/dang-ky"
                      ? {
                        pattern: {
                          value: VALIDATION_PATTERN.common.email,
                          message: "Email không đúng định dạng",
                        },
                      }
                      : {}),
                  })}
                />
                {errors.email && (
                  <Fielderror>{errors.email.message}</Fielderror>
                )}
              </Fieldcell>
            </Fieldrow>
            <Fieldrow modifiers={["input"]}>
              <Fieldcell>
                <Textfield
                  name="password"
                  label="Nhập mật khẩu"
                  type="password"
                  value={getValues().password}
                  modifiers={
                    (touched && formState.touched.password) || errors.password
                      ? errors.password
                        ? ["invalid"]
                        : ["valid"]
                      : []
                  }
                  refs={register({
                    required: {
                      value: true,
                      message: "Bạn chưa nhập mật khẩu",
                    },
                    ...(page === "/dang-ky"
                      ? {
                        pattern: {
                          value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
                          message:
                            "Mật khẩu ít nhất 6 ký tự, 1 chữ in hoa, 1 số, và 1 ký tự đặc biệt",
                        },
                        minLength: {
                          value: 6,
                          message:
                            "Mật khẩu ít nhất 6 ký tự, 1 chữ in hoa, 1 số, và 1 ký tự đặc biệt",
                        },
                      }
                      : {}),
                  })}
                />
                {errors.password && (
                  <Fielderror>{errors.password.message}</Fielderror>
                )}
              </Fieldcell>

              {page === "/dang-ky" && (
                <Fieldcell>
                  <Textfield
                    name="password_repeat"
                    label="Nhập lại mật khẩu"
                    type="password"
                    value={getValues().password_repeat}
                    modifiers={
                      (touched && formState.touched.password_repeat) ||
                        errors.password_repeat
                        ? errors.password_repeat
                          ? ["invalid"]
                          : ["valid"]
                        : []
                    }
                    refs={register({
                      required: {
                        value: true,
                        message: "Mật khẩu không khớp",
                      },
                      validate: value =>
                        value === watch("password") || "Mật khẩu không khớp",
                    })}
                  />
                  {errors.password_repeat && (
                    <Fielderror>{errors.password_repeat.message}</Fielderror>
                  )}
                </Fieldcell>
              )}
            </Fieldrow>
            {page === "/dang-ky" && (
              <Text size="sm">
                <span
                  style={{
                    color: "red",
                  }}
                >
                  *{" "}
                </span>
                Vui lòng đăng nhập đúng địa chỉ email để kích hoạt tài khoản sau
                khi đăng ký.
              </Text>
            )}
            {page === "/dang-nhap" && (
              <Checkbox
                name="save"
                type="checkbox"
                value={true}
                display="Ghi nhớ thông tin đăng nhập"
                refs={register}
              />
            )}
            <div
              className={`${owlClass}_right_form_${page === "/dang-nhap" || page === "/gio-hang"
                ? "signin"
                : "signup"
                }`}
            >
              {needVerify && KEY_CAPTCHA && page !== "/dang-ky" ? (
                <div className="form-recapcha">
                  <ReCAPTCHA
                    sitekey={KEY_CAPTCHA}
                    onChange={(value: any) => {
                      if (value) {
                        Cookies.remove("needVerify.SignInUpForm");
                        setNeedVerify(false);
                        setCount(undefined);
                      }
                    }}
                  />
                </div>
              ) : (
                <>
                  {page === "/dang-nhap" ? (
                    <>
                      <Button
                        size="xsmall"
                        type="button"
                        variant="secondary"
                        onClick={() => setToggleForgetPasswordForm(true)}
                      >
                        QUÊN MẬT KHẨU
                      </Button>
                      <Button
                        size="xsmall"
                        type="submit"
                        disabled={!formState.isValid}
                      >
                        ĐĂNG NHẬP
                      </Button>
                    </>
                  ) : page === "/dang-ky" ? (
                    <div className="form-recapcha register-capcha">
                      {!registerVerify && KEY_CAPTCHA ? (
                        <ReCAPTCHA
                          sitekey={KEY_CAPTCHA}
                          onChange={(value: any) => {
                            setRegisterVerify(!!value);
                          }}
                        />
                      ) : (
                        <Button
                          disabled={!registerVerify || !formState.isValid}
                        >
                          ĐĂNG KÝ
                        </Button>
                      )}
                    </div>
                  ) : (
                    <>
                      <Button
                        variant="secondary"
                        size="xsmall"
                        type="submit"
                        disabled={!formState.isValid}
                      >
                        ĐĂNG NHẬP
                      </Button>
                      <Button
                        size="xsmall"
                        type="button"
                        onClick={() => history.push("/thanh-toan")}
                      >
                        MUA HÀNG KHÔNG ĐĂNG NHẬP
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
        <div className={`${owlClass}_right_ask-register`}>
          <Text modifiers={["gray"]} size="sm">
            {page === "/dang-nhap" || page === "/gio-hang"
              ? "Nếu Bạn Chưa Có Tài Khoản. "
              : "Bạn Đã Có Tài Khoản. "}
            <Link
              href={
                page === "/dang-nhap" || page === "/gio-hang"
                  ? "/dang-ky"
                  : "/dang-nhap"
              }
            >
              <span>
                Vui Lòng{" "}
                {page === "/dang-nhap" || page === "/gio-hang"
                  ? "Đăng Ký"
                  : "Đăng Nhập"}
              </span>
            </Link>
          </Text>
        </div>
        <div className={`${owlClass}_right_hr`}>
          <hr />
        </div>
        <div className={`${owlClass}_right_register-with`}>
          <Text modifiers={["uppercase"]} weight="bold">
            Đăng nhập DỄ DÀNG hơn với
          </Text>
        </div>
        <div className={`${owlClass}_right_social-btn`}>
          <Button
            iconName="login-google"
            modifiers={["login", "google"]}
            onClick={() => loginBySocialMedia("google")}
          >
            Đăng Nhập Google
          </Button>
        </div>
      </div>
      {toggleForgetPasswordForm && (
        <ResetPassForm
          open={toggleForgetPasswordForm}
          onToggle={() => setToggleForgetPasswordForm(false)}
        />
      )}

      <Modal
        onCloseRequest={() => dispatchForgotPasswordResetState()}
        isOpen={showSuccessForgotPassword}
        resetModal
      >
        <Icon center iconName="success-blue" />

        <Heading type="h3" modifiers={["center"]}>
          ĐẶT LẠI MẬT KHẨU THÀNH CÔNG
        </Heading>
        <Text>Truy Cập Email Của Bạn Để Lấy Lại Mật Khẩu Của Bạn</Text>
      </Modal>

      {/* Modal */}
      <Modal isOpen={notify.status} onCloseRequest={onCloseModal} resetModal>
        <ModalBody>
          <Icon center iconName="success" />
          <Text modifiers={["center"]}>{notify.message}</Text>
          <Text modifiers={["center"]} size="sm">
            Vui lòng vào email để kích hoạt tài khoản
          </Text>
        </ModalBody>
        <ModalFooter footerClassName={"column"}>
          <Button
            onClick={goToLogin}
            variant="primary"
            size="small"
            className="mb-3"
            disabled={!subscriberEmail}
          >
            Bắt đầu
          </Button>
          <Checkbox
            name="save"
            type="checkbox"
            value={subscribe}
            display="Tôi đồng ý nhận các thông tin mới từ Daikin E-Shop"
            refs={register}
            onChange={(e: any) => {
              setSubscribe(e.target.checked);
            }}
          />
        </ModalFooter>
      </Modal>

      {/* Modal login wrong user type */}
      <Modal
        onCloseRequest={onCloseModalWrongUserType}
        isOpen={notifyWrongUserType.status}
        resetModal
      >
        <Icon center iconName="error" />

        <div
          className={`${owlClass}-closeModalSignUp`}
          onClick={onCloseModalWrongUserType}
        >
          <Icon center iconName="close" />
        </div>

        <Heading type="h3" modifiers={["center"]}>
          {
            page === "/dang-nhap" ? 'Đăng nhập không thành công' : 'Đăng ký không thành công'
          }
        </Heading>
        <Text modifiers={['center']} content={notifyWrongUserType.message} />
      </Modal>
    </div>
  );
};

SignInUpForm.defaultProps = {};

export default SignInUpForm;
