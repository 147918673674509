import { fetchFooter, fetchMenus } from "api/interna-api";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchFooterMenusOnError,
  fetchFooterMenusOnSuccess,
  fetchMenusOnError,
  fetchMenusOnSuccess,
  REQUEST_FETCH_FOOTER_MENU,
  REQUEST_FETCH_MENU,
} from "redux/actions/general/menus";

function* fetchMenusSaga() {
  try {
    const data = yield call(fetchMenus);
    yield put(fetchMenusOnSuccess(data));
  } catch (error) {
    yield put(fetchMenusOnError(error));
  }
}

function* fetchFooterMenusSaga() {
  try {
    const data = yield call(fetchFooter);
    yield put(fetchFooterMenusOnSuccess(data));
  } catch (error) {
    yield put(fetchFooterMenusOnError(error));
  }
}

export default function* watchFetchMenusSaga() {
  yield all([
    takeLatest(REQUEST_FETCH_MENU, fetchMenusSaga),
    takeLatest(REQUEST_FETCH_FOOTER_MENU, fetchFooterMenusSaga),
  ]);
}
