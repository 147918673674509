import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const useWindowScroll = (delay?: number) => {
  const [values, setValues] = useState({
    showScrollTop: window.pageYOffset > window.innerHeight,
    pageYOffset: window.pageYOffset,
    windowHeight: window.innerHeight,
    heightToShow: (window.innerHeight * 70) / 100,
  });
  const prevValueRef: { current: NodeJS.Timeout | null } = useRef(null);

  const windowHeight = useMemo(() => window.innerHeight, []);
  const heightToShow = useMemo(() => (windowHeight * 70) / 100, [windowHeight]);

  const setValueFn = useCallback(() => {
    setValues({
      showScrollTop: window.pageYOffset >= windowHeight,
      pageYOffset: window.pageYOffset,
      windowHeight: window.innerHeight,
      heightToShow,
    });
  }, [heightToShow, windowHeight]);

  useEffect(() => {
    const handleScroll = () => {
      if (prevValueRef.current) clearTimeout(prevValueRef.current);
      prevValueRef.current = setTimeout(setValueFn, delay || 250);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [windowHeight, heightToShow, delay, setValueFn]);

  return values;
};

export default useWindowScroll;
