import { AnyAction } from "redux";
import {
  REQUEST_POST_COMMENT,
  REQUEST_POST_COMMENT_ERROR,
  REQUEST_POST_COMMENT_SUCCESS,
  RESET_COMMENT,
} from "redux/actions/general/comment";

const defaultState = {
  data: {},
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case RESET_COMMENT:
      return {
        ...defaultState,
      };
    case REQUEST_POST_COMMENT:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_POST_COMMENT_SUCCESS:
      return {
        data: action.data,
        error: null,
        loading: false,
      };
    case REQUEST_POST_COMMENT_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
