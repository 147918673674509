import { activateRegister } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  requestActivateOnError,
  requestActivateOnSuccess,
  REQUEST_ACTIVATE,
} from "redux/actions/pages/activate";

function* requestActivateFlow({ token }: AnyAction) {
  try {
    const data = yield call(activateRegister, token);
    yield put(requestActivateOnSuccess(data));
  } catch (error) {
    yield put(requestActivateOnError(error));
  }
}

export default function* watchRequestActivateFlowSaga() {
  yield takeLatest(REQUEST_ACTIVATE, requestActivateFlow);
}
