export const REQUEST_FETCH_SHIPPING_PAGE = "REQUEST_FETCH_SHIPPING_PAGE";
export const FETCH_SHIPPING_PAGE_SUCCESS = "FETCH_SHIPPING_PAGE_SUCCESS";
export const FETCH_SHIPPING_PAGE_ERROR = "FETCH_SHIPPING_PAGE_ERROR";

export const requestFetchShippingPage = () => ({
  type: REQUEST_FETCH_SHIPPING_PAGE
});

export const fetchShippingPageOnSuccess = (shipping: any) => ({
  type: FETCH_SHIPPING_PAGE_SUCCESS,
  shipping
});

export const fetchShippingPageOnError = (error: any) => ({
  type: FETCH_SHIPPING_PAGE_ERROR,
  error
});
