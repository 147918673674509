import { initialNotify } from "constants/notify";
import { AnyAction } from "redux";

type ProductCompareType = {
  id: number;
  typeProduct: number;
  parentType: number;
};
const initialState: {
  loading: boolean;
  loaded: boolean;
  notify: NotifyType;
  selectedProduct: ProductCompareType | null;
} = {
  loading: false,
  loaded: false,
  notify: initialNotify,
  selectedProduct: null,
};

const SET_NOTIFY_COMPARE = "SET_NOTIFY_COMPARE";
const SET_SELECTED_PRODUCT_COMPARE = "SET_SELECTED_PRODUCT_COMPARE";

export const setNotifyCompareModal = (notify: NotifyType) => ({
  type: SET_NOTIFY_COMPARE,
  notify,
});
export const setSelectedProductCompare = (
  selectedProduct: ProductCompareType,
) => ({
  type: SET_SELECTED_PRODUCT_COMPARE,
  selectedProduct,
});

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_NOTIFY_COMPARE:
      return {
        ...state,
        notify: action.notify,
      };
    case SET_SELECTED_PRODUCT_COMPARE:
      return {
        ...state,
        selectedProduct: action.selectedProduct,
      };
    default:
      return state;
  }
};
