export const ON_TOGGLE_SEEN_PRODUCTS = "ON_TOGGLE_SEEN_PRODUCTS";
export const ON_SET_CURRENT_TARGET = "ON_SET_CURRENT_TARGET";

export const onToggleSeenProducts = (status?: boolean) => ({
  type: ON_TOGGLE_SEEN_PRODUCTS,
  status,
});
export const onSetCurrentTarget = (
  target: CompareTargetElement,
  status: boolean,
) => ({
  type: ON_SET_CURRENT_TARGET,
  target,
  status,
});
