import {
  fetchNewsCategories,
  fetchNewsDetail,
  fetchNewsListByCategory,
} from "api/interna-api";
import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchNewsCategoriesSuccess,
  fetchNewsDetailOnError,
  fetchNewsDetailOnSuccess,
  fetchNewsListOnError,
  fetchNewsListOnSuccess,
  requestFetchNewsListByCategory,
  REQUEST_FETCH_NEWS_CATEGORIES,
  REQUEST_FETCH_NEWS_DETAIl,
  REQUEST_FETCH_NEWS_LIST_BY_CATEGORY,
} from "redux/actions/news/list";

function* fetchNewsCategorySaga() {
  try {
    const data = yield call(fetchNewsCategories);
    yield put(fetchNewsCategoriesSuccess(data));
    yield put(requestFetchNewsListByCategory("", 10));
  } catch (error) {
    yield put(fetchNewsListOnError(error));
  }
}

function* fetchNewsListByCategorySaga(action: AnyAction) {
  try {
    const data = yield call(
      fetchNewsListByCategory,
      action.categorySlug || "",
      action.limit || 10,
    );
    yield put(fetchNewsListOnSuccess(data));
  } catch (error) {
    yield put(fetchNewsListOnError(error));
  }
}

function* fetchNewsDetailSaga(action: AnyAction) {
  try {
    const data = yield call(fetchNewsDetail, action.slug);
    yield put(requestFetchNewsListByCategory(data.category_slug, 4));
    yield put(fetchNewsDetailOnSuccess(data));
  } catch (error) {
    yield put(fetchNewsDetailOnError(error));
  }
}

export default function* watchFetchNewsSaga() {
  yield all([
    takeLatest(REQUEST_FETCH_NEWS_CATEGORIES, fetchNewsCategorySaga),
    takeLatest(
      REQUEST_FETCH_NEWS_LIST_BY_CATEGORY,
      fetchNewsListByCategorySaga,
    ),
    takeLatest(REQUEST_FETCH_NEWS_DETAIl, fetchNewsDetailSaga),
  ]);
}
