import {
  fetchHygienicService,
  fetchPackageServicesPage,
  fetchServicePackages,
} from "api/interna-api";
import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchPackageServicePageOnError,
  fetchPackageServicePageOnSuccess,
  requestExtraQuantityOnSuccess,
  requestFetchHygienicServiceOnError,
  requestFetchHygienicServiceOnSuccess,
  requestFetchPackagesServiceOnError,
  requestFetchPackagesServiceOnSuccess,
  requestHygienicQuantityOnSuccess,
  requestSchdulePackageServiceOnSuccess,
  requestToggleExtraOnSuccess,
  REQUEST_EXTRA_QUANTITY,
  REQUEST_FETCH_HYGIENIC_SERVICE,
  REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE,
  REQUEST_FETCH_PACKAGE_TEMP_SERVICE_PAGE,
  REQUEST_HYGIENIC_QUANTITY,
  REQUEST_SCHEDULE_PACKAGE_SERVICE,
  REQUEST_TOGGLE_EXTRA,
} from "redux/actions/pages/packageService";

function* fetchPackageTempServicePageFlow() {
  try {
    const data = yield call(fetchPackageServicesPage);
    yield put(fetchPackageServicePageOnSuccess(data));
  } catch (error) {
    yield put(fetchPackageServicePageOnError(error));
  }
}

function* fetchHygienicServiceFlow() {
  try {
    const data = yield call(fetchHygienicService, 15);
    yield put(requestFetchHygienicServiceOnSuccess(data));
  } catch (error) {
    yield put(requestFetchHygienicServiceOnError(error));
  }
}

function* fetchMaintainPackagesServiceFlow() {
  try {
    const data = yield call(fetchServicePackages);
    yield put(requestFetchPackagesServiceOnSuccess(data));
  } catch (error) {
    yield put(requestFetchPackagesServiceOnError(error));
  }
}

function* requestSchedulePackageServiceFlow({ id }: AnyAction) {
  yield put(requestSchdulePackageServiceOnSuccess(id));
}

function* requestHygienicQuantityFlow({ id, sign }: AnyAction) {
  yield put(requestHygienicQuantityOnSuccess(id, sign));
}

function* requestToggleExtraFlow({ hygienicId, extraId }: AnyAction) {
  yield put(requestToggleExtraOnSuccess(hygienicId, extraId));
}

function* requestExtraQuantityFlow({ hygienicId, extraId, sign }: AnyAction) {
  yield put(requestExtraQuantityOnSuccess(hygienicId, extraId, sign));
}

export default function* watchFetchPackageServicePageSaga() {
  yield all([
    takeLatest(
      REQUEST_FETCH_PACKAGE_TEMP_SERVICE_PAGE,
      fetchPackageTempServicePageFlow,
    ),
    takeLatest(REQUEST_FETCH_HYGIENIC_SERVICE, fetchHygienicServiceFlow),
    takeLatest(
      REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE,
      fetchMaintainPackagesServiceFlow,
    ),
  ]);
  yield takeLatest(
    REQUEST_SCHEDULE_PACKAGE_SERVICE,
    requestSchedulePackageServiceFlow,
  );
  yield takeLatest(REQUEST_HYGIENIC_QUANTITY, requestHygienicQuantityFlow);
  yield takeLatest(REQUEST_TOGGLE_EXTRA, requestToggleExtraFlow);
  yield takeLatest(REQUEST_EXTRA_QUANTITY, requestExtraQuantityFlow);
}
