export const REQUEST_FETCH_SERVICES_PAGE = "REQUEST_FETCH_SERVICES_PAGE";
export const FETCH_SERVICES_PAGE_SUCCESS = "FETCH_SERVICES_PAGE_SUCCESS";
export const FETCH_SERVICES_PAGE_ERROR = "FETCH_SERVICES_PAGE_ERROR";

export const requestFetchServicesPage = () => ({
  type: REQUEST_FETCH_SERVICES_PAGE
});

export const fetchServicesPageOnSuccess = (services: any) => ({
  type: FETCH_SERVICES_PAGE_SUCCESS,
  services
});

export const fetchServicesPageOnError = (error: any) => ({
  type: FETCH_SERVICES_PAGE_ERROR,
  error
});
