import React, { memo } from "react";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import "./index.scss";

export type IconName =
  | "compare-block"
  | "success-blue"
  | "dropdown"
  | "error"
  | "green-tick"
  | "add-circle"
  | "cancel-tb"
  | "error-gray"
  | "minus-white"
  | "plus"
  | "success"
  | "warning"
  | "white-flexible-payment"
  | "white-setting-professional"
  | "white-shopping-bag"
  | "white-tick"
  | "404-error"
  | "arrow-circle-blue"
  | "calendar"
  | "chevron-down-active"
  | "chevron-down-black"
  | "copy-white"
  | "copy"
  | "small-loading"
  | "energy-circle"
  | "filter"
  | "gift-blue"
  | "gift-white"
  | "gray-like"
  | "home-circle"
  | "account-info-blue"
  | "air-4"
  | "air-5"
  | "air-6"
  | "arrow-left-gray"
  | "arrow-left-white"
  | "arrow-right-gray"
  | "arrow-right-white"
  | "brochure"
  | "cancel"
  | "cart-blue"
  | "circle"
  | "close-white"
  | "close"
  | "compare"
  | "delivery"
  | "delivered"
  | "exchange"
  | "function-selected"
  | "function-white"
  | "hotline"
  | "i-blue"
  | "i"
  | "info-cyan"
  | "order-blue"
  | "order"
  | "pending"
  | "percent"
  | "phone-selected"
  | "phone-white"
  | "plus-blue"
  | "plus-thin"
  | "plus-white-circle"
  | "plus-white"
  | "policy-mobile"
  | "q-a"
  | "remove"
  | "search"
  | "seen-blue"
  | "seen"
  | "setting-selected"
  | "setting-white"
  | "settings"
  | "shipping"
  | "sms"
  | "star"
  | "warranty-info-blue"
  | "warranty-info"
  | "wrench"
  | "x-white"
  | "menu"
  | "tips"
  | "like"
  | "money-circle"
  | "outline-person-outline-24px"
  | "plus"
  | "setting-circle"
  | "star-gray"
  | "star-light-gray"
  | "star-orange"
  | "support-catalog"
  | "support-contact"
  | "support-email"
  | "support-hotline"
  | "support-percent"
  | "support-question"
  | "support-setting"
  | "trash"
  | "white-delivery-truck"
  | "wind-circle"
  | "alego"
  | "maps-and-flags-small"
  | "account-info"
  | "air-2"
  | "air-3"
  | "arrow-left"
  | "arrow-right"
  | "cart-white"
  | "cart"
  | "facebook"
  | "ibanking"
  | "logo-daikin"
  | "info-white"
  | "baokim"
  | "login-google"
  | "tienmat"
  | "phone"
  | "login-facebook"
  | "login"
  | "tragop"
  | "jcb"
  | "recent"
  | "master"
  | "mail"
  | "user"
  | "visa"
  | "youtube"
  | "bocongthuong"
  | "contact"
  | "arrow-down-white"
  | "choosen-stock-icon-white"
  | "icon-360"
  | "icon-360-2"
  | "old-renewed"
  | "check-circle-solid"
  | "recycle"
  | "american"
  | "payoo"
  | "catalogue"
  | "ar"
  | undefined; // TODO: more icon

interface IconProps {
  iconName: IconName;
  onClick?: () => void;
  center?: boolean;
  size?: "small" | "close-smaller";
  className?: string;
}

const Icon: React.FC<IconProps> = ({
  iconName,
  center,
  size,
  className,
  onClick,
}) =>
  center ? (
    <div
      onClick={onClick}
      className={joinClassnames(center ? `a-icon_center` : "", className)}
    >
      <span className={mapMultiClassnames("a-icon", iconName)} />
    </div>
  ) : (
    <span
      onClick={onClick}
      className={joinClassnames(
        mapMultiClassnames("a-icon", iconName, size ? size : ""),
        className,
      )}
    />
  );

Icon.defaultProps = {};

export default memo(Icon);
