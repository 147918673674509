import { AnyAction } from "redux";
import {
  FETCH_GUARANTEE_SERVICE_PAGE_ERROR,
  FETCH_GUARANTEE_SERVICE_PAGE_SUCCESS,
  REQUEST_FETCH_GUARANTEE_SERVICE_PAGE,
} from "redux/actions/pages/guaranteeService";

const defaultState = {
  data: {},
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_FETCH_GUARANTEE_SERVICE_PAGE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_GUARANTEE_SERVICE_PAGE_SUCCESS:
      return {
        data: action.guaranteeService,
        error: null,
        loading: false,
      };
    case FETCH_GUARANTEE_SERVICE_PAGE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
