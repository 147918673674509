import { combineReducers } from "redux";
import userInfor from "redux/reducers/auth/userInfor";
import cartBoxHeader from "redux/reducers/cart/cartBoxHeader";
import holiday from "redux/reducers/deliver/holiday";
import general from "redux/reducers/general";
import locationBuying from "redux/reducers/location/locationBuying";
import news from "redux/reducers/news/list";
import pages from "redux/reducers/pages";
import products from "redux/reducers/products";
import view from "redux/reducers/view";

export default combineReducers({
  products,
  userInfor,
  locationBuying,
  cartBoxHeader,
  pages,
  general,
  news,
  holiday,
  view,
});
