export const REQUEST_COMPARE_PRODUCT = "REQUEST_COMPARE_PRODUCT";
export const REQUEST_COMPARE_PRODUCT_SUCCESS =
  "REQUEST_COMPARE_PRODUCT_SUCCESS";
export const REQUEST_COMPARE_PRODUCT_ERROR = "REQUEST_COMPARE_PRODUCT_ERROR";

export const requestCompareProduct = (
  id: number,
  typeProduct: number,
  parentType: number,
) => ({
  type: REQUEST_COMPARE_PRODUCT,
  id,
  typeProduct,
  parentType,
});

export const requestCompareProductOnSuccess = (
  id: number,
  typeProduct: number,
  parentType: number,
) => ({
  type: REQUEST_COMPARE_PRODUCT_SUCCESS,
  id,
  typeProduct,
  parentType,
});

//////////////////////////////
export const FETCH_COMPARE = "FETCH_COMPARE";
export const FETCH_COMPARE_SUCCESS = "FETCH_COMPARE_SUCCESS";

export const fetchCompare = () => ({
  type: FETCH_COMPARE,
});
export const fetchCompareOnSuccess = () => ({
  type: FETCH_COMPARE_SUCCESS,
});

////////////////////////////////
export const REQUEST_FETCH_COMPARE_PRODUCTS = "REQUEST_FETCH_COMPARE_PRODUCTS";
export const FETCH_ABOUT_COMPARE_PRODUCTS_SUCCESS =
  "FETCH_ABOUT_COMPARE_PRODUCTS_SUCCESS";
export const FETCH_ABOUT_COMPARE_PRODUCTS_ERROR =
  "FETCH_ABOUT_COMPARE_PRODUCTS_ERROR";

export const requestFetchCompareProducts = (compareList: []) => ({
  type: REQUEST_FETCH_COMPARE_PRODUCTS,
  compareList,
});

export const fetchCompareProductsOnSuccess = (compare: any) => ({
  type: FETCH_ABOUT_COMPARE_PRODUCTS_SUCCESS,
  compare,
});

export const fetchCompareProductsOnError = (error: any) => ({
  type: FETCH_ABOUT_COMPARE_PRODUCTS_ERROR,
  error,
});

////////////////////////////////
export const REQUEST_RESET_DATA_RESULT_COMPARE =
  "REQUEST_RESET_DATA_RESULT_COMPARE";

export const requestResetDataResultCompare = () => ({
  type: REQUEST_RESET_DATA_RESULT_COMPARE,
});
