import LoadingBox from "components/molecules/LoadingBox";
import React from "react";
import styled, { css } from "styled-components/macro";

interface LoadingStateProps {
  error?: any;
  mask?: boolean;
}

const LoadingState: React.FC<LoadingStateProps> = ({ error, mask }) => {
  return (
    <Container className="a-loadingstate" mask={mask}>
      {mask && <LoadingEx />}
      {error && <ErrorBlock>{error}</ErrorBlock>}
    </Container>
  );
};

const LoadingEx = styled(LoadingBox)``;

const Container = styled.div<{ mask?: boolean }>`
  ${({ mask }) =>
    mask
      ? css`
          position: fixed;
          z-index: 9999999999;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.035);

          ${LoadingEx} {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        `
      : ""}
`;

const ErrorBlock = styled.div`
  font-size: 12px;
  color: white;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 1em;
  font-weight: bold;
`;

export default LoadingState;
