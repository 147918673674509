import Icon, { IconName } from "components/atoms/Icon";
import React from "react";
import "./index.scss";

interface SupportProps {
  title?: string;
  iconName?: IconName;
}

const Support: React.FC<SupportProps> = (props: SupportProps) => {
  return (
    <div className="m-support">
      <Icon iconName={props.iconName} />
      <div className="m-support_title">{props.title}</div>
    </div>
  );
};

Support.defaultProps = {};

export default Support;
