import { initialNotify } from "constants/notify";
import { AnyAction } from "redux";

const initialState: {
  loading: boolean;
  loaded: boolean;
  notify: NotifyType;
} = {
  loading: false,
  loaded: false,
  notify: initialNotify,
};

const SET_NOTIFY_MODAL = "SET_NOTIFY_MODAL";

export const setNotifyModal = (notify: NotifyType) => ({
  type: SET_NOTIFY_MODAL,
  notify,
});

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_NOTIFY_MODAL:
      return {
        ...state,
        notify: action.notify,
      };
    default:
      return state;
  }
};
