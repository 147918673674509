import { AnyAction } from "redux";
import {
  RESET_SELECTED_PACKAGE,
  SAVE_SELECTED_PACKAGE,
} from "redux/actions/pages/bookingPackage";

const defaultState = {
  data: {
    selectedPackage: "",
  },
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    // SAVE SELECTED PACKAGE
    case SAVE_SELECTED_PACKAGE:
      return {
        ...state,
        data: {
          ...state.data,
          selectedPackage: action.selectedPackage,
        },
      };
    // RESET SELECTED PACKAGE
    case RESET_SELECTED_PACKAGE:
      return {
        ...state,
        data: {
          ...state.data,
          selectedPackage: "",
        },
      };
    default:
      return state;
  }
};
