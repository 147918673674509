// FAQ CATEGORIES
export const REQUEST_FETCH_FAQ_CATEGORIES = "REQUEST_FETCH_FAQ_CATEGORIES";
export const REQUEST_FETCH_FAQ_CATEGORIES_SUCCESS =
  "REQUEST_FETCH_FAQ_CATEGORIES_PAGE_SUCCESS";
export const REQUEST_FETCH_FAQ_CATEGORIES_ERROR =
  "REQUEST_FETCH_FAQ_CATEGORIES_ERROR";

export const requestFetchFaqCategories = () => ({
  type: REQUEST_FETCH_FAQ_CATEGORIES
});

export const fetchFaqCategoriesOnSuccess = (faqCategories: any) => ({
  type: REQUEST_FETCH_FAQ_CATEGORIES_SUCCESS,
  faqCategories
});

export const fetchFaqCategoriesOnError = (error: any) => ({
  type: REQUEST_FETCH_FAQ_CATEGORIES_ERROR,
  error
});

// CONTENT BY CATEGORY
export const REQUEST_FETCH_CONTENT_BY_CATEGORY =
  "REQUEST_FETCH_CONTENT_BY_CATEGORY";
export const REQUEST_FETCH_CONTENT_BY_CATEGORY_SUCCESS =
  "REQUEST_FETCH_CONTENT_BY_CATEGORY_SUCCESS";
export const REQUEST_FETCH_CONTENT_BY_CATEGORY_ERROR =
  "REQUEST_FETCH_CONTENT_BY_CATEGORY_ERROR";

export const requestFetchContentCategory = (limit: number, id: number) => ({
  type: REQUEST_FETCH_CONTENT_BY_CATEGORY,
  id,
  limit
});

export const fetchContentCategoryOnSuccess = (contentCategory: any) => ({
  type: REQUEST_FETCH_CONTENT_BY_CATEGORY_SUCCESS,
  contentCategory
});

export const fetchContentCategoryOnError = (error: any) => ({
  type: REQUEST_FETCH_CONTENT_BY_CATEGORY_ERROR,
  error
});
