import React from "react";

export const getDataSchema = (data: any, key: string) => (
  <script
    key={`dataJSON-${key}`}
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: JSON.stringify(data),
    }}
  />
);

export const convertDangerousHtmlToChildren = (node: any) => {
  if (node.props && node.props.dangerouslySetInnerHTML) {
    return {
      ...node,
      props: {
        ...node.props,
        dangerouslySetInnerHTML: undefined,
        children: node.props.dangerouslySetInnerHTML.__html,
      },
    };
  }
  return node;
};
