import { SystemEnum } from "components/molecules/ViewedProduct";
import { useEffect, useMemo, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import useSystem from "./useSystem";
import ReactGA4 from 'react-ga4';
import { useSelector } from "./useApp";

const useAnalytics = () => {
  const { contact } = useSystem("contact");
  const location = useLocation();
  const locationStr = useMemo(() => location && location.pathname, [location]);
  const { data: systemData } = useSelector(state => state.general.system);
  const ga4Id = useMemo(() => {
    if (!systemData || systemData?.length < 1) {
      return undefined
    }
    const ga4 = systemData?.find((item: any) => item?.key === 'ga4');
    return ga4?.content;
  }, [systemData]);

  const gaId = useMemo(() => {
    if (!systemData || systemData?.length < 1) {
      return undefined
    }
    const ga = systemData?.find((item: any) => item?.key === 'ga');
    return ga?.content;
  }, [systemData]);

  const fbPixelIds = useMemo(() => {
    if (!systemData || systemData?.length < 1) {
      return undefined
    }
    const fbPxIds = systemData?.find((item: any) => item?.key === SystemEnum.fbPixelId);
    return fbPxIds?.content;
  }, [systemData]);

  useEffect(() => {
    if (fbPixelIds && Array.isArray(fbPixelIds)) {
      setTimeout(
        () => {
          fbPixelIds.forEach((item) => {
            ReactPixel.init(item, undefined, {
              autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
              debug: false, // enable logs
            });
          })
        },
        locationStr.includes("thanh-toan-thanh-cong") ? 0 : 800,
      );
    }
  }, [fbPixelIds]);

  useEffect(() => {
    if (gaId) {
      if (typeof gaId === 'string') {
        ReactGA.initialize(gaId);
      }
      if (typeof gaId === 'number') {
        ReactGA.initialize(gaId.toString());
      }
      ReactGA.plugin.require("ec"); // enhanced ecommerce
    }
  }, [gaId]);

  useEffect(() => {
    if (contact) {
      setTimeout(
        () => {
          ReactPixel.pageView(); // For tracking page view
        },
        locationStr.includes("thanh-toan-thanh-cong") ? 0 : 800,
      );
    }
  }, [contact, location.pathname, location.search, locationStr]);


  useEffect(() => {
    if (ga4Id) {
      if (typeof ga4Id === 'string') {
        ReactGA4.initialize(ga4Id);
      }
      if (typeof ga4Id === 'number') {
        ReactGA4.initialize(ga4Id.toString());
      }
    }
  }, [ga4Id]);

  useEffect(() => {
    if (ga4Id && location) {
      ReactGA4.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });
    }

    if (gaId && location) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location, gaId, ga4Id]);
};

export default useAnalytics;
