import { AnyAction } from "redux";

const initialState = {
  stock: false,
};

const SET_MODAL = "SET_MODAL";

export const setModal = (name: "stock", status: Boolean) => ({
  type: SET_MODAL,
  name,
  status,
});

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_MODAL:
      return {
        ...state,
        [action.name]: action.status,
      };
    default:
      return state;
  }
};
