import {
  removeAccessToken,
  setAccessRefreshToken,
  setAccessToken,
} from "api/access-token";
import {
  fetchCart,
  fetchCartByUser,
  fetchUserInfor,
  forgetPassword,
  login,
  register,
} from "api/interna-api";
import {
  dispatchError,
  dispatchForgotPasswordDone,
  dispatchForgotPasswordError,
  dispatchLoading,
  dispatchLoginDone,
  dispatchRegisterDone,
  dispatchRegisterError,
  dispatchReInit,
} from "hooks/useLoginForm";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  authForgotPasswordDone,
  authForgotPasswordError,
  authLoginDone,
  authLoginFailed,
  authRegisterDone,
  AUTH_FORGET_PASSWORD,
  AUTH_LOGIN,
  AUTH_LOGIN_DONE,
  AUTH_LOGOUT,
  AUTH_REGISTER,
} from "redux/actions/auth/auths";
import { removeUser, userInfor } from "redux/actions/auth/userInfor";
import {
  fetchCartProducts,
  fetchCartProductsOnError,
  fetchCartProductsOnSuccess,
} from "redux/actions/products/cart";

function* loginSaga({
  email,
  password,
}: {
  type: string;
  email: string;
  password: string;
}) {
  try {
    dispatchLoading();
    const data = yield call(login, {
      email,
      password,
    });
    const { token, refreshToken } = data;
    yield put(authLoginDone(token, refreshToken));
  } catch (error) {
    yield put(authLoginFailed(error));
    dispatchError(error);
  }
}

function* registerSaga({
  name,
  email,
  password,
}: {
  type: string;
  name: string;
  email: string;
  password: string;
}) {
  try {
    dispatchLoading();
    yield call(register, {
      name,
      email,
      password,
    });
    yield put(authRegisterDone());
    dispatchRegisterDone();
  } catch (error) {
    dispatchRegisterError(error);
  }
}

function* forgetPasswordSaga({ email }: { type: string; email: string }) {
  // dispatchLoginLoading();
  try {
    yield call(forgetPassword, email);
    yield put(authForgotPasswordDone());
    dispatchForgotPasswordDone();
    // dispatchLoginDone();
  } catch (error) {
    yield put(authForgotPasswordError());
    dispatchForgotPasswordError(error);
  }
}

function* tokenSaga({
  token,
  refreshToken,
}: {
  type: string;
  token: string;
  refreshToken: string;
}) {
  try {
    if (token && refreshToken) {
      setAccessToken(token);
      setAccessRefreshToken(refreshToken);
    }
    const userInformation = yield call(fetchUserInfor);
    yield put(userInfor(userInformation));

    const cart = JSON.parse(window.localStorage.getItem("cart"))
      ? JSON.parse(window.localStorage.getItem("cart")).map((c: any) => ({
        ...c,
        isHaveSetupService: c.isHaveSetupService || 0,
      }))
      : [];
    if (Object.values(userInformation) && cart.length === 0) {
      try {
        const data = yield call(fetchCartByUser);
        if (data.length > 0) {
          // POINT CHECK CART: FETCH CART HERE - BY User
          window.localStorage.setItem(
            "cart",
            JSON.stringify(
              data.map(c => ({
                productId: c.product_id,
                quantity: c.quantity,
                isHaveSetupService: 1,
              })),
            ),
          );
          yield put(fetchCartProducts());
        }
      } catch (error) {
        fetchCartProductsOnError(error);
      }
    } else {
      try {
        const data = yield call(fetchCart, cart);
        yield put(fetchCartProductsOnSuccess(data));
      } catch (error) {
        if (
          error.response.data.errors[0].code === "521" ||
          error.response.data.errors[0].code === "503"
        ) {
          window.localStorage.removeItem("cart");
          window.localStorage.removeItem("renewal");
        }
      }
    }
  } catch {
    // Empty
  } finally {
    dispatchLoginDone();
  }
}

function* logoutSaga() {
  dispatchReInit();
  yield put(removeUser());
  removeAccessToken();
}

export default function* watchAuthSaga() {
  yield all([
    takeLatest(AUTH_LOGIN, loginSaga),
    takeLatest(AUTH_REGISTER, registerSaga),
    takeLatest(AUTH_FORGET_PASSWORD, forgetPasswordSaga),
    takeLatest(AUTH_LOGIN_DONE, tokenSaga),
    takeLatest(AUTH_LOGOUT, logoutSaga),
  ]);
}
