export const getRoomValue = (_room: CheckboxField[]) =>
  _room.every(item => item.value === "1")
    ? "1"
    : _room.every(item => item.value === "2") ||
      (_room.some(
        item => item.value === "2" || item.value === "3" || item.value === "4",
      ) &&
        !_room.some(item => item.value === "1"))
    ? "2"
    : "3";

/**
 *
 * @param bank Đối tượng discount bank đã chọn
 * @param totalProvisional Giá trị áp dụng tính KM theo %
 * @returns Trả về giá KM theo discountBanking
 */
export const getDiscountValue = (
  bank: PromotionsBank,
  totalProvisional: number,
) =>
  bank && bank.discountType === 0
    ? Number(bank.discountValue)
    : Math.min(
        (Number(bank.discountValue) * totalProvisional) / 100,
        // áp dụng lấy giá trị KM tối đa là promotionLimitMax:
        bank.promotionLimitMax,
      );

export const objectFilter = (obj: any, predicate: any) =>
  Object.keys(obj)
    .filter((key: any) => predicate(obj[key]))
    .reduce((res: any, key: any) => {
      const k = obj[key].key;
      const collect = ((res[k] = obj[key].content), res);
      return collect;
    }, {});

export default {};
