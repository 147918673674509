import { fetchGuaranteeInfo, fetchStatusOrders } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchGuaranteeInfoOnError,
  fetchGuaranteeInfoOnSuccess,
  fetchStatusOrdersOnError,
  fetchStatusOrdersOnSuccess,
  REQUEST_FETCH_GUARANTEE_INFO,
  REQUEST_FETCH_STATUS_ORDERS,
} from "redux/actions/pages/dashboard";

function* fetchStatusOrdersFlow({ statusOrder }: AnyAction) {
  try {
    const data = yield call(fetchStatusOrders, statusOrder);
    yield put(fetchStatusOrdersOnSuccess(data));
  } catch (error) {
    yield put(fetchStatusOrdersOnError(error));
  }
}
function* fetchGuaranteeInfoFlow({ guaranteeInfo }: AnyAction) {
  try {
    const data = yield call(fetchGuaranteeInfo, guaranteeInfo);
    yield put(fetchGuaranteeInfoOnSuccess(data));
  } catch (error) {
    yield put(fetchGuaranteeInfoOnError(error));
  }
}

export default function* watchFetchStatusOrdersSaga() {
  yield takeLatest(REQUEST_FETCH_STATUS_ORDERS, fetchStatusOrdersFlow);
  yield takeLatest(REQUEST_FETCH_GUARANTEE_INFO, fetchGuaranteeInfoFlow);
}
