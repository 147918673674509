import { validationAccount } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  requestValidationAccountOnError,
  requestValidationAccountOnSuccess,
  REQUEST_VALIDATION_ACCOUNT,
} from "redux/actions/general/validationAccount";

function* requestValidationAccountFlow({ productId, email, phone }: AnyAction) {
  try {
    const data = yield call(validationAccount, {
      productId,
      email,
      phone,
    });
    yield put(requestValidationAccountOnSuccess(data));
  } catch (error) {
    yield put(requestValidationAccountOnError(error));
  }
}

export default function* watchRequestValidationAccountFlowSaga() {
  yield takeLatest(REQUEST_VALIDATION_ACCOUNT, requestValidationAccountFlow);
}
