export const REQUEST_FETCH_DISTRICT = "REQUEST_FETCH_DISTRICT";
export const REQUEST_FETCH_DISTRICT_SUCCESS = "REQUEST_FETCH_DISTRICT_SUCCESS";
export const REQUEST_FETCH_DISTRICT_ERROR = "REQUEST_FETCH_DISTRICT_ERROR";

export const requestFetchDistricts = (cityId: number) => ({
  type: REQUEST_FETCH_DISTRICT,
  cityId
});

export const fetchDistrictsOnSuccess = (districts: any) => ({
  type: REQUEST_FETCH_DISTRICT_SUCCESS,
  districts
});

export const fetchDistrictsOnError = (error: any) => ({
  type: REQUEST_FETCH_DISTRICT_ERROR,
  error
});
