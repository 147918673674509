import { AnyAction } from "redux";
import {
  FETCH_PROMOTION_BANNER_ERROR,
  FETCH_PROMOTION_BANNER_SUCCESS,
  FETCH_PROMOTION_PAGE_ERROR,
  FETCH_PROMOTION_PAGE_SUCCESS,
  REQUEST_FETCH_PROMOTION_BANNER,
  REQUEST_FETCH_PROMOTION_PAGE,
} from "redux/actions/pages/promotion";

const defaultState = {
  productData: {},
  productError: null,
  productLoading: false,
  bannerData: [],
  bannertError: null,
  bannertLoading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_FETCH_PROMOTION_PAGE:
      return {
        ...state,
        productLoading: true,
      };
    case FETCH_PROMOTION_PAGE_SUCCESS:
      return {
        ...state,
        productData: action.promotion,
        productError: null,
        productLoading: false,
      };
    case FETCH_PROMOTION_PAGE_ERROR:
      return {
        ...state,
        productError: action.error,
        productLoading: false,
      };
    // Banner
    case REQUEST_FETCH_PROMOTION_BANNER:
      return {
        ...state,
        bannerLoading: true,
      };
    case FETCH_PROMOTION_BANNER_SUCCESS:
      return {
        ...state,
        bannerData: action.banner,
        bannerError: null,
        bannerLoading: false,
      };
    case FETCH_PROMOTION_BANNER_ERROR:
      return {
        ...state,
        bannerError: action.error,
        bannerLoading: false,
      };
    default:
      return state;
  }
};
