import { AnyAction } from "redux";
import {
  FETCH_SUPPLIES_BY_HP_ERROR,
  FETCH_SUPPLIES_BY_HP_SUCCESS,
  FETCH_SUPPLY_PRODUCT_ERROR,
  FETCH_SUPPLY_PRODUCT_SUCCESS,
  REQUEST_FETCH_SUPPLIES_BY_HP,
  REQUEST_FETCH_SUPPLY_PRODUCT,
} from "redux/actions/products/supply";

const defaultState = {
  data: {
    supplies: [],
    suppliesByHp: [],
  },
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_FETCH_SUPPLY_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUPPLY_PRODUCT_SUCCESS:
      return {
        data: {
          ...state.data,
          supplies: action.supplies,
        },
        error: null,
        loading: false,
      };
    case FETCH_SUPPLY_PRODUCT_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    //
    case REQUEST_FETCH_SUPPLIES_BY_HP:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUPPLIES_BY_HP_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          suppliesByHp: action.suppliesByHp,
        },
        error: null,
        loading: false,
      };
    case FETCH_SUPPLIES_BY_HP_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
