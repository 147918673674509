import { AnyAction } from "redux";
import {
  FETCH_SEEN_PRODUCTS_ERROR,
  FETCH_SEEN_PRODUCTS_SUCCESS,
  REQUEST_FETCH_SEEN_PRODUCTS,
} from "redux/actions/products/seen";

const defaultState = {
  data: {},
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_FETCH_SEEN_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SEEN_PRODUCTS_SUCCESS:
      return {
        data: action.seen,
        error: null,
        loading: false,
      };
    case FETCH_SEEN_PRODUCTS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
