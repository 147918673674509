export const REQUEST_MAIL_SUBSCRIBER = "REQUEST_MAIL_SUBSCRIBER";
export const REQUEST_MAIL_SUBSCRIBER_SUCCESS =
  "REQUEST_MAIL_SUBSCRIBER_SUCCESS";
export const REQUEST_MAIL_SUBSCRIBER_ERROR = "REQUEST_MAIL_SUBSCRIBER_ERROR";

export const requestMailSubscriber = (mail: string) => ({
  type: REQUEST_MAIL_SUBSCRIBER,
  mail,
});

export const requestMailSubscriberOnSuccess = () => ({
  type: REQUEST_MAIL_SUBSCRIBER_SUCCESS,
});

export const requestMailSubscriberOnError = (error: any) => ({
  type: REQUEST_MAIL_SUBSCRIBER_ERROR,
  error,
});
