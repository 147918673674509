export const REQUEST_FETCH_SUB_SKYAIR = "REQUEST_FETCH_SUB_SKYAIR";
export const REQUEST_FETCH_SUB_SKYAIR_SUCCESS =
  "REQUEST_FETCH_SUB_SKYAIR_SUCCESS";
export const REQUEST_FETCH_SUB_SKYAIR_ERROR = "REQUEST_FETCH_SUB_SKYAIR_ERROR";

export const requestFetchSubSkyAir = (slug: string) => ({
  type: REQUEST_FETCH_SUB_SKYAIR,
  slug,
});

export const fetchSubSkyAirOnSuccess = (subSkyAir: any) => ({
  type: REQUEST_FETCH_SUB_SKYAIR_SUCCESS,
  subSkyAir,
});

export const fetchSubSkyAirOnError = (error: any) => ({
  type: REQUEST_FETCH_SUB_SKYAIR_ERROR,
  error,
});
