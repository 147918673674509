import { AnyAction } from "redux";
import {
  FETCH_NEWS_CATEGORIES_SUCCESS,
  FETCH_NEWS_LIST_BY_CATEGORY_ERROR,
  FETCH_NEWS_LIST_BY_CATEGORY_SUCCESS,
  REQUEST_FETCH_NEWS_CATEGORIES,
  REQUEST_FETCH_NEWS_DETAIl,
  REQUEST_FETCH_NEWS_DETAIl_ERROR,
  REQUEST_FETCH_NEWS_DETAIl_SUCCESS,
  REQUEST_FETCH_NEWS_LIST_BY_CATEGORY,
} from "redux/actions/news/list";

const defaultState = {
  data: {
    news: [],
    newsTotalCount: 0,
    categories: [],
    newsDetail: {},
  },
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_FETCH_NEWS_CATEGORIES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NEWS_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          categories: action.categories,
        },
        error: null,
      };
    case REQUEST_FETCH_NEWS_LIST_BY_CATEGORY:
      return {
        ...state,
        // loading: true,
      };
    case FETCH_NEWS_LIST_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          news: action.news.data,
          newsTotalCount: action.news.meta.totalRecord,
          seo: action.news.seo,
        },
        error: null,
        loading: false,
      };
    case FETCH_NEWS_LIST_BY_CATEGORY_ERROR:
      return {
        ...state,
        error: action.errors,
        loading: true,
      };
    //
    case REQUEST_FETCH_NEWS_DETAIl:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REQUEST_FETCH_NEWS_DETAIl_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          newsDetail: action.newsDetail,
        },
        loading: false,
        error: null,
      };
    case REQUEST_FETCH_NEWS_DETAIl_ERROR:
      return {
        ...state,
        error: action.error,
        loading: true,
      };
    default:
      return state;
  }
};
