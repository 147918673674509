import Button from "components/atoms/Button";
import Text from "components/atoms/Text";
import ButtonToolbar from "components/molecules/ButtonToolbar";
import Modal from "components/organisms/Modal";
import ModalBody from "components/organisms/Modal/Body";
import ModalFooter from "components/organisms/Modal/Footer";
import { useSelector } from "hooks/useApp";
import useCompare from "hooks/useCompare";
import React from "react";
import "./index.scss";

interface ModalsCompareProps {}

const ModalsCompare: React.FC<ModalsCompareProps> = (
  props: ModalsCompareProps,
) => {
  const {
    notifyCompareModal: { notify },
  } = useSelector(state => state.view);
  const {
    compare: { data: compareList },
  } = useSelector(state => state.pages);
  const { onClearCompare, onCloseModal, compareDifferentType } = useCompare(
    compareList,
  );

  return (
    <>
      <Modal isOpen={notify.status} onCloseRequest={onCloseModal} resetModal>
        <ModalBody>
          <Text modifiers={["center"]}>{notify.message}</Text>
        </ModalBody>
        <ModalFooter>
          {notify.type === "type" ? (
            <ButtonToolbar modifiers={["center"]}>
              <Button variant="primary" onClick={compareDifferentType}>
                So sánh
              </Button>
              <Button variant="secondary" onClick={onClearCompare}>
                Bỏ chọn
              </Button>
              <Button variant="secondary" onClick={onCloseModal}>
                Hủy
              </Button>
            </ButtonToolbar>
          ) : (
            <Button onClick={onCloseModal} variant="primary" size="small">
              Đóng
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

ModalsCompare.defaultProps = {};

export default ModalsCompare;
