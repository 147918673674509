import { fetchDetailProduct } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchDetailProductOnError,
  fetchDetailProductOnSuccess,
  REQUEST_FETCH_DETAIL_PRODUCT,
} from "redux/actions/products/detail";

function* fetchDetailProductFlow({ id, fetchType }: AnyAction) {
  try {
    const data = yield call(fetchDetailProduct, id, fetchType);
    yield put(fetchDetailProductOnSuccess(data, fetchType));
  } catch (error) {
    yield put(fetchDetailProductOnError(error, fetchType));
  }
}

export default function* watchFetchDetailProductSaga() {
  yield takeEvery(REQUEST_FETCH_DETAIL_PRODUCT, fetchDetailProductFlow);
}
