import "./index.scss";

import React, { useEffect } from "react";

import { getParams } from "utils/utils-common";
import { useHistory } from "react-router-dom";
import { clearLocalCart } from "constants/cart";

interface SocialProps { }

const Social: React.FC<SocialProps> = (props: SocialProps) => {
  const history = useHistory();
  useEffect(() => {
    const { token, refreshToken } = getParams();
    const pathname = window.localStorage.getItem("pathname") || "/";
    window.localStorage.setItem("userToken", token);
    window.localStorage.setItem("refreshToken", refreshToken);
    clearLocalCart();
    history.push(pathname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};

Social.defaultProps = {};

export default Social;
