import { RefObject, useCallback, useEffect } from "react";

const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  callback: () => void,
): void => {
  const handleClick = useCallback(
    (e: MouseEvent): void => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        callback();
      }
    },
    [callback, ref],
  );

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return (): void => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
};

export default useOutsideClick;
