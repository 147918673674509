export const RESET_DETAIL_PRODUCT = "RESET_DETAIL_PRODUCT";
export const REQUEST_FETCH_DETAIL_PRODUCT = "REQUEST_FETCH_DETAIL_PRODUCT";
export const FETCH_DETAIL_PRODUCT_SUCCESS = "FETCH_DETAIL_PRODUCT_SUCCESS";
export const FETCH_DETAIL_PRODUCT_ERROR = "FETCH_DETAIL_PRODUCT_ERROR";

export const resetDetailProduct = () => ({
  type: RESET_DETAIL_PRODUCT,
});

export const requestFetchDetailProduct = (
  id: string,
  fetchType: FetchDetailProductType,
) => ({
  type: REQUEST_FETCH_DETAIL_PRODUCT,
  id,
  fetchType,
});

export const fetchDetailProductOnSuccess = (
  detail: any,
  fetchType: FetchDetailProductType,
) => ({
  type: FETCH_DETAIL_PRODUCT_SUCCESS,
  detail,
  fetchType,
});

export const fetchDetailProductOnError = (
  error: any,
  fetchType: FetchDetailProductType,
) => ({
  type: FETCH_DETAIL_PRODUCT_ERROR,
  error,
  fetchType,
});
