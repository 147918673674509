export const getAccessToken = () => window.localStorage.getItem("userToken");
export const getAccessRefreshToken = () =>
  window.localStorage.getItem("refreshToken");

export const setAccessToken = (token: string) => {
  window.localStorage.setItem("userToken", token);
};
export const setAccessRefreshToken = (refreshToken: string) => {
  window.localStorage.setItem("refreshToken", refreshToken);
};

export const removeAccessToken = () => {
  window.localStorage.removeItem("userToken");
  window.localStorage.removeItem("refreshToken");
  window.localStorage.removeItem("pathname");
  window.localStorage.removeItem("productIds");
  window.localStorage.removeItem("compare");
};
