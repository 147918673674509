import byType from "./byType";
import cart from "./cart";
import { combineReducers } from "redux";
import criterias from "./criterias";
import detail from "./detail";
import search from "./search";
import seen from "./seen";
import supply from "./supply";

export default combineReducers({
  cart,
  detail,
  criterias,
  seen,
  search,
  byType,
  supply,
});
