export const REQUEST_FETCH_SYSTEM = "REQUEST_FETCH_SYSTEM";
export const REQUEST_FETCH_SYSTEM_SUCCESS = "REQUEST_FETCH_SYSTEM_SUCCESS";
export const REQUEST_FETCH_SYSTEM_ERROR = "REQUEST_FETCH_SYSTEM_ERROR";

export const requestFetchSystem = () => ({
  type: REQUEST_FETCH_SYSTEM,
});

export const fetchSystemOnSuccess = (system: any) => ({
  type: REQUEST_FETCH_SYSTEM_SUCCESS,
  system
});

export const fetchSystemOnError = (error: any) => ({
  type: REQUEST_FETCH_SYSTEM_ERROR,
  error
});