import React, { memo } from "react";
import { joinClassnames } from "utils/map-modifier";

interface Props {
  footerClassName?: string;
  children?: React.ReactNode;
}
const ModalFooter: React.FC<Props> = ({ children, footerClassName }) => {
  return (
    <div className={joinClassnames("o-modal-footer", footerClassName)}>
      {children}
    </div>
  );
};

export default memo(ModalFooter);
