import { css } from "styled-components/macro";

export const centerFlexCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const centerInlineFlexCss = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const centerYFlexCss = css`
  display: flex;
  align-items: center;
`;

export default {};
