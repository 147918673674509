export const REQUEST_FETCH_NEWS_CATEGORIES = 'REQUEST_FETCH_NEWS_CATEGORIES';
export const FETCH_NEWS_CATEGORIES_SUCCESS = 'FETCH_NEWS_CATEGORIES_SUCCESS';

export const REQUEST_FETCH_NEWS_LIST_BY_CATEGORY =
  'REQUEST_FETCH_NEWS_LIST_BY_CATEGORY';
export const FETCH_NEWS_LIST_BY_CATEGORY_SUCCESS =
  'FETCH_NEWS_LIST_BY_CATEGORY_SUCCESS';
export const FETCH_NEWS_LIST_BY_CATEGORY_ERROR =
  'FETCH_NEWS_LIST_BY_CATEGORY_ERROR';

// News Detail
export const REQUEST_FETCH_NEWS_DETAIl = 'REQUEST_FETCH_NEWS_DETAIl';
export const REQUEST_FETCH_NEWS_DETAIl_SUCCESS =
  'REQUEST_FETCH_NEWS_DETAIl_SUCCESS';
export const REQUEST_FETCH_NEWS_DETAIl_ERROR =
  'REQUEST_FETCH_NEWS_DETAIl_ERROR';

export const requestFetchNewsCategories = () => ({
  type: REQUEST_FETCH_NEWS_CATEGORIES,
});

export const fetchNewsCategoriesSuccess = (categories: any) => ({
  type: FETCH_NEWS_CATEGORIES_SUCCESS,
  categories,
});

export const requestFetchNewsListByCategory = (categorySlug: string, limit: number) => ({
  type: REQUEST_FETCH_NEWS_LIST_BY_CATEGORY,
  categorySlug,
  limit
});

export const fetchNewsListOnSuccess = (news: any) => ({
  type: FETCH_NEWS_LIST_BY_CATEGORY_SUCCESS,
  news,
});

export const fetchNewsListOnError = (error: any) => ({
  type: FETCH_NEWS_LIST_BY_CATEGORY_ERROR,
  error,
});

//
export const requestFetchNewsDetail = (slug: string) => ({
  type: REQUEST_FETCH_NEWS_DETAIl,
  slug,
});

export const fetchNewsDetailOnSuccess = (newsDetail: any) => ({
  type: REQUEST_FETCH_NEWS_DETAIl_SUCCESS,
  newsDetail,
});

export const fetchNewsDetailOnError = (error: any) => ({
  type: REQUEST_FETCH_NEWS_DETAIl_ERROR,
  error,
});
