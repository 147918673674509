import { fetchCities } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchCitiesOnError,
  fetchCitiesOnSuccess,
  REQUEST_FETCH_CITIES,
} from "redux/actions/general/cities";

function* fetchCitiesFlow({ countryId }: AnyAction) {
  try {
    const data = yield call(fetchCities, countryId);
    yield put(fetchCitiesOnSuccess(data));
  } catch (error) {
    yield put(fetchCitiesOnError(error));
  }
}

export default function* watchFetchCitiesSaga() {
  yield takeLatest(REQUEST_FETCH_CITIES, fetchCitiesFlow);
}
