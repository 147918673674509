import { AnyAction } from "redux";
import {
  FETCH_ABOUT_COMPARE_PRODUCTS_ERROR,
  FETCH_ABOUT_COMPARE_PRODUCTS_SUCCESS,
  FETCH_COMPARE_SUCCESS,
  REQUEST_COMPARE_PRODUCT,
  REQUEST_COMPARE_PRODUCT_SUCCESS,
  REQUEST_FETCH_COMPARE_PRODUCTS,
  REQUEST_RESET_DATA_RESULT_COMPARE,
} from "redux/actions/pages/compare";

const defaultState = {
  data: [],
  dataResult: [],
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_COMPARE_PRODUCT:
      return {
        ...state,
        // loading: true
      };
    case REQUEST_COMPARE_PRODUCT_SUCCESS:
      const { id, typeProduct, parentType } = action;
      const nextState = {
        ...state,
        data:
          state.data.length < 3
            ? state.data.length > 0
              ? state.data.find(c => c.id === id)
                ? state.data.filter(c => c.id !== id) || []
                : [...state.data, { id, typeProduct, parentType }]
              : [
                  {
                    id,
                    typeProduct,
                    parentType,
                  },
                ]
            : state.data.find(c => c.id === id)
            ? state.data.filter(c => c.id !== id)
            : [...state.data],
        error: null,
        loading: false,
      };
      window.localStorage.setItem("compare", JSON.stringify(nextState.data));
      return nextState;
    case FETCH_COMPARE_SUCCESS:
      let compare = JSON.parse(localStorage.getItem("compare")) || [];
      return {
        ...state,
        data: compare,
      };

    case REQUEST_FETCH_COMPARE_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ABOUT_COMPARE_PRODUCTS_SUCCESS:
      return {
        ...state,
        dataResult: action.compare,
        loading: false,
      };
    case FETCH_ABOUT_COMPARE_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case REQUEST_RESET_DATA_RESULT_COMPARE:
      return {
        ...state,
        dataResult: [],
      };
    default:
      return state;
  }
};
