const {
  REACT_APP_API_URL,
  REACT_APP_BASE_URL,
  REACT_APP_SITE_URL,
  // REACT_APP_API_NGAN_LUONG_PAY_URL,
  REACT_APP_INTERNAL,
  REACT_APP_KEY_CAPTCHA
} = process.env;

export const API_URL = REACT_APP_API_URL;
export const BASE_URL = REACT_APP_BASE_URL;
export const SITE_URL = REACT_APP_SITE_URL;
// export const NGAN_LUONG_PAY_URL = REACT_APP_API_NGAN_LUONG_PAY_URL;
export const INTERNAL = REACT_APP_INTERNAL;
export const KEY_CAPTCHA = REACT_APP_KEY_CAPTCHA;
