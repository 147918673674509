import { BASE_URL, SITE_URL } from "constants/env";
import { useSelector } from "hooks/useApp";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { convertDangerousHtmlToChildren, getDataSchema } from "utils/helmet";
import { filterSEOTags, isExternal, notEmptyObject } from "utils/utils-common";
import "./index.scss";

interface HeadProps {
  data: HeadData;
}

const Head: React.FC<HeadProps> = ({
  data = {
    titlePage: "",
    title: "",
    description: "",
    keywords: "",
    image: "",
  },
}) => {
  const { data: systemData } = useSelector(state => state.general.system);
  const [seoTitle, setSeoTitle] = useState("");
  const [seoDesc, setSeoDesc] = useState("");
  const [seoKeywords, setSeoKeywords] = useState("");
  const [seoImage, setSeoImage] = useState("");
  const [seoTwitterURL, setSeoTwitterURL] = useState("");
  const [seoTwitterLogo, setSeoTwitterLogo] = useState("");
  const [structuredData, setStructuredData] = useState<StructuredData>();
  const seo = filterSEOTags(systemData);

  const {
    titlePage,
    title,
    description,
    keywords,
    image,
    product,
    article,
    breadcrumb,
  } = data;

  useEffect(() => {
    try {
      seo.forEach((element: SystemProps) => {
        const elContent = (element.content && element.content.vi) || "";
        switch (String(element.key)) {
          case "seo_title":
            setSeoTitle(elContent);
            break;
          case "seo_description":
            setSeoDesc(elContent);
            break;
          case "seo_keyword":
            setSeoKeywords(elContent);
            break;
          case "seo_image":
            setSeoImage(elContent);
            break;
          case "twitter_url":
            setSeoTwitterURL(elContent);
            break;
          case "twitter_logo":
            setSeoTwitterLogo(elContent);
            break;
          default:
            break;
        }
      });
    } catch (error) {
      // console.log(error);
    }

    if (systemData) {
      let organization: Organization = {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "Daikin Vietnam",
        url: "https://shop.daikin.com.vn/",
        logo: "https://shop.daikin.com.vn/static/media/logo.2dd76d64.svg",
        contactPoint: [
          {
            "@type": "ContactPoint",
            telephone: "1800 6777",
            contactType: "Customer Service",
            availableLanguage: ["Tiếng Việt", "English"],
            contactOption: [],
          },
        ],
        sameAs: [
          "https://www.facebook.com/DaikinVietnam/",
          "https://www.youtube.com/channel/UCtd9FUxi0nvGtx4BD7KC7JQ",
        ],
      };

      setStructuredData(prevStruct => ({
        ...prevStruct,
        organization,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemData]);

  useEffect(() => {
    if (product) {
      setStructuredData(prevStruct => ({
        ...prevStruct,
        product,
      }));
    }
  }, [product]);

  useEffect(() => {
    if (article) {
      setStructuredData(prevStruct => ({
        ...prevStruct,
        article,
      }));
    }
  }, [article]);

  useEffect(() => {
    if (breadcrumb) {
      setStructuredData(prevStruct => ({
        ...prevStruct,
        breadcrumb,
      }));
    }
  }, [breadcrumb]);

  return (
    <Helmet>
      <title>{titlePage || title || seoTitle || ""}</title>
      <meta name="keywords" content={keywords || seoKeywords || ""} />
      <meta name="description" content={description || seoDesc || ""} />
      {/* fb */}
      <meta property="fb:app_id" content="" />
      {/* og */}
      <meta property="og:site_name" content={title || seoTitle || ""} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title || seoTitle || ""} />
      <meta property="og:description" content={description || seoDesc || ""} />
      <meta
        property="og:image"
        content={checkImageUrl(image) || checkImageUrl(seoImage) || ""}
      />
      <meta
        property="og:url"
        content={seoTwitterURL || SITE_URL + window.location.pathname}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      {/* twitter */}
      <meta
        name="twitter:card"
        content={
          checkImageUrl(image) ||
          checkImageUrl(seoTwitterLogo) ||
          checkImageUrl(seoImage) ||
          ""
        }
      />
      <meta
        name="twitter:site"
        content={seoTwitterURL || SITE_URL + window.location.pathname}
      />
      <meta name="twitter:title" content={title || seoTitle || ""} />
      <meta name="twitter:description" content={description || seoDesc || ""} />
      <meta
        property="twitter:image"
        content={
          checkImageUrl(image) ||
          checkImageUrl(seoTwitterLogo || checkImageUrl(seoImage) || "")
        }
      />
      <meta name="twitter:image:alt" content={description || seoDesc || ""} />

      {structuredData &&
        notEmptyObject(structuredData) &&
        Object.keys(structuredData).map((k, i) =>
          convertDangerousHtmlToChildren(getDataSchema(structuredData[k], k)),
        )}
    </Helmet>
  );
};

const checkImageUrl = (url: string) =>
  url ? `${!isExternal(url) && BASE_URL}${url}` : url;

export default React.memo(Head);
