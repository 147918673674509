import Grid, { GridCol, GridRow } from "components/organisms/Grid";
import Section from "components/organisms/Section";
import { SITE_URL } from "constants/env";
import React, { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { convertDangerousHtmlToChildren, getDataSchema } from "utils/helmet";
import "./index.scss";

interface BreadcrumbItemLinkProps {
  href: string;
}

interface BreadcrumbItemProps {}

const Breadcrumb: React.FC<BreadcrumbItemProps> = ({ children }) => {
  const [breadcrumbStructured, setBreadcrumbStructured] = useState<
    BreadcrumbStructured
  >();
  useEffect(() => {
    if (
      children &&
      typeof children === "object" &&
      typeof children.map === "function"
    ) {
      const structuredBreadcrumb: BreadcrumbStructured = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: children.map((ch: any, i: number) => {
          let structuredBreadcrumbItem: BreadcrumbStructuredItem = {
            "@type": "ListItem",
            position: 1,
            name: "",
          };
          if (ch) {
            const {
              props: { children: chChildren, href },
            } = ch;
            structuredBreadcrumbItem = {
              "@type": "ListItem",
              position: i,
              name: chChildren,
              ...(href ? { item: `${SITE_URL}${href}` } : {}),
            };
          }
          return structuredBreadcrumbItem;
        }),
      };
      setBreadcrumbStructured(structuredBreadcrumb);
    }
  }, [children]);

  return (
    <Section modifiers={["grayDark", "nopadding"]}>
      <Helmet>
        {breadcrumbStructured &&
          convertDangerousHtmlToChildren(
            getDataSchema(breadcrumbStructured, "breadcrumb"),
          )}
      </Helmet>

      <Grid>
        <GridRow>
          <GridCol>
            <ul className="m-breadcrumb">{children}</ul>
          </GridCol>
        </GridRow>
      </Grid>
    </Section>
  );
};

export const BreadcrumbItemLink: React.FC<BreadcrumbItemLinkProps> = memo(
  ({ href, children }) => {
    return (
      <li className="m-breadcrumb-item">
        <Link to={href}>{children}</Link>
      </li>
    );
  },
);

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = memo(
  ({ children }) => {
    return <li className="m-breadcrumb-item">{children}</li>;
  },
);

export default memo(Breadcrumb);
