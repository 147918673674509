import Image from "components/atoms/Image";
import Link from "components/atoms/Link";
import Text from "components/atoms/Text";
import Layout from "components/layout";
import Breadcrumb, {
  BreadcrumbItem,
  BreadcrumbItemLink,
} from "components/molecules/Breadcrumb";
import ProcessServingCarousel from "components/molecules/ProcessServingCarousel";
import Support from "components/molecules/Support";
import Grid, { GridCol, GridRow } from "components/organisms/Grid";
import Section from "components/organisms/Section";
import services3 from "images/common/guide-order.jpg";
import React from "react";
import "./index.scss";

interface GuideProps {
  data: any;
}

const Guide: React.FC<GuideProps> = ({ data }) => {
  const owlClass = "p-guide";
  const dataFromProps = data.page_meta_data;
  const SwiperSlide = data.page_meta_data["steps_68"];

  const {
    meta_data: { translations },
  } = data;

  const { description, extra_data, keywords, title } = translations[0];

  return (
    <div className={owlClass}>
      <Layout
        headData={{
          titlePage: title,
          title,
          description,
          keywords,
          image: typeof extra_data === "string" && JSON.parse(extra_data).image,
        }}
      >
        <Breadcrumb>
          <BreadcrumbItemLink href="/">Trang chủ</BreadcrumbItemLink>
          <BreadcrumbItem>Hướng dẫn đặt hàng</BreadcrumbItem>
        </Breadcrumb>
        {dataFromProps && (
          <React.Fragment>
            <Section space="space-md">
              <Grid>
                <GridRow>
                  <GridCol colMd={6}>
                    <ProcessServingCarousel methodList={SwiperSlide} />
                  </GridCol>
                  <GridCol colMd={6}>
                    <Image src={services3} alt="Guide Hướng dẫn đặt hàng" />
                  </GridCol>
                </GridRow>
              </Grid>
            </Section>
            <Section space="space-xs">
              <Text size="xl" weight="bold" modifiers={["center", "uppercase"]}>
                {dataFromProps["text_69"].text}
              </Text>
              <Grid>
                <GridRow>
                  <GridCol colLg={3} colMd={6} colSm={12}>
                    <Link href={dataFromProps["link_85"].link.link}>
                      <Support
                        iconName="support-question"
                        title={dataFromProps["link_85"].link.text}
                      />
                    </Link>
                  </GridCol>
                  <GridCol colLg={3} colMd={6} colSm={12}>
                    <Link
                      isLinkRouter={false}
                      href={dataFromProps["link_86"].link.link}
                    >
                      <Support
                        iconName="support-hotline"
                        title={dataFromProps["link_86"].link.text}
                      />
                    </Link>
                  </GridCol>
                  <GridCol colLg={3} colMd={6} colSm={12}>
                    <Link href={dataFromProps["link_87"].link.link}>
                      <Support
                        iconName="support-contact"
                        title={dataFromProps["link_87"].link.text}
                      />
                    </Link>
                  </GridCol>
                  <GridCol colLg={3} colMd={6} colSm={12}>
                    <Link href={dataFromProps["link_88"].link.link}>
                      <Support
                        iconName="support-percent"
                        title={dataFromProps["link_88"].link.text}
                      />
                    </Link>
                  </GridCol>
                </GridRow>
              </Grid>
            </Section>
          </React.Fragment>
        )}
      </Layout>
    </div>
  );
};

Guide.defaultProps = {};

export default Guide;
