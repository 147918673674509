export const REQUEST_FETCH_HOME_PAGE = "REQUEST_FETCH_HOME_PAGE";
export const FETCH_HOME_PAGE_SUCCESS = "FETCH_HOME_PAGE_SUCCESS";
export const FETCH_HOME_PAGE_ERROR = "FETCH_HOME_PAGE_ERROR";

export const requestFetchHomePage = () => ({
  type: REQUEST_FETCH_HOME_PAGE,
});

export const fetchHomePageOnSuccess = (home: any) => ({
  type: FETCH_HOME_PAGE_SUCCESS,
  home,
});

export const fetchHomePageOnError = (error: any) => ({
  type: FETCH_HOME_PAGE_ERROR,
  error,
});
