import { useEffect, useState } from "react";
import { BehaviorSubject } from "rxjs";

interface LoginFormState {
  show: boolean;
  redirect: string | null;
  error?: any;
  loading: boolean;
  done: boolean;
  showSuccessForgotPassword: boolean;
}

const subject = new BehaviorSubject<LoginFormState>({
  show: false,
  redirect: null,
  loading: false,
  done: false,
  showSuccessForgotPassword: false,
});

export function dispatchLoading() {
  subject.next({
    ...subject.value,
    error: null,
    loading: true,
    done: false,
  });
}

export function dispatchRegisterDone() {
  subject.next({
    ...subject.value,
    redirect: "/dang-nhap",
    error: null,
    loading: false,
    done: true,
  });
}

export function dispatchRegisterError(error: any) {
  subject.next({
    ...subject.value,
    error,
    loading: false,
    done: false,
  });
}

export function dispatchError(error?: any) {
  subject.next({
    ...subject.value,
    error,
    loading: false,
    done: false,
  });
}

export function dispatchLoginDone() {
  subject.next({
    ...subject.value,
    redirect: "/",
    error: null,
    loading: false,
    done: true,
  });
}

export function dispatchLoginByRefreshTokenError() {
  subject.next({
    ...subject.value,
    redirect: "/dang-nhap",
    error: true,
    loading: false,
    done: false,
  });
}

export function dispatchForgotPasswordError(error?: any) {
  subject.next({
    ...subject.value,
    showSuccessForgotPassword: false,
    error,
  });
}

export function dispatchForgotPasswordDone() {
  subject.next({
    ...subject.value,
    showSuccessForgotPassword: true,
    error: null,
  });
}

export function dispatchReInit() {
  subject.next({
    ...subject.value,
    error: null,
    loading: false,
    done: false,
  });
}

export function dispatchForgotPasswordResetState() {
  subject.next({
    ...subject.value,
    showSuccessForgotPassword: false,
  });
}

export function useLoginForm() {
  const [state, setState] = useState<LoginFormState>(subject.value);

  useEffect(() => {
    const subscription = subject.subscribe(nextState => {
      setState(nextState);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  return state;
}
