export const SAVE_USER = "SAVE_USER";
export const REMOVE_USER = "REMOVE_USER";
export const CHANGE_INFO = "CHANGE_INFO";

export const userInfor = (userInfor: any) => ({
  type: SAVE_USER,
  userInfor,
});

export const removeUser = () => ({
  type: REMOVE_USER,
});
