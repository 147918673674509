import React, { memo } from "react";
import { joinClassnames } from "utils/map-modifier";
import "./index.scss";

interface MenuProps {
  children?: React.ReactNode;
  href?: string;
  modifiers?: Modifiers;
  onClick?: () => void;
}

interface ItemProps {
  children: React.ReactNode;
  className?: string;
}

const Menu: React.FC<MenuProps> = ({ children }: MenuProps) => {
  return <ul className="m-menu">{children}</ul>;
};

export const MenuItem: React.FC<ItemProps> = ({
  children,
  className,
}: ItemProps) => {
  return (
    <li className={joinClassnames("m-menu-item", className)}>{children}</li>
  );
};

export default memo(Menu);
