import colors from "constants/colors";
import { maxQuery, SizeMax } from "constants/media";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  requestExtraQuantity,
  requestHygienicQuantity,
} from "redux/actions/pages/packageService";
import styled, { css } from "styled-components/macro";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";

interface Props {
  label?: string;
  modifiers?: Modifiers;
  name: string;
  error?: string;
  refs?: any;
  value: number;
  product?: any;
  hygienicId?: number | string;
  service?: any;
  hygienicExtraId?: number;
  responsive?: boolean;
  className?: string;
  onChangeQuantity?: (product: any, quantity: number, sign: string) => void;
}

const QuantityButton: React.FC<Props> = ({
  label,
  modifiers,
  name,
  error,
  refs,
  value,
  product,
  hygienicId,
  service,
  hygienicExtraId,
  responsive,
  className,
  onChangeQuantity,
}: Props) => {
  let [quantityTxt, setQuantitytxt] = useState(value || 1);
  const dispatch = useDispatch();

  const decrease = () => {
    if (product && onChangeQuantity) {
      onChangeQuantity(product, quantityTxt - 1, "-");
    } else if (hygienicId) {
      dispatch(requestHygienicQuantity(Number(hygienicId), "-"));
    } else if (service && hygienicExtraId) {
      dispatch(requestExtraQuantity(hygienicExtraId, service.id, "-"));
    }
  };

  const increase = () => {
    if (product && onChangeQuantity) {
      onChangeQuantity(product, quantityTxt + 1, "+");
    } else if (hygienicId) {
      dispatch(requestHygienicQuantity(Number(hygienicId), "+"));
    } else if (service && hygienicExtraId) {
      dispatch(requestExtraQuantity(hygienicExtraId, service.id, "+"));
    }
  };

  useEffect(() => {
    setQuantitytxt(value);
  }, [value]);

  return (
    <Container
      className={joinClassnames(
        mapMultiClassnames("a-quantity", modifiers),
        responsive && "has-responsive",
        className,
      )}
    >
      {label && (
        <LabelText className="a-quantity_label">
          {label && <span>{label}</span>}
        </LabelText>
      )}
      <Adjust className="a-quantity_adjust">
        <span>{error}</span>
        <button
          type="button"
          className="a-quantity_decrease"
          disabled={+quantityTxt <= 1}
          onClick={decrease}
        >
          -
        </button>
        <input
          name={name}
          value={quantityTxt}
          ref={refs}
          type="number"
          min="1"
          onChange={e => {
            setQuantitytxt(parseInt(e.target.value));
          }}
        />
        <button
          type="button"
          className="a-quantity_increase"
          onClick={increase}
        >
          +
        </button>
      </Adjust>
    </Container>
  );
};

const LabelText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  padding: 4px;
  height: 100%;
  color: ${colors.white};
  font: 500 normal 12px/16px "Roboto", sans-serif;
  color: ${colors.white};
  text-transform: uppercase;
  background-color: ${colors.pictonBlue};
`;

const Adjust = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white};
  border-radius: 3px;
  overflow: hidden;

  span {
    display: none;
  }

  button {
    height: 100%;
    width: 20%;
    color: ${colors.ashGrey};
    background-color: ${colors.white};
    border: none;
    cursor: pointer;
  }

  button:focus,
  input:focus {
    border: none;
    outline: none;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    pointer-events: none;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;

  &.a-quantity {
    &-normal {
      height: 44px;
      width: 100%;
      border: 1px solid ${colors.pictonBlue};
    }

    &-sort {
      min-width: 84px;
      max-width: 186px;
      height: 36px;
      border: 1px solid ${colors.spanishGray};
    }

    &-long {
      width: 186px;
      height: 36px;
      border: 1px solid ${colors.spanishGray};
    }

    &.has-responsive {
      ${maxQuery(
        SizeMax.Medium,
        css`
          width: 30px;
        `,
      )}
    }

    &-invalid {
      position: relative;
      border: 1px solid ${colors.pastelRed};
      ${LabelText} {
        background-color: ${colors.pastelRed};
      }
      ${Adjust} {
        position: relative;
        span {
          display: block;
          font: 200 normal 12px/16px "Roboto", sans-serif;
          position: absolute;
          color: white;
          text-align: left;
          margin-top: 0.125em;
          padding: 1px 5px;
          white-space: nowrap;
          right: 0;
          bottom: calc(100% + 5px);
          border-radius: 2px;
          background: ${colors.pastelRed};
          transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);

          &:before {
            content: "";
            border: 5px solid ${colors.pastelRed};
            border-bottom-color: transparent;
            border-right-color: transparent;
            border-left-color: transparent;
            position: absolute;
            top: 100%;
            right: 10%;
          }
        }
      }
    }
  }
`;

export default QuantityButton;
