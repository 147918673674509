import { combineReducers } from "redux";
import modal from "redux/reducers/view/modal";
import notifyCompareModal from "redux/reducers/view/notifyCompare";
import notifyModal from "redux/reducers/view/notifyModal";
import outstock from "redux/reducers/view/outstock";
import ref from "redux/reducers/view/ref";

export default combineReducers({
  modal,
  notifyCompareModal,
  notifyModal,
  ref,
  outstock,
});
