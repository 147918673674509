import { fetchPromotionBanners, fetchPromotionProducts } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchPromotionBannerOnError,
  fetchPromotionBannerOnSuccess,
  fetchPromotionPageOnError,
  fetchPromotionPageOnSuccess,
  REQUEST_FETCH_PROMOTION_BANNER,
  REQUEST_FETCH_PROMOTION_PAGE,
} from "redux/actions/pages/promotion";

function* fetchPromotionPageFlow({ promotion }: AnyAction) {
  try {
    const data = yield call(fetchPromotionProducts, promotion);
    yield put(fetchPromotionPageOnSuccess(data));
  } catch (error) {
    yield put(fetchPromotionPageOnError(error));
  }
}

function* fetchPromotionBannerFlow() {
  try {
    const data = yield call(fetchPromotionBanners);
    yield put(fetchPromotionBannerOnSuccess(data));
  } catch (error) {
    yield put(fetchPromotionBannerOnError(error));
  }
}

export default function* watchFetchPromotionPageSaga() {
  yield takeLatest(REQUEST_FETCH_PROMOTION_PAGE, fetchPromotionPageFlow);
  yield takeLatest(REQUEST_FETCH_PROMOTION_BANNER, fetchPromotionBannerFlow);
}
