import { AnyAction } from "redux";
import {
  GET_HOLIDAY,
  SET_BETWEEN_HOLIDAY,
  SET_HOLIDAY,
} from "redux/actions/deliver/holiday";

// DEFAULT HOLIDAY SETTINGS:

// TET HOLIDAY:
const startTetDate = new Date("02/12/2021");
startTetDate.setHours(0, 0, 0, 0);
const endTetDate = new Date("02/19/2021");
endTetDate.setHours(23, 59, 59);

const deliverFree = "Giao hàng tiêu chuẩn | 1 - 2 ngày";
const deliverFreeHoliday =
  "Giao hàng tiêu chuẩn | 1 - 2 ngày (Hoạt động trở lại từ ngày 20/02/2021)";

const DEFAULT_STATE_VALUE = {
  title: "TET_HOLIDAY",
  startDate: startTetDate,
  endDate: endTetDate,
  deliverText: deliverFree,
};

const defaultState: HolidayType = DEFAULT_STATE_VALUE;

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case GET_HOLIDAY:
      return {
        ...state,
      };
    case SET_HOLIDAY:
      const { title, startDate, endDate } = state;
      return {
        ...state,
        title,
        startDate,
        endDate,
      };
    case SET_BETWEEN_HOLIDAY:
      return {
        ...state,
        deliverText: deliverFreeHoliday,
      };
    default: {
      return state;
    }
  }
};
