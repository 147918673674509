export const REQUEST_CHECK_EMAIL_EXIST = "REQUEST_CHECK_EMAIL_EXIST";
export const REQUEST_CHECK_EMAIL_SUCCESS = "REQUEST_CHECK_EMAIL_SUCCESS";
export const REQUEST_CHECK_EMAIL_ERROR = "REQUEST_CHECK_EMAIL_ERROR";
export const RESET_STATE_CHECK_EMAIL = "RESET_STATE_CHECK_EMAIL";

export const resetStateCheckEmail = () => ({
  type: RESET_STATE_CHECK_EMAIL,
});

export const requestCheckEmailExist = (email: string) => ({
  type: REQUEST_CHECK_EMAIL_EXIST,
  email,
});

export const checkEmailOnSuccess = () => ({
  type: REQUEST_CHECK_EMAIL_SUCCESS,
});

export const checkEmailOnError = (error: any) => ({
  type: REQUEST_CHECK_EMAIL_ERROR,
  error,
});
