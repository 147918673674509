import { AnyAction } from "redux";
import {
  FETCH_SAVE_SUBSCRIBER_ERROR,
  FETCH_SAVE_SUBSCRIBER_SUCCESS,
  REQUEST_CLEAR_SUBSCRIBER,
  REQUEST_FETCH_SAVE_SUBSCRIBER,
} from "redux/actions/general/subscriber";

const defaultState = {
  data: null,
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_FETCH_SAVE_SUBSCRIBER:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SAVE_SUBSCRIBER_SUCCESS:
      return {
        data: action.result,
        error: false,
        loading: false,
      };
    case FETCH_SAVE_SUBSCRIBER_ERROR:
      return {
        data: action.error,
        error: true,
        loading: false,
      };
    case REQUEST_CLEAR_SUBSCRIBER:
      return {
        data: null,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};
