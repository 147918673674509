import { maxQuery, minQuery, SizeMax, SizeMin } from "constants/media";
import { BreakPointSwiper } from "constants/swiper";
import React, { ReactNode, useEffect, useMemo, useState } from "react";

import styled, { css } from "styled-components/macro";
import { Navigation, Pagination, SwiperOptions } from "swiper";
import { Swiper } from 'swiper/react';
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import Heading from "./../../atoms/Heading/index";
import "./index.scss";

interface CarouselProps {
  children: ReactNode;
  speed?: number;
  autoplay?: {
    delay: number;
    disableOnInteraction: boolean;
  };
  modifiers?: Modifiers;
  pagination?: boolean;
  title?: string;
  whiteHeading?: boolean;
  breakpoints?: BreakPointSwiper;
  className?: string;
  centeredSlides?: boolean;
  id?: string;
  slidesPerView?: number | "auto";
  rebuildOnUpdate?: boolean;
  moreHeight?: boolean;
  mousewheel?: boolean;
  autoHeight?: boolean;
  activeSlideKey?: string;
  hideNavigationAt?: {
    min?: SizeMin;
    max?: SizeMax;
  };
  loop?: boolean;
  onEndList?: () => void;
  customParams?: SwiperOptions;
  spaceBetween?: number;
  onTransitionEnd?: (sw: any) => void;
  activeIndex?: number;
  isThumbnail?: boolean;
}

const Carousel: React.FC<CarouselProps> = ({
  children,
  modifiers,
  pagination,
  title,
  whiteHeading,
  breakpoints,
  className,
  centeredSlides = false,
  slidesPerView,
  id,
  moreHeight = false,
  autoplay,
  speed = 200,
  mousewheel,
  autoHeight,
  hideNavigationAt,
  activeSlideKey,
  loop = false,
  onEndList,
  customParams,
  spaceBetween,
  onTransitionEnd,
  activeIndex,
  isThumbnail,
}) => {
  const [defaultKey, setDefaultKey] = useState("5");
  const params = useMemo(() => ({
    // Provide Swiper class as props
    Swiper,
    // Add modules you need
    modules: [Navigation, Pagination],
    spaceBetween: spaceBetween ? spaceBetween : 30,
    autoPlay: true,
    loop,
    preloadImages: true,
    watchOverflow: true,
    uniqueNavElements: true,
    lazy: true,
    mousewheel: mousewheel,
    grabCursor: true,
    centeredSlides,
    // spaceBetween: 100,
    speed,
    slidesPerView: slidesPerView || 2,
    slidesPerGroup: 1,
    breakpoints,
    autoplay,
    observer: true,
    observeParents: true,
    navigation: true,
    pagination: pagination ? {
      type: "bullets",
      clickable: true,
    } : false,
    freeMode: false,
    autoHeight,
    shouldSwiperUpdate: true,
    rebuildOnUpdate: true,
    activeSlideKey: defaultKey,
    effect: "coverflow",
    coverflowEffect: {
      rotate: 30,
      slideShadows: false,
    },
    ...customParams,
  } as SwiperOptions), [autoHeight, autoplay, breakpoints, centeredSlides, customParams, defaultKey, loop, mousewheel, pagination, slidesPerView, spaceBetween, speed]);
  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    if (swiper && isThumbnail) {
      swiper?.slideTo(activeIndex)
    }
  }, [activeIndex, isThumbnail, swiper])

  useEffect(() => {
    if (moreHeight && id) {
      const elementId = document.getElementById(id);
      if (elementId) {
        let parent = elementId.querySelectorAll(".swiper-wrapper");
        let cashbackLabel = elementId.querySelectorAll(".a-cashback-label");
        if (parent) {
          let cblheight = 0;
          if (cashbackLabel) {
            cblheight = cashbackLabel[0].clientHeight;
          }
          parent[0].setAttribute(
            "style",
            `height: ${parent[0].clientHeight + cblheight}`,
          );
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moreHeight]);

  useEffect(() => {
    if (id) {
      const elementId = document.getElementById(id);

      if (elementId) {
        let nextButton = elementId.querySelectorAll(".swiper-button-next");
        if (nextButton && nextButton[0]) {
          nextButton[0].addEventListener("click", function () {
            setTimeout(() => {
              if (nextButton[0].classList.contains("swiper-button-disabled"))
                onEndList && onEndList();
            }, 500);
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const heading = useMemo(
    () => (
      <Heading
        modifiers={whiteHeading ? ["white", "uppercase"] : ["uppercase"]}
      >
        {title}
      </Heading>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [title],
  );

  useEffect(() => {
    const id = setTimeout(() => {
      setDefaultKey(activeSlideKey ? activeSlideKey : "0");
    }, 500);
    return () => clearTimeout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id={id || ""}
      className={joinClassnames(
        mapMultiClassnames("m-carousel", modifiers),
        className,
      )}
    >
      {title && heading}
      {children && (
        <SwiperTyped
          hideNavigationAt={hideNavigationAt}
          className="m-carousel_wrap-slider wrap-slider"
        >
          <Swiper {...params} onTransitionEnd={onTransitionEnd} onSwiper={setSwiper}>{children}</Swiper>
        </SwiperTyped>
      )
      }
    </div >
  );
};

type SwiperType = {
  hideNavigationAt?: { min?: SizeMin; max?: SizeMax };
};

const SwiperTyped = styled.div<SwiperType>`
  ${({ hideNavigationAt }) =>
    hideNavigationAt &&
    (hideNavigationAt.min
      ? minQuery(
        hideNavigationAt.min,
        css`
            .swiper-button-next,
            .swiper-button-prev {
              display: none;
            }
          `,
      )
      : hideNavigationAt.max
        ? maxQuery(
          hideNavigationAt.max,
          css`
            .swiper-button-next,
            .swiper-button-prev {
              display: none;
            }
          `,
        )
        : css``)}
`;

export default Carousel;
