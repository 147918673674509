import { fetchFilters } from "api/interna-api";
import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  requestFetchFiltersOnError,
  requestFetchFiltersOnSuccess,
  REQUEST_FETCH_FILTERS,
} from "redux/actions/pages/product";

function* fetchFiltersFlow({ index }: AnyAction) {
  try {
    const data = yield call(fetchFilters, index);
    yield put(requestFetchFiltersOnSuccess(data));
  } catch (error) {
    yield put(requestFetchFiltersOnError(error));
  }
}

export default function* watchFetchProductSaga() {
  yield all([takeLatest(REQUEST_FETCH_FILTERS, fetchFiltersFlow)]);
}
