import React from "react";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import Icon, { IconName } from "../Icon";
import Text from "../Text";
import "./index.scss";

interface CheckboxProps {
  name: string;
  type: string;
  value?: string | boolean;
  display?: React.ReactNode;
  icon?: IconName;
  modifier?: Modifiers;
  refs?: any;
  checked?: boolean;
  title?: string;
  noSpacingLeft?: boolean;
  className?: string;
  onChange?: (e: any) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  display,
  onChange,
  modifier,
  checked,
  value,
  icon,
  refs,
  title,
  noSpacingLeft,
  className,
  ...inputProps
}: CheckboxProps) => (
  <label
    className={joinClassnames(
      mapMultiClassnames("a-checkbox", modifier),
      noSpacingLeft && "no-spacing-left",
      className,
    )}
  >
    <input
      name={name}
      checked={checked}
      onChange={onChange}
      ref={refs}
      title={title}
      {...inputProps}
    />
    <div className="a-checkbox_input">
      <span className="a-checkbox_input_icon"></span>
    </div>
    <span className="a-checkbox_text">
      <Text size="sm" modifiers={["gray"]}>
        <label
          dangerouslySetInnerHTML={{
            __html: display || "",
          }}
        />
      </Text>
    </span>
    {icon && <Icon iconName={icon} />}
  </label>
);

export default Checkbox;
