import { SizeMin, minQuery, maxQuery, SizeMax } from "./media";
import colors, { alphaColor } from "./colors";
import styled, { css } from "styled-components/macro";
import Button from "components/atoms/Button";

export const Loader = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  @include break-min(768px) {
    width: 80px;
    height: 80px;
    border-width: 4px;
  }
`;

export const SpinLoading = styled.div`
  position: fixed;
  background-color: ${alphaColor.black045};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 999999;

  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .rc-rate {
    padding-right: 10px;

    .rc-rate-star {
      margin-right: 2px;
    }
  }

  ${minQuery(
  SizeMin.Small,
  css`
      display: flex;
      margin-top: 5px;
    `,
)}
`;

export const RatingReviewCounting = styled.div`
  cursor: pointer;
  font-size: 11px;
  padding-top: 3px;

  ${minQuery(
  SizeMin.Small,
  css`
      font-size: 1em;
    `,
)}

  &:hover {
    color: ${colors.mikadoYellowStar} !important;
  }
`;

export const StatusIcon = styled.span`
  width: 8px;
  height: 8px;
`;

export const ActiveStatusIcon = styled(StatusIcon)`
  background-color: ${colors.richElectricBlue};
`;

export const DeActiveStatusIcon = styled(StatusIcon)`
  background-color: ${colors.pastelGray};
`;

export const LoadingInfo = styled.div`
  text-align: center;
  font-size: 11px;
  color: ${colors.spanishGray};
`;

export const WrapLoading = styled.div`
  position: relative;
  padding: 1em;

  ${LoadingInfo} {
    margin-top: 0.25em;
  }
`;

export const FillLoading = styled.div`
  position: absolute;
  inset: 0;

  > span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: ${colors.spanishGray};
    text-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  }

  &:before,
  &:after {
    content: "";
    display: block;
    background: linear-gradient(to right, transparent, gray);
    position: absolute;
    inset: 0;
    animation: slideToRight 2.5s infinite;
    transform: translateX(0);
    animation-direction: alternate;
  }

  &:before {
  }
  &:after {
    animation-delay: 2s;
  }

  @keyframes slideToRight {
    to {
      transform: translateX(100%);
    }
  }
`;

export const NotifyPanel = styled.div<NotifyPanel>`
  font-size: 11px;
  margin-top: 1em;
  padding-left: 0.25em;
  padding: 1em;
  margin-bottom: 1em;

  ${({ type }) =>
    type && type === "danger"
      ? css`
          background: ${alphaColor.lava015}!important;
          color: ${colors.lava}!important;
          strong {
            color: ${colors.green}!important;
          }
        `
      : type === "warning"
        ? css`
          background: ${alphaColor.pumpkin015}!important;
          color: ${colors.pumpkin}!important;
          strong {
            color: ${colors.green}!important;
          }
        `
        : type === "success"
          ? css`
          background: ${alphaColor.green015}!important;
          color: ${colors.green}!important;
          strong {
            color: ${colors.green}!important;
          }
        `
          : css`
          background: ${alphaColor.black016}!important;
          color: ${colors.black}!important;
          strong {
            color: ${colors.black}!important;
          }
        `}
`;

export const ButtonConfirmWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const ButtonConfirm = styled(Button)`
  max-width: 120px;

  &:last-of-type {
    margin-left: 1em;
  }
`;

export const TextConfirmCode = styled.code`
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  border-radius: 3px;
  padding: 0.25em 0.5em;
  background-color: ${colors.richElectricBlue};
  color: white;
`;

export const TextConfirm = styled.p`
  font-size: 12px;
`;

export const TitleConfirm = styled.h3`
  font-size: 16px;
  margin-bottom: 20px;
`;

export const ConfirmWrapper = styled.div`
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  max-width: 400px;
  padding: 30px 40px;
  background: ${colors.white};
  border-radius: 10px;
  ${maxQuery(
  SizeMax.Small,
  css`
      padding: 1em 1.5em;
    `,
)}
`;

export const DeliveryProvinceSelectionLabel = styled.div<{
  cartPage?: boolean;
}>`
  display: block;
  ${({ cartPage }) =>
    cartPage
      ? css`
          margin-top: 1em;
          font-size: 15px;
          white-space: nowrap;
        `
      : css`
          font-size: 17px;
        `};
  font-weight: bold;
`;

export default {};
