export const REQUEST_SEARCH_PRODUCTS = "REQUEST_SEARCH_PRODUCTS";
export const FETCH_SEARCH_PRODUCTS_SUCCESS = "FETCH_SEARCH_PRODUCTS_SUCCESS";
export const FETCH_SEARCH_PRODUCTS_ERROR = "FETCH_SEARCH_PRODUCTS_ERROR";

export const requestSearchProducts = (searchKey: string) => ({
  type: REQUEST_SEARCH_PRODUCTS,
  searchKey
});

export const fetchSearchProductsOnSuccess = (searchResult: any) => ({
  type: FETCH_SEARCH_PRODUCTS_SUCCESS,
  searchResult
});

export const fetchSearchProductsOnError = (error: any) => ({
  type: FETCH_SEARCH_PRODUCTS_ERROR,
  error
});
