import { searchStatusOrder } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  requestSearchOrderOnError,
  requestSearchOrderOnSuccess,
  REQUEST_SEARCH_ORDER,
} from "redux/actions/pages/statusOrder";

function* requestSearchOrderFlow({ code }: AnyAction) {
  try {
    const data = yield call(searchStatusOrder, code);
    yield put(requestSearchOrderOnSuccess(data));
  } catch (error) {
    yield put(requestSearchOrderOnError(error));
  }
}

export default function* watchRequestSearchOrderFlowSaga() {
  yield takeLatest(REQUEST_SEARCH_ORDER, requestSearchOrderFlow);
}
