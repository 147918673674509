import { fetchProductTypes } from "api/interna-api";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  requestFetchProductTypesOnError,
  requestFetchProductTypesOnSuccess,
  REQUEST_FETCH_PRODUCT_TYPES,
} from "redux/actions/pages/productTypes";

function* fetchProductTypesFlow() {
  try {
    const data = yield call(fetchProductTypes);
    yield put(requestFetchProductTypesOnSuccess(data));
  } catch (error) {
    yield put(requestFetchProductTypesOnError(error));
  }
}

export default function* watchFetchProductTypesSaga() {
  yield all([takeLatest(REQUEST_FETCH_PRODUCT_TYPES, fetchProductTypesFlow)]);
}
