import { IconName } from "components/atoms/Icon";
import React, { ChangeEvent, FocusEvent, MouseEvent, ReactNode } from "react";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import "./index.scss";

interface TextfieldProps {
  name: string;
  placeholder?: string;
  modifiers?: Modifiers;
  iconName?: IconName;
  value?: string;
  disabled?: boolean;
  type?: string;
  label?: string;
  validate?: "valid" | "invalid";
  button?: ReactNode;
  autoComplete?: "off";
  refs?: any;
  noBar?: boolean;
  required?: boolean;
  hideInvalidIcon?: boolean;
  autoFocus?: boolean;
  onFocus?: (e?: FocusEvent<HTMLInputElement>) => void;
  onClick?: (e?: MouseEvent<HTMLInputElement>) => void;
  onLeave?: (e?: MouseEvent<HTMLInputElement>) => void;
  onBlur?: (e?: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  setValue?: (name: string, value: string) => void;
  className?: string;
  classNameInput?: string;
}

const Textfield: React.FC<TextfieldProps> = ({
  name,
  refs,
  iconName,
  type,
  label,
  value,
  disabled,
  button,
  modifiers,
  validate,
  noBar,
  required,
  hideInvalidIcon,
  autoFocus,
  onFocus,
  onClick,
  onLeave,
  onBlur,
  onChange,
  setValue,
  className,
  classNameInput,
  ...innerProps
}) => {
  return (
    <div
      className={joinClassnames(
        mapMultiClassnames(
          "a-textfield",
          modifiers,
          value ? "emty" : "none-emty",
          "required",
        ),
        disabled && "a-textfield-disabled",
        className,
      )}
    >
      <div className="a-textfield_inner">
        <input
          type={type || undefined}
          name={name}
          ref={refs}
          autoFocus={autoFocus ? autoFocus : undefined}
          disabled={disabled}
          className={joinClassnames("a-textfield_input", classNameInput)}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          onChange={e => {
            onChange && onChange(e);
            setValue && setValue(name, e.currentTarget.value);
          }}
          {...innerProps}
        />
        {!noBar && <span className={"a-textfield_bar"}></span>}
        {label && <label>{label}</label>}
        {((modifiers &&
          (modifiers.includes("valid") || modifiers.includes("invalid"))) ||
          required) &&
          !hideInvalidIcon && <span className="a-textfield-valid_icon"></span>}
      </div>
      {button && <div className="a-textfield_button">{button}</div>}
    </div>
  );
};

export default Textfield;
