import { postCardToken } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  postCardTokenOnError,
  postCardTokenOnSuccess,
  REQUEST_POST_CARD_TOKEN,
} from "redux/actions/general/cardToken";

function* postCardTokenFlow({ requestParam }: AnyAction) {
  try {
    const data = yield call(postCardToken, requestParam);
    yield put(postCardTokenOnSuccess(data));
  } catch (error) {
    yield put(postCardTokenOnError(error));
  }
}

export default function* watchPostCardTokenSaga() {
  yield takeLatest(REQUEST_POST_CARD_TOKEN, postCardTokenFlow);
}
