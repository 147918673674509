import { fetchProductCities } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchProductCitiesOnError,
  fetchProductCitiesOnSuccess,
  REQUEST_FETCH_PRODUCT_CITIES,
} from "redux/actions/general/productCities";

function* fetchProductCitiesFlow({ productId, cityId }: AnyAction) {
  try {
    const data = yield call(fetchProductCities, productId, cityId);
    yield put(fetchProductCitiesOnSuccess(data));
  } catch (error) {
    yield put(fetchProductCitiesOnError(error));
  }
}

export default function* watchFetchCitiesSaga() {
  yield takeLatest(REQUEST_FETCH_PRODUCT_CITIES, fetchProductCitiesFlow);
}
