import { fetchPageViaSlug } from "api/interna-api";
import { SLUG } from "constants/page";
import { useEffect, useState } from "react";

interface PageData {
  data: any;
  loading: boolean;
  error?: any;
}

const usePage = (slug: string) => {
  const [data, setData] = useState<PageData>({
    data: null,
    loading: false,
    error: null,
  });

  useEffect(() => {
    if (slug && slug !== SLUG.notFound) {
      setData({
        data: null,
        loading: true,
      });

      fetchPageViaSlug(slug)
        .then(res => {
          setData({
            data: res,
            loading: false,
          });
        })
        .catch((error: any) => {
          setData({
            data: null,
            loading: false,
            error
          });
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  return data;
};

export default usePage;
