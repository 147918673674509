export const REQUEST_VALIDATION_ACCOUNT = "REQUEST_VALIDATION_ACCOUNT";
export const REQUEST_VALIDATION_ACCOUNT_SUCCESS =
  "REQUEST_VALIDATION_ACCOUNT_SUCCESS";
export const REQUEST_VALIDATION_ACCOUNT_ERROR =
  "REQUEST_VALIDATION_ACCOUNT_ERROR";

export const requestValidationAccount = ({
  productId,
  email,
  phone,
}: {
  productId: number;
  email: string;
  phone?: string;
}) => {
  return {
    type: REQUEST_VALIDATION_ACCOUNT,
    productId,
    email,
    phone,
  };
};

export const requestValidationAccountOnSuccess = (data: any) => ({
  type: REQUEST_VALIDATION_ACCOUNT_SUCCESS,
  data,
});

export const requestValidationAccountOnError = (error: any) => ({
  type: REQUEST_VALIDATION_ACCOUNT_ERROR,
  error,
});
