export const REQUEST_FETCH_PACKAGE_TEMP_SERVICE_PAGE =
  "REQUEST_FETCH_PACKAGE_TEMP_SERVICE_PAGE";
export const FETCH_PACKAGE_SERVICE_PAGE_SUCCESS =
  "FETCH_PACKAGE_SERVICE_PAGE_SUCCESS";
export const FETCH_PACKAGE_SERVICE_PAGE_ERROR =
  "FETCH_PACKAGE_SERVICE_PAGE_ERROR";

export const requestFetchPackageServicePage = () => ({
  type: REQUEST_FETCH_PACKAGE_TEMP_SERVICE_PAGE,
});

export const fetchPackageServicePageOnSuccess = (packageService: any) => ({
  type: FETCH_PACKAGE_SERVICE_PAGE_SUCCESS,
  packageService,
});

export const fetchPackageServicePageOnError = (error: any) => ({
  type: FETCH_PACKAGE_SERVICE_PAGE_ERROR,
  error,
});

export const REQUEST_FETCH_HYGIENIC_SERVICE_ON_SUCCESS =
  "REQUEST_FETCH_HYGIENIC_SERVICE_ON_SUCCESS";
export const REQUEST_FETCH_HYGIENIC_SERVICE_ON_ERROR =
  "REQUEST_FETCH_HYGIENIC_SERVICE_ON_ERROR";

export const REQUEST_FETCH_HYGIENIC_SERVICE = "REQUEST_FETCH_HYGIENIC_SERVICE";
export const requestFetchHygienicService = () => ({
  type: REQUEST_FETCH_HYGIENIC_SERVICE,
});

export const requestFetchHygienicServiceOnSuccess = (hygienic: any) => ({
  type: REQUEST_FETCH_HYGIENIC_SERVICE_ON_SUCCESS,
  hygienic,
});

export const requestFetchHygienicServiceOnError = (error: any) => ({
  type: REQUEST_FETCH_HYGIENIC_SERVICE_ON_ERROR,
  error,
});

export const REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE =
  "REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE";
export const REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE_ON_SUCCESS =
  "REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE_ON_SUCCESS";
export const REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE_ON_ERROR =
  "REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE_ON_ERROR";

export const requestFetchPackagesService = () => ({
  type: REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE,
});

export const requestFetchPackagesServiceOnSuccess = (packages: any) => ({
  type: REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE_ON_SUCCESS,
  packages,
});

export const requestFetchPackagesServiceOnError = (error: any) => ({
  type: REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE_ON_ERROR,
  error,
});

export const REQUEST_SCHEDULE_PACKAGE_SERVICE =
  "REQUEST_SCHEDULE_PACKAGE_SERVICE";
export const REQUEST_SCHEDULE_PACKAGE_SERVICE_ON_SUCCESS =
  "REQUEST_SCHEDULE_PACKAGE_SERVICE_ON_SUCCESS";

export const requestSchedulePackageService = (id: number) => ({
  type: REQUEST_SCHEDULE_PACKAGE_SERVICE,
  id,
});

export const requestSchdulePackageServiceOnSuccess = (id: number) => ({
  type: REQUEST_SCHEDULE_PACKAGE_SERVICE_ON_SUCCESS,
  id,
});

export const REQUEST_HYGIENIC_QUANTITY = "REQUEST_HYGIENIC_QUANTITY";
export const REQUEST_HYGIENIC_QUANTITY_ON_SUCCESS =
  "REQUEST_HYGIENIC_QUANTITY_ON_SUCCESS";

export const requestHygienicQuantity = (id: number, sign: string) => ({
  type: REQUEST_HYGIENIC_QUANTITY,
  sign,
  id,
});

export const requestHygienicQuantityOnSuccess = (id: number, sign: number) => ({
  type: REQUEST_HYGIENIC_QUANTITY_ON_SUCCESS,
  sign,
  id,
});

export const REQUEST_TOGGLE_EXTRA = "REQUEST_TOGGLE_EXTRA";
export const REQUEST_TOGGLE_EXTRA_ON_SUCCESS =
  "REQUEST_TOGGLE_EXTRA_ON_SUCCESS";
export const requestToggleExtra = (hygienicId: number, extraId: number) => ({
  type: REQUEST_TOGGLE_EXTRA,
  hygienicId,
  extraId,
});

export const requestToggleExtraOnSuccess = (
  hygienicId: number,
  extraId: number,
) => ({
  type: REQUEST_TOGGLE_EXTRA_ON_SUCCESS,
  hygienicId,
  extraId,
});

export const REQUEST_EXTRA_QUANTITY = "REQUEST_EXTRA_QUANTITY";
export const REQUEST_EXTRA_QUANTITY_ON_SUCCESS =
  "REQUEST_EXTRA_QUANTITY_ON_SUCCESS";
export const requestExtraQuantity = (
  hygienicId: number,
  extraId: number,
  sign: string,
) => ({
  type: REQUEST_EXTRA_QUANTITY,
  hygienicId,
  extraId,
  sign,
});

export const requestExtraQuantityOnSuccess = (
  extraHygienicId: number,
  extraQuantityId: number,
  extraSign: string,
) => ({
  type: REQUEST_EXTRA_QUANTITY_ON_SUCCESS,
  extraHygienicId,
  extraQuantityId,
  extraSign,
});
