import { requestSaveSchedule } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  removeScheduleOnSuccess,
  REQUEST_REMOVE_SCHEDULE,
  REQUEST_SAVE_SCHEDULE,
  saveScheduleOnError,
  saveScheduleOnSuccess,
} from "redux/actions/pages/schedule";

function* requestSaveSchduleFlow({ scheduleObj }: AnyAction) {
  try {
    const data = yield call(requestSaveSchedule, scheduleObj);
    yield put(saveScheduleOnSuccess(data));
  } catch (error) {
    yield put(saveScheduleOnError(error));
  }
}
function* requestRemoveSchduleFlow() {
  yield put(removeScheduleOnSuccess());
}

export default function* watchRequestScheduleSaga() {
  yield takeLatest(REQUEST_SAVE_SCHEDULE, requestSaveSchduleFlow);
  yield takeLatest(REQUEST_REMOVE_SCHEDULE, requestRemoveSchduleFlow);
}
