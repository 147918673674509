import { fetchServicesPage } from "api/interna-api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchServicesPageOnError,
  fetchServicesPageOnSuccess,
  REQUEST_FETCH_SERVICES_PAGE,
} from "redux/actions/pages/services";

function* fetchSerivesPageFlow() {
  try {
    const data = yield call(fetchServicesPage);
    yield put(fetchServicesPageOnSuccess(data));
  } catch (error) {
    yield put(fetchServicesPageOnError(error));
  }
}

export default function* watchFetchServicesPageSaga() {
  yield takeLatest(REQUEST_FETCH_SERVICES_PAGE, fetchSerivesPageFlow);
}
