import { mailSubscribe } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  requestMailSubscriberOnError,
  requestMailSubscriberOnSuccess,
  REQUEST_MAIL_SUBSCRIBER,
} from "redux/actions/pages/mailsubscriber";

function* requestMailSubscriberFlow({ mail }: AnyAction) {
  try {
    yield call(mailSubscribe, mail);
    yield put(requestMailSubscriberOnSuccess());
  } catch (error) {
    yield put(requestMailSubscriberOnError(error));
  }
}

export default function* watchRequestMailSubscriberFlowSaga() {
  yield takeLatest(REQUEST_MAIL_SUBSCRIBER, requestMailSubscriberFlow);
}
