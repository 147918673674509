import { AnyAction } from "redux";
import { REMOVE_USER, SAVE_USER } from "redux/actions/auth/userInfor";

export default (state = null, action: AnyAction) => {
  switch (action.type) {
    case SAVE_USER:
      return action.userInfor;
    case REMOVE_USER:
      return null;
    default:
      return state;
  }
};
