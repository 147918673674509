import { fetchDistricts } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchDistrictsOnError,
  fetchDistrictsOnSuccess,
  REQUEST_FETCH_DISTRICT,
} from "redux/actions/general/district";

function* fetchDistrictsFlow({ cityId }: AnyAction) {
  try {
    const data = yield call(fetchDistricts, cityId);
    yield put(fetchDistrictsOnSuccess(data));
  } catch (error) {
    yield put(fetchDistrictsOnError(error));
  }
}

export default function* watchFetchDistrictsSaga() {
  yield takeLatest(REQUEST_FETCH_DISTRICT, fetchDistrictsFlow);
}
