import Footer from "components/organisms/Footer";
import Header from "components/organisms/Header";
import SubScribe from "components/organisms/SubScribe";
import { useSelector } from "hooks/useApp";
import useWindowSize from "hooks/useWindowSize";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import LazyLoad from "react-lazyload";
import { useLocation } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import { joinClassnames } from "utils/map-modifier";
import "./index.scss";

// kick off the polyfill!
smoothscroll.polyfill();

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
  headData?: HeadData;
  pendingFooter?: boolean;
  pendingFooterDone?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  className,
}: LayoutProps) => {
  const [showingSearchMobile, setShowingSearchMobile] = useState(false);
  const { width } = useWindowSize();
  const location = useLocation();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isFixed, setIsFixed] = useState(true);
  const { ref, modal } = useSelector(state => state.view);

  useEffect(() => {
    const { pathname } = location;

    if (pathname.includes("san-pham") || pathname.includes("so-sanh")) {
      setIsFixed(false);
    } else {
      setIsFixed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes("/san-pham") || pathname.includes("/so-sanh")) {
      setHeaderHeight(0);
    } else {
      setHeaderHeight(ref["header"].clientHeight);
    }
  }, [location, ref, width]);

  const handleCallParent = useCallback((status: boolean) => {
    setShowingSearchMobile(status);
  }, []);

  const header = useMemo(() => {
    return <Header isFixed={isFixed} callParent={handleCallParent} />;
  }, [handleCallParent, isFixed]);

  return (
    <div className={joinClassnames("t-layout", className)}>
      {header}
      <main
        className={joinClassnames(
          `main-content-wrapper ${isFixed ? "main" : "main-fixed"}`,
          showingSearchMobile && "showingSearchMobile",
          modal["stock"] && "trim-main",
        )}
        style={{ marginTop: headerHeight }}
      >
        {children}
      </main>
      <LazyLoad once offset={200}>
        <SubScribe hide={modal["stock"]} />
      </LazyLoad>
      <LazyLoad once offset={200}>
        <Footer hide={modal["stock"]} />
      </LazyLoad>
    </div>
  );
};

export default Layout;
