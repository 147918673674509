import React, { memo } from "react";
import styled, { css } from "styled-components/macro";
import { joinClassnames } from "utils/map-modifier";

interface LoadingProps {
  widthHeight?: number;
  className?: string;
}

const Loading: React.FC<LoadingProps> = ({ widthHeight, className }) => {
  return (
    <Container className={joinClassnames(className)} widthHeight={widthHeight}>
      <IconLoading />
    </Container>
  );
};

Loading.defaultProps = {};

type IconTypesProps = {
  widthHeight?: number;
};

const IconLoading = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  -webkit-animation: lds-ring 2s linear infinite; /* Safari */
  animation: lds-ring 2s linear infinite;

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Container = styled.div<IconTypesProps>`
  position: relative;
  width: 64px;
  height: 64px;
  margin: 0 auto;

  ${({ widthHeight }) =>
    widthHeight &&
    css`
      width: ${widthHeight}px;
      height: ${widthHeight}px;

      ${IconLoading} {
        width: ${widthHeight}px;
        height: ${widthHeight}px;
      }
    `}
`;

export default memo(Loading);
