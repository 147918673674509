import React, { ReactNode } from "react";
import { mapMultiClassnames } from "utils/map-modifier";
import "./index.scss";
interface Props {
  children?: React.ReactNode;
  href?: string;
  modifiers?: Modifiers;
  button?: ReactNode;
  onClick?: () => void;
}

const ToggleSearchResults: React.FC<Props> = ({
  children,
  modifiers,
}: Props) => (
  <div className={mapMultiClassnames("m-toggle-search-results", modifiers)}>
    <>
      {React.Children.map(children, (item, index) => (
        <div key={index} className="m-toggle-search-results-show">
          {item}
        </div>
      ))}
    </>
  </div>
);

export default ToggleSearchResults;
