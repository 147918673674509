import React, { memo } from "react";
import { mapMultiClassnames } from "utils/map-modifier";
import "./index.scss";

interface ListProps {
  children: React.ReactNode;
  modifiers?: ("icon" | "disc" | "white" | "textlink")[];
}

const List: React.FC<ListProps> = ({ modifiers, children }: ListProps) => (
  <ul className={mapMultiClassnames("m-list", modifiers)}>{children}</ul>
);

export default memo(List);
