import { fetchSuplliesByHp, fetchSuppliesHP } from "api/interna-api";
import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchSuplyProductOnError,
  fetchSuplyProductOnSuccess,
  fetchSuppliesByHpOnError,
  fetchSuppliesByHpOnSuccess,
  requestFetchSuppliesByHp,
  REQUEST_FETCH_SUPPLIES_BY_HP,
  REQUEST_FETCH_SUPPLY_PRODUCT,
} from "redux/actions/products/supply";

function* fetchSupplyProductFlow() {
  try {
    const data = yield call(fetchSuppliesHP);
    yield put(fetchSuplyProductOnSuccess(data));
    yield put(requestFetchSuppliesByHp(data[0].id));
  } catch (error) {
    yield put(fetchSuplyProductOnError(error));
  }
}

function* fetchSuppliesByHpSaga(action: AnyAction) {
  try {
    const data = yield call(fetchSuplliesByHp, action.id);
    yield put(fetchSuppliesByHpOnSuccess(data));
  } catch (error) {
    yield put(fetchSuppliesByHpOnError(error));
  }
}

export default function* watchFetchSupplyProductSaga() {
  yield all([
    takeLatest(REQUEST_FETCH_SUPPLY_PRODUCT, fetchSupplyProductFlow),
    takeLatest(REQUEST_FETCH_SUPPLIES_BY_HP, fetchSuppliesByHpSaga),
  ]);
}
