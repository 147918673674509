export const REQUEST_FETCH_CRITERIAS = "REQUEST_FETCH_CRITERIAS";
export const FETCH_CRITERIAS_SUCCESS = "FETCH_CRITERIAS_SUCCESS";
export const FETCH_CRITERIAS_ERROR = "FETCH_CRITERIAS_ERROR";

export const requestFetchCriterias = (id?: number) => ({
  type: REQUEST_FETCH_CRITERIAS,
  id,
});

export const fetchCriteriasOnSuccess = (criterias: any) => ({
  type: FETCH_CRITERIAS_SUCCESS,
  criterias,
});

export const fetchCriteriasOnError = (error: any) => ({
  type: FETCH_CRITERIAS_ERROR,
  error,
});
