import Icon from "components/atoms/Icon";
import React, { memo } from "react";
import styled, { css } from "styled-components/macro";

const ModalHeader: React.FC<any> = ({
  onCloseRequest,
  noPadding,
  children,
  closeIconPosition,
  iconClose = true,
}: {
  onCloseRequest?: any;
  noPadding?: boolean;
  closeIconPosition?: "standardClose" | "outerStandardClose";
  children: any;
  iconClose?: boolean;
}) => {
  return (
    <div className={`o-modal-header${noPadding ? "-noPadding" : ""}`}>
      {iconClose && (
        <StyledCloseIconContainer
          className="o-modal_closebutton"
          onClick={onCloseRequest}
          closeIconPosition={closeIconPosition}
        >
          <Icon iconName="close" onClick={onCloseRequest} />
        </StyledCloseIconContainer>
      )}
      {children}
    </div>
  );
};

type CloseIconContainerProps = {
  closeIconPosition?: "standardClose" | "outerStandardClose";
};

const StyledCloseIconContainer = styled.span<CloseIconContainerProps>`
  ${({ closeIconPosition }) =>
    closeIconPosition === "standardClose"
      ? css`
          right: 0;
          top: 0;
        `
      : closeIconPosition === "outerStandardClose"
      ? css`
          right: -17px;
          top: -17px;
        `
      : css``}
`;

export default memo(ModalHeader);
