import { AnyAction } from "redux";

const initialState = {
  header: {
    clientHeight: 0,
  },
  hotTopHeader: {
    visible: false,
  },
};

const READY_REF = "READY_REF";
const READY_ELEMENT = "READY_ELEMENT";

export const readyRef = (name: "header", data: { clientHeight: number }) => ({
  type: READY_REF,
  name,
  data,
});

export const readyElement = (name: "hotTopHeader", visible: boolean) => ({
  type: READY_ELEMENT,
  name,
  visible,
});

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case READY_REF:
      return {
        ...state,
        [action.name]: {
          clientHeight: action.data.clientHeight,
        },
      };
    case READY_ELEMENT:
      return {
        ...state,
        [action.name]: {
          visible: action.visible,
        },
      };
    default:
      return state;
  }
};
