export const SAVE_SELECTED_PACKAGE = "SAVE_SELECTED_PACKAGE";
export const RESET_SELECTED_PACKAGE = "RESET_SELECTED_PACKAGE";

export const requestSaveSelectedPackage: (
  selectedPackage: number,
) => { type: string; selectedPackage: number } = selectedPackage => ({
  type: SAVE_SELECTED_PACKAGE,
  selectedPackage,
});

export const requestResetSelectedPackage = () => ({
  type: RESET_SELECTED_PACKAGE,
});
