import { darken, rgb, rgba } from "polished";

export const black = rgb(0, 0, 0);
export const raisinBlack = rgb(34, 30, 30);
export const spanishGray = rgb(153, 153, 153);
export const pastelGray = rgb(220, 210, 187);
export const ashGrey = rgb(180, 180, 180);
export const platinum = rgb(231, 231, 231);
export const sonicSilver = rgb(112, 112, 112);
export const white = rgb(255, 255, 255);
export const whiteSmoke = rgb(245, 245, 245);
export const pictonBlue = rgb(84, 195, 241);
export const smoke = rgb(242, 242, 242);
export const scarlet = rgb(215, 0, 13);
export const tangerine = rgb(240, 131, 0);
export const mikadoYellowStar = rgb(252, 192, 13);
export const shamrockGreen = rgb(12, 194, 67);
export const richElectricBlue = rgb(0, 151, 224);
export const vistaBlue = rgb(125, 167, 218);
export const pastelRed = rgb(255, 95, 95);
export const snow = rgb(250, 250, 250);
export const lava = rgb(216, 12, 24);
export const auroMetalSaurus = rgb(108, 117, 125);
export const jet = rgb(51, 51, 51);
export const dimGray = rgb(102, 102, 102);
export const gray = rgb(123, 123, 123);
export const pumpkin = rgb(253, 126, 20);
export const seaGreen = rgb(40, 167, 69);
export const green = rgb(104, 176, 34);
export const rustyRed = rgb(220, 53, 69);
export const glitter = rgb(233, 236, 239);
export const davySGrey = rgb(73, 80, 87);
export const electricCrimson = rgb(255, 0, 68);
export const pictonBlue2 = rgb(68, 200, 245);
export const topaz = rgb(255, 201, 119);
export const mikadoYellow = rgb(255, 198, 39);
export const lightGray = rgb(213, 213, 213);
export const coralRed = rgb(249, 60, 60);
export const tulip = rgb(255, 143, 143);

const colors = {
  black,
  raisinBlack,
  spanishGray,
  pastelGray,
  ashGrey,
  platinum,
  sonicSilver,
  white,
  whiteSmoke,
  pictonBlue,
  smoke,
  scarlet,
  tangerine,
  shamrockGreen,
  richElectricBlue,
  vistaBlue,
  pastelRed,
  snow,
  lava,
  auroMetalSaurus,
  jet,
  dimGray,
  gray,
  pumpkin,
  seaGreen,
  green,
  rustyRed,
  glitter,
  davySGrey,
  electricCrimson,
  pictonBlue2,
  topaz,
  mikadoYellow,
  lightGray,
  coralRed,
  tulip,
  mikadoYellowStar,
};

export default colors;

/* eslint-disable @typescript-eslint/camelcase */
// dont check camel_case from here to end
export const alphaColor = {
  // eslint-disable-next-line @typescript-eslint/camelcase
  black0035: rgba(0, 0, 0, 0.035),
  // eslint-disable-next-line @typescript-eslint/camelcase
  black016: rgba(0, 0, 0, 0.16),
  // eslint-disable-next-line @typescript-eslint/camelcase
  black025: rgba(0, 0, 0, 0.25),
  // eslint-disable-next-line @typescript-eslint/camelcase
  black045: rgba(0, 0, 0, 0.45),
  // eslint-disable-next-line @typescript-eslint/camelcase
  black075: rgba(0, 0, 0, 0.75),
  // eslint-disable-next-line @typescript-eslint/camelcase
  richElectricBlue01: rgba(colors.richElectricBlue, 0.1),
  // eslint-disable-next-line @typescript-eslint/camelcase
  electricCrimson045: rgba(colors.electricCrimson, 0.45),
  electricCrimson025: rgba(colors.electricCrimson, 0.25),
  scarlet045: rgba(colors.scarlet, 0.45),
  topaz015: rgba(colors.topaz, 0.15),
  lava015: rgba(colors.lava, 0.15),
  pumpkin015: rgba(colors.pumpkin, 0.15),
  green015: rgba(colors.green, 0.15),
  white015: rgba(colors.white, 0.15),
};

export const shadows = {
  lv1: `0 0 0 3px ${alphaColor.black0035}`,
  lv2: `0 1px 6px ${alphaColor.black016}`,
};

export const transition = {
  duration: {
    short: "0.2s",
    medium: "0.3s",
    long: "0.5s",
  },
  ease: {
    ease: "ease",
    in: "ease-in",
    out: "ease-out",
    inOut: "ease-in-out",
  },
};

export const link = {
  hover: darken(15, colors.richElectricBlue),
  hoverDecoration: "underline",
};
