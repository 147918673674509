import Button from "components/atoms/Button";
import Fielderror from "components/atoms/Fielderror";
import Heading from "components/atoms/Heading";
import Text from "components/atoms/Text";
import Textfield from "components/atoms/Textfield";
import Modal from "components/organisms/Modal";
import ModalBody from "components/organisms/Modal/Body";
import ModalFooter from "components/organisms/Modal/Footer";
import ModalHeader from "components/organisms/Modal/Header";
import { KEY_CAPTCHA } from "constants/env";
import { VALIDATION_PATTERN } from "constants/validation";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { authForgetPassword } from "redux/actions/auth/auths";
import { notEmptyObject } from "utils/utils-common";
import Fieldrow, { Fieldcell } from "../Fieldrow";
import "./index.scss";
interface ResetPassFormProps {
  open: boolean;
  onToggle?: () => void;
}

type FormData = {
  email: string;
};

const ResetPassForm: React.FC<ResetPassFormProps> = ({ open, onToggle }) => {
  const owlClass = "o-modal-reset-pass";
  const { handleSubmit, getValues, formState, errors, register } = useForm<
    FormData
  >({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });
  const touched = Object.values(formState.touched);
  const dispatch = useDispatch();
  const onSubmit = (data: any) => {
    const { email } = data;
    dispatch(authForgetPassword(email));
    onToggle && onToggle();
  };
  const [needVerify, setNeedVerify] = useState(false);
  const [count, setCount] = useState<number>();

  useEffect(() => {
    if (notEmptyObject(errors)) {
      const countSave = Cookies.get("needVerify.ResetPassForm");
      setCount(countSave ? Number(countSave) + 1 : count ? count + 1 : 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (count !== undefined) {
      setNeedVerify(count === 3);
      Cookies.set("needVerify.ResetPassForm", count ? count.toString() : "1", {
        expires: 1, // 1 day
      });
    }
  }, [count]);

  useEffect(() => {
    const checkNeedVerifyCount = Cookies.get("needVerify.ResetPassForm");
    if (checkNeedVerifyCount) {
      setCount(Number(checkNeedVerifyCount));
      if (Number(checkNeedVerifyCount) >= 3) {
        setNeedVerify(true);
      }
    }
  }, []);

  return (
    <div className={owlClass}>
      <Modal
        isOpen={open}
        onCloseRequest={onToggle}
        resetModal
        sizeMd
        balanceHeaderStyle
        className="modal-resetpassword"
      >
        <ModalHeader noPadding modifiers={["center"]} onCloseRequest={onToggle}>
          <div className="modal-heading">
            <Heading type="h3" modifiers={["center"]}>
              LẤY LẠI MẬT KHẨU
            </Heading>
          </div>
          <Text size="sm" modifiers={["gray", "center"]}>
            Vui lòng nhập email để lấy lại mật khẩu
          </Text>
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Fieldrow modifiers={["input"]}>
              <Fieldcell>
                <Textfield
                  name="email"
                  type="email"
                  label="Nhập email"
                  value={getValues().email}
                  modifiers={
                    (touched && formState.touched.email) || errors.email
                      ? errors.email
                        ? ["invalid"]
                        : ["valid"]
                      : []
                  }
                  refs={register({
                    required: {
                      value: true,
                      message: "Bạn chưa nhập - Email",
                    },
                    pattern: {
                      value: VALIDATION_PATTERN.common.email,
                      message: "Email không đúng định dạng",
                    },
                  })}
                  autoFocus
                />
                {errors.email && (
                  <Fielderror>{errors.email.message}</Fielderror>
                )}
              </Fieldcell>
            </Fieldrow>
          </ModalBody>
          <ModalFooter>
            {needVerify && KEY_CAPTCHA ? (
              <div className="form-recapcha">
                <form>
                  <ReCAPTCHA
                    sitekey={KEY_CAPTCHA}
                    onChange={(value: any) => {
                      if (value) {
                        Cookies.remove("needVerify.ResetPassForm");
                        setNeedVerify(false);
                        setCount(undefined);
                      }
                    }}
                  />
                </form>
              </div>
            ) : (
              <Button
                type="submit"
                modifiers={["xsmall", "w-min-200"]}
                variant="primary"
                disabled={!formState.isValid}
              >
                XÁC NHẬN
              </Button>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

ResetPassForm.defaultProps = {};

export default ResetPassForm;
