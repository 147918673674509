import Loading from "components/atoms/Loading";
import React from "react";
import { COMMON_LOADING_SIZE } from "constants/loading";
import {
  FillLoading,
  LoadingInfo,
  WrapLoading,
} from "constants/styledComponent";

interface LoadingBoxProps {
  label?: string;
  type?: "fill";
  className?: string;
}

const LoadingBox: React.FC<LoadingBoxProps> = ({ label, type, className }) =>
  type === "fill" ? (
    <FillLoading>
      <span>Đang tải dữ liệu...</span>
    </FillLoading>
  ) : (
    <WrapLoading className={className}>
      <Loading widthHeight={COMMON_LOADING_SIZE} />
      <LoadingInfo>{label}</LoadingInfo>
    </WrapLoading>
  );

export default React.memo(LoadingBox);
