import { all } from "redux-saga/effects";
import watchAuthSaga from "sagas/auth";
import watchFetchBanksSaga from "sagas/general/banks";
import watchPostCardTokenSaga from "sagas/general/cardToken";
import watchFetchCitiesSaga from "sagas/general/cities";
import watchPostCommentFlowSaga from "sagas/general/comment";
import watchFetchDistrictsSaga from "sagas/general/districts";
import watchFetchCheckEmailExistSaga from "sagas/general/email";
import watchFetchMenusSaga from "sagas/general/menus";
import watchFetchProductCitiesSaga from "sagas/general/productCities";
import watchPostRatingFlowSaga from "sagas/general/rating";
import watchFetchScheduleCitiesSaga from "sagas/general/scheduleCities";
import watchFetchSaveSubcSaga from "sagas/general/subscriber";
import watchFetchSubSkyAirsSaga from "sagas/general/subSkyAir";
import watchFetchSystemSaga from "sagas/general/System";
import watchRequestValidationAccountFlowSaga from "sagas/general/validationAccount";
import watchFetchWardsSaga from "sagas/general/wards";
import watchFetchNewsSaga from "sagas/news/list";
import watchFetchAboutUsPageSaga from "sagas/pages/aboutus";
import watchRequestActivateFlowSaga from "sagas/pages/activate";
import watchRequestCompareProductSaga from "sagas/pages/compare";
import watchFetchContactPageSaga from "sagas/pages/contact";
import watchFetchStatusOrdersSaga from "sagas/pages/dashboard";
import watchFetchFaqCategoriesPageSaga from "sagas/pages/faq";
import watchFetchGuaranteeServicePageSaga from "sagas/pages/guaranteeService";
import watchFetchHomePageSaga from "sagas/pages/home";
import watchRequestMailSubscriber from "sagas/pages/mailsubscriber";
import watchFetchPackageServicePageSaga from "sagas/pages/packageService";
import watchFetchPayPageSaga from "sagas/pages/pay";
import watchChangePaymentInfo from "sagas/pages/payment";
import watchFetchProductSaga from "sagas/pages/product";
import watchFetchProductTypesSaga from "sagas/pages/productTypes";
import watchFetchPromotionPageSaga from "sagas/pages/promotion";
import watchFetchRefundPolicyPageSaga from "sagas/pages/refundpolicy";
import watchRequestScheduleSaga from "sagas/pages/schedule";
import watchFetchServicesPageSaga from "sagas/pages/services";
import watchFetchShippingPageSaga from "sagas/pages/shipping";
import watchRequestSearchOrderFlowSaga from "sagas/pages/statusOrder";
import watchFetchProductsByTypeSaga from "sagas/products/byType";
import watchFetchCartSaga from "sagas/products/cart";
import watchFetchCriteriasSaga from "sagas/products/criterias";
import watchFetchDetailProductSaga from "sagas/products/detail";
import watchFetchSearchProductsSaga from "sagas/products/search";
import watchFetchSeenProductsSaga from "sagas/products/seen";
import watchFetchSupplyProductSaga from "sagas/products/supply";

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchFetchCartSaga(),
    watchFetchSearchProductsSaga(),
    watchAuthSaga(),
    watchFetchDetailProductSaga(),
    watchFetchCriteriasSaga(),
    watchFetchSeenProductsSaga(),
    watchFetchHomePageSaga(),
    watchFetchAboutUsPageSaga(),
    watchFetchPromotionPageSaga(),
    watchFetchServicesPageSaga(),
    watchFetchPackageServicePageSaga(),
    watchFetchGuaranteeServicePageSaga(),
    watchFetchPayPageSaga(),
    watchFetchShippingPageSaga(),
    watchFetchRefundPolicyPageSaga(),
    watchFetchProductsByTypeSaga(),
    watchFetchProductSaga(),
    watchFetchContactPageSaga(),
    watchFetchFaqCategoriesPageSaga(),
    watchFetchCitiesSaga(),
    watchFetchBanksSaga(),
    watchFetchProductCitiesSaga(),
    watchFetchScheduleCitiesSaga(),
    watchFetchDistrictsSaga(),
    watchFetchWardsSaga(),
    watchRequestScheduleSaga(),
    watchRequestCompareProductSaga(),
    watchFetchMenusSaga(),
    watchFetchNewsSaga(),
    watchFetchSupplyProductSaga(),
    watchFetchSaveSubcSaga(),
    watchChangePaymentInfo(),
    watchFetchCheckEmailExistSaga(),
    watchFetchSystemSaga(),
    watchRequestSearchOrderFlowSaga(),
    watchFetchStatusOrdersSaga(),
    watchRequestMailSubscriber(),
    watchFetchSubSkyAirsSaga(),
    watchFetchProductTypesSaga(),
    watchRequestActivateFlowSaga(),
    watchRequestValidationAccountFlowSaga(),
    watchPostRatingFlowSaga(),
    watchPostCommentFlowSaga(),
    watchPostCardTokenSaga(),
  ]);
}
