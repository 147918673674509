import Icon from "components/atoms/Icon";
import React, { useEffect, useRef, useState } from "react";
import { joinClassnames } from "utils/map-modifier";
import "./index.scss";

interface AccordionProps {
  title: string;
  content?: string;
  isOpen?: boolean;
  theme?: "toggle";
}

const Accordion: React.FC<AccordionProps> = ({
  isOpen,
  title,
  content,
  theme,
  children,
}) => {
  const [active, setActive] = useState(isOpen || false);
  const [height, setHeight] = useState("0px");

  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const toggleAccordion = () => {
    setActive(!active);
    setHeight(active ? "0px" : `${contentRef.current.scrollHeight}px`);
  };

  useEffect(() => {
    if (isOpen) {
      setHeight(`${contentRef.current.scrollHeight}px`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const originalClass =
    theme === "toggle" ? "m-accordion-theme" : "m-accordion";

  return theme === "toggle" ? (
    <div className={joinClassnames(originalClass, active && "isOpen")}>
      <div className={`${originalClass}_heading`} onClick={toggleAccordion}>
        {title}
      </div>
      <div
        className={`${originalClass}_body`}
        ref={contentRef}
        style={{ maxHeight: `${height}` }}
      >
        {children}
      </div>
    </div>
  ) : (
    <div className="m-accordion">
      <div className="m-accordion_tile">
        <div
          className={`m-accordion_tile-btn ${active ? "active" : ""}`}
          onClick={toggleAccordion}
        >
          {title}
          <Icon iconName={active ? "minus-white" : "plus"} />
        </div>
      </div>
      <div
        ref={contentRef}
        style={{ maxHeight: `${height}` }}
        className="m-accordion_content"
      >
        <div className="m-accordion_content-text">
          <label
            dangerouslySetInnerHTML={{
              __html: content || "",
            }}
          />
        </div>
      </div>
    </div>
  );
};

Accordion.defaultProps = {};

export default Accordion;
