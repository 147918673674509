export const REQUEST_FETCH_SEEN_PRODUCTS = "REQUEST_FETCH_SEEN_PRODUCTS";
export const FETCH_SEEN_PRODUCTS_SUCCESS = "FETCH_SEEN_PRODUCTS_SUCCESS";
export const FETCH_SEEN_PRODUCTS_ERROR = "FETCH_SEEN_PRODUCTS_ERROR";

export const requestFetchSeenProducts = (list: any) => ({
  type: REQUEST_FETCH_SEEN_PRODUCTS,
  list
})

export const fetchSeenProductsOnSuccess = (seen: any) => ({
  type: FETCH_SEEN_PRODUCTS_SUCCESS,
  seen
});

export const fetchSeenProductsOnError = (error: any) => ({
  type: FETCH_SEEN_PRODUCTS_ERROR,
  error
});
