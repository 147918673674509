import { useEffect, useRef, useState } from "react";

function useWindowSize() {
  const isClient = typeof window === "object";

  const [windowSize, setWindowSize] = useState({
    width: (isClient && window.innerWidth) || 0,
    height: (isClient && window.innerHeight) || 0,
  });
  const prevValueRef: { current: NodeJS.Timeout | null } = useRef(null);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    const getSize = () => ({
      width: (isClient && window.innerWidth) || 0,
      height: (isClient && window.innerHeight) || 0,
    });

    const handleResize = () => {
      if (prevValueRef.current) clearTimeout(prevValueRef.current);
      prevValueRef.current = setTimeout(() => {
        setWindowSize(getSize());
      }, 250);
    };

    window.addEventListener("resize", handleResize, { passive: true });
    return () => window.removeEventListener("resize", handleResize);
  }, [isClient]);

  return windowSize;
}

export default useWindowSize;
