export const REQUEST_FETCH_CONTACT_PAGE = "REQUEST_FETCH_CONTACT_PAGE";
export const FETCH_CONTACT_PAGE_SUCCESS = "FETCH_CONTACT_PAGE_SUCCESS";
export const FETCH_CONTACT_PAGE_ERROR = "FETCH_CONTACT_PAGE_ERROR";

export const requestFetchContactPage = () => ({
  type: REQUEST_FETCH_CONTACT_PAGE,
});

export const fetchContactPageOnSuccess = (contact: any) => ({
  type: FETCH_CONTACT_PAGE_SUCCESS,
  contact,
});

export const fetchContactPageOnError = (error: any) => ({
  type: FETCH_CONTACT_PAGE_ERROR,
  error,
});
