import { AnyAction } from "redux";
import {
  REQUEST_FETCH_FILTERS,
  REQUEST_FETCH_FILTERS_ON_ERROR,
  REQUEST_FETCH_FILTERS_ON_SUCCESS,
} from "redux/actions/pages/product";

const defaultState = {
  data: {
    filters: [],
  },
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_FETCH_FILTERS:
      return {
        ...state,
        loadingFilter: true,
      };
    case REQUEST_FETCH_FILTERS_ON_SUCCESS:
      return {
        ...state,
        data: {
          filters: action.filters,
        },
        error: null,
        loading: false,
      };
    case REQUEST_FETCH_FILTERS_ON_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
