import React, { ReactNode } from "react";

import "./index.scss";

interface FielderrorProps {
  message?: string;
  children?: ReactNode;
}

const Fielderror: React.FC<FielderrorProps> = (props: FielderrorProps) => {
  return (
    <span className="a-fielderror">
      <span className="a-fielderror_message">{props.children}</span>
    </span>
  );
};

Fielderror.defaultProps = {};

export default Fielderror;
