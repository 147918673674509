import { initialNotify } from "constants/notify";
import { useSelector } from "hooks/useApp";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  onSetCurrentTarget,
  onToggleSeenProducts,
} from "redux/actions/general/toggleSeenProducts";
import {
  fetchCompare,
  requestCompareProduct,
} from "redux/actions/pages/compare";
import {
  setNotifyCompareModal,
  setSelectedProductCompare,
} from "redux/reducers/view/notifyCompare";
import failCompare from "utils/utils-product";

const useCompare = (compareList: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showCompare, setShowCompare] = useState<NotifyType>(initialNotify);

  const onCloseModal = useCallback(() => {
    dispatch(setNotifyCompareModal(initialNotify));
  }, [dispatch]);

  const onHideCompare = useCallback(() => {
    setShowCompare(initialNotify);
  }, []);

  const goToCompare = useCallback(() => {
    if (compareList.length === 1) {
      dispatch(
        setNotifyCompareModal({
          status: true,
          type: "quantity",
          message: "Vui lòng chọn thêm sản phẩm so sánh",
        }),
      );
    } else {
      history.push("/so-sanh");
    }
  }, [compareList.length, dispatch, history]);

  const onClearCompare = useCallback(() => {
    window.localStorage.removeItem("compare");
    dispatch(fetchCompare());
    onCloseModal();
  }, [dispatch, onCloseModal]);

  const {
    notifyCompareModal: { selectedProduct },
  } = useSelector(state => state.view);

  const compareDifferentType = useCallback(() => {
    window.localStorage.setItem("compare", JSON.stringify([selectedProduct]));
    dispatch(fetchCompare());
    onCloseModal();
  }, [dispatch, onCloseModal, selectedProduct]);

  const {
    toggleSeenProducts: { compareTarget }, // * Set mặc định ẩn hoặc hiện - BLOCK - SẢN PHẨM ĐÃ XEM // initial value toggle in redux = false
  } = useSelector(state => state.general);

  useEffect(() => {
    if (compareTarget) {
      const { target, status } = compareTarget;
      if (compareList && compareList.length > 0 && target === "compareBtn") {
        status && dispatch(onToggleSeenProducts(true));
      }
    }
  }, [compareList, compareTarget, dispatch]);

  const onCompare = useCallback(
    (e: any, id: number, typeProduct: number, parentType: number) => {
      e.preventDefault();

      if (compareList.length > 0) {
        if (compareList.length > 2) {
          if (compareList.findIndex(c => c.id === id) > -1) {
            dispatch(requestCompareProduct(id, typeProduct, parentType));
          } else {
            dispatch(
              setNotifyCompareModal({
                status: true,
                type: "limixitems",
                message: "Không được so sánh quá 3 sản phẩm",
              }),
            );
          }
        } else {
          if (failCompare(compareList, typeProduct, parentType)) {
            dispatch(
              setNotifyCompareModal({
                status: true,
                type: "type",
                message: "Không được so sánh sản phẩm khác loại",
              }),
            );

            dispatch(
              setSelectedProductCompare({
                id,
                typeProduct,
                parentType,
              }),
            );
          } else {
            dispatch(requestCompareProduct(id, typeProduct, parentType));
          }
        }
      } else {
        dispatch(requestCompareProduct(id, typeProduct, parentType));
      }
    },
    [compareList, dispatch],
  );

  const handleCallCompare = (e: any, product: any) => {
    onCompare(
      e,
      product.product_id,
      product.product_type_id,
      product.product_parent_type_id || null,
    );
  };

  const handleUnCompare = (e: any, product: any) => {
    handleCallCompare(e, product);
    dispatch(onSetCurrentTarget("compareBtn", false));
  };
  const handleCompare = (e: any, product: any) => {
    handleCallCompare(e, product);
    dispatch(onSetCurrentTarget("compareBtn", true));
  };

  return {
    showCompare,
    setShowCompare,
    onCloseModal,
    onHideCompare,
    onCompare,
    handleCompare,
    handleUnCompare,
    onClearCompare,
    goToCompare,
    compareDifferentType,
  };
};

export default useCompare;
