const productTypeMatch = (inputItem: CompareProductType, code: number | null) =>
  inputItem && inputItem.typeProduct === code;

const parentTypeMatch = (inputItem: CompareProductType, code: number | null) =>
  inputItem && inputItem.parentType === code;

const allItemsIsChildOfSkyairOrSkyair = (list: CompareProductType[]) =>
  list.every(
    item =>
      productTypeMatch(item, 5) ||
      productTypeMatch(item, 6) ||
      productTypeMatch(item, 7) ||
      productTypeMatch(item, 8) || //is child of skyair
      (productTypeMatch(item, 4) && parentTypeMatch(item, null)), // is skyair
  );

const allItemsIsChildOf2DanRoiOr2DanRoi = (list: CompareProductType[]) =>
  list.every(
    item => productTypeMatch(item, 3), // is 2DanRoi
  );

const inputItemIsSkyairOrChildOfSkyairAndListIsSkyairOrChildOfSkyair = (
  list: CompareProductType[],
  inputItem: CompareProductType,
) =>
  allItemsIsChildOfSkyairOrSkyair(list) &&
  ((productTypeMatch(inputItem, 4) && parentTypeMatch(inputItem, null)) ||
    productTypeMatch(inputItem, 5) ||
    productTypeMatch(inputItem, 6) ||
    productTypeMatch(inputItem, 7) ||
    productTypeMatch(inputItem, 8));

const inputItemIs2DanRoiOrChildOf2DanRoiAndListIs2DanRoiOrChildOf2DanRoi = (
  list: CompareProductType[],
  inputItem: CompareProductType,
) =>
  allItemsIsChildOf2DanRoiOr2DanRoi(list) &&
  (productTypeMatch(inputItem, 3) || parentTypeMatch(inputItem, 3));

const failCompare = (
  list: CompareProductType[],
  typeProduct: number,
  parentType: number,
) =>
  !inputItemIsSkyairOrChildOfSkyairAndListIsSkyairOrChildOfSkyair(list, {
    typeProduct,
    parentType,
  }) &&
  !inputItemIs2DanRoiOrChildOf2DanRoiAndListIs2DanRoiOrChildOf2DanRoi(list, {
    typeProduct,
    parentType,
  });

export default failCompare;
