import { useEffect, useMemo } from "react";
import TagManager from 'react-gtm-module';
import { useSelector } from "./useApp";

const useGTM = () => {
  const { data: systemData } = useSelector(state => state.general.system);

  const gtmId = useMemo(() => {
    if (!systemData || systemData?.length < 1) {
      return undefined
    }
    const gtm = systemData?.find((item: any) => item?.key === 'gtm');
    return gtm?.content;
  }, [systemData]);

  useEffect(() => {
    if (gtmId) {
      if (typeof gtmId === 'string') {
        TagManager.initialize({
          gtmId: gtmId,
        });
      }
      if (typeof gtmId === 'number') {
        TagManager.initialize({
          gtmId: gtmId.toString(),
        });
      }
    };
  }, [gtmId])
}

export default useGTM;
