import React from "react";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import "./index.scss";

interface PaymentMethodsProps {
  children?: React.ReactNode[];
  modifiers?: Modifiers;
}

const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  children,
  modifiers,
}: PaymentMethodsProps) => {
  return (
    <div className="m-payment-methods">
      <div className={mapMultiClassnames("m-payment-methods_icon", modifiers)}>
        {React.Children.map(children, (item, index) => (
          <div
            key={index}
            className={joinClassnames("m-payment-methods_icon_item")}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethods;
