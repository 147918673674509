import { AnyAction } from "redux";
import {
  POST_SAVE_UTM
} from "redux/actions/general/utm";

export type UtmTypes = {
  utmId?: string,
  utmSource?: string,
  utmMedium?: string,
  utmCampaign?: string,
  utmTerm?: string,
  utmContent?: string,
}

const defaultState: UtmTypes = {
  utmCampaign: "",
  utmContent: "",
  utmMedium: "",
  utmId: "",
  utmSource: "",
  utmTerm: "",
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case POST_SAVE_UTM:
      return {
        data: action.dataUTM
      };
    default:
      return state;
  }
};
