import { BASE_URL } from "constants/env";
import { AnyAction } from "redux";
import {
  FETCH_DETAIL_PRODUCT_ERROR,
  FETCH_DETAIL_PRODUCT_SUCCESS,
  REQUEST_FETCH_DETAIL_PRODUCT,
  RESET_DETAIL_PRODUCT,
} from "redux/actions/products/detail";

const defaultState = {
  data: {
    step1: null,
    step2: null,
  },
  error: null,
  loading: false,
  fetchType: "step1",
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case RESET_DETAIL_PRODUCT:
      return {
        ...defaultState,
      };
    case REQUEST_FETCH_DETAIL_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DETAIL_PRODUCT_SUCCESS:
      const baseUrl = BASE_URL;
      return {
        data: {
          ...state.data,
          ...(action.fetchType === "step1"
            ? {
                step1: {
                  ...action.detail,
                  images: [
                    ...Object.values(JSON.parse(action.detail.images))
                      .sort(
                        (a: any, b: any) =>
                          Number(a.display_order) - Number(b.display_order),
                      )
                      .map((image: any, index: number) =>
                        image && image.youtube_embedded
                          ? {
                              key: index,
                              type: "youtube",
                              src: image.youtube_embedded,
                              thumb: `${baseUrl}${image.mobile}`,
                              preview: `${baseUrl}${image.thumb}`,
                            }
                          : {
                              key: index,
                              type: "image",
                              src: `${baseUrl}${image.thumb}`,
                              thumb: `${baseUrl}${image.mobile}`,
                            },
                      )
                      .filter(item => item.src !== null),
                  ],
                },
              }
            : { step2: action.detail }),
        },
        error: null,
        loading: false,
        fetchType: action.fetchType,
      };
    case FETCH_DETAIL_PRODUCT_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        fetchType: action.fetchType,
      };
    default:
      return state;
  }
};
