import { AnyAction } from "redux";
import {
  REQUEST_FETCH_PRODUCT_TYPES,
  REQUEST_FETCH_PRODUCT_TYPES_ON_ERROR,
  REQUEST_FETCH_PRODUCT_TYPES_ON_SUCCESS,
} from "redux/actions/pages/productTypes";

const defaultState = {
  data: null,
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_FETCH_PRODUCT_TYPES:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_FETCH_PRODUCT_TYPES_ON_SUCCESS:
      return {
        ...state,
        data: action.productTypes,
        error: null,
        loading: false,
      };
    case REQUEST_FETCH_PRODUCT_TYPES_ON_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
