import React from "react";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import "./index.scss";

type FontType = "muli";
type TextSizeType =
  | "sm" //sm
  | "sm-im" //sm
  | "lg" //lg
  | "xl"; //xl
type WeightType = "light" | "bold";
export type Modifiers =
  | "uppercase"
  | "italic"
  | "decoration"
  | "white"
  | "primary"
  | "dark"
  | "gray"
  | "gray-light"
  | "grayscale"
  | "error"
  | "orange"
  | "center"
  | "right"
  | "w-760"
  | "underline"
  | 'azure';
interface TextProps extends React.HtmlHTMLAttributes<HTMLParagraphElement> {
  font?: FontType;
  size?: TextSizeType;
  weight?: WeightType;
  modifiers?: Modifiers[];
  className?: string;
  content?: string;
}

const Text: React.FC<TextProps> = ({
  children,
  size,
  weight,
  modifiers,
  className,
  content,
}: TextProps) => (
  <>
    {content ? (
      <div
        className={joinClassnames(
          mapMultiClassnames("a-text", size, weight, modifiers),
          className,
        )}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    ) : (
      <p
        className={joinClassnames(
          mapMultiClassnames("a-text", size, weight, modifiers),
          className,
        )}
      >
        {children}
      </p>
    )}
  </>
);

export default Text;
