import { AnyAction } from "redux";
import {
  REQUEST_CHECK_EMAIL_ERROR,
  REQUEST_CHECK_EMAIL_EXIST,
  REQUEST_CHECK_EMAIL_SUCCESS,
  RESET_STATE_CHECK_EMAIL,
} from "redux/actions/general/email";

const defaultState = {
  data: {
    checked: "",
  },
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case RESET_STATE_CHECK_EMAIL:
      return {
        data: {
          checked: "",
        },
        error: null,
        loading: false,
      };
    case REQUEST_CHECK_EMAIL_EXIST:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_CHECK_EMAIL_SUCCESS:
      return {
        data: {
          checked: "noexist",
        },
        error: null,
        loading: false,
      };
    case REQUEST_CHECK_EMAIL_ERROR:
      return {
        data: {
          checked: "exist",
        },
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
