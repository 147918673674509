import { http, httpToken } from "api/axios";
import { INTERNAL } from "constants/env";
import { VALUE_INTERNAL } from "constants/global";
import { getDefaultLocationDeliveryStore } from "constants/location";
import { getAccessRefreshToken, getAccessToken } from "./access-token";

let defaultLocale = "vi";

declare global {
  interface Window {
    formatMoney: any;
  }
}

export const httpType = () => {
  if (INTERNAL === VALUE_INTERNAL) return httpToken;
  return http;
}

// PRODUCT API - PROMOTION DETAIL CŨ:
export const fetchPromotionProducts = async (promotion: any) => {
  const { slug, pageSize, pageNum } = promotion;
  const res = await httpToken.get(
    `product/promotion-page-detail?locale=${defaultLocale}&slug=${slug}&pageSize=${pageSize}&pageNum=${pageNum}`,
  );
  return res.data;
};

// GET LIST BANNER IN PROMOTION PAGE
export const mailSubscribe = async (mail: string) => {
  const res = await http.post(`subscribers`, { email: mail });
  return res.data.data;
};

export const activateRegister = async (token: string) => {
  const res = await http.get(`customer/active-account?token=${token}`);
  return res.status;
};

export const fetchPromotionBanners = async () => {
  const res = await httpType().get(
    `product/promotion-page-list?locale=${defaultLocale}`,
  );
  return res.data.data;
};

// PROMOTION PAGE:
export const getPromotions = async (
  params: GetPromotionsParams,
): Promise<APIPromotions> => {
  const {
    locale = defaultLocale,
    pageSize = 6,
    pageNum = 1,
    isOutDate = false,
    sortCreatedAt = "desc",
    excludeId,
  } = params;
  let res = await httpToken.get<APIPromotions>(
    `product/promotion-pages?locale=${locale}&pageSize=${pageSize}&pageNum=${pageNum}${isOutDate ? `&isOutDate=${isOutDate}` : ""
    }${sortCreatedAt ? `&sortCreatedAt=${sortCreatedAt}` : ""}${excludeId ? `&excludeId=${excludeId}` : ""
    }`,
  );
  return res.data;
};

// PROMOTION DETAIL PAGE:
export const getPromotionsDetail = async (
  params: GetPromotionParams,
): Promise<APIPromotion> => {
  const {
    locale = defaultLocale,
    slug = "",
    emailSuffix,
    fullEmail,
    idUser,
  } = params;
  let res = await httpToken.get<APIPromotion>(
    `product/promotion-pages/${slug}?locale=${locale}${emailSuffix ? `&emailSuffix=${emailSuffix}` : ""
    }${fullEmail ? `&fullEmail=${fullEmail}` : ""}${idUser ? `&idUser=${idUser}` : ""
    }`,
  );
  return res.data;
};

export const fetchCart = async (list: any, code?: string) => {
  const token = getAccessToken();
  const city = getDefaultLocationDeliveryStore() as {
    id: number;
    name: string;
  };
  const requestList = list.map(p => ({
    ...p,
    cityId: city.id || 50,
  }));
  if (list.length === 0) {
    if (token) {
      await httpToken.delete("order/clear-cart");
      return {};
    } else {
      window.localStorage.removeItem("cart");
      window.localStorage.removeItem("renewal");
      return {};
    }
  } else {
    if (token) {
      const res = await httpToken.post(
        "order/show-cart",
        code
          ? {
            locale: defaultLocale,
            code,
            list: requestList,
          }
          : {
            locale: defaultLocale,
            list: requestList,
          },
      );
      return res.data.data;
    } else {
      const res = await http.post(
        "order/show-cart",
        code
          ? {
            locale: defaultLocale,
            code,
            list: requestList,
          }
          : {
            locale: defaultLocale,
            list: requestList,
          },
      );
      return res.data.data;
    }
  }
};

export const fetchCartByUser = async () => {
  const res = await httpToken.get(`order/get-cart?locale=${defaultLocale}`);
  return res.data.data;
};

export const fetchCartUpdate = async (list: any, code?: string) => {
  const token = getAccessToken();
  if (token) {
    const res = await httpToken.post(
      "order/show-cart",
      code
        ? {
          locale: defaultLocale,
          code,
          list,
        }
        : {
          locale: defaultLocale,
          list,
        },
    );
    return res.data.data;
  } else {
    const res = await http.post(
      "order/show-cart",
      code
        ? {
          locale: defaultLocale,
          code,
          list,
        }
        : {
          locale: defaultLocale,
          list,
        },
    );
    return res.data.data;
  }
};

export const updateCart = async (productId: number, quantity: number) => {
  const res = await httpToken.post("cart/add-item", {
    productId,
    quantity,
  });
  return res.data;
};

export const fetchHotProducts = async (type: number, quantity: number) => {
  const res = await httpToken.get(
    `product/product-hot?locale=${defaultLocale}&productType=${type}&limit=${quantity}`,
  );
  return res.data.result;
};

export const fetchDetailProduct = async (
  id: number,
  fetchType: FetchDetailProductType,
) => {
  const token = getAccessToken();
  if (token) {
    const res = await httpToken.get(
      fetchType === "step1"
        ? `product/detail/step-1?locale=${defaultLocale}&slug=${id}`
        : `product/detail/step-2?locale=${defaultLocale}&slug=${id}`,
    );
    return res.data.data;
  } else {
    const res = await http.get(
      fetchType === "step1"
        ? `product/detail/step-1?locale=${defaultLocale}&slug=${id}`
        : `product/detail/step-2?locale=${defaultLocale}&slug=${id}`,
    );
    return res.data.data;
  }
};

export const fetchSeenProducts = async (list: any) => {
  const res = await httpToken.post("product/product-seen", {
    locale: "vi",
    ...list,
  });
  return res.data;
};
export const fetchSearchProducts = async (params: {
  searchKey?: string, ids?: string
}) => {
  const res = await httpToken.get(
    `product/search?locale=${defaultLocale}${(params.searchKey ? `&keyword=${params.searchKey}` : '')}${(params.ids ? `&ids=${params.ids}` : '')}`,
  );
  return res.data.data;
};

export const fetchRelatedProducts = async (params: {
  searchKey?: string, ids?: string
}) => {
  const res = await http.get(
    `product/search?locale=${defaultLocale}${(params.searchKey ? `&keyword=${params.searchKey}` : '')}${(params.ids ? `&ids=${params.ids}` : '')}`,
  );
  return res.data.data;
};

export const fetchSubSkyAir = async (slug: string) => {
  const res = await httpType().get(
    `product/list-sub-product-type?locale=${defaultLocale}&slug=${slug}`,
  );
  return res.data.data;
};

export const fetchProductTypes = async () => {
  const res = await httpType().get(
    `product/list-product-type?locale=${defaultLocale}`,
  );
  return res.data.data;
};

// Products By Type
export const fetchProductsByType = async (action: any) => {
  const { isHomepage } = action.filters;
  if (isHomepage === 1 || isHomepage === 0) {
    const params = Object.entries(action.filters)
      .map(([k, v]) => (k === "pageSize" ? `&limit=${v}` : `&${k}=${v}`))
      .join("");
    const res = await httpToken.get(
      `product/product-is-homepage?locale=${defaultLocale}&${params}`,
    );
    return res.data;
  }
  let query = Object.entries(action.filters)
    .map(([key, parentValue]) => {
      switch (key) {
        case "ROOM":
        case "LOAI_MAY":
        case "CONG_SUAT_LAM_LANH":
        case "subcategory":
        case "CONG_SUAT_LANH_SA":
        case "DIEN_NGUON":
        case "PRICE": {
          const { value } = parentValue as {
            selected: CheckboxField[];
            value: string;
          };
          return `&${key}=${value}`;
        }

        case "CONG_NGHE_INVERTER": {
          const { value } = parentValue as {
            selected: CheckboxField[];
            value: string;
          };
          if (value.includes(",")) {
            return "";
          } else return `&${key}=${value}`;
        }

        case "CAN_BANG_DO_AM_THONG_MINH":
        case "LUONG_GIO_3_CHIEU":
        case "TIET_KIEM_NANG_LUONG":
        case "LAM_LANH_NHANH":
        case "TINH_LOC_KHONG_KHI":
        case "LUONG_GIO_COANDA":
        case "CHUC_NANG_CHONG_AM_MOC":
        case "DO_BEN_CAO":
        case "DIEU_KHIEN_TIEN_NGHI": {
          const { checked } = parentValue as {
            title: string;
            checked: boolean;
          };
          return `&${key}=${checked ? 1 : 0}`;
        }

        default:
          return `&${key}=${parentValue}`;
      }
    })
    .join("");

  const { slug } = action.filters;
  if (slug === "sky-air") {
    const res = await httpToken.get(
      `product/list-product-sky-air?locale=${defaultLocale}&${query}`,
    );
    return res.data;
  } else {
    const res = await httpType().get(
      `product/list-by-product-type?locale=${defaultLocale}&${query}`,
    );
    return res.data;
  }
};

// ACCOUNT
export const login = async (data: { email: string; password: string }) => {
  const res = await http.post("customer/login", data);
  return res.data.data;
};

export const logout = async () => {
  const refreshToken = getAccessRefreshToken();
  const res = await httpToken.delete(
    `/customer/logout?refreshToken=${refreshToken}`,
  );
  return res.data.data;
};

export const register = async (data: {
  name: string;
  email: string;
  password: string;
}) => {
  const res = await http.post("customer/register", data);
  return res.data.result;
};

export const forgetPassword = async (email: string) => {
  const res = await http.put("customer/forgot-password/", { email });
  return res;
};

// CUSTOMER
export const fetchUserInfor = async () => {
  const res = await httpToken.get("customer/profile");
  return res.data;
};

export const changeInfo = async (userInfo: any) => {
  const res = await httpToken.put("customer/change-info", userInfo);
  return res.data.data;
};

export const updateTokenByRefreshToken = async (
  refreshToken: string,
): Promise<ObjectReponse<GetTokenTypes>> => {
  const res = await http.post<ObjectReponse<GetTokenTypes>>(
    "customer/refresh-token",
    { refreshToken },
  );
  return res.data;
};

// HOME PAGE
export const fetchHomePage = async () => {
  const res = await http.get("home-page");
  return res.data.data;
};

export const fetchBlockBanner = async () => {
  const res = await http.get("block/homepage-block");
  return res.data.data;
};

// ABOUT US PAGE
export const fetchAboutUsPage = async () => {
  const res = await http.get("page/ve-chung-toi");
  return res.data.data;
};

// SERVICES PAGE
export const fetchServicesPage = async () => {
  const res = await http.get("page/dich-vu");
  return res.data.data;
};

// PACKAGE SERVICES PAGE
export const fetchPackageServicesPage = async () => {
  const res = await http.get("page/dich-vu-tron-goi");
  return res.data.data;
};

// GUARANTEE SERVICE PAGE
export const fetchGuaranteeService = async () => {
  const res = await http.get("page/dich-vu-bao-hanh");
  return res.data.data;
};

// HYGIENIC SERVICE PAGE
export const fetchHygienicService = async (limit = 3) => {
  const res = await http.get(`services?limit=${limit}`);
  return res.data.data;
};

// SERVICE PACKAGES PAGE
export const fetchServicePackages = async (locale = "vi") => {
  const res = await httpType().get(
    `order/maintenance-booking/packages?locale=${defaultLocale}`,
  );
  return res.data.data;
};

// HYGIENIC SERVICE PAGE
export const fetchFilters = async (productTypeId = 1) => {
  const res = await http.get(
    `attribute/list-by-product-type?locale=${defaultLocale}&productTypeId=${productTypeId}`,
  );
  return res.data.result;
};

// BOOK SCHEDULE
export const requestSaveSchedule = async (scheduleObj: any) => {
  const res = await httpToken.post("order/maintenance-booking/booking", {
    ...scheduleObj,
  });
  return res.data.data;
};

// Pay Page
export const fetchPayPage = async () => {
  const res = await http.get("page/tra-gop");
  return res.data.data;
};
// Shipping page
export const fetchShippingpage = async () => {
  const res = await http.get("page/van-chuyen-lap-dat");
  return res.data.data;
};
// refund policy
export const fetcRefundPolicyPage = async () => {
  const res = await http.get("page/chinh-sach-doi-tra");
  return res.data.data;
};
// contact
export const fetchContactPage = async () => {
  const res = await http.get("page/lien-he");
  return res.data.data;
};

export const fetchSubmitMessageForm = async (message: any) => {
  const res = await http.post("contacts", message);
  return res.data;
};

export const fetchCitiesSCRMForm = async (params?: { keyword: string }) => {
  const res = await http.get("cities/scrm", { params });
  return res.data.data;
};

export const fetchContactTitlesForm = async () => {
  const res = await http.get("contact-titles");
  return res.data.data;
};

// FAQ
// Categories
export const fetchFaqCategories = async () => {
  const res = await http.get("faq-categories");
  return res.data.data;
};

// FAQ
// content by Category id
export const fetchContentCategory = async (limit: number = 20, id: number) => {
  const res = await http.get(`faqs?limit=${limit}&category_id=${id}`);
  return res.data.data;
};

// CITIES
export const fetchCities = async (countryId = 1) => {
  const res = await http.get(
    `cities?locale=${defaultLocale}&country_id=${countryId}`,
  );
  return res.data.data;
};

// BANKS
export const fetchBanks = async () => {
  const res = await httpType().get(`order/promotion-bank?locale=${defaultLocale}`);
  return res.data.data;
};

// CARD-TOKEN
export const postCardToken = async (requestParam: CardTokenParams) => {
  const res = await http.post("order/card-request-token", requestParam);
  return res.data;
};

// PRODUCT CITY
export const fetchProductCities = async (
  productId: number,
  cityId?: number,
) => {
  const res = await http.get(
    `products/product-city?product_id=${productId}${cityId ? `&city_id=${cityId}` : ""
    }`,
  );
  return res.data.data;
};

export const fetchScheduleCities = async () => {
  const res = await httpType().get(
    `/order/maintenance-booking/supporting-city?locale=${defaultLocale}`,
  );
  return res.data.data;
};

// DISTRICTS
export const fetchDistricts = async (cityId: number) => {
  const res = await http.get(
    `districts?locale=${defaultLocale}&city_id=${cityId}`,
  );
  return res.data.data;
};

// WARDS
export const fetchWards = async (districtId: number) => {
  const res = await http.get(
    `wards?locale=${defaultLocale}&district_id=${districtId}`,
  );
  return res.data.data;
};

// COMPARE PRODUCTS
export const fetchCompareProducts = async (list: []) => {
  const res = await httpToken.post("product/compare", {
    locale: defaultLocale,
    list,
  });
  return res.data.data;
};
// MENUS
export const fetchMenus = async () => {
  const res = await http.get("get-menu/main_menu");
  return res.data.data;
};

// GET PAGES VIA SLUG
export const fetchPageViaSlug = async (slug: string) => {
  const res = await http.get(`page/${slug}`);
  return res.data.data;
};

// footer menus
export const fetchFooter = async () => {
  const res = await http.get("get-menu/footer_menu");
  return res.data.data;
};

// fetch news categories
export const fetchNewsCategories = async () => {
  const res = await httpType().get("news/news-categories?locale=vi")
  return res.data.data;
};

export const fetchNewsListByCategory = async (slug: string, limit: number) => {
  const res = await httpType().get(
    `news/news-by-categories?locale=vi${slug ? `&slug=${slug}` : ""
    }&limit=${limit}`,
  );
  return res.data;
};

export const fetchNewsDetail = async (slug: string) => {
  const res = await httpType().get(
    `news/news-detail?slug=${slug}&locale=${defaultLocale}`,
  );
  return res.data.data;
};

// supplies
export const fetchSuppliesHP = async () => {
  const res = await httpType().get("supplies/supplies-hp?locale=vi");
  return res.data.data;
};

export const fetchSuplliesByHp = async (id: any) => {
  const res = await httpType().get("supplies/supplies-by-hp?locale=vi&id=" + id);
  return res.data.data;
};

// subscriberπ
export const fetchSaveSubscriber = (email: string) => {
  const result = http
    .post("/subscribers", { email })
    .then(res => res.data)
    .catch(err => err.response.data);

  return result;
};

// order
export const fetchSubmitOrder = async (orderDetail: any) => {
  const res = await httpToken.post("/order/cart", orderDetail);
  return res.data;
};

// searchStatusOrder
export const searchStatusOrder = async (code: string) => {
  const res = await httpType().get(`order/search?locale=vi&orderCode=${code}`);
  return res.data.data;
};

export const onSubmitInfoBuying = async (data: any) => {
  const res = await http.post("commercial-product-contact", data);
  return res.status;
};

export const getCriterias = async (id?: number) => {
  const res = await http.get(`criterias${id ? `?criteria_id=${id}` : ""}`);
  return res.data.data;
};

export const getRatings = async (
  limit: number,
  productId: number,
  page: number,
  keyWords?: string,
  numberRating?: number,
) => {
  const res = await http.get(
    `ratings?limit=${limit}&product_id=${productId}&page=${page}${keyWords ? `&key_words=${keyWords}` : ""
    }${numberRating ? `&number_rating=${numberRating}` : ""}`,
  );
  return res.data;
};

export const validationAccount = async (data: {
  productId: number;
  email: string;
  phone?: string;
}) => {
  const { productId, email, phone } = data;
  const res = await http.post(`ratings/validation-account`, {
    product_id: productId,
    email,
    ...(phone ? { phone } : {}),
  });
  return res.data.data;
};

export const validationComment = async (data: {
  ratingId: number;
  email: string;
  phone?: string;
}) => {
  const { ratingId, email, phone } = data;
  const res = await http.post(`ratings/comments/validation-account`, {
    rating_id: ratingId,
    email,
    ...(phone ? { phone } : {}),
  });
  return res.data.data;
};

export const getProductsEvaluatedByOrder = async (
  orderIds: string[],
  customerEmail: string,
) => {
  const res = await http.get(
    `ratings/products-evaluated-by-order?order_ids=${orderIds}&customer_email=${customerEmail}`,
  );
  return res.data;
};

export const getTotalComponentByIdAndKeywords = async (
  productId: number,
  keyWords?: string,
) => {
  const res = await http.get(
    `ratings/total-component?product_id=${productId}${keyWords ? `&key_words=${keyWords}` : ""
    }`,
  );
  return res.data;
};
export const postRating = async (data: any) => {
  const res = await http.post("ratings", data);
  return res.data;
};
export const postComment = async (data: any) => {
  const res = await http.post("ratings/comments", data);
  return res.data;
};

export const postLike = async (data: LikeType) => {
  const res = await http.post("ratings/like", data);
  return res.data;
};

export const postDisLike = async (data: LikeType) => {
  const res = await http.post("ratings/dislike", data);
  return res.data;
};

// email
export const fetchCheckEmailExist = async (email: string) => {
  const res = await httpType().post("/order/email-checkout", { email });
  return res.data;
};

export const fetchSystem = async () => {
  const res = await http.get<APIList<SystemProps>>("system-config");
  return Object.values(res.data.data);
};

export const fetchStatusOrders = async (statusOrder: any) => {
  const { status, pageSize, pageNum } = statusOrder;
  let res;
  if (status.toString().length > 0) {
    res = await httpToken.get(
      `order/order-status?status=${status}&pageSize=${pageSize}&pageNum=${pageNum}`,
    );
    return res.data;
  } else {
    res = await httpToken.get(
      `order/order-status?pageSize=${pageSize}&pageNum=${pageNum}`,
    );
    return res.data;
  }
};

export const fetchGuaranteeInfo = async (guaranteeInfo: any) => {
  const { pageSize, pageNum } = guaranteeInfo;
  let res = await httpToken.get(
    `order/warranty-info?locale=${defaultLocale}&pageSize=${pageSize}&pageNum=${pageNum}`,
  );
  return res.data;
};

export const requestChangePassword = async () => {
  const res = await httpToken.put("customer/change-password");
  return res.data;
};

export const changePassword = async (data: ChangePasswordTypes) => {
  const { token, newPassword } = data;
  const res = await httpToken
    .put(`customer/reset-password?token=${token}`, {
      newPassword,
    })
    .then(data => Promise.resolve(data))
    .catch(response => {
      if (
        response &&
        response.response &&
        response.response.data &&
        response.response.data.errors &&
        response.response.data.errors[0] &&
        response.response.data.errors[0].code === "005"
      ) {
        return Promise.reject(
          "Đã có lỗi xảy ra! Mã xác thực hết hạn hoặc không hợp lệ.",
        );
      } else {
        return Promise.reject(
          "Đổi mật khẩu thất bại! Mã xác thực không tìm thấy.",
        );
      }
    });
  return res;
};

export const fetchBlockPopupTCDM = async () => {
  const res = await http.get("block/trade-product-static-block");
  return res.data.data;
};

export const fetchBlockTCDMTemp = async () => {
  const res = await http.get("block/trade-product-temp-static-block");
  return res.data.data;
};

// 301
export const fetchRedirect301Service = async (path: string): Promise<any> => {
  const res = await http.get(`redirects/show?path=${path}`);
  return res.data;
};