import React, { ReactNode } from "react";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import "./index.scss";
interface Props {
  children?: React.ReactNode;
  href?: string;
  modifiers?: Modifiers;
  button?: ReactNode;
  show?: boolean;
  onClick?: () => void;
}

const ToggleCartBox: React.FC<Props> = ({
  children,
  modifiers,
  show,
}: Props) => (
  <div
    className={joinClassnames(
      mapMultiClassnames("m-toggle-cart-box", modifiers),
      show && "show",
    )}
  >
    {React.Children.map(children, (item, index) => (
      <div key={index} className="m-toggle-cart-box-show">
        {item}
      </div>
    ))}
  </div>
);

export default ToggleCartBox;
