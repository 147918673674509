export const SET_HOLIDAY = "SET_HOLIDAY";
export const SET_BETWEEN_HOLIDAY = "SET_BETWEEN_HOLIDAY";
export const GET_HOLIDAY = "GET_HOLIDAY";

export const setHoliday = (holiday: HolidayType) => ({
  type: SET_HOLIDAY,
  holiday,
});

export const setBetweenHoliday = () => ({
  type: SET_BETWEEN_HOLIDAY,
});

export const getHoliday = () => ({
  type: GET_HOLIDAY,
});
