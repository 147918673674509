import { AnyAction } from "redux";
import {
  FETCH_PACKAGE_SERVICE_PAGE_ERROR,
  FETCH_PACKAGE_SERVICE_PAGE_SUCCESS,
  REQUEST_EXTRA_QUANTITY_ON_SUCCESS,
  REQUEST_FETCH_HYGIENIC_SERVICE,
  REQUEST_FETCH_HYGIENIC_SERVICE_ON_ERROR,
  REQUEST_FETCH_HYGIENIC_SERVICE_ON_SUCCESS,
  REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE,
  REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE_ON_ERROR,
  REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE_ON_SUCCESS,
  REQUEST_FETCH_PACKAGE_TEMP_SERVICE_PAGE,
  REQUEST_HYGIENIC_QUANTITY_ON_SUCCESS,
  REQUEST_SCHEDULE_PACKAGE_SERVICE_ON_SUCCESS,
  REQUEST_TOGGLE_EXTRA_ON_SUCCESS,
} from "redux/actions/pages/packageService";

const defaultState = {
  data: {
    page: {},
    hygienic: [],
    packages: [],
    total: 0,
  },
  errorPage: null,
  loadingPage: false,
  errorHygienic: null,
  loadingHygienic: false,
  errorPackages: null,
  loadingPackages: false,
};

const calcMoney = (item: any, init: number) => {
  let found = item.filter(de => de.toggle === true);
  if (found.length > 0) {
    return found.reduce((total, de) => total + de.price * de.quantity, init);
  } else {
    return init;
  }
};

const totalMoney = (hygienicList: []) => {
  let found = hygienicList.filter(hy => hy.isSchedule === true);
  if (found.length > 0) {
    return found.reduce((total, hy) => total + hy.total, 0);
  }
  return 0;
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    // PAGE
    case REQUEST_FETCH_PACKAGE_TEMP_SERVICE_PAGE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PACKAGE_SERVICE_PAGE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          page: action.packageService,
        },
        errorPage: null,
        loadingPage: false,
      };
    case FETCH_PACKAGE_SERVICE_PAGE_ERROR:
      return {
        ...state,
        errorPage: action.error,
        loadingPage: false,
      };

    // HYGIENIC
    case REQUEST_FETCH_HYGIENIC_SERVICE:
      return {
        ...state,
        loadingHygienic: true,
      };
    case REQUEST_FETCH_HYGIENIC_SERVICE_ON_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          hygienic: action.hygienic,
        },
        errorHygienic: null,
        loadingHygienic: false,
      };
    case REQUEST_FETCH_HYGIENIC_SERVICE_ON_ERROR:
      return {
        ...state,
        errorHygienic: action.error,
        loadingHygienic: false,
      };

    // PACKAGES
    case REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE:
      return {
        ...state,
        loadingPackages: true,
      };
    case REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE_ON_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          packages: action.packages,
        },
        errorPackages: null,
        loadingPackages: false,
      };
    case REQUEST_FETCH_MAINTAIN_PACKAGES_SERVICE_ON_ERROR:
      return {
        ...state,
        errorPackages: action.error,
        loadingPackages: false,
      };

    case REQUEST_SCHEDULE_PACKAGE_SERVICE_ON_SUCCESS:
      let {
        data: { hygienic },
      } = state;
      if (hygienic.length > 0) {
        hygienic = hygienic.map(hy =>
          hy.id === action.id
            ? hy.isSchedule
              ? {
                  ...hy,
                  total: 0,
                  quantity: 1,
                  details: hy.details.map(de => ({
                    ...de,
                    toggle: false,
                    quantity: 1,
                    totalExtra: 0,
                  })),
                  isSchedule: !hy.isSchedule,
                }
              : {
                  ...hy,
                  isSchedule: true,
                  quantity: hy.quantity ? hy.quantity : 1,
                  total: hy.quantity
                    ? calcMoney(hy.details, hy.quantity * hy.price)
                    : hy.price,
                }
            : {
                ...hy,
              },
        );
      }
      return {
        ...state,
        data: {
          ...state.data,
          hygienic,
          total: totalMoney(hygienic),
        },
      };

    case REQUEST_HYGIENIC_QUANTITY_ON_SUCCESS:
      const { id, sign } = action;
      let {
        data: { hygienic: hygienicList },
      } = state;
      if (hygienicList.length > 0) {
        if (sign === "+") {
          hygienicList = hygienicList.map(hy =>
            hy.id === id && hy.isSchedule
              ? hy.quantity
                ? {
                    ...hy,
                    total: calcMoney(hy.details, (hy.quantity + 1) * hy.price),
                    quantity: hy.quantity + 1,
                    // total: (hy.quantity + 1) * hy.price
                  }
                : { ...hy, quantity: 2, total: 2 * hy.price }
              : { ...hy },
          );
        } else {
          hygienicList = hygienicList.map(hy =>
            hy.id === id && hy.isSchedule
              ? hy.quantity
                ? {
                    ...hy,
                    quantity: hy.quantity - 1,
                    total: calcMoney(hy.details, (hy.quantity - 1) * hy.price),

                    // total: (hy.quantity - 1) * hy.price
                  }
                : { ...hy, quantity: 1, total: hy.price }
              : { ...hy },
          );
        }
      }
      return {
        ...state,
        data: {
          ...state.data,
          hygienic: hygienicList,
          total: totalMoney(hygienicList),
        },
      };

    case REQUEST_TOGGLE_EXTRA_ON_SUCCESS:
      const { hygienicId, extraId } = action;
      let {
        data: { hygienic: hygienicToggleList },
      } = state;
      hygienicToggleList = hygienicToggleList.map(hy =>
        hy.id === hygienicId
          ? {
              ...hy,
              details: hy.details.map(de =>
                de.id === extraId
                  ? {
                      ...de,
                      toggle: hy.isSchedule
                        ? de.toggle
                          ? !de.toggle
                          : true
                        : false,
                      quantity: 1,
                      totalExtra: de.price,
                    }
                  : {
                      ...de,
                    },
              ),
            }
          : {
              ...hy,
            },
      );
      hygienicToggleList = hygienicToggleList.map(hy =>
        hy.id === hygienicId && hy.isSchedule
          ? { ...hy, total: calcMoney(hy.details, hy.quantity * hy.price) }
          : { ...hy },
      );
      return {
        ...state,
        data: {
          ...state.data,
          hygienic: hygienicToggleList,
          total: totalMoney(hygienicToggleList),
        },
      };

    case REQUEST_EXTRA_QUANTITY_ON_SUCCESS:
      const { extraHygienicId, extraQuantityId, extraSign } = action;
      let {
        data: { hygienic: hygienicExtraQuantityList },
      } = state;
      hygienicExtraQuantityList = hygienicExtraQuantityList.map(hy =>
        hy.id === extraHygienicId && hy.isSchedule
          ? {
              ...hy,
              details: hy.details.map(de =>
                de.id === extraQuantityId && hy.isSchedule
                  ? {
                      ...de,
                      quantity:
                        extraSign === "+" ? de.quantity + 1 : de.quantity - 1,
                    }
                  : {
                      ...de,
                    },
              ),
            }
          : { ...hy },
      );
      hygienicExtraQuantityList = hygienicExtraQuantityList.map(hy =>
        hy.id === extraHygienicId && hy.isSchedule
          ? { ...hy, total: calcMoney(hy.details, hy.quantity * hy.price) }
          : { ...hy },
      );
      return {
        ...state,
        data: {
          ...state.data,
          hygienic: hygienicExtraQuantityList,
          total: totalMoney(hygienicExtraQuantityList),
        },
      };
    default:
      return state;
  }
};
