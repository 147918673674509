export const REQUEST_FETCH_STATUS_ORDERS = "REQUEST_FETCH_STATUS_ORDERS";
export const FETCH_STATUS_ORDERS_SUCCESS = "FETCH_STATUS_ORDERS_SUCCESS";
export const FETCH_STATUS_ORDERS_ERROR = "FETCH_STATUS_ORDERS_ERROR";

export const requestFetchStatusOrders = (statusOrder: any) => ({
  type: REQUEST_FETCH_STATUS_ORDERS,
  statusOrder
});

export const fetchStatusOrdersOnSuccess = (statusOrders: any) => ({
  type: FETCH_STATUS_ORDERS_SUCCESS,
  statusOrders
});

export const fetchStatusOrdersOnError = (error: any) => ({
  type: FETCH_STATUS_ORDERS_ERROR,
  error
});


export const REQUEST_FETCH_GUARANTEE_INFO = "REQUEST_FETCH_GUARANTEE_INFO";
export const FETCH_GUARANTEE_INFO_SUCCESS = "FETCH_GUARANTEE_INFO_SUCCESS";
export const FETCH_GUARANTEE_INFO_ERROR = "FETCH_GUARANTEE_INFO_ERROR";

export const requestFetchGuaranteeInfo = (guaranteeInfo: any) => ({
  type: REQUEST_FETCH_GUARANTEE_INFO,
  guaranteeInfo
});

export const fetchGuaranteeInfoOnSuccess = (guaranteeInfo: any) => ({
  type: FETCH_GUARANTEE_INFO_SUCCESS,
  guaranteeInfo
});

export const fetchGuaranteeInfoOnError = (error: any) => ({
  type: FETCH_GUARANTEE_INFO_ERROR,
  error
});