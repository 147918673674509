export const REQUEST_FETCH_PRODUCT_CITIES = "REQUEST_FETCH_PRODUCT_CITIES";
export const REQUEST_FETCH_PRODUCT_CITIES_SUCCESS =
  "REQUEST_FETCH_PRODUCT_CITIES_SUCCESS";
export const REQUEST_FETCH_PRODUCT_CITIES_ERROR =
  "REQUEST_FETCH_PRODUCT_CITIES_ERROR";
export const REQUEST_FETCH_SCHEDULE_CITIES = "REQUEST_FETCH_SCHEDULE_CITIES";
export const REQUEST_FETCH_SCHEDULE_CITIES_SUCCESS =
  "REQUEST_FETCH_SCHEDULE_CITIES_SUCCESS";
export const REQUEST_FETCH_SCHEDULE_CITIES_ERROR =
  "REQUEST_FETCH_SCHEDULE_CITIES_ERROR";

export const requestFetchProductCities = (
  productId: number,
  cityId?: number,
) => ({
  type: REQUEST_FETCH_PRODUCT_CITIES,
  productId,
  cityId,
});

export const fetchProductCitiesOnSuccess = (
  productCities: ProductCityAPITypes[],
) => ({
  type: REQUEST_FETCH_PRODUCT_CITIES_SUCCESS,
  productCities,
});

export const fetchProductCitiesOnError = (error: any) => ({
  type: REQUEST_FETCH_PRODUCT_CITIES_ERROR,
  error,
});
