import Guide from "components/pages/Guide";
import { SLUG } from "constants/page";
import usePage from "hooks/usePage";
import React, { lazy, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import RedirectNavigate from '../redirect301';

const AboutUs = lazy(() => import("components/pages/AboutUs"));
const GuaranteeServices = lazy(() =>
  import("components/pages/GuaranteeServices"),
);
const RefundPolicy = lazy(() => import("components/pages/RefundPolicy"));
const Service = lazy(() => import("components/pages/Service"));
const Shipping = lazy(() => import("components/pages/Shipping"));
const PackageService = lazy(() => import("components/pages/PackageService"));
const Promotions = lazy(() => import("components/pages/Promotions"));
const Pay = lazy(() => import("components/pages/Pay"));
const LoadingState = lazy(() => import("components/atoms/LoadingState"));
const Contact = React.lazy(() => import("components/pages/Contact"));

interface DynamicRoutingPageProps { }
const DynamicRoutingPage: React.FC<DynamicRoutingPageProps> = (
  props: DynamicRoutingPageProps,
) => {
  const match = useRouteMatch();
  const slug = match.params ? match.params.slug : undefined;

  const data = usePage(slug);

  const { error } = data;

  const [pageData, setPageData] = useState();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const { loading, data: dataResponse } = data;
    setLoading(loading);

    if (!loading && dataResponse && history) {
      const { active } = dataResponse;

      if (active === "0") {
        history.push(SLUG.notFound);
        setPageData(undefined);
      } else {
        setPageData(dataResponse);
      }
    }
  }, [data, history]);

  const renderCorrespodingPage = (templateCode: string, pageData: any) => {
    switch (templateCode) {
      case "none_banner-_heading&_multiple-_title-_content-_image": // tra-gop, /dich-vu
        return <Pay data={pageData} />; // Da gan heading h1
      case "huong_dan_dat_hang":
        return <Guide data={pageData} />; // Da gan heading h1
      case "lien_he":
        return <Contact data={pageData} />; // Da gan heading h1
      case "dich_vu_bao_tri": // dich-vu-tron-goi
        return <PackageService data={pageData} />;
      case "none_banner&_multiple-_title-_content-_image": // van-chuyen-lap-dat
        return <Shipping data={pageData} />;
      case "none_banner_heading_multiple_title_content": // chinh-sach-doi-tra
        return <RefundPolicy data={pageData} />; // Da gan heading h1
      case "banner_one_button&_multiple-_title-_content-_image": // dich-vu-bao-hanh
        return <GuaranteeServices data={pageData} />; // Da gan heading h1
      case "dịch_vụ":
        return <Service data={pageData} />;
      case "ve_daikin":
        return <AboutUs data={pageData} />; // Da gan heading h1
      case "khuyen_mai":
        return <Promotions data={pageData} />; // Da gan heading h1
    }
  };

  if (!pageData && error) return <RedirectNavigate errors={error} />;

  return (
    <div>
      {!loading ? (
        pageData && renderCorrespodingPage(pageData.template_code, pageData)
      ) : (
        <LoadingState />
      )}
    </div>
  );
};

export default DynamicRoutingPage;
