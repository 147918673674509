import { fetchCheckEmailExist } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  checkEmailOnError,
  checkEmailOnSuccess,
  REQUEST_CHECK_EMAIL_EXIST,
  resetStateCheckEmail,
  RESET_STATE_CHECK_EMAIL,
} from "redux/actions/general/email";

function resetStateCheckEmailDispatch() {
  return resetStateCheckEmail();
}

function* fetchCheckEmailExistFlow({ email }: AnyAction) {
  try {
    yield call(fetchCheckEmailExist, email);
    yield put(checkEmailOnSuccess());
  } catch (error) {
    yield put(checkEmailOnError(error));
  }
}

export default function* watchFetchCheckEmailExistSaga() {
  yield takeLatest(REQUEST_CHECK_EMAIL_EXIST, fetchCheckEmailExistFlow);
  yield takeLatest(RESET_STATE_CHECK_EMAIL, resetStateCheckEmailDispatch);
}
