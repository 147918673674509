import React from "react";
import { Redirect, Route } from "react-router-dom";

type PrivateRouteNewProp = {
  component: any;
  path?: string;
  exact?: boolean
};

const PrivateRouteNew = ({
  component: Component,
  ...rest
}: PrivateRouteNewProp) => {
  return (
    <Route
      {...rest}
      render={(routeProps) =>
      (window.localStorage.getItem("userToken") ? (
        <Component {...routeProps} />
      ) : (
        <Redirect
          to={{
            pathname: "/dang-nhap",
            state: { from: routeProps.location },
          }}
        />
      ))}
    />
  );
};

PrivateRouteNew.defaultProps = {
  exact: true
};

export default PrivateRouteNew;
