import Heading from "components/atoms/Heading";
import React, { useEffect, useMemo } from "react";
import "swiper/swiper.min.css";
// For version >=2.4.0
// SyntaxError: Cannot use import statement outside a module
import { Pagination, Swiper, SwiperOptions } from "swiper/swiper.esm";
// import { Swiper } from 'swiper/react';
// import { SwiperOptions, Pagination } from 'swiper';
import { mapMultiClassnames } from "utils/map-modifier";
import "./index.scss";

interface ProcessServingCarouselProps {
  modifiers?: string;
  methodList: [];
}

const ProcessServingCarousel: React.FC<ProcessServingCarouselProps> = ({
  modifiers,
  methodList,
}) => {
  const data = useMemo(
    () => Object.keys(methodList.items).map(key => methodList.items[key]),
    [methodList],
  );

  // configure Swiper to use modules
  Swiper.use([Pagination]);

  const swiperOption: SwiperOptions = useMemo(
    () => ({
      preloadImages: false,
      lazy: true,
      slidesPerView: 1,
      centeredSlides: true,
      allowTouchMove: false,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + "</span>";
        },
      },
      loop: false,
      spaceBetween: 30,
    }),
    [],
  );

  useEffect(() => {
    new Swiper(".m-processservingcarousel .wrap-slider", swiperOption);
  }, [swiperOption]);

  return (
    Array.isArray(data) &&
    !!data.length && (
      <div
        className={mapMultiClassnames("m-processservingcarousel", modifiers)}
      >
        <div className="wrap-slider">
          <Heading type="h1" size="medium" modifiers={["uppercase"]}>
            {methodList.heading}
          </Heading>
          <div className="swiper-wrapper">
            {data.map(method => (
              <div className="swiper-slide">
                <div className="title">{method.title}</div>
                <label
                  dangerouslySetInnerHTML={{
                    __html: method.content || "",
                  }}
                />
              </div>
            ))}
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    )
  );
};

export default ProcessServingCarousel;
