import { fetchCompareProducts } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchCompareOnSuccess,
  fetchCompareProductsOnError,
  fetchCompareProductsOnSuccess,
  FETCH_COMPARE,
  requestCompareProductOnSuccess,
  REQUEST_COMPARE_PRODUCT,
  REQUEST_FETCH_COMPARE_PRODUCTS,
} from "redux/actions/pages/compare";

function* requestCompareProductFlow({
  id,
  typeProduct,
  parentType,
}: AnyAction) {
  yield put(requestCompareProductOnSuccess(id, typeProduct, parentType));
}

function* fetchCompareFlow() {
  yield put(fetchCompareOnSuccess());
}

function* fetchCompareProductsFlow({ compareList }: AnyAction) {
  try {
    const data = yield call(fetchCompareProducts, compareList);
    yield put(fetchCompareProductsOnSuccess(data));
  } catch (error) {
    yield put(fetchCompareProductsOnError(error));
  }
}

export default function* watchRequestCompareProductSaga() {
  yield takeLatest(REQUEST_COMPARE_PRODUCT, requestCompareProductFlow);
  yield takeLatest(FETCH_COMPARE, fetchCompareFlow);
  yield takeLatest(REQUEST_FETCH_COMPARE_PRODUCTS, fetchCompareProductsFlow);
}
