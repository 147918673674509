import { fetchBanks } from "api/interna-api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchBanksOnError,
  fetchBanksOnSuccess,
  REQUEST_FETCH_BANKS,
} from "redux/actions/general/banks";

function* fetchBanksFlow() {
  try {
    const data = yield call(fetchBanks);
    yield put(fetchBanksOnSuccess(data));
  } catch (error) {
    yield put(fetchBanksOnError(error));
  }
}

export default function* watchFetchBanksSaga() {
  yield takeLatest(REQUEST_FETCH_BANKS, fetchBanksFlow);
}
