import React, { useEffect, useMemo } from "react";
import ReactModal from "react-modal";
import { joinClassnames } from "utils/map-modifier";
import "./index.scss";

interface ModalProps {
  id?: string;
  isOpen: boolean;
  sizeLg?: boolean;
  sizeMd?: boolean;
  noPadding?: boolean;
  radius?: boolean;
  fullWidthContent?: boolean;
  resetModal?: boolean;
  footerClassName?: string;
  balanceHeaderStyle?: boolean;

  /* String className to be applied to the portal.
  See the `Styles` section for more details. */
  portalClassName?: string; // By default: "ReactModalPortal"

  /* String className to be applied to the overlay.
  See the `Styles` section for more details. */
  overlayClassName?: string; // By default: "ReactModal__Overlay"

  /* String id to be applied to the content div. */
  // id?: string; // By default: "some-id" -> not exist in current package.

  className?: string; // By default: "ReactModal__Content"
  /* String className to be applied to the modal content.
  See the `Styles` section for more details. */

  modalClassName?: string;

  injectHTML?: React.ReactNode;

  onCloseRequest?: () => void;
}

const Modal: React.FC<ModalProps> = ({
  id,
  isOpen,
  sizeLg,
  sizeMd,
  onCloseRequest,
  noPadding,
  radius,
  fullWidthContent,
  portalClassName,
  overlayClassName,
  className,
  modalClassName,
  resetModal,
  injectHTML,
  balanceHeaderStyle,
  ...props
}) => {
  const style = useMemo(
    () => ({
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        padding: sizeLg || noPadding ? "0px" : sizeMd ? "40px" : "20px",
        borderRadius: radius ? "12px" : "",
      },
    }),
    [noPadding, radius, sizeLg, sizeMd],
  );

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen])

  return (
    // This will become child
    <div id={id} className={joinClassnames("o-modal", modalClassName)}>
      {
        // This will become parent
      }
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        ariaHideApp={false}
        style={style}
        className={joinClassnames(
          className,
          sizeLg ? "sizeLg" : sizeMd ? "sizeMd" : "sizeAuto",
          resetModal && "reset-modal",
          balanceHeaderStyle && "balance-header-style",
        )}
        portalClassName={joinClassnames("ReactModalPortal", portalClassName)}
        contentLabel="Modal"
      >
        {injectHTML || null}
        <div
          className={joinClassnames(
            "o-modal_content",
            fullWidthContent && "full-width-content",
          )}
        >
          {props.children}
        </div>
      </ReactModal>
    </div>
  );
};

Modal.defaultProps = {};

export default Modal;
