import Icon from "components/atoms/Icon";
import { useSelector } from "hooks/useApp";
import React from "react";

import "./index.scss";

interface ChoosenStockProps {
  onClick?: () => void;
}

const ChoosenStock: React.FC<ChoosenStockProps> = ({
  onClick,
}: ChoosenStockProps) => {
  const { locationBuying } = useSelector(state => state);
  return (
    <div className="m-choosen-stock" onClick={onClick}>
      <Icon
        iconName="choosen-stock-icon-white"
        className="m-choosen-stock_icon"
      />
      <span className="m-choosen-stock_current-stock">
        <label className="m-choosen-stock_current-stockLabel">
          Tỉnh/thành đang chọn
        </label>
        <span className="m-choosen-stock_current-stockValueWrap">
          <span className="m-choosen-stock_current-stockValue">
            {locationBuying?.name}
          </span>
          <Icon
            className="m-choosen-stock_current-stockValueIcon"
            iconName="arrow-down-white"
          />
        </span>
      </span>
    </div>
  );
};

ChoosenStock.defaultProps = {};

export default ChoosenStock;
