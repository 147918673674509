import { fetchSaveSubscriber } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchSaveSubscriberOnError,
  fetchSaveSubscriberOnSuccess,
  requestClearSubscriber,
  REQUEST_CLEAR_SUBSCRIBER,
  REQUEST_FETCH_SAVE_SUBSCRIBER,
} from "redux/actions/general/subscriber";

function* fetchSaveSubscriberFlow({ email }: AnyAction) {
  try {
    const data = yield call(fetchSaveSubscriber, email);
    yield put(fetchSaveSubscriberOnSuccess(data));
  } catch (error) {
    yield put(fetchSaveSubscriberOnError(error));
  }
}

function requestClearSubscriberDispatch() {
  return requestClearSubscriber();
}

export default function* watchFetchSaveSubcSaga() {
  yield takeLatest(REQUEST_FETCH_SAVE_SUBSCRIBER, fetchSaveSubscriberFlow);
  yield takeLatest(REQUEST_CLEAR_SUBSCRIBER, requestClearSubscriberDispatch);
}
