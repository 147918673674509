import React from "react";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import Icon, { IconName } from "../Icon";
import "./index.scss";

interface Props {
  children?: React.ReactNode;
  onClick?: (e: any) => void;
  id?: string;
  href?: string;
  iconName?: IconName;
  active?: boolean;
  disabled?: boolean;
  dataAttr?: string;
  variant?:
  | "primary"
  | "primary-link"
  | "secondary"
  | "success"
  | "warning"
  | "danger"
  | "info"
  | "light"
  | "dark"
  | "compare"
  | "link"
  | "outline"
  | "link-gray"
  | "outline-noHover";
  modifiers?: (
    | "cart-border"
    | "light-blue"
    | "small-radius"
    | "full-width-mobile"
    | "rounded"
    | "square"
    | "floating"
    | "xsmall"
    | "small"
    | "login"
    | "facebook"
    | "google"
    | "white"
    | "black"
    | "icon"
    | "w-min-170"
    | "w-min-185"
    | "w-min-210"
    | "w-min-200"
    | "w-min-226"
    | "w-min-255"
    | "max-width")[];
  size?: "xsmall" | "small" | "large";
  className?: string;
  type?: "button" | "submit" | "reset";
}

const Inner: React.FC<
  Pick<Props, "children" | "iconName" | "modifiers">
> = props => {
  let iconName: IconName = props.iconName;
  return (
    <>
      {iconName && <Icon iconName={iconName} />}
      {props.children}
    </>
  );
};

const Button: React.FC<Props> = ({
  id,
  children,
  onClick,
  href,
  iconName,
  active,
  variant = "primary",
  modifiers,
  size,
  className,
  dataAttr,
  type,
  ...innerProps
}: Props) => {
  return (
    <button
      id={id}
      className={joinClassnames(
        mapMultiClassnames("a-button", variant, modifiers, size),
        className,
        dataAttr && "has-attr-data",
      )}
      onClick={onClick}
      data-attr={dataAttr}
      type={type}
      {...innerProps}
    >
      <Inner children={children} iconName={iconName} modifiers={modifiers} />
    </button>
  );
};

export default Button;
