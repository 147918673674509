import { fetchWards } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchWardsOnError,
  fetchWardsOnSuccess,
  REQUEST_FETCH_WARDS,
} from "redux/actions/general/wards";

function* fetchWardsFlow({ districtId }: AnyAction) {
  try {
    const data = yield call(fetchWards, districtId);
    yield put(fetchWardsOnSuccess(data));
  } catch (error) {
    yield put(fetchWardsOnError(error));
  }
}

export default function* watchFetchWardsSaga() {
  yield takeLatest(REQUEST_FETCH_WARDS, fetchWardsFlow);
}
