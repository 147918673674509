import Icon from "components/atoms/Icon";
import useWindowScroll from "hooks/useWindowScroll";
import React, { MouseEvent } from "react";
import { joinClassnames } from "utils/map-modifier";
import "./index.scss";

interface ScrollTopProps {}

const ScrollTop: React.FC<ScrollTopProps> = () => {
  const { showScrollTop } = useWindowScroll();

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={joinClassnames("a-scrollTop", showScrollTop && "show")}
      onClick={handleClick}
    >
      <div className="wrap-icon">
        <Icon iconName="arrow-right" />
      </div>
    </div>
  );
};

export default React.memo(ScrollTop);
