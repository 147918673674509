import { fetchProductsByType } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchProductsByTypeOnError,
  fetchProductsByTypeOnSuccess,
  REQUEST_FETCH_PRODUCTS_BY_TYPE,
} from "redux/actions/products/byType";

function* fetchProductsByTypeFlow(action: AnyAction) {
  const { filters, homeFilter } = action;
  try {
    const data = yield call(fetchProductsByType, action);
    yield put(
      fetchProductsByTypeOnSuccess(filters.slug, data, homeFilter || {}),
    );
  } catch (error) {
    yield put(fetchProductsByTypeOnError(filters.slug, error));
  }
}

export default function* watchFetchProductsByTypeSaga() {
  yield takeEvery(REQUEST_FETCH_PRODUCTS_BY_TYPE, fetchProductsByTypeFlow);
}
