import Heading from "components/atoms/Heading";
import Icon from "components/atoms/Icon";
import Link from "components/atoms/Link";
import Text from "components/atoms/Text";
import Accordion from "components/molecules/Accordion";
import List from "components/molecules/List";
import PaymentMethods from "components/molecules/PaymentMethods";
import { Grid, GridCol, GridRow } from "components/organisms/Grid";
import { useSelector } from "hooks/useApp";
import useWindowSize from "hooks/useWindowSize";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { requestFetchFooterMenus } from "redux/actions/general/menus";
import { mappingChildrenToParent } from "utils/utils-common";
import "./index.scss";

interface FooterProps {
  hide?: boolean;
}

const Footer: React.FC<FooterProps> = ({ children, hide }) => {
  const owlClass = "o-footer";
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState<boolean>(width <= 576);

  const {
    menus: { footerMenus },
  } = useSelector((state: any) => state.general);

  useEffect(() => {
    dispatch(requestFetchFooterMenus());
  }, [dispatch]);

  useEffect(() => {
    setIsMobile(width <= 576);
  }, [width]);

  const getList = (footer: any, i: number) => (
    <List modifiers={["textlink"]}>
      {footer.children.length > 0 &&
        footer.children.map((child: any, j: number) => {
          const url = child.reference_id
            ? `/${child.reference.slug}`
            : child.link;
          return (
            <li key={i + j}>
              <Link
                isLinkRouter={child.target === "_self"}
                href={
                  child.css_class
                    ? child.css_class === "mail"
                      ? "mailto:" + url
                      : url
                    : url
                }
                target={child.target ? child.target === "_blank" : false}
              >
                {child.title}
              </Link>
            </li>
          );
        })}
    </List>
  );

  const getFooter = useMemo(
    () => (isMobile: boolean, footer: any, i: number) => {
      return isMobile ? (
        <GridCol colMd={12} key={i}>
          <Accordion title={footer.title} isOpen={false} theme="toggle">
            {getList(footer, i)}
          </Accordion>
        </GridCol>
      ) : (
        <GridCol colMd={3} key={i}>
          <Heading type="h4" modifiers={["gray"]}>
            {footer.title}
          </Heading>
          {getList(footer, i)}
        </GridCol>
      );
    },
    [],
  );

  const footerNode = useMemo(() => {
    return (
      footerMenus.length > 0 &&
      mappingChildrenToParent(footerMenus, true).map((footer: any, i: number) =>
        getFooter(isMobile, footer, i),
      )
    );
  }, [footerMenus, getFooter, isMobile]);

  const mainNode = useMemo(
    () => (
      <footer className={`${owlClass} ${(hide && "hide") || ""}`} id="footer">
        <div className={`${owlClass}_top`}>
          <Grid>
            <div className={`${owlClass}_top_logo`}>
              <Link href="/logo-daikin">
                <Icon iconName="logo-daikin" />
              </Link>
            </div>
            <GridRow>
              {footerNode}
              <GridCol colMd={isMobile ? 12 : 3}>
                <Heading type="h4" modifiers={["gray"]}>
                  PHƯƠNG THỨC THANH TOÁN
                </Heading>
                <div className={`${owlClass}_top_payment`}>
                  <PaymentMethods modifiers={["horizontal"]}>
                    <Icon iconName="visa" />
                    <Icon iconName="master" />
                    <Icon iconName="jcb" />
                    <Icon iconName="payoo" />
                    <Icon iconName="tragop" />
                    <Icon iconName="american" />
                  </PaymentMethods>
                </div>
              </GridCol>
            </GridRow>
          </Grid>
        </div>
        <div className={`${owlClass}_bottom`}>
          <Grid>
            <GridRow>
              <GridCol colMd={9}>
                <Text size="sm">
                  {" "}
                  Công ty cổ phần Daikin Air Conditioning (Vietnam). GCNDKDN:
                  0301450108 do Sở Kế hoạch và Đầu tư TP. Hồ Chí Minh cấp ngày
                  04/04/2019{" "}
                </Text>
                <Text size="sm">
                  Trụ sở chính: Tầng 14-15, số 201-203 Cách Mạng Tháng Tám,
                  Phường 4, Quận 3, Thành phố Hồ Chí Minh, Việt Nam
                </Text>
                <Text size="sm"> Điện thoại: +84-28-62 504 888 </Text>
                <Text size="sm"> Fax: +84-28-62 504 999 </Text>
                <Text size="sm"> Email: support@daikin.com.vn </Text>
                <Text size="sm">
                  {" "}
                  Bản quyền thuộc về Daikin Vietnam ©2019.{" "}
                </Text>
              </GridCol>
              <GridCol colMd={3}>
                <div className={`${owlClass}_bottom_ministry`}>
                  <Link
                    isLinkRouter={false}
                    target={true}
                    href="http://online.gov.vn/Home/WebDetails/68864"
                  >
                    <Icon iconName="bocongthuong" />
                  </Link>
                </div>
              </GridCol>
            </GridRow>
          </Grid>
        </div>
      </footer>
    ),
    [footerNode, hide, isMobile],
  );

  return mainNode;
};

export default memo(Footer);
