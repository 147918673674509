import { fetchSeenProducts } from "api/interna-api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchSeenProductsOnError,
  fetchSeenProductsOnSuccess,
  REQUEST_FETCH_SEEN_PRODUCTS,
} from "redux/actions/products/seen";

function* fetchSeenProductsFlow({ list }: { list: any }) {
  try {
    const data = yield call(fetchSeenProducts, list);
    yield put(fetchSeenProductsOnSuccess(data));
  } catch (error) {
    yield put(fetchSeenProductsOnError(error));
  }
}

export default function* watchFetchSeenProductsSaga() {
  yield takeLatest(REQUEST_FETCH_SEEN_PRODUCTS, fetchSeenProductsFlow);
}
