import { AnyAction } from "redux";
import {
  REQUEST_FETCH_CONTENT_BY_CATEGORY,
  REQUEST_FETCH_CONTENT_BY_CATEGORY_ERROR,
  REQUEST_FETCH_CONTENT_BY_CATEGORY_SUCCESS,
  REQUEST_FETCH_FAQ_CATEGORIES,
  REQUEST_FETCH_FAQ_CATEGORIES_ERROR,
  REQUEST_FETCH_FAQ_CATEGORIES_SUCCESS,
} from "redux/actions/pages/faq";

const defaultState = {
  data: {
    faqCategogories: [],
    contentCategory: [],
  },
  errorFaqCategories: null,
  errorContentCategory: null,

  loadingFaqCategories: false,
  loadingContentCategory: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_FETCH_FAQ_CATEGORIES:
      return {
        ...state,
        loadingFaqCategories: true,
      };
    case REQUEST_FETCH_FAQ_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          faqCategogories: action.faqCategories,
        },
        errorFaqCategories: null,
        loadingFaqCategories: false,
      };
    case REQUEST_FETCH_FAQ_CATEGORIES_ERROR:
      return {
        ...state,
        errorFaqCategories: action.error,
        loadingFaqCategories: false,
      };
    case REQUEST_FETCH_CONTENT_BY_CATEGORY:
      return {
        ...state,
        loadingContentCategory: true,
      };
    case REQUEST_FETCH_CONTENT_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          contentCategory: action.contentCategory,
        },
        errorContentCategory: null,
        loadingContentCategory: false,
      };
    case REQUEST_FETCH_CONTENT_BY_CATEGORY_ERROR:
      return {
        ...state,
        errorContentCategory: action.error,
        loadingContentCategory: false,
      };
    default:
      return state;
  }
};
