import { fetchContentCategory, fetchFaqCategories } from "api/interna-api";
import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchContentCategoryOnError,
  fetchContentCategoryOnSuccess,
  fetchFaqCategoriesOnError,
  fetchFaqCategoriesOnSuccess,
  REQUEST_FETCH_CONTENT_BY_CATEGORY,
  REQUEST_FETCH_FAQ_CATEGORIES,
} from "redux/actions/pages/faq";

function* fetchFaqCategoriesFlow() {
  try {
    const data = yield call(fetchFaqCategories);
    yield put(fetchFaqCategoriesOnSuccess(data));
  } catch (error) {
    yield put(fetchFaqCategoriesOnError(error));
  }
}

function* fetchContentCategoryFlow({ limit, id }: AnyAction) {
  try {
    const data = yield call(fetchContentCategory, limit, id);
    yield put(fetchContentCategoryOnSuccess(data));
  } catch (error) {
    yield put(fetchContentCategoryOnError(error));
  }
}

export default function* watchFetchFaqCategoriesPageSaga() {
  yield all([
    takeLatest(REQUEST_FETCH_FAQ_CATEGORIES, fetchFaqCategoriesFlow),
    takeLatest(REQUEST_FETCH_CONTENT_BY_CATEGORY, fetchContentCategoryFlow),
  ]);
}
