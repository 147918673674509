import { AnyAction } from "redux";
import {
  FETCH_GUARANTEE_INFO_ERROR,
  FETCH_GUARANTEE_INFO_SUCCESS,
  FETCH_STATUS_ORDERS_ERROR,
  FETCH_STATUS_ORDERS_SUCCESS,
  REQUEST_FETCH_GUARANTEE_INFO,
  REQUEST_FETCH_STATUS_ORDERS,
} from "redux/actions/pages/dashboard";

const defaultState = {
  data: {
    statusOrders: { total: 0, orders: [], error: null, loading: false },
    guaranteeInfo: { total: 0, infos: [], error: null, loading: false },
  },
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_FETCH_STATUS_ORDERS:
      return {
        ...state,
        data: {
          ...state.data,
          statusOrders: {
            ...state.data.statusOrders,
            loading: true,
          },
        },
      };
    case FETCH_STATUS_ORDERS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          statusOrders: {
            total: action.statusOrders.meta.totalPages,
            orders: action.statusOrders.data,
            error: null,
            loading: false,
          },
        },
      };
    case FETCH_STATUS_ORDERS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          statusOrders: {
            ...state.data.statusOrders,
            error: action.error,
            loading: false,
          },
        },
      };
    case REQUEST_FETCH_GUARANTEE_INFO:
      return {
        ...state,
        data: {
          ...state.data,
          guaranteeInfo: {
            ...state.data.guaranteeInfo,
            loading: true,
          },
        },
      };
    case FETCH_GUARANTEE_INFO_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          guaranteeInfo: {
            total: action.guaranteeInfo.meta.totalPages,
            infos: action.guaranteeInfo.data,
            error: null,
            loading: false,
          },
        },
      };
    case FETCH_GUARANTEE_INFO_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          guaranteeInfo: {
            ...state.data.guaranteeInfo,
            error: action.error,
            loading: false,
          },
        },
      };
    default:
      return state;
  }
};
