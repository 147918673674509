import React from "react";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import "./index.scss";

interface FieldrowProps {
  modifiers?: string[];
  className?: string;
}

interface FieldcellProps {
  modifiers?: string[];
  className?: string;
}

const Fieldrow: React.FC<FieldrowProps> = ({
  modifiers,
  children,
  className,
}) => (
  <div className={mapMultiClassnames("m-fieldrow", modifiers, className)}>
    {children}
  </div>
);

export const Fieldcell: React.FC<FieldcellProps> = ({
  children,
  className,
}) => {
  return (
    <div className={joinClassnames("m-fieldrow_cell", className)}>
      {children}
    </div>
  );
};

export default Fieldrow;
