import React, { ReactNode } from "react";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import "./index.scss";

interface GridProps {
  variant?: "layout";
  className?: string;
  ref: any;
  children: ReactNode;
}

interface GridRowProps {
  no_gutters?: boolean;
  gutter?: 10 | 20;
  className?: string;
}

interface GridColProps {
  colSm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  colMd?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  colLg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  colXl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  colXxl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  order?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "first" | "last";
  offset?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  no_gutters?: boolean;
  className?: string;
}

export const Grid = React.forwardRef<HTMLDivElement, GridProps>(
  ({ variant, children, className }, ref) => (
    <div
      ref={ref}
      className={joinClassnames(
        mapMultiClassnames("o-container", variant),
        className,
      )}
    >
      {children}
    </div>
  ),
);

export const GridRow: React.FC<GridRowProps> = ({
  no_gutters,
  gutter,
  className,
  children,
}) => (
  <div
    className={joinClassnames(
      mapMultiClassnames(
        "o-row",
        no_gutters && "no-gutters",
        gutter && `gutter-${gutter}`,
      ),
      className,
    )}
  >
    {children}
  </div>
);

export const GridCol: React.FC<GridColProps> = ({
  colXxl,
  colXl,
  colLg,
  colMd,
  colSm,
  order,
  offset,
  no_gutters,
  children,
  className,
}) => (
  <div
    className={joinClassnames(
      mapMultiClassnames(
        "o-col",
        colXxl && `xxl-${colXxl}`,
        colXl && `xl-${colXl}`,
        colLg && `lg-${colLg}`,
        colMd && `md-${colMd}`,
        colSm && `sm-${colSm}`,
        order && `order-${order}`,
        offset !== undefined && `offset-${offset}`,
        no_gutters ? "no-gutters" : undefined,
      ),
      className,
    )}
  >
    {children}
  </div>
);

GridCol.defaultProps = {
  colSm: 12,
  colMd: 12,
};

export default Grid;
