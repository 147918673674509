export const REQUEST_SEARCH_ORDER = "REQUEST_SEARCH_ORDER";
export const REQUEST_SEARCH_ORDER_SUCCESS = "REQUEST_SEARCH_ORDER_SUCCESS";
export const REQUEST_SEARCH_ORDER_ERROR = "REQUEST_SEARCH_ORDER_ERROR";

export const requestSearchOrder = (code: string) => ({
  type: REQUEST_SEARCH_ORDER,
  code
});

export const requestSearchOrderOnSuccess = (statusOrder: any) => ({
  type: REQUEST_SEARCH_ORDER_SUCCESS,
  statusOrder
});

export const requestSearchOrderOnError = (error: any) => ({
  type: REQUEST_SEARCH_ORDER_ERROR,
  error
});