import { useEffect } from "react";
import { resetStatusOfTradeProduct } from 'sagas/products/cart';

const useCheckHaveApplyTCDMInShoppingCart = () => {
  useEffect(() => {
    const LocalCart = window.localStorage.getItem("cart");
    const renewal = window.localStorage.getItem("renewal");
    if (LocalCart) {
      const LOCAL_CART = JSON.parse(LocalCart);
      if (LOCAL_CART && LOCAL_CART.length === 0) {
        window.localStorage.removeItem("renewal");
      }
    } else {
      window.localStorage.removeItem("renewal");
    }
    if (!renewal) {
      resetStatusOfTradeProduct()
    }
  }, []);
};

export default useCheckHaveApplyTCDMInShoppingCart;
