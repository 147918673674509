import { AnyAction } from "redux";
import {
  REQUEST_FETCH_BANKS,
  REQUEST_FETCH_BANKS_ERROR,
  REQUEST_FETCH_BANKS_SUCCESS,
} from "redux/actions/general/banks";

const defaultState = {
  data: [],
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_FETCH_BANKS:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_FETCH_BANKS_SUCCESS:
      return {
        data: action.banks,
        error: null,
        loading: false,
      };
    case REQUEST_FETCH_BANKS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
