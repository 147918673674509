import { postComment } from "api/interna-api";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  postCommentOnError,
  postCommentOnSuccess,
  REQUEST_POST_COMMENT,
} from "redux/actions/general/comment";

function* postCommentFlow({ data }: AnyAction) {
  try {
    const responseData = yield call(postComment, data);
    yield put(postCommentOnSuccess(responseData));
  } catch (error) {
    yield put(postCommentOnError(error));
  }
}

export default function* watchPostRatingSaga() {
  yield takeLatest(REQUEST_POST_COMMENT, postCommentFlow);
}
