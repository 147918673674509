import Dropdown from "components/atoms/Dropdown";
import Icon from "components/atoms/Icon";
import colors, { transition } from "constants/colors";
import { centerYFlexCss } from "constants/css";
import React from "react";
import styled, { css } from "styled-components/macro";
import "./index.scss";

type LabelSizeProps = "small" | "medium" | "large";
type LabelProps = {
  size?: LabelSizeProps;
};
interface DeliveryProvinceSelectionProps {
  list?: DeliveryProviceProps[];
  currentIndex: number;
  hideTitle?: boolean;
  label?: string;
  selectLabel?: string;
  labelSize?: LabelSizeProps;
  onChange: (item: string) => void;
  onShow?: (show: boolean) => void;
}

const DeliveryProvinceSelection: React.FC<DeliveryProvinceSelectionProps> = ({
  list,
  currentIndex,
  hideTitle,
  label,
  selectLabel,
  labelSize,
  onChange,
  onShow,
}) => (
  <DeliveryProvices>
    {!hideTitle &&
      DeleveryLabel(label ? label : "Tỉnh thành giao hàng", labelSize)}
    <DeliveryProvicesList hideTitle={hideTitle}>
      <DropdownExtended
        title={
          currentIndex >= 0
            ? list && list[currentIndex] && list[currentIndex].name
            : selectLabel || "Chọn tỉnh thành"
        }
        noPaddingMenu
        alignLeftContent
        activeStyle={{
          icon: {
            icon: <Icon iconName="chevron-down-active" />,
            position: "right",
          },
          colorActive: colors.richElectricBlue,
        }}
        onChange={onChange}
        closeOnChange
        onShow={onShow}
      >
        {list &&
          list.map(
            (province: DeliveryProviceProps, index: number) =>
              province && (
                <UIIconItem
                  key={index}
                  disabled={
                    province.status !== undefined && province.status !== 1
                  }
                  value={province.name}
                >
                  {province.status &&
                    (province.status === 1 ? (
                      <UIIcon ui="circle" size={4} type="info" />
                    ) : (
                      <UIIcon ui="circle" size={4} type="error" />
                    ))}{" "}
                  <DeliveryProvince>{province.name}</DeliveryProvince>{" "}
                  {province.status &&
                    (province.status === 1 ? (
                      <UIText ui="circle" size="small" type="info">
                        Còn Hàng
                      </UIText>
                    ) : (
                      <UIText ui="circle" size="small" type="error">
                        Hết Hàng
                      </UIText>
                    ))}
                </UIIconItem>
              ),
          )}
      </DropdownExtended>
    </DeliveryProvicesList>
  </DeliveryProvices>
);

DeliveryProvinceSelection.defaultProps = {};

export const DeliveryProvince = styled.div`
  padding: 1em;
  cursor: pointer;
  font-size: 1em;
`;

type UIIconProps = {
  size: number;
  ui?: UITypes;
  type: VariantTypes;
};

type UITextProps = {
  size: UITextSizeTypes;
  ui?: UITypes;
  type: VariantTypes;
};

const UIIcon = styled.span<UIIconProps>`
  display: inline-block;
  ${({ ui = "rect" }) =>
    ui === "circle" &&
    css`
      border-radius: 50%;
    `}
  
  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `}

  ${({ type }) =>
    type === "info"
      ? css`
          background-color: ${colors.richElectricBlue};
        `
      : type === "success"
        ? css`
          background-color: ${colors.shamrockGreen};
        `
        : css`
          background-color: ${colors.pastelRed};
        `}
`;

const UIText = styled.span<UITextProps>`
  display: inline-block;
  -webkit-font-smoothing: subpixel-antialiased;

  ${({ ui = "rect" }) =>
    ui === "circle" &&
    css`
      border-radius: 5px;
    `}

  ${({ size }) =>
    size === "small"
      ? css`
          font-size: 8px;
          padding: 0.25em;
        `
      : size === "medium"
        ? css`
          font-size: 10px;
          padding: 0.5em;
        `
        : css`
          font-size: 12px;
          padding: 0.75em;
        `}
  
  ${({ type }) =>
    type === "info"
      ? css`
          color: ${colors.white};
          background-color: ${colors.richElectricBlue};
        `
      : type === "success"
        ? css`
          color: ${colors.white};
          background-color: ${colors.shamrockGreen};
        `
        : css`
          color: ${colors.white};
          background-color: ${colors.pastelRed};
        `}
`;
const UIIconItem = styled.div<DropdownItemType>`
  position: relative;
  display: flex;
  white-space: nowrap;
  padding-right: 40px;
  padding-left: 15px;
  transition: 0.35s;

  ${({ disabled }) =>
    disabled
      ? css`
          background: ${colors.smoke};
          color: ${colors.grayDark};
          cursor: not-allowed;

          ${DeliveryProvince} {
            cursor: not-allowed;
          }
        `
      : css`
          &:hover {
            background: ${colors.smoke};
          }
        `}

  ${DeliveryProvince} {
    display: block;
  }

  ${UIIcon} {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  ${UIText} {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const DropdownExtended = styled(Dropdown)`
  padding-top: 0;
  padding-bottom: 0;

  .a-dropdown-menu {
    /* transition: ${transition.duration.short}; */
  }
`;

const DeliveryProvices = styled.div`
  position: relative;
  ${centerYFlexCss};

  ${DropdownExtended} {
    margin-left: 0.25em;

  }
`;

type DeliveryProvincesListPropsTypes = {
  hideTitle?: boolean;
};
const DeliveryProvicesList = styled.div<DeliveryProvincesListPropsTypes>`
  ${({ hideTitle }) =>
    !hideTitle &&
    css`
      margin-left: 16px;
    `}
`;

const DeliveryProvicesLabel = styled.span<LabelProps>`
  ${({ size }) => {
    switch (size) {
      case "small":
        return css`
          font-size: 0.75em;
        `;
      case "medium":
        return css`
          font-size: 1em;
        `;
      case "large":
        return css`
          font-size: 1.25em;
        `;
      default:
        return ``;
    }
  }}
`;

export const DeleveryLabel = (label: string, size?: LabelSizeProps) => (
  <DeliveryProvicesLabel size={size}>{label}:</DeliveryProvicesLabel>
);

export default DeliveryProvinceSelection;
