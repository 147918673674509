import { logout } from "api/interna-api";
import Button from "components/atoms/Button";
import Dropdown from "components/atoms/Dropdown";
import Icon from "components/atoms/Icon";
import Image from "components/atoms/Image";
import Link from "components/atoms/Link";
import QuantityButton from "components/atoms/QuantityButton";
import Text from "components/atoms/Text";
import ChoosenStock from "components/molecules/ChoosenStock";
import NavTop, { NavTopItem } from "components/molecules/NavTop";
import SearchOrder from "components/molecules/searchorder";
import Modal from "components/organisms/Modal";
import ModalBody from "components/organisms/Modal/Body";
import ModalFooter from "components/organisms/Modal/Footer";
import { clearLocalCart } from "constants/cart";
import { BASE_URL } from "constants/env";
import { useSelector } from "hooks/useApp";
import useCompare from "hooks/useCompare";
import useWindowSize from "hooks/useWindowSize";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { authLoginDone, authLogout } from "redux/actions/auth/auths";
import { fetchCompare } from "redux/actions/pages/compare";
import {
  descreaseIncreaseCartProducts,
  fetchCartProducts
} from "redux/actions/products/cart";
import { setModal } from "redux/reducers/view/modal";
import { joinClassnames } from "utils/map-modifier";
import CartBox from "../CartBox";
import ToggleCartBox from "../ToggleCartBox";
import "./index.scss";
interface Props {
  children?: React.ReactNode;
  style?: any;
  href?: string;
  modifiers?: Modifiers;
  className?: string;
  onClick?: () => void;
  onToggleShowingBoxMobile?: (status: boolean) => void;
  onToggleShowingSearchBoxTablet?: (status: boolean) => void;
  onCloseToggleCheckOrderTablet?: boolean;
  onCloseToggleCheckOrderMobile?: boolean;
}

const RightNavTop: React.FC<Props> = ({
  className,
  style,
  onToggleShowingBoxMobile,
  onToggleShowingSearchBoxTablet,
  onCloseToggleCheckOrderTablet,
  onCloseToggleCheckOrderMobile,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { width } = useWindowSize();
  const {
    general: { system },
    userInfor,
    products: { cart },
    pages: { compare },
  } = useSelector(state => state);
  const { showCompare, onHideCompare, goToCompare } = useCompare(compare.data);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [systemList, setSystemList] = useState([]);
  const [cartList, setCartList] = useState([]);
  const SearchBoxDesktop_query = 991;
  const SearchBox992_query = 992;
  const [isDesktop, setIsDesktop] = useState<boolean>(
    width > SearchBoxDesktop_query,
  );
  const SearchBoxMobile_query = 768;
  const [isTablet, setIsTablet] = useState<boolean>(
    width > SearchBoxMobile_query - 1 && width < SearchBox992_query,
  );
  const [toggleCheckOrderMobile, setToggleCheckOrderMobile] = useState(false);
  const [toggleCheckOrderTablet, setToggleCheckOrderTablet] = useState(false);

  const handleToggleAccount = useCallback(() => setToggleDropdown(!toggleDropdown), [toggleDropdown]);

  useEffect(() => {
    const {
      data: { list },
    } = cart;
    const cartStore =
      JSON.parse(window.localStorage.getItem("cart") || "{}") || [];
    if (list && !!list.length && cartStore && cartStore.length > 0) {
      let updatecart =
        typeof cartStore.map === "function"
          ? cartStore.map((c: any) => {
            let foundProduct = list.find(p => p.product_id === c.productId);
            if (foundProduct) {
              return {
                ...c,
                quantity: foundProduct.quantity,
              };
            } else {
              return c;
            }
          })
          : [];
      // TODO: SET CART INCLUDE CASHBACK:

      // is object
      if (updatecart) {
        if (
          typeof updatecart === "object" &&
          typeof updatecart.map === "function"
        ) {
          window.localStorage.setItem("cart", JSON.stringify(updatecart));
        } else if (typeof updatecart === "string") {
          window.localStorage.setItem("cart", updatecart);
        }
        setCartList(list);
      }
    }
    else {
      setCartList([]);
    }
  }, [cart]);

  useEffect(() => {
    if (system.data.length > 0) {
      const found = system.data.filter(
        (ge: any) =>
          ge.key === "facebook_url" ||
          ge.key === "facebook_logo" ||
          ge.key === "youtube_url" ||
          ge.key === "youtube_logo",
      );
      setSystemList(found);
    }
  }, [system]);

  useEffect(() => {
    const token = window.localStorage.getItem("userToken");
    const refreshToken = window.localStorage.getItem("refreshToken");
    if (token) {
      if (!userInfor) {
        dispatch(authLoginDone(token, refreshToken));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchCompare());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setIsDesktop(width > SearchBoxDesktop_query);
    setIsTablet(
      width > SearchBoxMobile_query - 1 && width < SearchBox992_query,
    );
  }, [width]);

  useEffect(() => {
    onToggleShowingSearchBoxTablet &&
      onToggleShowingSearchBoxTablet(toggleCheckOrderTablet);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleCheckOrderTablet]);

  useEffect(() => {
    onCloseToggleCheckOrderTablet !== undefined &&
      setToggleCheckOrderTablet(onCloseToggleCheckOrderTablet);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCloseToggleCheckOrderTablet]);

  useEffect(() => {
    onCloseToggleCheckOrderMobile !== undefined &&
      setToggleCheckOrderMobile(onCloseToggleCheckOrderMobile);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCloseToggleCheckOrderMobile]);

  // hello-hello
  const fbLinkItem = useMemo(() => {
    return <NavTopItem>
      <Link
        isLinkRouter={false}
        target
        href={systemList?.find((sys: any) => sys.key === "facebook_url")?.content}
      >
        <Icon iconName="facebook" />
      </Link>
    </NavTopItem>
  },
    [systemList])

  // hello-hello
  const youtubeLinkItem = useMemo(() => {
    return <NavTopItem>
      <Link
        isLinkRouter={false}
        target
        href={systemList?.find((sys: any) => sys.key === "youtube_url")?.content}
      >
        <Icon iconName="youtube" />
      </Link>
    </NavTopItem>
  },
    [systemList])

  const dispatchStockModal = useCallback(() => {
    dispatch(setModal("stock", true));
  }, [dispatch]);

  const onLogout = useCallback(() => {
    logout()
      .then(() => {
        dispatch(authLogout());
        // TODO:
        // Trường hợp đăng nhập đăng xuất, hiện tại chưa thấy quản lý localstorage cho việc lưu theo từng user.
        // Tạm thời clear localstorage cart khi đăng xuất.
        clearLocalCart();

        dispatch(fetchCartProducts());
        window.location.reload();
      })
      .catch(() => {
      });
  }, [dispatch]);

  const goToSignUpIn = useCallback((sign: string) => {
    const {
      location: { pathname },
    } = history;
    window.localStorage.setItem("pathname", pathname === "/" ? "" : pathname);
    history.push(sign === "sign-in" ? "/dang-nhap" : "/dang-ky");
  }, [history]);

  const loggedDropdownItem = useMemo(() => {
    return (
      <>
        <Icon iconName="user" />
        <Dropdown title={userInfor?.name || ""}>
          <Link href="/tai-khoan?t=seen-products">
            <span className="d-flex align-items-center">
              <Icon iconName="recent" /> Sản Phẩm Đã Xem
            </span>
          </Link>
          <Link href="/tai-khoan?t=user-info">
            <span className="d-flex align-items-center">
              <Icon iconName="account-info" /> Thông Tin Tài Khoản
            </span>
          </Link>
          <Link href="/tai-khoan?t=guarantee-info">
            <span className="d-flex align-items-center">
              <Icon iconName="warranty-info" /> Thông Tin Bảo Hành
            </span>
          </Link>
          <Link href="">
            <span className="d-flex align-items-center" onClick={onLogout}>
              <Icon iconName="login" /> Đăng Xuất
            </span>
          </Link>
        </Dropdown>
      </>
    );
  }, [onLogout, userInfor]);

  const unLoggedDropdownItem = useMemo(() => {
    return (
      <>
        <Icon iconName="user" />
        <Icon iconName="dropdown" />
        <Dropdown title="Tài khoản">
          <Link href="/tai-khoan?t=seen-products">
            <span className="d-flex align-items-center">
              <Icon iconName="recent" /> Sản Phẩm Đã Xem
            </span>
          </Link>
          <Link href="#">
            <span
              onClick={() => goToSignUpIn("sign-up")}
              className="d-flex align-items-center"
            >
              <Icon iconName="login" /> Đăng Ký
            </span>
          </Link>
          <Link href="#">
            <span
              onClick={() => goToSignUpIn("sign-in")}
              className="d-flex align-items-center"
            >
              <Icon iconName="login" /> Đăng Nhập
            </span>
          </Link>
        </Dropdown>
      </>
    );
  }, [goToSignUpIn])

  const accountItemContent = useMemo(() => {
    return userInfor ? loggedDropdownItem : unLoggedDropdownItem;
  }, [loggedDropdownItem, unLoggedDropdownItem, userInfor]);

  const handleCompareItemClick = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
    goToCompare();
  }, [goToCompare])

  const compareItem = useMemo(() => {
    return (
      <NavTopItem>
        <a
          rel="noreferrer"
          href="/"
          onClick={handleCompareItemClick}
          className={compare.data.length > 0 ? "noti" : ""}
        >
          <Icon iconName="compare" />
          <span>So Sánh ({compare.data.length})</span>
        </a>
      </NavTopItem>
    )
  }, [compare.data.length, handleCompareItemClick])

  const accountItem = useMemo(() => {
    return (
      <NavTopItem
        onClick={handleToggleAccount}
        itemClassName="account-item"
      >
        {accountItemContent}
      </NavTopItem>
    )
  }, [accountItemContent, handleToggleAccount])

  const navTopSocial = useMemo(() => {
    let hotline;
    if (system.data) {
      const hotlineData: any = system.data?.find((item: any) => item.key === 'hotline');
      if (hotlineData) {
        hotline = {
          phone_buy_product: hotlineData.content?.buy_product.phone,
          text_buy_product: hotlineData.content?.buy_product.text,
          phone_cskh: hotlineData.content?.cskh.phone,
          text_cskh: hotlineData.content?.cskh.text,
        }
      }
    }

    return (
      <NavTop modifiers={["social"]}>
        <NavTopItem itemClassName="location-block">
          {
            // Location | delivery 
          }
          <ChoosenStock onClick={dispatchStockModal} />
        </NavTopItem>
        {
          hotline && (hotline.phone_buy_product || hotline?.phone_cskh) && (
            <NavTopItem>
              <Icon iconName="hotline" />
              <div className="m-nav-top_hotline">
                {
                  hotline.phone_buy_product && (
                    <Link
                      isLinkRouter={false}
                      className="text-blue"
                      href={`tel:${hotline.phone_buy_product}`}
                    >
                      <span>{hotline.text_buy_product} {hotline.phone_buy_product}</span>
                    </Link>
                  )
                }
                {
                  hotline.phone_cskh && (
                    <Link
                      isLinkRouter={false}
                      className="text-blue"
                      href={`tel:${hotline.phone_cskh}`}
                    >
                      <span>{hotline.text_cskh} {hotline.phone_cskh}</span>
                    </Link>
                  )
                }
              </div>
            </NavTopItem>
          )
        }

        {systemList?.length > 0 && (
          <>
            {" "}
            {fbLinkItem}
            {youtubeLinkItem}
          </>
        )}
      </NavTop>
    )
  }, [dispatchStockModal, fbLinkItem, systemList, youtubeLinkItem])

  const onChangeQuantity = useCallback((product: any, quantity: number, sign: string) => {
    const { code } = cart;
    if (sign === "+") {
      dispatch(descreaseIncreaseCartProducts(product, "+", code));
    } else {
      dispatch(descreaseIncreaseCartProducts(product, "-", code));
    }
  }, [cart, dispatch]);

  const onChangeQuantityDiv = (e: any) => {
    e.preventDefault();
  };

  const cartBoxItemHtml = useMemo(() => {
    return (
      // hello-hello
      cartList?.length > 0 ? (
        cartList.map(product => (
          <Link
            key={product.product_id}
            href={`/san-pham/${product.slug}`}
          >
            <CartBox.Item key={product.product_id}>
              <CartBox.Image>
                <Image
                  src={`${BASE_URL}${product.thumbnail}`}
                  alt={product.name}
                />
              </CartBox.Image>
              <CartBox.Info>
                <CartBox.Name>{product.name}</CartBox.Name>
                <CartBox.Model>Model: {product.model}</CartBox.Model>
                <CartBox.Price>
                  <CartBox.Label>Giá</CartBox.Label>
                  <CartBox.Val>
                    <span>
                      {window.formatMoney(
                        Math.round(
                          (product.default_price *
                            +product.quantity) /
                          1000,
                        ) * 1000,
                      )}
                    </span>
                  </CartBox.Val>
                </CartBox.Price>
                <CartBox.Quantity>
                  <CartBox.Label>Số lượng</CartBox.Label>
                  <CartBox.Input>
                    <div onClick={onChangeQuantityDiv}>
                      <QuantityButton
                        name=""
                        value={product.quantity}
                        modifiers={["long"]}
                        onChangeQuantity={onChangeQuantity}
                        product={product}
                      />
                    </div>
                  </CartBox.Input>
                </CartBox.Quantity>
              </CartBox.Info>
            </CartBox.Item>
          </Link>
        ))
      ) : (
        <Text weight="bold" modifiers={["center"]}>
          Không có sản phẩm trong giỏ hàng
        </Text>
      )
    )
  }, [cartList, onChangeQuantity]);

  const cartListItemLabel = useMemo(() => {
    return <span className="count-items">({cartList?.length})</span>
  }, [cartList])

  const cartLabel = useMemo(() => {
    return <span>Giỏ Hàng ({cart?.loading ? ".." : cartList.length})</span>
  }, [cart, cartList.length])

  const cartBoxClassName = useMemo(() => {
    return `toggle-cart-box${cartList.length > 0 ? " noti" : ""
      }`
  }, [cartList])

  const checkOrderStatusItem = useMemo(() => {
    return (
      <NavTopItem>
        <div
          className={joinClassnames(
            "toggle-cart-box",
            toggleCheckOrderMobile && "mobile-selected",
            toggleCheckOrderTablet && "tablet-selected",
          )}
          onClick={e => {
            !isDesktop &&
              setToggleCheckOrderMobile(!toggleCheckOrderMobile);
            onToggleShowingBoxMobile &&
              onToggleShowingBoxMobile(!toggleCheckOrderMobile);
            isTablet && setToggleCheckOrderTablet(!toggleCheckOrderTablet);
          }}
        >
          <Icon iconName="order" />
          <span>Kiểm tra đơn hàng</span>
          {isDesktop && (
            <div className="toggle-cart-box_check-order desktop">
              <SearchOrder />
            </div>
          )}
        </div>
      </NavTopItem>
    )
  }, [isDesktop, isTablet, onToggleShowingBoxMobile, toggleCheckOrderMobile, toggleCheckOrderTablet])

  const gotoMyCart = () => {
    history.push("/gio-hang", { isTracking: true });
  }

  const cartBoxHtml = useMemo(() => {
    return (
      <NavTopItem itemClassName="item-cart">
        <div
          onClick={gotoMyCart}
          className={cartBoxClassName}
        >
          <span className="link-car-box">
            <Icon iconName="cart" />
            {cartLabel}
          </span>
          <ToggleCartBox>
            <CartBox>
              <CartBox.Header>
                <CartBox.Title>
                  GIỎ HÀNG CỦA TÔI{" "}
                  {cartListItemLabel}
                </CartBox.Title>
              </CartBox.Header>
              <CartBox.Body>
                <CartBox.Scroll>
                  {cartBoxItemHtml}
                </CartBox.Scroll>
              </CartBox.Body>
              <CartBox.Footer>
                <Link href="/gio-hang">
                  <Button>Kiểm tra giỏ hàng</Button>
                </Link>
              </CartBox.Footer>
            </CartBox>
          </ToggleCartBox>
        </div>
      </NavTopItem>
    )
  }, [cartBoxClassName, cartBoxItemHtml, cartLabel, cartListItemLabel, history]);

  const tipsItem = useMemo(() => {
    return (
      <NavTopItem>
        <Link href="/kinh-nghiem-hay">
          <Icon iconName="tips" />
          <span>Kinh nghiệm hay</span>
        </Link>
      </NavTopItem>
    );
  }, [])

  const navTopPersonal = useMemo(() => {
    return (
      <NavTop modifiers={["personal"]}>
        {
          // Kinh nghiem hay
        }
        {tipsItem}

        {
          // kiem tra don hang
        }
        {checkOrderStatusItem}

        {
          // gio hang - desktop
        }
        {cartBoxHtml}

        {
          // so sanh
        }
        {compareItem}

        {
          // tai khoan
        }
        {accountItem}
      </NavTop>
    )
  }, [accountItem, cartBoxHtml, checkOrderStatusItem, compareItem, tipsItem])

  const navTop = useMemo(() => {
    return (
      <>
        {navTopSocial}
        {navTopPersonal}
      </>
    )

  }, [navTopPersonal, navTopSocial])

  const modalCompare = useMemo(() => {
    return <Modal isOpen={showCompare.status} onCloseRequest={onHideCompare} resetModal>
      <ModalBody>
        <Text modifiers={["center"]}>
          Vui lòng chọn thêm sản phẩm so sánh
        </Text>
      </ModalBody>

      <ModalFooter>
        <Button onClick={onHideCompare} variant="primary" size="small">
          Đóng
        </Button>
      </ModalFooter>
    </Modal>
  }, [showCompare.status, onHideCompare])

  // hello-hello
  return <div
    className={joinClassnames("m-nav-top", className || "")}
    style={style}
  >
    {navTop}
    {modalCompare}
  </div>
};

export default memo(RightNavTop);
