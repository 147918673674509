import { CART_NAME } from "./cart";

export const ERROR_TYPES = {
  LIMIT_PROMOTION: "limit-promotion", // Lỗi giới hạn khuyến mãi
  SAME_TYPE: "same-type", // Lỗi mua 2 sp khác loại
  DEFAULT_LOCATION_OUT_STOCK: "out-stock", // Lỗi tỉnh thành mặc định hết hàng
  ALL_LOCATION_OUT_STOCK: "all-out-stock", // Lỗi tất cả tỉnh thành hết hàng
};
export const LOCATION_DELIVERY_NAME = "locationDelivery";
export const DEFAULT_LOCATION_DELIVERY_NAME = "defaultLocation";

export const getProductsLocationDeliveryStore = () => {
  const locationDeliveryData = getLocalStorageByName(LOCATION_DELIVERY_NAME);
  if (locationDeliveryData) {
    const parseData: LocationDelivery = JSON.parse(locationDeliveryData);
    if (parseData.cart && parseData.cart.some(item => item.deleted)) {
      setLocalStorageByName(
        LOCATION_DELIVERY_NAME,
        parseData.cart.filter(item => !item.deleted),
      );
      return getLocalStorageByName(LOCATION_DELIVERY_NAME);
    }
    return parseData;
  }
  return null;
};

export const getProductInProductsLocationDeliveryStoreById = (
  productId: number,
) => {
  const locationDeliveryData = getProductsLocationDeliveryStore();
  if (locationDeliveryData) {
    return locationDeliveryData.cart.find(item => item.productId === productId);
  }
  return null;
};

export const removeProductInProductsLocationDeliveryStoreById = (
  productId: number,
) => {
  const locationDeliveryData = getProductsLocationDeliveryStore();
  if (locationDeliveryData) {
    const findIndex = locationDeliveryData.cart.findIndex(
      item => item.productId === productId,
    );
    if (findIndex > -1) {
      locationDeliveryData.cart.splice(findIndex, 1);
      if (!!locationDeliveryData.cart.length) {
        setProductsLocationDeliveryStore({
          cart: locationDeliveryData.cart,
        });
      } else {
        removeLocalStorageByName(LOCATION_DELIVERY_NAME);
      }
      return true;
    }
  }
  return null;
};

export const removeProductInCartStoreById = (productId: number) => {
  let cartLocal = getLocalStorageByName("cart");
  if (cartLocal) {
    cartLocal = JSON.parse(cartLocal);
    const findIndex =
      typeof cartLocal.findIndex === "function"
        ? cartLocal.findIndex(item => item.productId === productId)
        : [];
    if (findIndex > -1) {
      cartLocal.splice(findIndex, 1);
      if (!!cartLocal.length) {
        setLocalStorageByName("cart", cartLocal);
      } else {
        removeLocalStorageByName(CART_NAME);
        removeLocalStorageByName(LOCATION_DELIVERY_NAME);
      }
      return true;
    }
  }
  return null;
};

export const updateProductLocationInProductsLocationDeliveryStoreById = (
  productId: number,
  location: string,
) => {
  let locationDeliveryData = getProductsLocationDeliveryStore();
  if (locationDeliveryData) {
    setProductsLocationDeliveryStore({
      cart: locationDeliveryData.cart.map(item => {
        if (item.productId === productId) {
          return {
            ...item,
            location,
          };
        }
        return item;
      }),
    });
  }
  return null;
};

export const getLocalStorageByName = (name: string) => {
  return localStorage.getItem(name);
};

export const setLocalStorageByName = (name: string, data: any) => {
  localStorage.setItem(
    name,
    typeof data === "string" ? data : JSON.stringify(data),
  );
};

export const removeLocalStorageByName = (name: string) => {
  localStorage.removeItem(name);
};

export const setProductsLocationDeliveryStore = (
  locationDeliveryData: LocationDelivery,
) => {
  localStorage.setItem(
    LOCATION_DELIVERY_NAME,
    JSON.stringify(locationDeliveryData),
  );
};

export const getDefaultLocationDeliveryStore = () => {
  const locationItem = localStorage.getItem(DEFAULT_LOCATION_DELIVERY_NAME);
  let result;
  if (locationItem && typeof locationItem === "string") {
    try {
      result = JSON.parse(locationItem);
      if (typeof result === "string") {
        removeLocalStorageByName(DEFAULT_LOCATION_DELIVERY_NAME);
        result = null;
      }
    } catch {
      removeLocalStorageByName(DEFAULT_LOCATION_DELIVERY_NAME);
      result = null;
    }
  }
  return result;
};

export const setDefaultLocationDeliveryStore = (location: LocationItem) => {
  localStorage.setItem(
    DEFAULT_LOCATION_DELIVERY_NAME,
    JSON.stringify(location),
  );
};

export const findAvalableIndexStock = (list: any[], productId: number) =>
  list.findIndex(item => item.status === 1);

export const getCities = (cities: any) => {
  if (cities) {
    return cities.map(item => ({
      id: item.city_id,
      name: item.city.name,
      status: item.status,
    }));
  }
  return [];
};

export default {};
