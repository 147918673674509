export const REQUEST_POST_RATING = "REQUEST_POST_RATING";
export const REQUEST_POST_RATING_SUCCESS = "REQUEST_POST_RATING_SUCCESS";
export const REQUEST_POST_RATING_ERROR = "REQUEST_POST_RATING_ERROR";
export const RESET_RATING = "RESET_RATING";

export const resetRating = () => ({
  type: RESET_RATING,
});

export const requestPostRating = (data: any) => ({
  type: REQUEST_POST_RATING,
  data,
});

export const postRatingOnSuccess = (data: any) => ({
  type: REQUEST_POST_RATING_SUCCESS,
  data,
});

export const postRatingOnError = (error: any) => ({
  type: REQUEST_POST_RATING_ERROR,
  error,
});
