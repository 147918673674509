import { AnyAction } from "redux";
import {
  REQUEST_FETCH_FOOTER_MENU,
  REQUEST_FETCH_FOOTER_MENU_ERROR,
  REQUEST_FETCH_FOOTER_MENU_SUCCESS,
  REQUEST_FETCH_MENU,
  REQUEST_FETCH_MENU_ERROR,
  REQUEST_FETCH_MENU_SUCCESS,
} from "redux/actions/general/menus";

const defaultState = {
  mainMenus: [],
  footerMenus: [],
  error: null,
  loading: false,
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_FETCH_MENU:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_FETCH_MENU_SUCCESS:
      return {
        ...state,
        mainMenus: action.menus,
        error: null,
        loading: false,
      };
    case REQUEST_FETCH_MENU_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case REQUEST_FETCH_FOOTER_MENU:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_FETCH_FOOTER_MENU_SUCCESS:
      return {
        ...state,
        footerMenus: action.menus,
        error: null,
        loading: false,
      };
    case REQUEST_FETCH_FOOTER_MENU_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
