export const REQUEST_FETCH_SAVE_SUBSCRIBER = "REQUEST_FETCH_SAVE_SUBSCRIBER";
export const FETCH_SAVE_SUBSCRIBER_SUCCESS = "FETCH_SAVE_SUBSCRIBER_SUCCESS";
export const FETCH_SAVE_SUBSCRIBER_ERROR = "FETCH_SAVE_SUBSCRIBER_ERROR";
export const REQUEST_CLEAR_SUBSCRIBER = "REQUEST_CLEAR_SUBSCRIBER";

export const requestFetchSaveSubscriber = (email: string) => ({
  type: REQUEST_FETCH_SAVE_SUBSCRIBER,
  email,
});

export const fetchSaveSubscriberOnSuccess = (result: any) => ({
  type: FETCH_SAVE_SUBSCRIBER_SUCCESS,
  result,
});

export const fetchSaveSubscriberOnError = (error: any) => ({
  type: FETCH_SAVE_SUBSCRIBER_ERROR,
  error,
});

export const requestClearSubscriber = () => ({
  type: REQUEST_CLEAR_SUBSCRIBER,
});
