import { fetchHomePage } from "api/interna-api";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchHomePageOnError,
  fetchHomePageOnSuccess,
  REQUEST_FETCH_HOME_PAGE,
} from "redux/actions/pages/home";

function* fetchHomePageFlow() {
  try {
    const data = yield call(fetchHomePage);
    yield put(fetchHomePageOnSuccess(data));
  } catch (error) {
    yield put(fetchHomePageOnError(error));
  }
}

export default function* watchFetchHomePageSaga() {
  yield all([takeLatest(REQUEST_FETCH_HOME_PAGE, fetchHomePageFlow)]);
}
