// const COMMON_PATTERN_EMAIL_1 = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// const COMMON_PATTERN_EMAIL_1 = /^([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(, )*)+$/;

/**
 * https://jira.3forcom.com/browse/DE-1137(FE)
 * https://jira.3forcom.com/browse/DE-1138(BE)
 */
// eslint-disable-next-line no-useless-escape
const BEEmailRegex = /^[a-zA-Z][a-zA-Z0-9_\.\-]{3,32}@[a-zA-Z0-9\-]{2,}(\.[a-zA-Z0-9\-]{2,4}){1,2}$/;
// eslint-disable-next-line no-useless-escape
export const BEAddressRegex = /^[a-z0-9A-Z_ ,\/\-ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂ ưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+$/;
// eslint-disable-next-line no-useless-escape
export const BEStringRegex = /^[a-z0-9A-Z_ ,\/ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂ ưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+$/;
export const BEPhoneRegex = /^(0|\d{2}|\+\d{2})\d{9}$/;

// const COMMON_PATTERN_EMAIL_2 = /^[a-z][a-z0-9_\-.]{4,32}@[a-z0-9_-]{2,}(.[a-z0-9]{2,4}){1,2}$/;
// const COMMON_PATTERN_EMAIL_2 = COMMON_PATTERN_EMAIL_1;
// const COMMON_PATTERN_EMAIL_FROM_BE = /^[a-z][a-z0-9_\\.\\-]{4,32}@[a-z0-9\\-]{2,}(\\.[a-z0-9\\-]{2,4}){1,2}$/;
// const COMMON_PATTERN_EMAIL_2 = COMMON_PATTERN_EMAIL_FROM_BE;
const COMMON_PATTERN_EMAIL_2 = BEEmailRegex;

export const PATTERN_NOT_NUMBER = /^[\D]+$/;
export const PATTERN_NOT_SPECIAL_WORD = /^[^`\-=[\]\\;',./~!@#$%^&*()_+{}|:"<>?]+$/;
export const PATTERN_SPECIAL_WORD = /[`\-=[\]\\;',./~!@#$%^&*()_+{}|:"<>?]+/;
export const PATTERN_NAME = /^[a-z0-9A-Z_ ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂ ưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+$/;

export const VALIDATION_PATTERN = {
  common: {
    name: BEStringRegex,
    email: COMMON_PATTERN_EMAIL_2,
    phone: BEPhoneRegex,
  },
  // signup: {
  //   name: PATTERN_NAME,
  // },
  // signin: {
  //   email: COMMON_PATTERN_EMAIL_2,
  // },
  // payment: {
  //   email: COMMON_PATTERN_EMAIL_2, // use COMMON_PATTERN_EMAIL_1 before.
  // },
  // buying: {
  //   email: COMMON_PATTERN_EMAIL_2,
  // },
  // account: {
  //   email: COMMON_PATTERN_EMAIL_2, // use COMMON_PATTERN_EMAIL_1 before.
  // },
  // message: {
  //   email: COMMON_PATTERN_EMAIL_2,
  // },
  // subscribe: {
  //   email: COMMON_PATTERN_EMAIL_2, // use COMMON_PATTERN_EMAIL_1 before.
  // },
  // schedule: {
  //   email: COMMON_PATTERN_EMAIL_2,
  // },
  // review: {
  //   email: COMMON_PATTERN_EMAIL_2,
  // },
  // resetPass: {
  //   email: COMMON_PATTERN_EMAIL_2,
  // },
};

export default {};
