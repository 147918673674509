import { SystemEnum } from "components/molecules/ViewedProduct";
import { useEffect, useState } from "react";
import { useSelector } from "./useApp";

const useSystem = (slug?: "delivery" | "contact") => {
  const { data: systemData } = useSelector(state => state.general.system);
  const [deliveryData, setDeliveryData] = useState<SystemProps>();
  const [contact, setContact] = useState<any>();

  useEffect(() => {
    if (systemData) {
      const updatedData: { [key: string]: SystemContentProps } = {};
      for (let i = 1; i < Object.keys(systemData).length; i++) {
        if (systemData[i] && systemData[i].key)
          updatedData[systemData[i].key] = systemData[i];
      }
      switch (slug) {
        case "delivery":
          const {
            fast_delivery,
            fast_delivery_text,
            standard_delivery,
            standard_delivery_text,
          } = updatedData;
          setDeliveryData({
            fast_delivery,
            fast_delivery_text,
            standard_delivery,
            standard_delivery_text,
          });
          break;
        case "contact":
          const result: { [key: string]: string | number } = {};
          let pass = 0;
          Object.values(systemData).forEach((item: any): void => {
            if (item.key === SystemEnum.hotline) {
              result[item.key] = item.content;
              pass += 1;
            }
            if (item.key === SystemEnum.fbPageId) {
              result[item.key] = item.content;
              pass += 1;
            }
            if (item.key === SystemEnum.fbColor) {
              result[item.key] = item.content;
              pass += 1;
            }
            if (item.key === SystemEnum.ggAnalyticsViewId) {
              result[item.key] = item.content;
              pass += 1;
            }
            if (item.key === SystemEnum.fbPixelId) {
              result[item.key] = item.content;
              pass += 1;
            }
            if (pass === 5) {
              return;
            }
          });
          setContact(result);
          break;
        default:
          setDeliveryData({
            ...updatedData,
          });
          break;
      }
    }
  }, [slug, systemData]);

  return { deliveryData, contact };
};

export default useSystem;
