import Button from "components/atoms/Button";
import Image from "components/atoms/Image";
import Link from "components/atoms/Link";
import Loading from "components/atoms/Loading";
import ScrollTop from "components/atoms/ScrollTop";
import Text from "components/atoms/Text";
import Carousel from "components/molecules/Carousel";
import CarouselItem from "components/molecules/Carousel/CarouselItem";
import ChatGroup from "components/molecules/ChatGroup";
import { BASE_URL } from "constants/env";
import { CAROUSEL_IDS, VIEWED_PRODUCT } from "constants/swiper";
import { useSelector } from "hooks/useApp";
import useCompare from "hooks/useCompare";
import useSystem from "hooks/useSystem";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { onToggleSeenProducts } from "redux/actions/general/toggleSeenProducts";
import { requestFetchSeenProducts } from "redux/actions/products/seen";
import { SwiperSlide } from "swiper/react";
import { joinClassnames, mapMultiClassnames } from "utils/map-modifier";
import CartBox from "../CartBox";
import "./index.scss";

import useWindowSize from 'hooks/useWindowSize';

interface ViewedProductProps {
  modifiers?: Modifiers;
  onCompare?: (
    e: any,
    productId: number,
    productTypeId: number,
    productParentTypeId: number,
  ) => void;
}

export enum SystemEnum {
  hotline = "phone_hotline",
  fbPageId = "fb_page_id",
  fbColor = "fb_color",
  ggAnalyticsViewId = "google_analytics_view_id",
  fbPixelId = "fb_pixel_ids",
}

const ViewedProduct: React.FC<ViewedProductProps> = ({ modifiers }) => {
  const [seenList, setSeenList] = useState([]);

  const toggleOnclick = () => {
    dispatch(onToggleSeenProducts(!toggle));
  };

  const dispatch = useDispatch();
  const { seen } = useSelector(state => state.products);
  const {
    toggleSeenProducts: { toggle }, // * Set mặc định ẩn hoặc hiện - BLOCK - SẢN PHẨM ĐÃ XEM // initial value toggle in redux = false
  } = useSelector(state => state.general);

  const productIds = window.localStorage.getItem("productIds");
  const [loading, setLoading] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const list = productIds ? productIds.split(",").map(x => +x) : [];
    let limit = list.length;
    let offset = 0;
    if (list.length > 0) {
      dispatch(
        requestFetchSeenProducts({
          pageSize: limit,
          pageNum: offset,
          list: list || [],
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { loading: seenLoading, data } = seen;
    setLoading(seenLoading);
    if (data && Object.entries(data).length > 0) {
      setSeenList(data.data);
    }
  }, [dispatch, seen]);

  const location = useLocation();
  const locationStr = useMemo(() => location && location.pathname, [location]);

  const isPassUrl = useMemo(
    () =>
      locationStr.includes("danh-muc/loai-2-dan-roi") ||
      locationStr.includes("danh-muc/sky-air"),
    [locationStr],
  );

  const {
    compare: { data: compareList },
  } = useSelector(state => state.pages);

  const { handleCompare, handleUnCompare } = useCompare(compareList);

  const { contact } = useSystem("contact");

  const { width: windowWidth } = useWindowSize();
  const bodyPosition = document.body.style.position;

  useEffect(() => {
    const liveChatEl = document.getElementById('cs-live-chat');
    if (liveChatEl) {
      liveChatEl.style.transform = "";
    }
  }, [location.pathname])

  useEffect(() => {
    const boxEl = document.getElementById('m-viewed-product-box');
    const liveChatEl = document.getElementById('cs-live-chat');
    if (!boxEl || !liveChatEl) { return };
    if (windowWidth < 1200) {
      if (toggle) {
        const boxHeight = boxEl.scrollHeight;
        if (bodyPosition === 'static' && isPassUrl) {
          liveChatEl.style.transform = `scale(0.85) translateY(-${boxHeight + 35}px)`;
        } else {
          liveChatEl.style.transform = "";
        }
      } else {
        liveChatEl.style.transform = ""
      }
    } else {
      liveChatEl.style.transform = "";
    }

    return () => {
      liveChatEl.style.transform = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, windowWidth, seenList, location.pathname]);

  return (
    <div className={mapMultiClassnames("m-viewed-product", modifiers)}>
      <div
        className={joinClassnames(
          "expaned",
          !isPassUrl ? "hidden" : !toggle ? "hidden" : "",
        )}
      >
        <div className="m-viewed-product-button">
          {isPassUrl && (
            <span
              className="m-viewed-product-button-box"
              onClick={toggleOnclick}
            >
              {toggle ? (
                <span className="m-viewed-product-button-text">
                  {" "}
                  Ẩn sản phẩm đã xem
                </span>
              ) : (
                <span className="m-viewed-product-button-text-icon">
                  Hiện sản phẩm đã xem
                </span>
              )}
            </span>
          )}
        </div>
        <div className="m-viewed-product-box" ref={boxRef} id="m-viewed-product-box">
          <div className="m-viewed-product-content">
            <ScrollTop />
            {contact
              &&
              (contact[SystemEnum.hotline] || contact[SystemEnum.fbPageId])
              && (
                <ChatGroup
                  hotline={contact[SystemEnum.hotline]}
                  fbPageId={contact[SystemEnum.fbPageId]}
                  fbPageColor={contact[SystemEnum.fbColor]}
                />
              )}

            <div className="m-viewed-product-content-inner">
              {loading ? (
                <Loading />
              ) : seenList.length > 0 ? (
                <Carousel
                  title={"Sản phẩm đã xem"}
                  breakpoints={VIEWED_PRODUCT(4)}
                  id={`viewed-product-${CAROUSEL_IDS.SEEN_PRODUCTS}`}
                >
                  {seenList &&
                    seenList.map(
                      product =>
                        product && (
                          <Link
                            key={`viewed-product-${product.slug}`}
                            href={`/san-pham/${product.slug}`}
                          >
                            <SwiperSlide>
                              <CarouselItem>
                                <CartBox.Item>
                                  <CartBox.Image>
                                    <Image
                                      src={`${BASE_URL}${product.thumbnail}`}
                                      alt={product.name}
                                    />
                                  </CartBox.Image>
                                  <CartBox.Info>
                                    <CartBox.Name>
                                      <Text>
                                        {product.product_type_id === 1
                                          ? "Loại multi"
                                          : product.product_type_id === 2
                                            ? "Máy lọc không khí"
                                            : product.product_type_id === 3
                                              ? "Loại 2 dàn rời"
                                              : "Sky air"}
                                      </Text>
                                      {product.product_type_id === 3 ||
                                        product.product_type_id === 4 ||
                                        product.product_parent_type_id === 4 ? (
                                        compareList &&
                                          compareList.find(
                                            c => c && c.id === product.id,
                                          ) ? (
                                          <Button
                                            role="button"
                                            aria-label="Compare Button"
                                            aria-roledescription="compare button"
                                            variant={"dark"}
                                            modifiers={["xsmall", "rounded"]}
                                            onClick={(e: any) =>
                                              handleUnCompare(e, product)
                                            }
                                            className="compare-button selected"
                                            dataAttr="So sánh"
                                          />
                                        ) : (
                                          <Button
                                            role="button"
                                            aria-label="Compare Button"
                                            aria-roledescription="compare button"
                                            element="secondary"
                                            modifiers={[
                                              "xsmall",
                                              "rounded",
                                              "black",
                                            ]}
                                            onClick={(e: any) =>
                                              handleCompare(e, product)
                                            }
                                            className="compare-button"
                                            dataAttr="So sánh"
                                          />
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </CartBox.Name>
                                    <CartBox.Model>{product.model}</CartBox.Model>
                                    <CartBox.Price>
                                      <CartBox.Label>Giá</CartBox.Label>
                                      <CartBox.Val>
                                        <span>
                                          {window.formatMoney(
                                            Math.round(product.price / 1000) *
                                            1000,
                                          )}
                                        </span>
                                      </CartBox.Val>
                                    </CartBox.Price>
                                  </CartBox.Info>
                                </CartBox.Item>
                              </CarouselItem>
                            </SwiperSlide>
                          </Link>
                        ),
                    )}
                </Carousel>
              ) : (
                <Text weight="bold" modifiers={["center"]}>
                  Không có sản phẩm đã xem
                </Text>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewedProduct;
